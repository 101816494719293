(function () {
    'use strict';

    angular
      .module('app.services')
      .controller('ServicesListController', ServicesListController);

    ServicesListController.$inject=['$scope', 'ServicesService', 'uiGridConstants', 'uiGridGroupingConstants'];

    function ServicesListController($scope, ServicesService, uiGridConstants, uiGridGroupingConstants) {
        var vm = this;

        vm.service = ServicesService.getservices();
        vm.ServicesList = {
            paginationPageSizes: [10, 25, 50, 75],
            paginationPageSize: 10,
            data: vm.service,
            rowHeight: 39,
            enableVerticalScrollbar: 2,
            enableHorizontalScrollbar: 2,
            enableGroupHeaderSelection: true,
            columnDefs: [
                { name: 'id', displayName: 'Detay', cellTemplate: '<div class="ui-grid-cell-contents"><a href="/services/{{COL_FIELD}}"><i class="material-icons md-18 mdc-text-grey-600">zoom_in</i></a></div>' },
                { name: 'Adi' },
                { name: 'Kodu' }
            ]
        };

        activate();

        function activate() {
        }
    }
})();
