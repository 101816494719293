(function() {
  "use strict";

  angular.module("app.banks").run(appRun);

  appRun.$inject = ["routerHelper"];

  function appRun(routerHelper) {
    routerHelper.configureStates(getStates());
  }

  function getStates() {
    return [
      {
        state: "banks",
        config: {
          url: "/banks",
          title: "Ödeme Yöntemleri",
          abstract: true,
          templateUrl: "app/banks/banks.html"
        }
      },
      {
        state: "banks.list",
        config: {
          templateUrl: "app/banks/banks.list.html",
          controller: "BanksListController",
          controllerAs: "vm",
          url: "/list",
          title: "Ödeme Yöntemleri",
          settings: {
            nav: 10,
            name: "banks.list",
            topName: "system",
            content: "Ödeme Yöntemleri",
            resource: "menu.banks.list"
          }
        }
      }
    ];
  }
})();
