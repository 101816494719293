(function () {
    "use strict";

    angular.module("app.resources").controller("ResourcesController", Controller);

    Controller.$inject = [
        "$q",
        "$location",
        "NgTableParams",
        "ResourcesService",
        "GeneralService",
        "$uibModal",
        "$uibModalStack",
        "Settings"
    ];

    function Controller($q, $location, NgTableParams, ResourcesService, GeneralService, $uibModal, $uibModalStack, Settings) {
        var vm = this;

        activate();

        function activate() {
            vm.firstLoad = true;
            vm.tableParams = new NgTableParams(angular.extend({
                page: 1,
                count: 15
            }, $location.search()), {
                getData: function (params) {
                    if (!vm.firstLoad) {
                        $location.search(params.url());
                    }
                    vm.firstLoad = false;
                    var filterData2 = params.filter();
                    var filterData = {};
                    filterData.PageIndex = params.page();
                    filterData.PageSize = params.count();
                    filterData.SortProperty = Object.keys(params.sorting())[0];
                    filterData.SortType = params.sorting()[Object.keys(params.sorting())[0]];
                    var keys = Object.keys(filterData2);
                    for (var i = 0; i < keys.length; i++) {
                        filterData[keys[i]] = filterData2[keys[i]];
                    }
                    return ResourcesService.getAll(filterData).then(function (data) {
                        params.total(data.FilteredCount);
                        return data.Data;
                    });
                }
            });

            vm.tinymceOptions = {
                image_advtab: true,
                image_dimensions: false,
                image_title: true,
                relative_urls: false,
                forced_root_block: "",
                height: "100",
                fontsize_formats: "2px 4px 6px 8px 10px 12px 14px 18px 24px 36px",
                lineheight_formats: "2px 4px 6px 8px 10px 12px 14px 18px 24px 36px",
                images_upload_credentials: true,
                plugins: "image code imagetools advlist autolink lists link image charmap print preview hr anchor pagebreak searchreplace wordcount visualblocks visualchars code fullscreen insertdatetime media nonbreaking save table contextmenu directionality emoticons template paste textcolor colorpicker textpattern imagetools codesample toc lineheight",
                toolbar1: " styleselect sizeselect fontselect fontsizeselect lineheightselect  | bold italic strikethrough forecolor backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image media codesample",
                content_css: [globalSettings.WebServer + "/themes/" + globalSettings.ThemeName + "/dist/css/site.css"],
                entity_encoding: "raw",
                file_picker_callback: function (cb, value, meta) {
                    this.settings.activateFileManager(cb, value, meta);
                },
                entity_encoding: "raw",
                setup: function (ed) {
                    if (
                        $("#" + ed.id)
                        .closest("fieldset")
                        .prop("disabled")
                    ) {
                        ed.settings.readonly = true;
                    }
                }
            };
            var $uibModalInstance;
            vm.tinymceOptions.activateFileManager = function (cb) {
                vm.tinycb = cb;

                $uibModalInstance = $uibModal.open({
                    windowClass: "filemanager-modal",
                    template: '<div class="modal-body" id="modal-body-{{name}}">' +
                        "<angular-filemanager></angular-filemanager>" +
                        "</div>"
                });

                function pickItem(evt) {
                    var item = evt.detail.item;
                    vm.tinycb(Settings.MediaServer + "/" + item.path.join("/") + "/" + item.name, {
                        title: item.name
                    });
                    $uibModalInstance.dismiss();
                    window.removeEventListener("onPickItem", pickItem);
                }
                window.addEventListener("onPickItem", pickItem, false);
            };
        }

        vm.cultures = ResourcesService.getCultures().then(function (data) {
            vm.cultureList = data
                .sort(function (a, b) {
                    return a.DisplayOrder - b.DisplayOrder;
                })
                .map(function (item) {
                    return {
                        id: item.Id,
                        title: item.Description + " - " + item.Name
                    };
                });

            return vm.cultureList;
        });

        vm.cancel = cancel;
        vm.save = save;
        vm.add = add;
        vm.modalTitle = "Yeni Dil Kaynağı";
        vm.entity = {};

        ///////////////////////////////////

        vm.clearCache = function () {
            vm.loading = true;
            GeneralService.clearCache().then(function () {
                vm.loading = false;
            });
        };

        vm.saveResources = function () {
            var resourceList = [];
            for (var culture of vm.cultureList) {
                var newResource = {
                    Key: vm.entity.Key,
                    CultureId: culture.id,
                    Value: vm.entity.Values[culture.id]
                };
                var add = ResourcesService.add(newResource);
                resourceList.push(add);
            }
            $q.all(resourceList).then(function () {
                vm.tableParams.reload();
                vm.modalCancel();
            });
        };

        function cancel(row, rowForm) {
            vm.tableParams.reload();
        }

        function save(row, rowForm) {
            if (row.Id) {
                row.Culture = null;
                ResourcesService.edit(row).then(function (data) {
                    vm.tableParams.reload();
                });
            }
        }

        function add() {
            vm.openModal();
        }

        // Modal
        vm.modalInstance = null;
        vm.openModal = function () {
            vm.modalInstance = $uibModal.open({
                templateUrl: "ModalContent.html",
                controller: "ResourcesController",
                controllerAs: "vm",
                windowClass: "right",
                size: "lg"
            });
        };

        vm.modalCancel = function () {
            $uibModalStack.dismissAll();
        };
    }
})();