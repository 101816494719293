(function () {
    'use strict';

    angular
        .module('app.ggIntegration')
        .controller('GGEditMatchCategoryFilterValueController', GGEditMatchCategoryFilterValueController);

    GGEditMatchCategoryFilterValueController.$inject = ['$scope', '$stateParams', '$location', 'GGIntegrationService', 'NgTableParams'];

    function GGEditMatchCategoryFilterValueController($scope, $stateParams, $location, GGIntegrationService, NgTableParams) {
        /* jshint validthis:true */
        var vm = this;

        vm.settings = globalSettings;
        activate();

        function activate() {
            vm.firstLoad = true;
            vm.tableParams = new NgTableParams(
                angular.extend({ page: 1, count: 10 }, $location.search()),
                {
                    getData: function () {
                        return GGIntegrationService.gggetMatchCategoryFilter($stateParams.Id)
                            .then(function (data) {
                                console.log("datavalue", data);
                                return GGIntegrationService.getCategoryFilterValue(data.MatchCategory.GGCategoryCode, data.IntegrationCategoryAttrValueName)
                                    .then(function (result) {
                                        console.log("resultvalue", result);
                                        return result;
                                    });
                            });
                    }
                });

            GGIntegrationService.gggetMatchCategoryFilter($stateParams.Id)
                .then(function (data) {
                    GGIntegrationService.getOptionValue(data.OptionId).then(function (result) {
                        console.log("dataoptionvalue", result);
                        vm.entity = result;
                        vm.entity.refresh = true;
                        vm.loading = false;
                    });
                });


            vm.MatchCategoryFilterId = $stateParams.Id;
        }

        vm.selectFilterValue = function (filter, option) {
            vm.loading = true;
            console.log("filter", filter, option);
            vm.catAttrValue = {};
            vm.catAttrValue.OptionValueId = option;
            vm.catAttrValue.MatchCategoryFilterId = $stateParams.Id;
            vm.catAttrValue.IntegrationCategoryAttrValueId = filter.Id;
            vm.catAttrValue.IntegrationCategoryAttrValueName = filter.Name;
            console.log("catAttrValue", vm.catAttrValue);
            GGIntegrationService.ggeditMatchCategoryFilterValue(vm.catAttrValue).then(function (data) {
                vm.loading = false;
                $location.path('/ggIntegration/editmatchcategoryfiltervalue/' + data.MatchCategoryFilterId);
            });
        };

    }
})();