(function () {
    'use strict';

    angular
        .module('app.deliveries')
        .factory('DeliveriesService', DeliveriesService);

    DeliveriesService.$inject = ['config', '$http', '$q', 'ngAuthSettings', 'logger', '$stateParams', 'CustomerAddressService'];

    function DeliveriesService(config, $http, $q, ngAuthSettings, logger, $stateParams, CustomerAddressService) {
        var service = {
            getDeliveries: getDeliveries,
            getSalesOrders: getSalesOrders,
            getDelivery: getDelivery,
            addDelivery: addDelivery,
            removeDelivery: removeDelivery,
            removeDeliveryMedia: removeDeliveryMedia,
            editDelivery: editDelivery,
            getDeliveryFields: getDeliveryFields,
            getUndeliveriedProducts: getUndeliveriedProducts,
            getUndeliveriedOrder: getUndeliveriedOrder,
            getCustomer: getCustomer
        };

        var serviceBase = ngAuthSettings.apiServiceBaseUri;

        var deliveryFields = [
            {
                key: 'OrderCode',
                type: 'horizontalInput',
                templateOptions: {
                    disabled: 'disabled',
                    type: 'text',
                    label: 'Sipariş Kodu'
                }
            },
            {
                key: 'Code',
                type: 'horizontalInput',
                templateOptions: {
                    disabled: 'disabled',
                    type: 'text',
                    label: 'Teslimat Kodu'
                }
            },
            {
                key: 'Description',
                type: 'horizontalTextarea',
                templateOptions: {
                    type: 'text',
                    label: 'Teslimat Açıklama'
                }
            },
            {
                key: 'AddressId',
                type: 'horizontalSelect',
                templateOptions: {
                    required: true,
                    label: 'Müşteri Adresi',
                    options: []
                },
                controller: ['$scope', function ($scope) {
                    $scope.$watch('model.AddressList', function (newValue, oldValue) {
                        if (newValue) {
                            $scope.model.Addresses = $scope.model.AddressList;
                            $scope.to.options = $scope.model.AddressList.map(function (select) {
                                return {
                                    Id: select.Id,
                                    Name: select.Name + "," + select.Description
                                };
                            });
                        }
                    }, true);
                }]
            }

            //{
            //    key: 'Location',
            //    type: 'horizontalTag',
            //    templateOptions: {
            //        label: 'Lokasyon',
            //        valueProperty: 'Name'
            //    }
            //}

            //{
            //    key: "Konum",
            //    type: "horizontalSelectMap",
            //    templateOptions: {
            //        label: 'Konum'
            //    }
            //},
            //{
            //    key: "Kat",
            //    type: "horizontalInput",
            //    templateOptions: {
            //        placeholder: 'Kat',
            //        type: 'text',
            //        label: 'Kat',
            //    }
            //},
            //{
            //    key: 'Bölüm',
            //    type: 'horizontalInput',
            //    templateOptions: {
            //        placeholder: 'Bölüm',
            //        type: 'text',
            //        label: 'Bölüm',

            //    }
            //},
            //{
            //    key: 'Alan',
            //    type: 'horizontalInput',
            //    templateOptions: {
            //        placeholder: 'Alan',
            //        type: 'text',
            //        label: 'Alan',

            //    }
            //},
            //{
            //    key: 'FileUpload',
            //    type: 'horizontalFileUpload',
            //    templateOptions: {
            //        type: 'file',
            //        label: 'Resimler',
            //        required: true,
            //        multiple: true
            //    }
            //}
        ];

        return service;

        function getSalesOrders(CustomerId) {
            var deferred = $q.defer();

            $http.get(serviceBase + 'api/SalesOrder/Customer/' + CustomerId)
                .then(getSalesOrdersComplete)
                .catch(getSalesOrdersFailed);

            function getSalesOrdersComplete(response) {
                deferred.resolve(response.data);
            }

            function getSalesOrdersFailed(error) {
                deferred.reject(error);
            }

            return deferred.promise;
        }

        function getCustomer(CustomerId) {
            var deferred = $q.defer();

            $http.get(serviceBase + 'api/Delivery/Customer/' + CustomerId)
                .then(getCustomerComplete)
                .catch(getCustomerFailed);

            function getCustomerComplete(response) {
                deferred.resolve(response.data);
            }

            function getCustomerFailed(error) {
                deferred.reject(error);
            }

            return deferred.promise;
        }

        function getUndeliveriedProducts(CustomerId) {
            var deferred = $q.defer();

            $http.get(serviceBase + 'api/Delivery/Customer/' + CustomerId + "/UndeliveriedProducts")
                .then(getUndeliveriedProductsComplete)
                .catch(getUndeliveriedProductsFailed);

            function getUndeliveriedProductsComplete(response) {
                deferred.resolve(response.data);
            }

            function getUndeliveriedProductsFailed(error) {
                deferred.reject(error);
            }

            return deferred.promise;
        }

        function getUndeliveriedOrder(CustomerId) {
            var deferred = $q.defer();

            $http.get(serviceBase + 'api/Delivery/Customer/' + CustomerId + "/UndeliveriedOrder")
                .then(getUndeliveriedOrderComplete)
                .catch(getUndeliveriedOrderFailed);

            function getUndeliveriedOrderComplete(response) {
                deferred.resolve(response.data);
            }

            function getUndeliveriedOrderFailed(error) {
                deferred.reject(error);
            }

            return deferred.promise;
        }

        function getDeliveries(filterParams) {
            var deferred = $q.defer();

            $http.get(serviceBase + 'api/Delivery/', {
                params: filterParams
            })
                .then(getDeliveriesComplete)
                .catch(getDeliveriesFailed);

            function getDeliveriesComplete(response) {
                deferred.resolve(response.data);
            }

            function getDeliveriesFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function getDelivery(id) {
            var deferred = $q.defer();

            $http.get(serviceBase + 'api/Delivery/' + id)
                .then(getDeliveryComplete)
                .catch(getDeliveryFailed);

            function getDeliveryComplete(response) {
                deferred.resolve(response.data);
            }

            function getDeliveryFailed(error) {
                logger.error('getDelivery Failed ' + error.data);
                deferred.reject(error);
            }

            return deferred.promise;
        }

        function getDeliveryFields() {
            return deliveryFields;
        }

        function addDelivery(createdelivery) {
            var deferred = $q.defer();
            $http.post('api/Delivery', createdelivery)
                .then(addDeliveryComplete)
                .catch(addDeliveryFailed);

            function addDeliveryComplete(response) {
                deferred.resolve(response.data);
            }

            function addDeliveryFailed(error) {
                logger.error('addDelivery Failed ' + error.data);
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function removeDelivery(deletedelivery) {
            var deferred = $q.defer();

            $http.delete(serviceBase + 'api/Delivery/' + deletedelivery.Id)
                .then(removeDeliveryComplete)
                .catch(removeDeliveryFailed);

            function removeDeliveryComplete(response) {
                deferred.resolve(response.data);
            }

            function removeDeliveryFailed(error) {
                logger.error('removeDelivery Failed ' + error.data);
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function removeDeliveryMedia(deliveryId, MediaId) {
            var deferred = $q.defer();

            $http.delete(serviceBase + 'api/Delivery/' + deliveryId + "/Media/" + MediaId)
                .then(removeDeliveryMediaComplete)
                .catch(removeDeliveryMediaFailed);

            function removeDeliveryMediaComplete(response) {
                deferred.resolve(response.data);
            }

            function removeDeliveryMediaFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function editDelivery(editdelivery) {
            var deferred = $q.defer();

            $http.put(serviceBase + 'api/Delivery/' + editdelivery.Id, editdelivery)
                .then(editDeliveryComplete)
                .catch(editDeliveryFailed);

            function editDeliveryComplete(response) {
                deferred.resolve(response.data);
            }

            function editDeliveryFailed(error) {
                logger.error('editDelivery Failed ' + error.data);
                deferred.reject(error);
            }
            return deferred.promise;
        }
    }
})();