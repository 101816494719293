(function () {
    "use strict";

    angular.module("app.account").factory("AccountService", AccountService);

    AccountService.$inject = [
        "$rootScope",
        "$http",
        "$q",
        "localStorageService",
        "ngAuthSettings",
        "logger",
        "VendorsService",
        "ConsultantsService",
        "SharedData"
    ];

    function AccountService(
        $rootScope,
        $http,
        $q,
        localStorageService,
        ngAuthSettings,
        logger,
        VendorsService,
        ConsultantsService,
        SharedData) {
        var service = {
            saveRegistration: saveRegistration,
            login: login,
            logOut: logOut,
            fillAuthData: fillAuthData,
            authentication: authentication,
            refreshToken: refreshToken,
            obtainAccessToken: obtainAccessToken,
            getUserInfo: getUserInfo,
            getUserMenu: getUserMenu,
            getUsers: getUsers,
            getUserFields: getUserFields,
            getUser: getUser,
            addUser: addUser,
            removeUser: removeUser,
            editUser: editUser,
            addRoleToUser: addRoleToUser,
            changePassword: changePassword
        };

        var serviceBase = ngAuthSettings.apiServiceBaseUri;

        var authentication = {
            isAuth: false,
            userName: "",
            useRefreshTokens: true
        };

        var userFields = [{
                key: "Name",
                type: "floatInput",
                templateOptions: {
                    required: true,
                    type: "text",
                    label: "Adı",
                    resource: "form.name",
                    placeholder: "Adı",
                    class: "col-xs-6"
                }
            },
            {
                key: "Surname",
                type: "floatInput",
                templateOptions: {
                    type: "text",
                    label: "Soyadı",
                    resource: "form.surname",
                    placeholder: "Soyadı",
                    class: "col-xs-6"
                }
            },
            {
                key: "Email",
                type: "floatInput",
                templateOptions: {
                    required: true,
                    type: "email",
                    resource: "form.email",
                    label: "Email",
                    placeholder: "Email Giriniz.."
                }
            },
            {
                key: "Password",
                type: "floatInput",
                templateOptions: {
                    required: true,
                    type: "password",
                    label: "Şifre",
                    resource: "form.password",
                    placeholder: "Şifre Giriniz.."
                },
                hideExpression: function ($viewValue, $modelValue, scope) {
                    return scope.model.mode === "detail";
                },
                "expressionProperties": {
                    "templateOptions.required": "model.mode !== 'edit'"
                }
            },
            {
                key: "ConfirmPassword",
                type: "floatInput",
                templateOptions: {
                    required: true,
                    type: "password",
                    extras: {
                        validateOnModelChange: true
                    },
                    label: "Şifre Tekrar",
                    resource: "form.confirm-password",
                    placeholder: "Şifreyi Tekrar Giriniz.."
                },
                hideExpression: function ($viewValue, $modelValue, scope) {
                    return scope.model.mode === "detail";
                },
                validators: {
                    fieldMatch: {
                        expression: function (viewValue, modelValue, fieldScope) {
                            if (!fieldScope.model.Password) {
                                return true;
                            }
                            return modelValue === fieldScope.model.Password;
                        },
                        message: '"Şifreler eşleşmiyor."'
                    }
                },
                "expressionProperties": {
                    "templateOptions.required": "model.Password"
                }
            },
            {
                key: "Code",
                type: "floatInput",
                templateOptions: {
                    type: "text",
                    resource: "form.code",
                    label: "Code",
                    class: "col-xs-4"
                }
            },
            {
                key: "TcNo",
                type: "floatInput",
                templateOptions: {
                    type: "text",
                    label: "TC No",
                    resource: "form.customer-code",
                    mask: "99999999999",
                    class: "col-xs-8"
                }
            },
            {
                key: "PhoneNumber",
                type: "floatPhone",
                templateOptions: {
                    resource: "form.phone",
                    label: "Telefon"
                }
            },
            {
                key: 'VendorId',
                type: 'floatSelect',
                templateOptions: {
                    label: 'Satıcı',
                    resource: 'form.vendor',
                    options: [{
                        Name: '...'
                    }]
                },
                controller: ['$scope', function ($scope) {
                    VendorsService.getAll({ PageSize:-1 })
                        .then(function (response) {
                            $scope.to.options = response.Data;
                        });
                }]
            },
            {
                key: 'ConsultantId',
                type: 'floatSelect',
                templateOptions: {
                    label: 'Danışman',
                    resource: 'form.consultant',
                    options: [{
                        Name: '...'
                    }]
                },
                controller: ['$scope', function ($scope) {
                    ConsultantsService.getAll({ PageSize:-1 })
                        .then(function (response) {
                            $scope.to.options = response.Data;
                        });
                }]
            }
            //{
            //    key: 'DepartmentId',
            //    type: 'floatSelect',
            //    templateOptions: {
            //        label: 'Departman',
            //        resource: 'form.department',
            //        options: [{ Name: 'Yükleniyor...' }]
            //    },
            //    controller: ['$scope', function ($scope) {
            //        AccountGeneralService.getDepartments()
            //            .then(function (response) {
            //                $scope.to.options = response;
            //            });
            //    }]
            //},
            //{
            //    key: 'GenderId',
            //    type: 'floatSelect',
            //    templateOptions: {
            //        label: 'Cinsiyet',
            //        resource: 'form.gender',
            //        options: [{ Name: 'Yükleniyor...' }]
            //    },
            //    controller: ['$scope', function ($scope) {
            //        AccountGeneralService.getGenders().then(function (response) {
            //            $scope.to.options = response;
            //        });
            //    }]
            //},
            //{
            //    key: 'BirthDate',
            //    type: 'floatDatepicker',
            //    templateOptions: {
            //        type: 'text',
            //        resource: 'form.birth-date',
            //        label: 'Doğum Tarihi'
            //    }
            //},
            //{
            //    key: 'MaritalStatusId',
            //    type: 'floatSelect',
            //    templateOptions: {
            //        label: 'Medeni Durumu',
            //        resource: 'form.marital-status',
            //        options: [{ Name: 'Yükleniyor...' }]
            //    },
            //    controller: ['$scope', function ($scope) {
            //        AccountGeneralService.getMaritalStatus().then(function (response) {
            //            $scope.to.options = response;
            //        });
            //    }]
            //},
            //{
            //    key: 'EducationalStatusId',
            //    type: 'floatSelect',
            //    templateOptions: {
            //        label: 'Eğitim Durumu',
            //        resource: 'form.educational-status',
            //        options: [{ Name: 'Yükleniyor...' }]
            //    },
            //    controller: ['$scope', function ($scope) {
            //        AccountGeneralService.getEducationalStatus().then(function (response) {
            //            $scope.to.options = response;
            //        });
            //    }]
            //},
            //{
            //    key: 'JobId',
            //    type: 'floatSelect',
            //    templateOptions: {
            //        label: 'Meslek',
            //        resource: 'form.job',
            //        //addonLeft: {
            //        //    class: 'fa fa-black-tie'
            //        //},
            //        options: [{ Name: 'Yükleniyor...' }]
            //    },
            //    controller: ['$scope', function ($scope) {
            //        AccountGeneralService.getJobs().then(function (response) {
            //            $scope.to.options = response;
            //        });
            //    }]
            //}
        ];

        return service;

        function getUsers(filterParams) {
            var deferred = $q.defer();

            $http
                .get(serviceBase + "api/User", {
                    params: filterParams
                })
                .then(getUsersComplete)
                .catch(getUsersFailed);

            function getUsersComplete(response) {
                deferred.resolve(response.data);
            }

            function getUsersFailed(error) {
                deferred.reject(error);
            }

            return deferred.promise;
        }

        function getUser(id) {
            var deferred = $q.defer();

            $http
                .get(serviceBase + "api/User/" + id)
                .then(getUserComplete)
                .catch(getUserFailed);

            function getUserComplete(response) {
                deferred.resolve(response.data);
            }

            function getUserFailed(error) {
                logger.error("getUser Failed " + error.data);
                deferred.reject(error);
            }

            return deferred.promise;
        }

        function getUserFields() {
            return userFields;
        }

        function addUser(createuser) {
            var deferred = $q.defer();

            $http
                .post(serviceBase + "api/User", createuser)
                .then(addUserComplete)
                .catch(addUserFailed);

            function addUserComplete(response) {
                deferred.resolve(response.data);
            }

            function addUserFailed(error) {
                logger.error("addUser Failed " + error.data);
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function addRoleToUser(UserId, SelectedRoles) {
            var deferred = $q.defer();

            $http
                .put(serviceBase + "api/User/" + UserId + "/AddRole", SelectedRoles)
                .then(addUserComplete)
                .catch(addUserFailed);

            function addUserComplete(response) {
                deferred.resolve(response.data);
            }

            function addUserFailed(error) {
                logger.error("addRoleToUser Failed " + error.data);
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function removeUser(deleteuser) {
            var deferred = $q.defer();

            var userid = deleteuser.Id;
            $http
                .delete(serviceBase + "api/User/" + userid)
                .then(removeUserComplete)
                .catch(removeUserFailed);

            function removeUserComplete(response) {
                deferred.resolve(response.data);
            }

            function removeUserFailed(error) {
                logger.error("removeUser Failed " + error.data);
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function editUser(edituser) {
            var deferred = $q.defer();

            $http
                .put(serviceBase + "api/User/" + edituser.Id, edituser)
                .then(editUserComplete)
                .catch(editUserFailed);

            function editUserComplete(response) {
                deferred.resolve(response.data);
            }

            function editUserFailed(error) {
                logger.error("editUser Failed " + error.data);
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function saveRegistration(registration) {
            var deferred = $q.defer();

            logOut();

            $http
                .post(serviceBase + "api/account/register", registration)
                .then(saveRegistrationComplete)
                .catch(saveRegistrationFailed);

            function saveRegistrationComplete(response) {
                deferred.resolve(response.data);
            }

            function saveRegistrationFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function login(loginData) {
            var data =
                "client_id=angular.client&client_secret=secret&grant_type=password&username=" +
                loginData.Email +
                "&password=" +
                loginData.Password;

            if (loginData.useRefreshTokens) {
                data = data + "&client_id=" + ngAuthSettings.clientId;
            }

            var deferred = $q.defer();

            $http
                .post(serviceBase + "connect/token", data, {
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded"
                    }
                })
                .then(loginComplete)
                .catch(loginFailed);

            function loginComplete(response) {
                if (loginData.useRefreshTokens) {
                    let userData = {
                        token: response.data.access_token,
                        userName: loginData.userName,
                        refreshToken: response.data.refresh_token,
                        useRefreshTokens: true
                    };
                    localStorageService.set("authorizationData", userData);
                } else {
                    let userData = {
                        token: response.data.access_token,
                        userName: loginData.userName,
                        refreshToken: "",
                        useRefreshTokens: false
                    };
                    localStorageService.set("authorizationData", userData);
                }
                authentication.isAuth = true;
                authentication.userName = loginData.userName;
                authentication.useRefreshTokens = loginData.useRefreshTokens;

                deferred.resolve(response.data);
            }

            function loginFailed(error) {
                logOut();
                logger.error("XHR Failed " + error);
                deferred.reject(error);
            }

            return deferred.promise;
        }

        function logOut() {
            localStorageService.remove("authorizationData");
            SharedData.UserInfo = null;
            authentication.isAuth = false;
            authentication.userName = "";
            authentication.useRefreshTokens = false;
        }

        function fillAuthData() {
            var authData = localStorageService.get("authorizationData");
            if (authData) {
                authentication.isAuth = true;
                authentication.userName = authData.userName;
                authentication.useRefreshTokens = authData.useRefreshTokens;
            }
        }

        function refreshToken() {
            var deferred = $q.defer();

            var authData = localStorageService.get("authorizationData");

            if (authData && authData.useRefreshTokens) {
                var data =
                    "grant_type=refresh_token&client_secret=secret&refresh_token=" +
                    authData.refreshToken +
                    "&client_id=" +
                    ngAuthSettings.clientId;

                localStorageService.remove("authorizationData");

                $http
                    .post(serviceBase + "connect/token", data, {
                        headers: {
                            "Content-Type": "application/x-www-form-urlencoded"
                        }
                    })
                    .then(refreshTokenSuccess)
                    .catch(refreshTokenError);
            } else {
                deferred.reject();
            }

            function refreshTokenSuccess(response) {
                var userData = {
                    token: response.data.access_token,
                    userName: response.data.userName,
                    refreshToken: response.data.refresh_token,
                    useRefreshTokens: true
                };
                localStorageService.set("authorizationData", userData);
                deferred.resolve(response.data);
            }

            function refreshTokenError(response) {
                logOut();
                deferred.reject(response);
            }

            return deferred.promise;
        }

        function obtainAccessToken() {
            var deferred = $q.defer();

            $http
                .get(serviceBase + "api/account/ObtainLocalAccessToken", {
                    params: {
                        provider: externalData.provider,
                        externalAccessToken: externalData.externalAccessToken
                    }
                })
                .then(obtainAccessTokenComplete)
                .catch(obtainAccessTokenFailed);

            function obtainAccessTokenComplete(response) {
                var userData = {
                    token: response.data.access_token,
                    userName: response.data.userName,
                    refreshToken: "",
                    useRefreshTokens: false
                };
                localStorageService.set("authorizationData", userData);

                authentication.isAuth = true;
                authentication.userName = response.data.userName;
                authentication.useRefreshTokens = false;

                deferred.resolve(response.data);
            }

            function obtainAccessTokenFailed(error) {
                logger.error("XHR Failed " + error.data);
                logOut();
                deferred.reject(error);
            }

            return deferred.promise;
        }

        function getUserInfo() {
            var deferred = $q.defer();

            $http
                .get(serviceBase + "api/account/UserInfo")
                .then(getUserInfoComplete)
                .catch(getUserInfoFailed);

            function getUserInfoComplete(response) {
                $rootScope.$broadcast("loggedin");
                deferred.resolve(response.data);
            }

            function getUserInfoFailed(error) {
                logOut();
                deferred.reject(error);
            }

            return deferred.promise;
        }

        function getUserMenu() {
            var deferred = $q.defer();

            $http
                .get(serviceBase + "api/account/UserMenu")
                .then(getUserInfoComplete)
                .catch(getUserInfoFailed);

            function getUserInfoComplete(response) {
                deferred.resolve(response.data);
            }

            function getUserInfoFailed(error) {
                logOut();
                deferred.reject(error);
            }

            return deferred.promise;
        }

        function addAuthentication() {
            var deferred = $q.defer();

            $http
                .post(serviceBase + "api/User/Authentication")
                .then(addAuthenticationComplete)
                .catch(addAuthenticationFailed);

            function addAuthenticationComplete(response) {
                deferred.resolve(response.data);
            }

            function addAuthenticationFailed(error) {
                deferred.reject(error);
            }

            return deferred.promise;
        }

        function changePassword(passwordModel) {
            var deferred = $q.defer();

            $http
                .post(serviceBase + "api/account/ChangePassword", passwordModel)
                .then(changePasswordComplete)
                .catch(changePasswordFailed);

            function changePasswordComplete(response) {
                deferred.resolve(response.data);
            }

            function changePasswordFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }
    }
})();