(function () {
    'use strict';

    angular.module('app.simplemailing', [
        // Angular modules 
        'ngRoute',

        // Custom modules 
        'treeControl'
        // 3rd Party Modules
        
    ]);
})();