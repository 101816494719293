(function () {
    'use strict';

    angular.module('app.salespoints')
        .run(appRun);

    appRun.$inject = ['routerHelper'];

    function appRun(routerHelper) {
        routerHelper.configureStates(getStates());
    }

    function getStates() {
        return [
            {
                state: 'salespoints',
                config: {
                    url: '/salespoints',
                    title: 'Bayiler',
                    abstract: true,
                    templateUrl: 'app/salespoints/salespoints.html'
                }
            },
            {
                state: 'salespoints.list',
                config: {
                    templateUrl: 'app/salespoints/salespoints.list.html',
                    controller: 'SalespointsListController',
                    controllerAs: 'vm',
                    url: '/list',
                    title: 'Bayi Listesi',
                    settings: {
                        nav: 33,
                        name: 'salespoints.list',
                        topName: 'sales',
                        content: 'Bayi Listesi',
                        resource: 'menu.salespoints.list'
                    }
                }
            },
            {
                state: 'salespoints.add',
                config: {
                    templateUrl: 'app/salespoints/salespoints.add.html',
                    controller: 'SalespointsAddController',
                    controllerAs: 'vm',
                    url: '/add',
                    title: 'Bayi Ekle',
                    settings: {
                        topName: 'salespoints.list',
                        resource: 'menu.salespoints.add'
                    }
                }
            },
            {
                state: 'salespoints.edit',
                config: {
                    templateUrl: 'app/salespoints/salespoints.edit.html',
                    controller: 'SalespointsEditController',
                    controllerAs: 'vm',
                    url: '/edit/:SalespointId',
                    title: 'Bayi Düzenle',
                    settings: {
                        topName: 'salespoints.list',
                        resource: 'menu.salespoints.edit'
                    }
                }
            },
            {
                state: 'salespoints.detail',
                config: {
                    templateUrl: 'app/salespoints/salespoints.detail.html',
                    controller: 'SalespointsDetailController',
                    controllerAs: 'vm',
                    url: '/detail/:SalespointId',
                    title: 'Bayi Detay',
                    settings: {
                        topName: 'salespoints.list',
                        resource: 'menu.salespoints.detail'
                    }
                }
            }
        ];
    }
})();