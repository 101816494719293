(function () {
    'use strict';

    angular
        .module('app.orders')
        .controller('OrdersAddController', OrdersAddController);

    OrdersAddController.$inject = ['$scope', '$stateParams', '$filter', '$location', 'logger', 'CustomersService', 'OrdersService', 'ProductsService', 'uiGridConstants', 'uiGridGroupingConstants'];

    function OrdersAddController($scope, $stateParams, $filter, $location, logger, CustomersService, OrdersService, ProductsService, uiGridConstants, uiGridGroupingConstants) {
        var vm = this;

        activate();

        function activate() {
            var h = angular.element(window).height() - 40 - 40 - 30 - 20;
            angular.element('#customer-info').css("min-height", h);
            angular.element('#order-add').css("min-height", h);
            angular.element('#customer-search').css("min-height", h);

            vm.newOrder = {
                Code: 'SP478562',
                CustomerId: $stateParams.UserId,
                OrderProduct: [{
                    Quantity: 1,
                    UnitPrice: 0,
                    Tax: 0.18
                }]
            };

            logger.showToasts = true;

            vm.orderFields = OrdersService.getOrderFields();

            if ($stateParams.UserId) {
                CustomersService.getCustomer($stateParams.UserId).then(function (data) {
                    vm.customer = data;
                });
            }
        }

        vm.addOrderProduct = function () {
            var newProduct = {
                Quantity: 1,
                UnitPrice: 0,
                Tax: 0.18
            };
            vm.newOrder.OrderProduct.push(newProduct);
        };

        vm.removeOrderProduct = function (orderProduct) {
            var index = vm.newOrder.OrderProduct.indexOf(orderProduct);
            vm.newOrder.OrderProduct.splice(index, 1);
        };

        vm.saveOrder = function () {
            if (vm.form.$valid) {
                vm.loading = true;
                OrdersService.addOrder(vm.newOrder).then(function (data) {
                    vm.loading = false;
                    $location.path('/orders/' + data.Id);
                },
                    function (data) {
                        vm.loading = false;
                        logger.error(data.message + " - " + data.detailedMessage);
                    });
            }
        };

        vm.getProduct = function (val) {
            return ProductsService.getProducts({ Name: val }).then(function (response) {
                return response.Data;
            });
        };

        vm.setProduct = function ($item, orderProduct) {
            orderProduct.ProductId = $item.Id;
            orderProduct.UnitPrice = $item.Price;
        };

        vm.getCustomer = function (val) {
            return CustomersService.getCustomers({ Name: val }).then(function (response) {
                return response.Data;
            });
        };

        vm.setCustomer = function ($item) {
            if ($item.Id) {
                CustomersService.getCustomer($item.Id).then(function (data) {
                    vm.customer = data;
                    vm.newOrder.CustomerId = data.Id;
                });
            }
        };

        vm.getSubTotal = function () {
            var total = 0;
            for (var i = 0; i < vm.newOrder.OrderProduct.length; i++) {
                var product = vm.newOrder.OrderProduct[i];
                total += product.Quantity * product.UnitPrice;
            }
            return total;
        };

        vm.getTaxTotal = function () {
            var total = 0;
            for (var i = 0; i < vm.newOrder.OrderProduct.length; i++) {
                var product = vm.newOrder.OrderProduct[i];
                total += product.Quantity * product.UnitPrice * product.Tax;
            }
            return total;
        };

        vm.getTotal = function () {
            var total = 0;
            for (var i = 0; i < vm.newOrder.OrderProduct.length; i++) {
                var product = vm.newOrder.OrderProduct[i];
                total += product.Quantity * product.UnitPrice * (parseFloat(product.Tax) + 1.00);
            }
            return total;
        };

        vm.getProductTotal = function (product) {
            return product.Quantity * product.UnitPrice * (parseFloat(product.Tax) + 1.00);
        };

        vm.getProductTax = function (product, tax) {
            return $filter('currency')(product.Quantity * product.UnitPrice * parseFloat(tax));
        };
    }
})();