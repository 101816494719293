(function () {
    'use strict';

    angular
        .module('app.n11Integration')
        .controller('AddTemplateController', AddTemplateController);

    AddTemplateController.$inject = ['$scope', '$stateParams','$location', 'N11IntegrationService', 'NgTableParams'];

    function AddTemplateController($scope, $stateParams,$location, N11IntegrationService, NgTableParams) {
        /* jshint validthis:true */
        var vm = this;

        vm.settings = globalSettings;
        activate();

        function activate() {
            vm.fields = N11IntegrationService.getTemplateFields();
        }

        vm.addTemplate = function () {
            if (vm.form.$valid) {
                vm.loading = true;
                N11IntegrationService.addTemplate(vm.entity).then(function (data) {
                    vm.loading = false;
                    $location.path('/n11Integration/templatelist');
                },
                function (data) {
                    vm.loading = false;
                });
                vm.loading = false;
            }
        };
    }
})();