(function () {
    'use strict';

    angular
        .module('app.tags')
        .controller('PostTagsEditController', PostTagsEditController);

    PostTagsEditController.$inject = ['$location', '$stateParams', 'BlogPostTagService'];

    function PostTagsEditController($location, $stateParams, BlogPostTagService) {

        var vm = this;

        vm.loading = true;
        vm.title = '';

        BlogPostTagService.get($stateParams.Id).then(function (data) {
            vm.entity = data;
            vm.loading = false;
        });

        vm.fields = BlogPostTagService.getFields();

        vm.edit = function () {
            if (vm.form.$valid) {
                vm.loading = true;
                vm.entity.Media = null;
                BlogPostTagService.edit(vm.entity).then(function () {
                    vm.loading = false;
                    $location.path('/blogposttag/detail/' + vm.entity.Id);
                });
            }
        };

        vm.cancel = function () {
            $location.path('/blogposttag/detail/' + vm.entity.Id);
        };
    }
})();