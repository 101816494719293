(function () {
    'use strict';

    angular
        .module('app.reviews')
        .controller('ReviewsAddController', AddController);

    AddController.$inject = ['$location', 'ReviewsService'];

    function AddController($location, Service) {
        /* jshint validthis:true */
        var vm = this;

        vm.entity = {};

        vm.fields = Service.getFields();

        vm.create = function () {
            if (vm.form.$valid) {
                vm.loading = true;
                Service.add(vm.entity).then(function (data) {
                    vm.loading = false;
                    $location.path('/reviews/detail/' + data.Id);
                });
            }
        };
    }
})();