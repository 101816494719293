(function () {
    'use strict';

    angular
        .module('app.general')
        .factory('GeneralService', GeneralService);

    GeneralService.$inject = ['$http', '$q', 'ngAuthSettings', 'logger'];

    function GeneralService($http, $q, ngAuthSettings, logger) {
        var service = {
            getCountry: getCountry,
            getBrands: getBrands,
            getCultures: getCultures,
            getActiveCultures: getActiveCultures,
            getActiveContractType: getActiveContractType,
            getReviewTypes: getReviewTypes,
            getStateProvince: getStateProvince,
            getDistrict: getDistrict,
            getCity: getCity,
            clearCache: clearCache,
            shutdown: shutdown,
            sendFeedback: sendFeedback,
            optimizeAllImage: optimizeAllImage,
            getGoogleToken: getGoogleToken
        };

        var serviceBase = ngAuthSettings.apiServiceBaseUri;

        return service;

        function getCountry() {
            var deferred = $q.defer();

            $http.get(serviceBase + 'api/general/country')
                .then(getCountryComplete)
                .catch(getCountryFailed);

            function getCountryComplete(response) {
                deferred.resolve(response.data);
            }

            function getCountryFailed(error) {
                logger.error('XHR Failed ' + error);
                deferred.reject(error);
            }

            return deferred.promise;
        }

        function getBrands() {
            var deferred = $q.defer();

            $http.get(serviceBase + 'api/general/brands')
                .then(getBrandsComplete)
                .catch(getBrandsFailed);

            function getBrandsComplete(response) {
                deferred.resolve(response.data);
            }

            function getBrandsFailed(error) {
                logger.error('XHR Failed ' + error);
                deferred.reject(error);
            }

            return deferred.promise;
        }

        function getCultures() {
            var deferred = $q.defer();

            $http.get(serviceBase + 'api/general/cultures')
                .then(getCulturesComplete)
                .catch(getCulturesFailed);

            function getCulturesComplete(response) {
                deferred.resolve(response.data);
            }

            function getCulturesFailed(error) {
                logger.error('XHR Failed ' + error);
                deferred.reject(error);
            }

            return deferred.promise;
        }

        function getActiveCultures() {
            var deferred = $q.defer();

            $http.get(serviceBase + 'api/general/activecultures')
                .then(getActiveCulturesComplete)
                .catch(getActiveCulturesFailed);

            function getActiveCulturesComplete(response) {
                deferred.resolve(response.data);
            }

            function getActiveCulturesFailed(error) {
                logger.error('XHR Failed ' + error);
                deferred.reject(error);
            }

            return deferred.promise;
        }

        function getActiveContractType() {
            var deferred = $q.defer();

            $http.get(serviceBase + 'api/general/activecontracttype')
                .then(getActiveContractTypeComplete)
                .catch(getActiveContractTypeFailed);

            function getActiveContractTypeComplete(response) {
                deferred.resolve(response.data);
            }

            function getActiveContractTypeFailed(error) {
                logger.error('XHR Failed ' + error);
                deferred.reject(error);
            }

            return deferred.promise;
        }

        function getReviewTypes() {
            var deferred = $q.defer();

            $http.get(serviceBase + 'api/general/reviewtype')
                .then(getReviewTypesComplete)
                .catch(getReviewTypesFailed);

            function getReviewTypesComplete(response) {
                deferred.resolve(response.data);
            }

            function getReviewTypesFailed(error) {
                logger.error('XHR Failed ' + error);
                deferred.reject(error);
            }

            return deferred.promise;
        }

        function getStateProvince(CountryId) {
            var deferred = $q.defer();

            $http.get(serviceBase + 'api/general/stateProvince/' + CountryId)
                .then(getStateProvinceComplete)
                .catch(getStateProvinceFailed);

            function getStateProvinceComplete(response) {
                deferred.resolve(response.data);
            }

            function getStateProvinceFailed(error) {
                logger.error('XHR Failed ' + error);
                deferred.reject(error);
            }

            return deferred.promise;
        }

        function getDistrict(StateProvinceId) {
            var deferred = $q.defer();

            $http.get(serviceBase + 'api/general/district/' + StateProvinceId)
                .then(getDistrictComplete)
                .catch(getDistrictFailed);

            function getDistrictComplete(response) {
                deferred.resolve(response.data);
            }

            function getDistrictFailed(error) {
                logger.error('XHR Failed ' + error);
                deferred.reject(error);
            }

            return deferred.promise;
        }

        function getCity(DistrictId) {
            var deferred = $q.defer();

            $http.get(serviceBase + 'api/general/city/' + DistrictId)
                .then(getCityComplete)
                .catch(getCityFailed);

            function getCityComplete(response) {
                deferred.resolve(response.data);
            }

            function getCityFailed(error) {
                deferred.reject(error);
            }

            return deferred.promise;
        }

        function clearCache() {
            var deferred = $q.defer();

            $http.get(serviceBase + 'api/general/clearCache/')
                .then(getComplete)
                .catch(getFailed);

            function getComplete(response) {
                deferred.resolve(response.data);
            }

            function getFailed(error) {
                deferred.reject(error);
            }

            return deferred.promise;
        }

        function shutdown() {
            var deferred = $q.defer();

            $http.get(serviceBase + 'api/general/shutdown/')
                .then(getComplete)
                .catch(getFailed);

            function getComplete(response) {
                deferred.resolve(response.data);
            }

            function getFailed(error) {
                deferred.reject(error);
            }

            return deferred.promise;
        }

        function optimizeAllImage() {
            var deferred = $q.defer();

            $http.get(serviceBase + 'api/general/OptimizeAllImage/')
                .then(getComplete)
                .catch(getFailed);

            function getComplete(response) {
                deferred.resolve(response.data);
            }

            function getFailed(error) {
                deferred.reject(error);
            }

            return deferred.promise;
        }

        function getGoogleToken() {
            var deferred = $q.defer();

            $http.get(serviceBase + 'api/general/GoogleToken')
                .then(getComplete)
                .catch(getFailed);

            function getComplete(response) {
                deferred.resolve(response.data);
            }

            function getFailed(error) {
                logger.error('XHR Failed ' + error);
                deferred.reject(error);
            }

            return deferred.promise;
        }

        function sendFeedback(feedback) {
            var deferred = $q.defer();

            $http
                .post(serviceBase + 'api/general/SendFeedback/', feedback)
                .then(complete)
                .catch(failed);

            function complete(response) {
                deferred.resolve(response.data);
            }

            function failed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }
    }
})();