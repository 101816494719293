(function () {
    'use strict';

    angular
        .module('app.deliveries')
        .run(appRun);

    appRun.$inject = ['routerHelper'];

    function appRun(routerHelper) {
        routerHelper.configureStates(getStates());
    }

    function getStates() {
        return [
            {
                state: 'deliveries',
                config: {
                    url: '/deliveries',
                    title: 'Teslimatlar',
                    abstract: true,
                    templateUrl: 'app/deliveries/deliveries.html',
                    //settings: {
                    //    name: 'deliveries',
                    //    icon: 'fa fa-truck',
                    //    nav: 6,
                    //    content: 'Teslimat'
                    //}
                }
            },
            {
                state: 'deliveries.list',
                config: {
                    templateUrl: 'app/deliveries/deliveries.list.html',
                    controller: 'DeliveriesListController',
                    controllerAs: 'vm',
                    url: '/list',
                    title: 'Teslimat Listesi',
                    settings: {
                        nav: 6,
                        name: 'deliveries.list',
                        topName: 'deliveries',
                        content: 'Teslimat Listesi'
                    }
                }
            },
            {
                state: 'deliveries.delete',
                config: {
                    templateUrl: 'app/deliveries/deliveries.delete.html',
                    controller: 'DeliveriesDeleteController',
                    controllerAs: 'vm',
                    url: '/delete/:id',
                    title: 'Teslimat Sil',
                }
            },
            {
                state: 'deliveries.edit',
                config: {
                    templateUrl: 'app/deliveries/deliveries.edit.html',
                    controller: 'DeliveriesEditController',
                    controllerAs: 'vm',
                    url: '/edit/:id',
                    title: 'Teslimat Düzenle',
                }
            },
            {
                state: 'deliveries.edit.address',
                config: {
                    templateUrl: 'app/deliveries/deliveries.address.html',
                    url: '/address',
                    title: 'Müşteri Adresleri',
                    controller: 'DeliveriesAddressController',
                    controllerAs: 'vm'
                }
            },
            {
                state: 'deliveries.detail',
                config: {
                    templateUrl: 'app/deliveries/deliveries.detail.html',
                    controller: 'DeliveriesDetailController',
                    controllerAs: 'vm',
                    url: '/detail/:id',
                    title: 'Teslimat Detayı',
                }
            },
            {
                state: 'deliveries.add',
                config: {
                    templateUrl: 'app/deliveries/deliveries.add.html',
                    controller: 'DeliveriesAddController',
                    controllerAs: 'vm',
                    url: '/add/:CustomerId',
                    title: 'Teslimat Ekle',
                }
            },
            {
                state: 'deliveries.add.address',
                config: {
                    templateUrl: 'app/deliveries/deliveries.address.html',
                    url: '/address',
                    title: 'Müşteri Adresleri',
                    controller: 'DeliveriesAddressController',
                    controllerAs: 'vm'
                }
            }

        ];
    }
})();