(function () {
    'use strict';

    angular
        .module('app.excel')
        .controller('ExcelOrderExportController', ExcelOrderExportController);

    ExcelOrderExportController.$inject = ['$scope', '$stateParams', '$filter', '$location', 'logger', 'ExcelService'];

    function ExcelOrderExportController($scope, $stateParams, $filter, $location, logger, ExcelService) {
        var vm = this;

        activate();

        function activate() {
            vm.excelOrderExport = {};
        }

        vm.excelOrderExportFields = ExcelService.getExcelOrderExportFields();
        vm.excelOrderFilterFields = ExcelService.getExcelOrderFilterFields();

        vm.exportOrderExcel = function () {
            console.log("vm.exportOrderExcel", vm.exportOrderExcel);

            vm.loading = true;
            ExcelService.exportOrderFile(vm.excelOrderExport).then(function (result, a, b, c) {
                console.log('excel result', result, a, b, c);
                vm.loading = false;
            });
        };

        vm.exportOrder = function () {
            if (vm.excelOrderExport.MinDate) {
                vm.excelOrderExport.MinDate = vm.excelOrderExport.MinDate.toLocaleDateString();
            }
            if (vm.excelOrderExport.MaxDate) {
                vm.excelOrderExport.MaxDate = vm.excelOrderExport.MaxDate.toLocaleDateString();
            }

            console.log("vm.exportOrder", vm.exportOrder);
            vm.loading = true;
            setTimeout(function () {
                $("#exportOrderForm").submit();
            }, 1000);
            vm.loading = false;
        };

    }
})();

