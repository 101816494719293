(function () {
    'use strict';

    angular
        .module('app.messageTemplates')
        .factory('MessageTemplatesService', Service);

    Service.$inject = ['$http', '$q', 'ngAuthSettings', 'logger', 'config', 'Settings', 'MediaService'];

    function Service($http, $q, ngAuthSettings, logger, config, Settings, MediaService) {
        var service = {
            getAll: getAll,
            get: get,
            edit: edit,
            getFields: getFields
        };

        var serviceBase = ngAuthSettings.apiServiceBaseUri + 'api/messageTemplate/';

        var fields = [
            {
                key: 'Name',
                type: 'horizontalInput',
                templateOptions: {
                    type: 'text',
                    label: 'Adı',
                    resource: 'form.name',
                    placeholder: 'Adı',
                    readonly: true
                }
            },
            {
                key: 'AvailableVariables',
                type: 'horizontalTextarea',
                templateOptions: {
                    type: 'text',
                    label: 'Varolan Değişkenler',
                    resource: 'form.available-variables',
                    placeholder: 'Varolan Değişkenler',
                    readonly: true
                }
            },
            {
                key: 'Active',
                type: 'horizontalCheckbox',
                defaultValue: true,
                templateOptions: {
                    label: 'Yayında',
                    resource: 'form.active',
                    placeholder: 'Yayında'
                }
            },
            {
                key: 'Subject',
                type: 'horizontalInput',
                templateOptions: {
                    required: true,
                    type: 'text',
                    label: 'Başlık',
                    resource: 'form.subject',
                    placeholder: 'Başlık'
                }
            },
            {
                key: 'Body',
                type: 'horizontalTinyMCE',
                templateOptions: {
                    label: 'İçerik',
                    resource: 'form.body',
                    placeholder: 'İçerik',
                    tinymceOptions: {
                        plugins: 'image code imagetools advlist autolink lists link image charmap print preview hr anchor pagebreak searchreplace wordcount visualblocks visualchars code fullscreen insertdatetime media nonbreaking save table contextmenu directionality emoticons template paste textcolor colorpicker textpattern imagetools codesample toc lineheight',
                        toolbar1: 'undo redo | insert | styleselect | bold italic | sizeselect | bold italic | fontselect |  fontsizeselect lineheightselect | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image',
                        toolbar2: 'print preview media | forecolor backcolor emoticons | codesample',
                        image_advtab: true,
                        image_dimensions: false,
                        templates: [
                            { title: 'Test template 1', content: 'Test 1' },
                            { title: 'Test template 2', content: 'Test 2' }
                        ],
                        content_css: [
                            Settings.WebServer + '/themes/' + Settings.ThemeName + '/dist/css/mazakacore-ecommerce.css'
                        ],
                        image_title: true,
                        entity_encoding: "raw",
                        file_picker_types: 'image',
                        relative_urls: false,
                        forced_root_block: "",
                        height: "400",
                        fontsize_formats: "2px 4px 6px 8px 10px 12px 14px 18px 24px 36px",
                        lineheight_formats: "2px 4px 6px 8px 10px 12px 14px 18px 24px 36px",
                        images_upload_credentials: true,
                        entity_encoding: "raw",
                        file_picker_callback: function (cb, value, meta) {
                            var input = document.createElement('input');
                            input.setAttribute('type', 'file');
                            input.setAttribute('accept', 'image/*');
                            input.onchange = function () {
                                var file = this.files[0];
                                var id = 'blobid' + (new Date()).getTime();
                                var blobCache = tinymce.activeEditor.editorUpload.blobCache;
                                var blobInfo = blobCache.create(id, file);
                                blobCache.add(blobInfo);
                                cb(blobInfo.blobUri(), { title: file.name });
                            };
                            input.click();
                        },
                        images_upload_handler: function (blobInfo, success, failure) {
                            var formData = new FormData();
                            formData.append('File', blobInfo.blob(), blobInfo.filename());
                            MediaService.add(formData).then(function (data) {
                                var name = Settings.MediaServer + '/' + data.FileName;
                                success(name);
                            }, failure);
                        },
                        setup: function (ed) {
                            if ($('#' + ed.id).closest("fieldset").prop('disabled')) {
                                ed.settings.readonly = true;
                            }
                        }
                    }
                }
            }
        ];

        return service;

        /////////////////////////////////////////

        function getFields() {
            return fields;
        }

        function getAll(filterParams) {
            var deferred = $q.defer();

            $http.get(serviceBase, {
                params: filterParams
            })
                .then(getComplete)
                .catch(getFailed);

            function getComplete(response) {
                deferred.resolve(response.data);
            }

            function getFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function get(Id) {
            var deferred = $q.defer();

            $http.get(serviceBase + Id)
                .then(getComplete)
                .catch(getFailed);

            function getComplete(response) {
                deferred.resolve(response.data);
            }

            function getFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function edit(entity) {
            var deferred = $q.defer();

            $http.put(serviceBase + entity.Id, entity)
                .then(editComplete)
                .catch(editFailed);

            function editComplete(response) {
                deferred.resolve(response.data);
            }

            function editFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }
    }
})();