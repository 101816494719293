(function () {
    'use strict';

    angular
        .module('app.reporting')
        //.module('app.reporting', ['chart.js'])
        .controller('SummaryReportController', SummaryReportController);

    SummaryReportController.$inject = ['$scope', '$timeout', 'SummaryReportService', 'NgTableParams', '$location','OrdersService'];

    function SummaryReportController($scope, $timeout, SummaryReportService, NgTableParams, $location,OrdersService) {

        var vm = this;
        
        activate();

        function activate() {
            

            vm.refresh = function () {
                vm.loading = true;
                vm.tableParams.reload();
                vm.loading = false;
            };

            vm.firstLoad = true;
            vm.tableParams = new NgTableParams(
                angular.extend({ page: 1, count: 10 }, $location.search()),
                {
                    getData: function (params) {
                        if (!vm.firstLoad) {
                            $location.search(params.url());
                        }
                        vm.firstLoad = false;
                        var filterData2 = params.filter();
                        var filterData = {};
                        filterData.PageIndex = params.page();
                        filterData.PageSize = params.count();
                        filterData.ProductName = params.filter().Name;
                        if (params.filter().OrderDateMin) {
                            filterData.StartDate = new Date(params.filter().OrderDateMin).toLocaleDateString();
                        }
                        if (params.filter().OrderDateMax) {
                            filterData.DueDate = new Date(params.filter().OrderDateMax).toLocaleDateString();
                        }
                        filterData.SortProperty = Object.keys(params.sorting())[0];
                        filterData.SortType = params.sorting()[Object.keys(params.sorting())[0]];
                        filterData.groupType=0;
                        filterData.statusId="6fab9e8b-e3ef-4336-8f98-bba4607193d1";
                        var keys = Object.keys(filterData2);
                        for (var i = 0; i < keys.length; i++) {
                            filterData[keys[i]] = filterData2[keys[i]];
                        }
                        SummaryReportService.getSummaryReportChart(filterData).then(function (chartdata) {
                            $scope.chartdata= chartdata;

                        })

                        return SummaryReportService.getSummaryReport(filterData).then(function (data) {
                            return data;
                        });
                        
                    }
                });
            vm.OrderDateFilter = {
                OrderDateMin: { id: 'ng-table/date.html', placeholder: 'Min Tarih' },
                OrderDateMax: { id: 'ng-table/date.html', placeholder: 'Max Tarih' }
            };

            vm.Status = OrdersService.getOrderStatuses().then(function (response) {
                return response.map(function (item) {
                    return { id: item.Id, title: item.Name };
                });
            });
            vm.groupTypeList = [
                {id:0,title:'Günlük'},
                {id:1,title:'Haftalık'},
                {id:2,title:'Aylık'},
                {id:3,title:'Saatlik'}
            ];


        };
        
        $scope.labels = ["","",""];

        $scope.series = ['Hazırlanıyor', 'Kargoya verildi','Tamamlandı','İade Talebi','İptal Edildi','Hata','Ödeme Terk','Onay Bekliyor','Ödeme Bekliyor'];
        $scope.type='line';
        $scope.options= {
          title:{
            display:true,
            position:'top',
            text:'Son 3 Ay\'a Ait Satışlar'
          },
          legend: {
            display: true
          },
          aspectRatio	:2,
          responsive:true
      };

    }
})();