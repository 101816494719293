(function() {
    "use strict";

    angular.module("app.banks").controller("BanksListController", ListController);

    ListController.$inject = [
        "$scope",
        "$location",
        "BanksService",
        "$timeout",
        "RolesService",
        "NgTableParams",
        "InstallmentService"
    ];

    function ListController($scope, $location, Service, $timeout, RolesService, NgTableParams, InstallmentService) {
        /* jshint validthis:true */
        var vm = this;

        function activate() {
            vm.entity = null;
            vm.getBankList();

            vm.fields = Service.getFields();

            RolesService.getAll({ PageSize: -1, IsBase: false }).then(function(data) {
                vm.roles = data.Data;
            });
        }

        vm.regenerateTreeTable = function() {
            $timeout(function() {
                $(".bank-list")
                    .removeData("treetable")
                    .removeClass("treetable");
                $(".bank-list").treetable({ expandable: true });
                $(".bank-list").treetable("expandAll");
            }, 0);
        };

        vm.getOtherRoles = function(bank) {
            if (!vm.banks || vm.banks.length === 0) {
                return;
            }
            var subRoleIds = vm.getSubBankList(bank.Id).map(function(item) {
                return item.RoleId;
            });
            if (!vm.roles) {
                return;
            }
            var otherRoles = vm.roles.filter(function(item) {
                return !subRoleIds.includes(item.Id);
            });
            return otherRoles;
        };

        vm.copyBankToRole = function(bankId, roleId) {
            Service.copyToRole(bankId, roleId).then(function() {
                vm.getBankList();
            });
        };

        vm.getBankList = function() {
            Service.getAll({ PageSize: -1 }).then(function(data) {
                vm.banksMixed = data.Data.sort(function(a, b) {
                    if (a.Name < b.Name) {
                        return -1;
                    }
                    if (a.Name > b.Name) {
                        return 1;
                    }
                    return 0;
                });
                vm.banks = vm.getSubBankList(null);
                vm.regenerateTreeTable();
            });
        };

        vm.getSubBankList = function(TopBankId) {
            var bankList = [];
            var subBanksList = vm.banksMixed.filter(function(bank) {
                return bank.TopBankId === TopBankId;
            });
            for (var i = 0; i < subBanksList.length; i++) {
                var item = subBanksList[i];
                bankList.push(item);
                var subItems = vm.getSubBankList(item.Id);
                bankList = bankList.concat(subItems);
            }
            return bankList;
        };

        vm.prepareEdit = function(data) {
            vm.entity = null;
            vm.entity = angular.copy(data);
            vm.installmentTableParams.reload();
        };

        vm.cancel = function() {
            vm.entity = {};
        };

        vm.edit = function() {
            vm.loading = true;
            Service.edit(vm.entity)
                .then(function() {
                    vm.entity = {};
                    vm.loading = false;
                    vm.getBankList();
                })
                .catch(function() {
                    vm.entity = {};
                    vm.loading = false;
                    vm.getBankList();
                });
        };

        vm.delete = function(entity) {
            vm.loading = true;
            Service.del(entity)
                .then(function() {
                    vm.entity = {};
                    vm.loading = false;
                    vm.getBankList();
                })
                .catch(function() {
                    vm.entity = {};
                    vm.loading = false;
                    vm.getBankList();
                });
        };

        activate();

        ///////////////// Installment

        vm.installmentFirstLoad = true;
        vm.installmentTableParams = new NgTableParams(angular.extend({ page: 1, count: 10 }, $location.search()), {
            getData: function(params) {
                if (!vm.installmentFirstLoad) {
                    $location.search(params.url());
                }
                vm.installmentFirstLoad = false;
                var filterData2 = params.filter();
                var filterData = {};
                filterData.PageSize = -1;
                filterData.BankId = vm.entity.Id;
                var keys = Object.keys(filterData2);
                for (var i = 0; i < keys.length; i++) {
                    filterData[keys[i]] = filterData2[keys[i]];
                }
                return InstallmentService.getAll(filterData).then(function(data) {
                    params.total(data.FilteredCount);
                    return data.Data;
                });
            }
        });

        vm.installmentCancel = function(row, rowForm) {
            vm.installmentTableParams.reload();
        };

        vm.installmentDel = function(row) {
            if (confirm("Silmek istediğinize emin misiniz?")) {
                InstallmentService.del(row).then(function(data) {
                    vm.installmentTableParams.reload();
                });
            }
        };

        vm.installmentSave = function(row, rowForm) {
            if (rowForm.$valid) {
                if (row.Id) {
                    InstallmentService.edit(row).then(function(data) {
                        vm.installmentTableParams.reload();
                    });
                } else {
                    row.BankId = vm.entity.Id;
                    InstallmentService.add(row).then(function(data) {
                        vm.installmentTableParams.reload();
                    });
                }
            }
        };

        vm.installmentAdd = function() {
            vm.installmentTableParams.data.unshift({
                Title: "",
                Active: true,
                isEditing: true
            });
        };
    }
})();
