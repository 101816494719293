(function () {
    'use strict';

    angular
        .module('app.settings')
        .factory('EInvoiceSettingService', Service);

    Service.$inject = ['$http', '$q', 'ngAuthSettings'];

    function Service($http, $q, ngAuthSettings) {
        var service = {
            getAll: getAll,
            getInvoiceAll: getInvoiceAll,
            getAllProviders: getAllProviders,
            getEInvoiceSettings: getEInvoiceSettings,
            editInvoiceProvider: editInvoiceProvider,
            editInvoiceGlobalSett: editInvoiceGlobalSett,
            addImageByFileManager: addImageByFileManager,
            getSettingAddImageFields: getSettingAddImageFields,
            saveXsltConfig: saveXsltConfig,
            getEInvoiceTemplates: getEInvoiceTemplates,
            saveXsltDefault: saveXsltDefault,
            getDefaultProvider: getDefaultProvider
        };

        var serviceBase = ngAuthSettings.apiServiceBaseUri + 'api/einvoicesetting/';

        var settingAddImageFields = [
            {
                key: "File",
                type: "horizontalSingleMedia",
                templateOptions: {
                    label: "Fotoğraf Yükle",
                    placeholder: "Fotoğraf Yükle",
                    valueKey: "Id"
                }
            }
        ];

        var settings = null;

        return service;

        ////////////////////////////////////////

        function getAll(filterParams) {
            var deferred = $q.defer();
            $http.get(serviceBase + "all", {
                params: filterParams
            })
                .then(getComplete)
                .catch(getFailed);

            function getComplete(response) {
                deferred.resolve(response.data);
            }

            function getFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function getInvoiceAll(filterParams) {
            var deferred = $q.defer();
            $http.get(serviceBase + "all", {
                params: filterParams
            })
                .then(getComplete)
                .catch(getFailed);

            function getComplete(response) {

                function getVeriban(response) {

                    const dataReverse = response.data.Data.reverse();
                    var veribanArr = [];

                    dataReverse.map(x => {

                        let veriban = x.Key.includes('Veriban');
                        let QNB = x.Key.includes('Qnb');
                        let global = x.Key.includes('Globalnvoice');
                        if (veriban || QNB || global) {

                            veribanArr.push(x);
                            return x;
                        }
                        else {
                            return;
                        }

                    });

                    return veribanArr;
                }

                deferred.resolve(getVeriban(response));
            }

            function getFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function getAllProviders() {

            var deferred = $q.defer();
            $http.get(serviceBase + "getAllProviders")
                .then(getComplete)
                .catch(getFailed);

            function getComplete(response) {
                deferred.resolve(response.data);
            }

            function getFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function getDefaultProvider() {

            var deferred = $q.defer();
            $http.get(serviceBase + "getDefaultProvider")
                .then(getComplete)
                .catch(getFailed);

            function getComplete(response) {
                deferred.resolve(response.data);
            }

            function getFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function editInvoiceProvider(invoice) {
            var deferred = $q.defer();

            $http.put(serviceBase + "einvoiceprovider", invoice)
                .then(editComplete)
                .catch(editFailed);

            function editComplete(response) {
                deferred.resolve(response.data);
            }

            function editFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function editInvoiceGlobalSett(invoice) {

            var deferred = $q.defer();

            $http.put(serviceBase + "einvoiceglobal", invoice)
                .then(editComplete)
                .catch(editFailed);

            function editComplete(response) {
                deferred.resolve(response.data);
            }

            function editFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function getEInvoiceSettings() {
            var deferred = $q.defer();

            $http.get(serviceBase + 'EInvoiceSettings')
                .then(getSettingsComplete)
                .catch(getSettingsFailed);


            function getSettingsComplete(response) {
                settings = response;
                deferred.resolve(response.data);
            }

            function getSettingsFailed(error) {
                deferred.reject(error);
            }

            return deferred.promise;
        }

        function getEInvoiceTemplates() {
            var deferred = $q.defer();

            $http.get(serviceBase + 'EInvoiceTemplates')
                .then(getSettingsComplete)
                .catch(getSettingsFailed);


            function getSettingsComplete(response) {
                settings = response;
                deferred.resolve(response.data);
            }

            function getSettingsFailed(error) {
                deferred.reject(error);
            }

            return deferred.promise;
        }

        function addImageByFileManager() {
            var deferred = $q.defer();

            $http.post(serviceBase + 'UploadImageByFileManager')
                .then(addImageByFileManagerComplete)
                .catch(addImageByFileManagerFailed);

            function addImageByFileManagerComplete(response) {
                deferred.resolve(response.data);
            }

            function addImageByFileManagerFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function saveXsltConfig(json) {
            var deferred = $q.defer();

            $http.post(serviceBase + 'xsltconfig', JSON.stringify(json))
                .then(saveXsltConfigComplete)
                .catch(saveXsltConfigFailed);

            function saveXsltConfigComplete(response) {
                deferred.resolve(response.data);
            }

            function saveXsltConfigFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function saveXsltDefault(data) {
            var deferred = $q.defer();

            $http.post(serviceBase + 'xsltdefault', data)
                .then(saveXsltConfigComplete)
                .catch(saveXsltConfigFailed);

            function saveXsltConfigComplete(response) {
                deferred.resolve(response.data);
            }

            function saveXsltConfigFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function getSettingAddImageFields() {
            return settingAddImageFields;
        }
    }


})();