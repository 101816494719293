(function () {
    'use strict';

    angular.module('app.collections')
        .run(appRun);

    appRun.$inject = ['routerHelper'];

    function appRun(routerHelper) {
        routerHelper.configureStates(getStates());
    }

    function getStates() {
        return [
            {
                state: 'collections',
                config: {
                    url: '/collections',
                    title: 'Koleksiyonlar',
                    abstract: true,
                    templateUrl: 'app/collections/collections.html'
                }
            },
            {
                state: 'collections.list',
                config: {
                    templateUrl: 'app/collections/collections.list.html',
                    controller: 'CollectionsListController',
                    controllerAs: 'vm',
                    url: '/list',
                    title: 'Koleksiyon Listesi',
                    //settings: {
                    //    nav: 3,
                    //    name: 'collections.list',
                    //    topName: 'products',
                    //    content: 'Koleksiyon Listesi',
                    //    resource: 'menu.collections.list'
                    //}
                }
            },
            {
                state: 'collections.add',
                config: {
                    templateUrl: 'app/collections/collections.add.html',
                    controller: 'CollectionsAddController',
                    controllerAs: 'vm',
                    url: '/add',
                    title: 'Koleksiyon Ekle',
                    settings: {
                        name: 'collections.list',
                        topName: 'products',
                        content: 'Koleksiyon Ekle',
                        resource: 'menu.collections.add'
                    }
                }
            },
            {
                state: 'collections.edit',
                config: {
                    templateUrl: 'app/collections/collections.edit.html',
                    controller: 'CollectionsEditController',
                    controllerAs: 'vm',
                    url: '/edit/:CollectionId',
                    title: 'Koleksiyon Düzenle',
                    settings: {
                        name: 'collections.edit',
                        topName: 'products',
                        content: 'Koleksiyon Düzenle',
                        resource: 'menu.collections.edit'
                    }
                }
            },
            {
                state: 'collections.detail',
                config: {
                    templateUrl: 'app/collections/collections.detail.html',
                    controller: 'CollectionsDetailController',
                    controllerAs: 'vm',
                    url: '/detail/:CollectionId',
                    title: 'Koleksiyon Detay',
                    settings: {
                        name: 'collections.detail',
                        topName: 'products',
                        content: 'Koleksiyon Detay',
                        resource: 'menu.collections.detail'
                    }
                }
            }
        ];
    }
})();