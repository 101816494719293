(function () {
    'use strict';

    angular
        .module('app.settings')
        .controller('SettingsEinvoiceApplyController', EinvoiceApplyController);

    EinvoiceApplyController.$inject = ['$scope', '$location', 'SettingsService', 'GeneralService', '$uibModal', '$uibModalStack'];

    function EinvoiceApplyController($scope, $location, SettingsService, GeneralService, $uibModal, $uibModalStack) {

    }

})();