(function () {
    'use strict';

    angular
        .module('app.ggIntegration')
        .controller('GGMatchCategoryFilterValueListController', GGMatchCategoryFilterValueListController);

    GGMatchCategoryFilterValueListController.$inject = ['$location', '$stateParams', 'GGIntegrationService', 'NgTableParams', '$uibModal'];

    function GGMatchCategoryFilterValueListController($location, $stateParams, GGIntegrationService, NgTableParams, $uibModal) {
        /* jshint validthis:true */
        var vm = this;
        vm.settings = globalSettings;
        activate();
        function activate() {
            vm.firstLoad = true;
            vm.tableParams = new NgTableParams(
                angular.extend({ page: 1, count: 10 }, $location.search()),
                {
                    getData: function () {
                        return GGIntegrationService.gggetMatchCategoryFilterValueList($stateParams.Id).then(function (data) {
                            console.log("data", data);
                            return data;
                        });
                    }
                });
        }
    }
})();