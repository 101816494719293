(function() {
    "use strict";

    angular.module("app.marketing").controller("CampaignsAddController", AddController);

    AddController.$inject = [
        "$location",
        "$stateParams",
        "CampaignsService",
        "ProductsService",
        "CategoriesService",
        "ProductBrandService",
        "TagsService",
        "BanksService",
    ];

    function AddController($location, $stateParams, Service, ProductsService, CategoriesService, ProductBrandService, TagsService, BanksService) {
        /* jshint validthis:true */
        var vm = this;

        function activate() {
            vm.fields = Service.getFields();
            if ($stateParams.Id == "add") {
                vm.entity = {
                    CampaignTypeId: 0,
                    Priority: 1,
                    Active: true,
                    CampaignTopConditionTypeId: "0",
                    CampaignCondition: [],
                    CampaignAction: [],
                };
            } else {
                Service.get($stateParams.Id).then(function(data) {
                    vm.entity = data;
                    for (let i = 0; i < vm.entity.CampaignAction.length; i++) {
                        var item = vm.entity.CampaignAction[i];
                        if (item.CampaignActionData) {
                            item.CampaignActionData = item.CampaignActionData.split(",");
                        }
                    }
                    vm.loading = false;
                });
            }
        }
        activate();

        vm.create = function() {
            if (vm.form.$valid) {
                vm.loading = true;
                for (let i = 0; i < vm.entity.CampaignAction.length; i++) {
                    var item = vm.entity.CampaignAction[i];
                    console.log("item.CampaignActionData", item.CampaignActionData);
                    if (item.CampaignActionData) {
                        item.CampaignActionData = item.CampaignActionData.join();
                    }
                }
                vm.entity.Media = null;
                if (vm.entity.Id) {
                    Service.edit(vm.entity).then(function(data) {
                        vm.loading = false;
                        activate();
                    });
                } else {
                    Service.add(vm.entity).then(function(data) {
                        vm.loading = false;
                        $location.path("/marketing/campaigns/" + data.Id);
                    });
                }
            }
        };

        vm.delete = function() {
            if (confirm("Silmek istediğinize emin misiniz?")) {
                Service.del(vm.entity).then(function(data) {
                    $location.path("/marketing/campaigns/list");
                });
            }
        };

        vm.addCampaignCondition = function(campaignConditionGroup) {
            var condition = {
                CampaignConditionGroupCountTypeId: "0",
                CampaignConditionGroupCorrectnessTypeId: "0",
            };
            if (!campaignConditionGroup) {
                if (vm.entity.CampaignCondition.length <= 0) {
                    campaignConditionGroup = 1;
                } else {
                    campaignConditionGroup = vm.entity.CampaignCondition[vm.entity.CampaignCondition.length - 1].CampaignConditionGroup + 1;
                }
            }
            condition.CampaignConditionGroup = campaignConditionGroup;
            vm.entity.CampaignCondition.push(condition);
        };

        vm.removeCampaignCondition = function(condition) {
            var index = vm.entity.CampaignCondition.indexOf(condition);
            if (index > -1) {
                vm.entity.CampaignCondition.splice(index, 1);
            }
        };
        vm.addAction = function() {
            vm.entity.CampaignAction.push({});
        };

        vm.removeAction = function(action) {
            var index = vm.entity.CampaignAction.indexOf(action);
            if (index > -1) {
                vm.entity.CampaignAction.splice(index, 1);
            }
        };

        vm.searchCampaignActionDataRefresh = function(text, campaignActionTypeId, selected) {
            vm.searchCampaignActionDataList = [
                {
                    Id: "",
                    Name: "Yükleniyor...",
                },
            ];
            var params = {
                Name: text,
                PageSize: 10,
                PageIndex: 1,
                SelectFields: "Id,Name",
                SortProperty: "Name",
                SortType: "asc",
            };
            if (campaignActionTypeId == 100 || campaignActionTypeId == 202 || campaignActionTypeId == "c_103") {
                params.SelectedIds = selected;
                return ProductsService.getProducts(params).then(function(response) {
                    if (response.Data.length > 0) {
                        vm.searchCampaignActionDataList = response.Data;
                    } else {
                        vm.searchCampaignActionDataList = [
                            {
                                Id: "",
                                Name: "Bulunamadı (" + text + ")",
                            },
                        ];
                    }
                });
            } else if (campaignActionTypeId == 101 || campaignActionTypeId == "c_104") {
                params.PageSize = -1;
                return CategoriesService.getCategories(params).then(function(response) {
                    if (response.Data.length > 0) {
                        vm.searchCampaignActionDataList = response.Data;
                    } else {
                        vm.searchCampaignActionDataList = [
                            {
                                Id: "",
                                Name: "Bulunamadı (" + text + ")",
                            },
                        ];
                    }
                });
            } else if (campaignActionTypeId == 102 || campaignActionTypeId == "c_105") {
                params.PageSize = -1;
                return ProductBrandService.getAll(params).then(function(response) {
                    if (response.Data.length > 0) {
                        vm.searchCampaignActionDataList = response.Data;
                    } else {
                        vm.searchCampaignActionDataList = [
                            {
                                Id: "",
                                Name: "Bulunamadı (" + text + ")",
                            },
                        ];
                    }
                });
            } else if (campaignActionTypeId == 103 || campaignActionTypeId == "c_106") {
                params.PageSize = -1;
                return TagsService.getAll(params).then(function(response) {
                    if (response.Data.length > 0) {
                        vm.searchCampaignActionDataList = response.Data;
                    } else {
                        vm.searchCampaignActionDataList = [
                            {
                                Id: "",
                                Name: "Bulunamadı (" + text + ")",
                            },
                        ];
                    }
                });
            } else if (campaignActionTypeId == "c_401") {
                params.PageSize = -1;
                return BanksService.getAll(params).then(function(response) {
                    if (response.Data.length > 0) {
                        vm.searchCampaignActionDataList = response.Data;
                    } else {
                        vm.searchCampaignActionDataList = [
                            {
                                Id: "",
                                Name: "Bulunamadı (" + text + ")",
                            },
                        ];
                    }
                });
            } else if (campaignActionTypeId) {
                vm.searchCampaignActionDataList = [];
            } else {
                vm.searchCampaignActionDataList = [
                    {
                        Id: "",
                        Name: "Kampanya Tipi Seçiniz...",
                    },
                ];
                return [];
            }
        };
    }
})();
