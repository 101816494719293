(function () {
    'use strict';

    angular
        .module('app.stockItems')
        .controller('StockItemsDetailController', DetailController);

    DetailController.$inject = ['$location', '$stateParams', 'NgTableParams', 'StockItemsService', 'StockHistoriesService', 'WarehouseLocationsService'];

    function DetailController($location, $stateParams, NgTableParams, Service, StockHistoriesService, WarehouseLocationsService) {
        /* jshint validthis:true */
        var vm = this;

        Service.get($stateParams.StockItemId)
            .then(function (data) {
                vm.entity = data;
                vm.getStockHistories();
            });

        vm.fields = Service.getFields();

        vm.delete = function () {
            if (confirm("Silmek istediğinize emin misiniz?")) {
                Service.del(vm.entity).then(function (data) {
                    $location.path('/stockHistories/list');
                });
            }
        };

        vm.refresh = function () {
            vm.loading = true;
            vm.tableParams.reload();
            vm.loading = false;
        };

        vm.getStockHistories = function () {
            vm.tableParams = new NgTableParams({}, {
                getData: function (params) {
                    vm.firstLoad = false;
                    var filterData2 = params.filter();
                    var filterData = {};
                    filterData.PageSize = -1;
                    var keys = Object.keys(filterData2);
                    for (var i = 0; i < keys.length; i++) {
                        filterData[keys[i]] = filterData2[keys[i]];
                    }
                    filterData.StockItemId = vm.entity.Id;
                    return StockHistoriesService.getAll(filterData).then(function (data) {
                        params.total(data.FilteredCount);
                        return data.Data;
                    });
                }
            });
        }

        vm.cancel = function (row, rowForm) {
            vm.tableParams.reload();
        }

        vm.refreshStockItem = function (text) {
            var params = {
                Code: text,
                PageSize: 18,
                PageIndex: 1,
                SortProperty: 'Code',
                SortType: 'asc',
                ProductId: vm.entity.ProductId,
                WarehouseId: vm.entity.WarehouseId
            };
            vm.refreshStockItemList = [{
                Id: '',
                Name: 'Yükleniyor...'
            }];
            return StockItemsService.getAll(params)
                .then(function (response) {
                    if (response.Data.length > 0) {
                        vm.refreshStockItemList = response.Data;
                    } else {
                        vm.refreshStockItemList = [{
                            Id: '',
                            Name: 'Bulunamadı (' + text + ')'
                        }];
                    }
                });
        }

    }
})();