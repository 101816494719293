(function () {
    'use strict';

    angular
        .module('app.blogPost')
        .controller('BlogPostAddController', BlogPostAddController);

    BlogPostAddController.$inject = ['$scope', '$stateParams', '$filter', '$location', 'logger', 'BlogPostService'];

    function BlogPostAddController($scope, $stateParams, $filter, $location, logger, BlogPostService) {
        var vm = this;

        activate();

        function activate() {
            vm.newPost = {};

            logger.showToasts = true;

            vm.postFields = BlogPostService.getPostFields();
        }

        vm.savePost = function () {
            console.log('vm.newPost', vm.newPost);
            if (vm.form.$valid) {
                vm.loading = true;
                console.log('vm.newPost', vm.newPost);
                BlogPostService.addPost(vm.newPost).then(function (data) {
                    console.log('vm.newPost data', data);
                    vm.loading = false;
                    $location.path('/blogpost/edit/' + data.Id);
                },
                    function (data) {
                        vm.loading = false;
                    });
                vm.loading = false;
            }
        };

        vm.addSeo = function () {
            if (vm.form.$valid) {
                vm.loading = true;
                BlogPostService.addSeo(vm.seo).then(function () {
                    vm.loading = false;
                    $location.path('/blogpost/detail/' + vm.seo.Id);
                });
            }
        };
    }
})();