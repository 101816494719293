(function () {
    'use strict';

    angular
        .module('app.customers')
        .controller('CustomerAddressesController', CustomerAddressController);

    CustomerAddressController.$inject = ['$stateParams', 'CustomerAddressService'];

    function CustomerAddressController($stateparams, CustomerAddressService) {
        var vm = this;

        vm.title = '';
        vm.customerAddresses = [{ Name: "Yükleniyor..." }];
        vm.UserId = $stateparams.UserId;

        activate();

        function activate() {
            CustomerAddressService.getCustomerAddresses(vm.UserId).then(function (data) {
                vm.customerAddresses = data;
            });
        }

        vm.deleteAddress = function (address) {
            if (confirm("Silmek istediğinize emin misiniz?")) {
                CustomerAddressService.deleteCustomerAddress(address).then(function (data) {
                    var index = vm.customerAddresses.indexOf(address);
                    vm.customerAddresses.splice(index, 1);
                });
            }
        };
    }
})();