(function () {
    'use strict';

    angular
        .module('app.n11Integration')
        .controller('EditTemplateController', EditTemplateController);

    EditTemplateController.$inject = ['$scope', '$stateParams','$location', 'N11IntegrationService', 'NgTableParams'];

    function EditTemplateController($scope, $stateParams,$location, N11IntegrationService, NgTableParams) {
        /* jshint validthis:true */
        var vm = this;
        vm.settings = globalSettings;
        activate();

        function activate() {
            vm.fields = N11IntegrationService.getTemplateFields();
           
            N11IntegrationService.getTemplate($stateParams.Id)
                .then(function (data) {
                    
                    vm.entity = data;

                    vm.loading = false;
                });
        }

        vm.editTemplate = function () {
            if (vm.form.$valid) {
                vm.loading = true;
                N11IntegrationService.editTemplate(vm.entity).then(function () {
                    vm.loading = false;
                    $location.path('/n11Integration/templatelist');
                });
            }
        };

    }
})();