(function () {
    'use strict';

    angular
        .module('app.currency')
        .controller('AddCurrencyController', AddCurrencyController);

    AddCurrencyController.$inject = ['$scope', '$stateParams', '$location', 'CurrencyService', 'NgTableParams'];

    function AddCurrencyController($scope, $stateParams, $location, CurrencyService, NgTableParams) {
        /* jshint validthis:true */
        var vm = this;

        vm.settings = globalSettings;
        activate();

        function activate() {
            vm.fields = CurrencyService.getCurrencyFields();
        }

        vm.addCurrency = function () {
            if (vm.form.$valid) {
                vm.loading = true;
                CurrencyService.addCurrency(vm.entity).then(function (data) {
                    vm.loading = false;
                    $location.path('/currency/currencylist');
                },
                    function (data) {
                        vm.loading = false;
                    });
                vm.loading = false;
            }
        };
    }
})();