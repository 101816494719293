(function () {
    "use strict";

    angular
        .module("app.stockHistories")
        .factory("StockHistoriesService", Service);

    Service.$inject = [
        "$http",
        "$q",
        "ngAuthSettings",
        "ProductsService",
        "WarehousesService",
        "ProductsGeneralService"
    ];

    function Service(
        $http,
        $q,
        ngAuthSettings,
        ProductsService,
        WarehousesService,
        ProductsGeneralService
    ) {
        var service = {
            getAll: getAll,
            get: get,
            add: add,
            edit: edit,
            del: del,
            getFields: getFields,
            getProductFields: getProductFields
        };

        var serviceBase = ngAuthSettings.apiServiceBaseUri + "api/stockHistories/";

        var fields = [{
                key: 'ProductId',
                type: 'float-async-ui-select',
                templateOptions: {
                    required: true,
                    label: 'Ürün',
                    resource: 'form.product',
                    options: [],
                    placeholder: '...',
                    valueProp: 'Id',
                    labelProp: 'Name',
                    refresh: function (text, options, model) {
                        var params = {
                            SelectFields: "Id,Name"
                        };
                        if (model.Product != null) {
                            params.Id = model.Product.Id;
                        } else {
                            params.SearchKey = (text || (model.Product != null ? model.Product.Name : ''));
                            params.TrackStockQuantity = true;
                        }
                        return ProductsService.getProducts(params)
                            .then(function (response) {
                                if (response.Data.length > 0) {
                                    options.templateOptions.options = response.Data;
                                } else {
                                    options.templateOptions.options = [{
                                        Id: '',
                                        Name: 'Bulunamadı (' + text + ')'
                                    }];
                                }
                            });
                    },
                    refreshDelay: 2
                }
            },
            {
                key: 'WarehouseId',
                type: 'float-async-ui-select',
                templateOptions: {
                    required: true,
                    label: 'Depo',
                    resource: 'form.warehouse',
                    options: [],
                    placeholder: '...',
                    valueProp: 'Id',
                    labelProp: 'Name',
                    refresh: function (text, options, model) {
                        var params = {
                            Name: (text || (model.Warehouse != null ? model.Warehouse.Name : '')),
                            SelectFields: "Id,Name"
                        };
                        return WarehousesService.getAll(params)
                            .then(function (response) {
                                if (response.Data.length > 0) {
                                    options.templateOptions.options = response.Data;
                                } else {
                                    options.templateOptions.options = [{
                                        Id: '',
                                        Name: 'Bulunamadı (' + text + ')'
                                    }];
                                }
                            });
                    },
                    refreshDelay: 2
                }
            },
            {
                key: "StockHistoryTypeId",
                type: "floatRadio",
                className: "float-radio-horizontal",
                defaultValue: "9509be17-9671-4c98-8c89-36ca7b525e28",
                templateOptions: {
                    options: [{
                            "Name": "Giriş",
                            "Id": "9509be17-9671-4c98-8c89-36ca7b525e28"
                        },
                        {
                            "Name": "Çıkış",
                            "Id": "4a812f3b-ab27-4c59-a4d2-b6cbfec9a23a"
                        }
                    ],
                    class: "col-xs-6"
                }
            },
            {
                key: "Quantity",
                type: "floatInput",
                defaultValue: "1",
                templateOptions: {
                    label: "Miktar",
                    required: true,
                    placeholder: "Miktar ",
                    numberMask: 2,
                    class: "col-xs-6"
                }
            },
            {
                key: "Date",
                type: "floatDatepicker",
                templateOptions: {
                    resource: "form.date",
                    label: "Tarih",
                    placeholder: "Tarih",
                    class: "col-xs-6"
                }
            },
            {
                key: "Cost",
                type: "floatInput",
                templateOptions: {
                    resource: "form.stockitem-cost",
                    label: "Maliyet",
                    placeholder: "Maliyet ",
                    numberMask: 2,
                    class: "col-xs-6"
                },
                hideExpression: 'model.StockHistoryTypeId === "4a812f3b-ab27-4c59-a4d2-b6cbfec9a23a"',
            },
            {
                key: "RecommendedPrice",
                type: "floatInput",
                templateOptions: {
                    resource: "form.recommended-price",
                    label: "Tavsiye Edilen Fiyat",
                    placeholder: "Tavsiye Edilen Fiyat ",
                    numberMask: 2,
                    class: "col-xs-6"
                },
                hideExpression: 'model.StockHistoryTypeId === "4a812f3b-ab27-4c59-a4d2-b6cbfec9a23a"',
            }
        ];

        var productFields = [{
                key: "Name",
                type: "floatInput",
                templateOptions: {
                    required: true,
                    type: "text",
                    label: "Adı",
                    placeholder: "Adı",
                    resource: "form.name"
                }
            },
            {
                key: "Price",
                type: "floatInput",
                templateOptions: {
                    required: true,
                    resource: "form.price",
                    label: "Fiyat",
                    placeholder: "Fiyat ",
                    numberMask: 2,
                    class: "col-xs-6"
                }
            },
            {
                key: "Tax",
                type: "floatInput",
                templateOptions: {
                    required: true,
                    label: "Vergi",
                    resource: "form.tax",
                    placeholder: "Vergi ",
                    numberMask: 2,
                    class: "col-xs-6"
                }
            },
            {
                key: "Categories",
                type: "floatTreeMultiSelect",
                templateOptions: {
                    label: "Kategori",
                    resource: "form.categories",
                    topProp: 'TopCategoryId',
                    required: true,
                    placeholder: "Kategori Seçiniz ",
                    options: [{
                        Name: "Yükleniyor..."
                    }]
                },
                controller: [
                    "$scope",
                    function ($scope) {
                        $scope.first = true;
                        $scope.$watch("model.Categories", function (newValue, oldValue) {
                            if ($scope.first && newValue) {
                                $scope.first = false;
                                ProductsGeneralService.getCategories().then(function (response) {
                                    $scope.to.options = response;
                                });
                            }
                        });
                    }
                ]
            },
            {
                key: "Active",
                type: "floatCheckbox",
                defaultValue: true,
                templateOptions: {
                    label: "Yayında",
                    resource: "form.active",
                    placeholder: "Yayında",
                    class: "col-xs-3"
                }
            },
            {
                key: "DisplayOrder",
                type: "floatInput",
                defaultValue: 99,
                templateOptions: {
                    type: "number",
                    resource: "form.display-order",
                    label: "Sıra",
                    placeholder: "Sıra",
                    class: "col-xs-3"
                }
            },
            {
                key: "UnitMeasureId",
                type: "floatSelect",
                templateOptions: {
                    label: "Birim",
                    resource: "form.unit-measure",
                    options: [{
                        Name: "..."
                    }],
                    class: "col-xs-6"
                },
                controller: [
                    "$scope",
                    function ($scope) {
                        ProductsGeneralService.getUnitMeasures().then(function (response) {
                            $scope.to.options = response;
                        });
                    }
                ]
            },
            {
                key: "Description",
                type: "floatTinyMCE",
                templateOptions: {
                    label: "İçerik",
                    placeholder: "İçerik",
                    resource: "form.description"
                }
            }
        ];

        return service;

        /////////////////////////////////////////

        function getFields() {
            return fields;
        }

        function getProductFields() {
            return productFields;
        }

        function getAll(filterParams) {
            var deferred = $q.defer();

            $http
                .get(serviceBase, {
                    params: filterParams
                })
                .then(getComplete)
                .catch(getFailed);

            function getComplete(response) {
                deferred.resolve(response.data);
            }

            function getFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function get(Id) {
            var deferred = $q.defer();

            $http
                .get(serviceBase + Id)
                .then(getProductComplete)
                .catch(getProductFailed);

            function getProductComplete(response) {
                deferred.resolve(response.data);
            }

            function getProductFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function add(entity) {
            var deferred = $q.defer();

            $http
                .post(serviceBase, entity)
                .then(addComplete)
                .catch(addFailed);

            function addComplete(response) {
                deferred.resolve(response.data);
            }

            function addFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function edit(entity) {
            var deferred = $q.defer();

            $http
                .put(serviceBase + entity.Id, entity)
                .then(editComplete)
                .catch(editFailed);

            function editComplete(response) {
                deferred.resolve(response.data);
            }

            function editFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function del(entity) {
            var deferred = $q.defer();

            $http
                .delete(serviceBase + entity.Id, entity)
                .then(deleteComplete)
                .catch(deleteFailed);

            function deleteComplete(response) {
                deferred.resolve(response.data);
            }

            function deleteFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }
    }
})();