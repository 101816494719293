(function () {
    'use strict';

    angular
      .module('app.spares')
      .controller('SparesListController', SparesListController);

    SparesListController.$inject = ['$scope', 'SparesService', 'uiGridConstants', 'uiGridGroupingConstants'];

    function SparesListController($scope, SparesService, uiGridConstants, uiGridGroupingConstants) {
        var vm = this;

        vm.spare = SparesService.getspares();
        vm.SparesList = {
            paginationPageSizes: [10, 25, 50, 75],
            paginationPageSize: 10,
            data: vm.spare,
            rowHeight: 39,
            enableVerticalScrollbar: 2,
            enableHorizontalScrollbar: 2,
            enableGroupHeaderSelection: true,
            columnDefs: [
                { name: 'id', displayName: 'Detay', cellTemplate: '<div class="ui-grid-cell-contents"><a href="/spares/{{COL_FIELD}}"><i class="material-icons md-18 mdc-text-grey-600">zoom_in</i></a></div>' },
                { name: 'Adi', displayName: 'Adı' },
                { name: 'Kodu', displayName: 'Kodu' },
                { name: 'BagliUrunAdi', displayName: 'Bağlı Ürün Adı' },
                { name: 'BagliUrunKodu', displayName: 'Bağlı Ürün Kodu' },
                { name: 'Fiyati', displayName: 'Fiyatı' },
                { name: 'Boyutu', displayName: 'Boyutu' }
            ]
        };

        activate();

        function activate() {
        }
    }
})();
