(function () {
    'use strict';

    angular
        .module('app.markettemplates')
        .controller('EditShipmentTemplateController', EditShipmentTemplateController);

    EditShipmentTemplateController.$inject = ['$scope', '$stateParams', '$location', 'MarketTemplatesService', 'NgTableParams'];

    function EditShipmentTemplateController($scope, $stateParams, $location, MarketTemplatesService, NgTableParams) {
        /* jshint validthis:true */
        var vm = this;
        vm.settings = globalSettings;
        activate();

        function activate() {
            vm.fields = MarketTemplatesService.getShipmentTemplateFields();
           

            MarketTemplatesService.getShipmentTemplate($stateParams.Id)
                .then(function (data) {
                    vm.entity = data;
                    //vm.entity.refresh = true;
                    vm.loading = false;
                });
        }

        vm.editTemplate = function () {
            if (vm.form.$valid) {
                vm.loading = true;
                MarketTemplatesService.editShipmentTemplate(vm.entity).then(function () {
                    vm.loading = false;
                    window.history.back();
                });
            }
        };

    }
})();