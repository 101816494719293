(function () {
    'use strict';

    angular.module('app.salespointTypes', [
        // Angular modules 
        'ngRoute',
        'ngFileUpload'

        // Custom modules 

        // 3rd Party Modules

    ]);
})();