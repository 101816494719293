(function () {
    'use strict';

    angular
        .module('app.customers')
        .controller('CustomerDebtAddController', Controller);

    Controller.$inject = ['$stateParams', '$location', 'Settings', 'OrdersService'];

    function Controller($stateparams, $location, Settings, OrdersService) {
        var vm = this;

        vm.UserId = $stateparams.UserId;
        vm.debt = { CustomerId: vm.UserId };
        vm.debtFields = OrdersService.getDebtFields();

        vm.saveDebt = function () {
            if (vm.frmDebt.$valid) {
                vm.debt.PaymentMethodId = 'bab50812-0929-44ca-98b9-b415b8619a62';
                vm.debt.StatusId = '9537cd7e-8665-4533-8247-80b1da78cb06';
                vm.debt.SalesOrderTypeId = '167f2802-fe76-44c3-b5c7-a6ceaa6c9428';
                vm.settings = Settings;
                OrdersService.addOrder(vm.debt)
                    .then(function (data) {
                        if (vm.debt.SendByLinkEmail && vm.debt.Email !== "") {
                            vm.debt.OrderId = data.Id;
                            OrdersService.sendMail(vm.debt).then(function () {
                            });
                        }
                        if (vm.debt.PhoneNumber !== "") {
                            if (vm.debt.SendByLinkSms) {
                                vm.debt.OrderId = data.Id;
                                OrdersService.sendSms(vm.debt).then(function () {
                                });
                            }
                            if (vm.debt.SendByLinkWhatsapp) {
                                vm.debt.OrderId = data.Id;
                                var message = "Aşağıda yer alan linke tıklayarak " + vm.debt.Description + " açıklamalı borç ödemesini gerçekleştirebilirsiniz. " + vm.settings.WebServer + "/Checkout/Debt/" + vm.debt.OrderId;
                                window.open("https://api.whatsapp.com/send?phone=90" + vm.debt.PhoneNumber + "&text=" + message, '_blank');
                            }
                        }
                        $location.path('/customers/' + vm.UserId + '/orders/' + data.Id);
                    });
            }
        };
    }
})();