(function () {
    'use strict';

    angular
        .module('app.reporting')
        .run(appRun);

    appRun.$inject = ['routerHelper'];

    function appRun(routerHelper) {
        routerHelper.configureStates(getStates());
    }

    function getStates() {
        return [
            {
                state: 'reporting',
                config: {
                    url: '/reporting',
                    title: 'Raporlama',
                    abstract: true,
                    templateUrl: 'app/reporting/reporting.html',
                    settings: {
                        nav: 3,
                        name: 'products',
                        icon: 'fa fa-cubes',
                        content: 'Ürün',
                        resource: 'menu.reporting'
                    }
                }
            },
            {
                state: 'reporting.visitor',
                config: {
                    templateUrl: 'app/reporting/visitorReporting/visitor.reporting.html',
                    controller: 'VisitorReportingController',
                    controllerAs: 'vm',
                    url: '/visitor',
                    title: 'Ziyaretçi Raporları',
                    settings: {
                        nav: 4,
                        name: 'reporting.visitor',
                        topName: 'system',
                        content: 'Diller',
                        resource: 'menu.cultures'
                    }
                }
            },
            {
                state: 'reporting.sales',
                config: {
                    templateUrl: 'app/reporting/salesReporting/sales.reporting.html',
                    controller: 'SalesReportController',
                    controllerAs: 'vm',
                    url: '/sales',
                    title: 'Satış Raporları',
                    settings: {
                        nav: 2,
                        name: 'reporting.sales',
                        topName: 'system',
                        content: 'Diller',
                        resource: 'menu.cultures'
                    }
                }
            },
            {
                state: 'reporting.summary',
                config: {
                    templateUrl: 'app/reporting/summaryReporting/summary.reporting.html',
                    controller: 'SummaryReportController',
                    controllerAs: 'vm',
                    url: '/summary',
                    title: 'Toplam Raporları',
                    settings: {
                        nav: 5,
                        name: 'reporting.summary',
                        topName: 'system',
                        content: 'Diller',
                        resource: 'menu.cultures'
                    }
                }
            }
        ];
    }
})();