(function () {
    'use strict';

    angular
        .module('app.discounts')
        .controller('DiscountsEditController', EditController);

    EditController.$inject = ['$location', '$stateParams', 'DiscountsService'];

    function EditController($location, $stateParams, Service) {
        /* jshint validthis:true */
        var vm = this;

        vm.loading = true;
        vm.title = '';

        Service.get($stateParams.Id)
            .then(function (data) {
                vm.entity = data;
                vm.loading = false;
            });

        vm.fields = Service.getFields();

        vm.edit = function () {
            if (vm.form.$valid) {
                vm.loading = true;

                vm.entity.Categories = vm.entity.Categories || [];
                vm.entity.DiscountCategory = vm.entity.Categories.map(function (item) {
                    return {
                        CategoryId: item.id,
                        DiscountId: vm.entity.Id
                    };
                });
                vm.entity.Categories = null;

                vm.entity.Products = vm.entity.Products || [];
                vm.entity.DiscountProduct = vm.entity.Products.map(function (item) {
                    return {
                        ProductId: item,
                        DiscountId: vm.entity.Id
                    };
                });
                vm.entity.Products = null;

                Service.edit(vm.entity)
                    .then(function () {
                        vm.loading = false;
                        $location.path('/discounts/list');
                    });
            }
        };

        vm.cancel = function () {
            $location.path('/discounts/list');
        };
    }
})();