(function() {
    'use strict';

    angular
        .module('app.reviews')
        .controller('ReviewsListController', ListController);

    ListController.$inject = ['$location', 'ReviewsService', 'NgTableParams'];

    function ListController($location, Service, NgTableParams) {
        /* jshint validthis:true */
        var vm = this;

        activate();

        function activate() {
            vm.firstLoad = true;
            vm.tableParams = new NgTableParams(
                angular.extend({ page: 1, count: 10 }, $location.search()),
                {
                    getData: function(params) {
                        if (!vm.firstLoad) {
                            $location.search(params.url());
                        }
                        vm.firstLoad = false;
                        var filterData2 = params.filter();
                        var filterData = {};
                        filterData.PageIndex = params.page();
                        filterData.PageSize = params.count();
                        filterData.SortProperty = Object.keys(params.sorting())[0];
                        filterData.SortType = params.sorting()[Object.keys(params.sorting())[0]];
                        var keys = Object.keys(filterData2);
                        for (var i = 0; i < keys.length; i++) {
                            filterData[keys[i]] = filterData2[keys[i]];
                        }
                        return Service.getAll(filterData).then(function(data) {
                            params.total(data.FilteredCount);
                            return data.Data;
                        });
                    }
                });

            vm.showmainpageFilterData = [{ id: '', title: '' }, { id: 'true', title: 'Ana Sayfada Göster' }, { id: 'false', title: 'Ana Sayfada Gösterme' }];
            vm.approveFilterData = [{ id: '', title: '' }, { id: 'true', title: 'Onaylanan' }, { id: 'false', title: 'Onaylanmayan' }];
            vm.ratingFilterData = [{ id: '0', title: '0' }, { id: '1', title: '1' }, { id: '2', title: '2' }, { id: '3', title: '3' }, { id: '4', title: '4' }, { id: '5', title: '5' }];
        }

        vm.approve = function(Id, approved) {
            vm.loading = true;
            Service.approve(Id, approved).then(function() {
                vm.tableParams.reload();
                vm.loading = false;
            });
        };

        vm.changeStatus = function (review) {
            console.log("review",review);
            vm.loading = true;
            if (review.ShowMainPage) {
                console.log("reviewtrue", review);
                review.ShowMainPage = true;
            } else {
                console.log("reviewfalse", review);
                review.ShowMainPage = false;
            }
            Service
                .showmainpage(review.Id,review.ShowMainPage)
                .then(function (data) {
                    vm.tableParams.reload();
                    vm.loading = false;
                });
        };

        vm.delete = function(entity) {
            vm.loading = true;
            Service.del(entity).then(function() {
                vm.tableParams.reload();
                vm.loading = false;
            });
        };

        vm.DateFilter = {
            DateMin: { id: 'ng-table/date.html', placeholder: 'Minimum Tarih' },
            DateMax: { id: 'ng-table/date.html', placeholder: 'Maximum Tarih' }
        };
    }
})();