(function () {
    'use strict';

    angular
        .module('app.excel')
        .controller('ExcelAddImageController', ExcelAddImageController);

    ExcelAddImageController.$inject = ['$scope', '$stateParams', '$filter', '$location', 'logger', 'ExcelService', '$uibModal', '$uibModalStack'];

    function ExcelAddImageController($scope, $stateParams, $filter, $location, logger, ExcelService, $uibModal, $uibModalStack) {
        var vm = this;

        activate();

        function activate() {
        }

        vm.excelAddImageFields = ExcelService.getExcelAddImageFields();

        vm.ImageSaveByFileManager = function () {
            vm.loading = true;
            console.log("vm.excelAddImages", vm.excelAddImages);
            ExcelService.addImageByFileManager()
                .then(function (data) {
                    vm.loading = false;
                    logger.success("Kayıt işlemi başarıyla tamamlanmıştır.");
                    $location.path('/filemanager');
                })
                .catch(function () {
                    vm.loading = false;
                });
        };

    }
})();