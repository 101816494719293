(function () {
    'use strict';

    angular
        .module('app.sellers')
        .factory('SellersService', SellersService);

    SellersService.$inject = ['$http', '$q', 'ngAuthSettings', 'logger', 'config', '$timeout'];

    function SellersService($http, $q, ngAuthSettings, logger, config, $timeout) {
        var service = {
            getAll: getAll,
            get: get,
            add: add,
            edit: edit,
            del: del,
            getUsers: getUsers,
            getFields: getFields
        };

        var serviceBase = ngAuthSettings.apiServiceBaseUri + 'api/sellers/';

        var fields = [
            {
                key: 'UserId',
                type: 'horizontalSelect',
                templateOptions: {
                    required: true,
                    label: 'Kullanıcı',
                    options: [{ Name: 'Yükleniyor...' }]
                },
                controller: ['$scope', function ($scope) {
                    getUsers().then(function (response) {
                        $scope.to.options = response;
                    });
                }]
            },
            {
                key: 'Enable',
                type: 'horizontalCheckbox',
                templateOptions: {
                    label: 'Aktif'
                }
            },
            {
                key: 'TotalMontlyAmount',
                type: 'horizontalInput',
                templateOptions: {
                    required: true,
                    moneyMask: '2',
                    type: 'text',
                    label: 'Aylık Toplam Tutar',
                    placeholder: 'Aylık Toplam Tutar'
                }
            }
        ];

        return service;

        ///////////////////////////////

        function getAll(filterParams) {
            var deferred = $q.defer();
            $http.get(serviceBase, {
                params: filterParams
            })
                .then(getAllComplete)
                .catch(getAllFailed);

            function getAllComplete(response) {
                deferred.resolve(response.data);
            }

            function getAllFailed(error) {
                deferred.reject(error);
            }

            return deferred.promise;
        }

        function get(id) {
            var deferred = $q.defer();

            $http.get(serviceBase + id)
                .then(getComplete)
                .catch(getFailed);

            function getComplete(response) {
                deferred.resolve(response.data);
            }

            function getFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function add(entity) {

            var deferred = $q.defer();

            $http.post(serviceBase, entity)
                .then(addComplete)
                .catch(addFailed);

            function addComplete(response) {
                deferred.resolve(response.data);
            }

            function addFailed(error) {
                deferred.reject(error);
            }

            return deferred.promise;

        }

        function edit(entity) {

            var deferred = $q.defer();

            $http.put(serviceBase + entity.Id, entity)
                .then(editComplete)
                .catch(editFailed);

            function editComplete(response) {
                deferred.resolve(response.data);
            }

            function editFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;

        }

        function del(customer) {

            var deferred = $q.defer();

            $http.delete(serviceBase + customer.Id, customer)
                .then(delComplete)
                .catch(delFailed);

            function delComplete(response) {
                deferred.resolve(response.data);
            }

            function delFailed(error) {
                deferred.reject(error);
            }

            return deferred.promise;

        }

        function getFields() {
            return fields;
        }

        function getUsers() {
            var deferred = $q.defer();
            $http.get(serviceBase + 'users')
                .then(getUsersComplete)
                .catch(getUsersFailed);

            function getUsersComplete(response) {
                deferred.resolve(response.data);
            }

            function getUsersFailed(error) {
                deferred.reject(error);
            }

            return deferred.promise;
        }

    }
})();
