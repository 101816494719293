(function () {
    'use strict';

    angular
        .module('app.pages')
        .controller('PagesAddController', PagesAddController);

    PagesAddController.$inject = ['$scope', '$location', 'logger', 'PagesService'];

    function PagesAddController($scope, $location, logger, PagesService) {
        var vm = this;

        activate();
        function activate() {
            var h = angular.element(window).height() - 40 - 40 - 30 - 20;
            angular.element('#customer-info').css("height", h);
            angular.element('#customer-detail').css("height", h);
        }

        vm.entity = {};

        vm.fields = PagesService.getFields();

        vm.save = function () {
            if (vm.form.$valid) {
                vm.loading = true;
                PagesService.add(vm.entity).then(function (data) {
                    vm.loading = false;
                    $location.path('/pages/detail/' + data.Id);
                });
            }
        };
    }
})();