(function () {
    'use strict';

    var invoiceId = "";
    var kindInvoice = "";

    angular
        .module('app.invoices')
        .controller('InvoiceListController', ListController);

    ListController.$inject = ['$scope', '$location', 'InvoiceService', 'NgTableParams', 'GeneralService', '$uibModal'];

    function ListController($scope, $location, InvoiceService, NgTableParams, GeneralService, $uibModal) {
        var vm = this;

        vm.totalAmount = null;

        vm.typeInvoice = null;

        vm.invoiceCount = null;

        vm.remainCredits = null;

        activate();

        function activate() {

            vm.filterModel = {};
            vm.firstLoad = true;
            vm.showFilter = false;
            vm.InvoiceTypes = [];
            vm.RefreshInvoice = function () {

                vm.loading = true;

                InvoiceService.getUpdateInvoices().then(function (res) {
                    vm.tableParams.reload();
                    vm.loading = false;
                });


            };


            vm.tableParams = new NgTableParams(
                angular.extend({
                    page: 1,
                    count: 10,
                    sorting: {
                        InvoiceNumber: "desc"
                    }
                }, {}), {
                getData: function (params) {
                    if (!vm.firstLoad) {
                        $location.search(params.url());
                    }
                    vm.firstLoad = false;
                    var filterData2 = params.filter();
                    var filterData = {};
                    filterData.PageIndex = params.page();
                    filterData.PageSize = params.count();
                    filterData.SortProperty = Object.keys(params.sorting())[0];
                    filterData.SortType = params.sorting()[Object.keys(params.sorting())[0]];
                    var baseFilterKeys = Object.keys(filterData2);
                    for (var i = 0; i < baseFilterKeys.length; i++) {
                        filterData[baseFilterKeys[i]] = filterData2[baseFilterKeys[i]];
                    }

                    var filterModelKeys = Object.keys(vm.filterModel);
                    for (var i = 0; i < filterModelKeys.length; i++) {
                        filterData[filterModelKeys[i]] = vm.filterModel[filterModelKeys[i]];
                        }

                        vm.totalAmount = 0;
                        vm.invoiceCount = 0;

                        return InvoiceService.getInvoices(filterData).then(function (data) {

                            console.log;(data.Data);

                            if (data.Data.length !== 0) {

                                data.Data.forEach(function (item) {
                                    vm.totalAmount += item.TotalAmount;
                                });

                                vm.totalAmount = vm.totalAmount.toFixed(2);

                                console.log(vm.totalAmount);

                                vm.remainCredits = data.Data[0].InvoiceProvider.Credits;

                                console.log(vm.remainCredits);
                            }

                            vm.invoiceCount = data.Data.length;

                            params.total(data.FilteredCount);

                            return data.Data;
                    });
                }
            });
        }

        vm.download = function (invoiceId) {
            //console.log("dhgffhfhgf");
            InvoiceService.getDownloadInvoice(invoiceId).then(function (data) {
                window.open("data:application/pdf;base64," + data);
                return data;
            });
        }

        vm.hashCode = function (str) {
            // java String#hashCode
            var hash = 0;
            for (var i = 0; i < str.length; i++) {
                hash = str.charCodeAt(i) + ((hash << 5) - hash);
            }
            return hash;
        }

        vm.intToRGB = function (i) {
            var c = (i & 0x00FFFFFF)
                .toString(16)
                .toUpperCase();

            return "00000".substring(0, 6 - c.length) + c;
        }

        vm.InvoiceDateFilter = {
            InvoiceDateMin: { id: 'ng-table/date.html', placeholder: 'Min Tarih' },
            InvoiceDateMax: { id: 'ng-table/date.html', placeholder: 'Max Tarih' }
        };


        vm.InserDateFilter = {
            InsertDateMin: { id: 'ng-table/insert_date.html', placeholder: 'Min Tarih' },
            InsertDateMax: { id: 'ng-table/insert_date.html', placeholder: 'Max Tarih' }
        };

        vm.PriceFilter = {
            PriceMin: { id: 'ng-table/priceFilter.html', placeholder: "Min Tutar"},
            PriceMax: { id: 'ng-table/priceFilter.html', placeholder: "Max Tutar"}
        };

        vm.InvoiceTypeFilter = {
            //invoiceType: { id: 'ng-table/invoce_type.html', placeholder: "E-Dönüşüm Tipi", model:"vm.InvoiceTypes" }
            kindOfInvoice: { id: 'ng-table/invoce_type.html', placeholder: "E-Fatura/E-Arşiv Tipi", model:"vm.kindOfInvoice" }
        };

        vm.InvoiceStateFilter = {
            stateInvoice: { id: 'ng-table/invoce_state.html', placeholder: "E-Fatura/E-Arşiv Tipi", model: "vm.stateInvoice" }
        }

        vm.kindOfInvoice = [{ id: '0', title: 'Hepsi' }, { id: '1', title: 'E-Fatura' }, { id: '2', title: 'E-Arşiv' }];

        vm.stateInvoice = [{ id: '0', title: 'Hepsi' }, { id: '1', title: 'Başarılı' }, { id: '2', title: 'Bekliyor' }, { id: '3', title: 'İptal' }, { id: '4', title: 'Hata' }];


        vm.InvoiceTypes = InvoiceService.getInvoiceTypes().then(function (response) {
            var resp = response.map(function (type) {
                return {
                    id: type.Id,
                    title: type.Name
                };
            });

            console.log(resp);
            return resp;
        });


        vm.getKindOfInvoiceEnum = function (num) {

            if (num == 1)
                return "E-Fatura";
            if (num == 2)
                return "E-Arşiv";
        }


        vm.getStateInvoiceNum = function (num) {

            if (num == 1)
                return "Başarılı";
            if (num == 2)
                return "Bekliyor"
            if (num == 3)
                return "İptal";
            if (num == 4)
                return "Hatalı";

        }

        vm.updateSelected = function (action, id) {
            if (action === 'add' && $scope.selected.indexOf(id) === -1) {
                $scope.selected.push(id);
            }
            if (action === 'remove' && $scope.selected.indexOf(id) !== -1) {
                $scope.selected.splice($scope.selected.indexOf(id), 1);
            }
        };

        vm.updateSelection = function ($event, id) {
            var checkbox = $event.target;
            var action = (checkbox.checked ? 'add' : 'remove');
            vm.updateSelected(action, id);
        };

        vm.click = function () {
            console.log(vm.tableParams);
        }

        vm.preview = function (invoiceNumber) {

            InvoiceService.getEarsivHtml(invoiceNumber).then(function (response) {

                var popupWin = window.open('', '_blank', '');
                popupWin.document.open();
                var printContent = response;
                popupWin.document.write(printContent);
                popupWin.document.close();

            });

        }

        vm.showAlert = function (invoiceid) {

            invoiceId = invoiceid;
            //kindInvoice = kindOfInvoice;

            // Modal

            vm.modalTitle = "Fatura Görüntüleme";
            $uibModal.open({
                    templateUrl: 'ModalContent.html',
                    controller: 'InvoiceOutModalController',
                    controllerAs: 'vm',
                    windowClass: 'app-modal-window'
                });
            

        }


        //function activate() {
        //    vm.firstLoad = true;
        //    vm.tableParams = new NgTableParams(
        //        angular.extend({ page: 1, count: 10 }, $location.search()),
        //        {
        //            getData: function (params) {
        //                if (!vm.firstLoad) {
        //                    $location.search(params.url());
        //                }
        //                vm.firstLoad = false;
        //                var filterData2 = params.filter();
        //                var filterData = {};
        //                filterData.PageIndex = params.page(),
        //                    filterData.PageSize = params.count(),
        //                    filterData.SortProperty = Object.keys(params.sorting())[0],
        //                    filterData.SortType = params.sorting()[Object.keys(params.sorting())[0]];
        //                var keys = Object.keys(filterData2);
        //                for (var i = 0; i < keys.length; i++) {
        //                    filterData[keys[i]] = filterData2[keys[i]];
        //                }
        //                return InvoiceService.getInvoices(filterData).then(function (data) {
        //                    params.total(data.FilteredCount);

        //                    var deneData = data.data;

        //                    console.log(deneData);

        //                    return data.Data;
        //                });
        //            }
        //        });
        //}

    }


    angular
        .module('app.invoices')
        .controller('InvoiceOutModalController', InvoiceModalController);

    InvoiceModalController.$inject = ['$scope', '$stateParams', '$filter', '$location', 'logger', 'InvoiceService', '$uibModal', '$uibModalStack', '$uibModalInstance', '$sce'];

    function InvoiceModalController($scope, $stateParams, $filter, $location, logger, InvoiceService, $uibModal, $uibModalStack, $uibModalInstance,$sce) {

        var vm = this;

        activate();
        vm.loading = true; 

        function activate() {

            InvoiceService.getPreviewInvoice(invoiceId).then(function (response) {

                vm.content = $sce.trustAsHtml(response);
                vm.loading = false;  

            });
        }
    }

})();
