(function () {
    'use strict';

    angular
        .module('app.account')
        .factory('AccountAuthenticationService', AccountAuthenticationService);

    AccountAuthenticationService.$inject = ['$http', '$q', 'ngAuthSettings'];

    function AccountAuthenticationService($http, $q, ngAuthSettings) {
        var service = {
            getAuthentications: getAuthentications,
            getUserAuthentications: getUserAuthentications,
            getUserAuthentication: getUserAuthentication,
            setUserAuthentications: setUserAuthentications
        };

        var serviceBase = ngAuthSettings.apiServiceBaseUri;

        return service;

        function getAuthentications() {
            var deferred = $q.defer();

            $http.get(serviceBase + 'api/user/authentication/yetki')
                .then(getAuthenticationsComplete)
                .catch(getAuthenticationsFailed);

            function getAuthenticationsComplete(response) {
                deferred.resolve(response.data);
            }

            function getAuthenticationsFailed(error) {
                deferred.reject(error);
            }

            return deferred.promise;
        }

        function getUserAuthentications() {
            var deferred = $q.defer();

            $http.get(serviceBase + 'api/user/authentication/useryetki')
                .then(getUserAuthenticationsComplete)
                .catch(getUserAuthenticationsFailed);

            function getUserAuthenticationsComplete(response) {
                deferred.resolve(response.data);
            }

            function getUserAuthenticationsFailed(error) {
                deferred.reject(error);
            }

            return deferred.promise;
        }

        function getUserAuthentication(UserId) {
            var deferred = $q.defer();

            $http.get(serviceBase + 'api/user/authentication/useryetki/' + UserId)
                .then(getUserAuthenticationsComplete)
                .catch(getUserAuthenticationsFailed);

            function getUserAuthenticationsComplete(response) {
                deferred.resolve(response.data);
            }

            function getUserAuthenticationsFailed(error) {
                deferred.reject(error);
            }

            return deferred.promise;
        }

        function setUserAuthentications(UserAuthentications) {
            var deferred = $q.defer();

            $http.post(serviceBase + 'api/user/authentication/useryetki', UserAuthentications)
                .then(setUserAuthenticationsComplete)
                .catch(setUserAuthenticationsFailed);

            function setUserAuthenticationsComplete(response) {
                deferred.resolve(response.data);
            }

            function setUserAuthenticationsFailed(error) {
                deferred.reject(error);
            }

            return deferred.promise;
        }
    }
})();