(function () {
    'use strict';

    angular
        .module('app.settings')
        .factory('SettingsService', Service);

    Service.$inject = ['$http', '$q', 'ngAuthSettings', 'logger', 'SharedData', '$timeout', 'Settings'];

    function Service($http, $q, ngAuthSettings, logger, SharedData, $timeout, Settings) {
        var service = {      
            getAll: getAll,
            get: get,
            edit: edit,
            getSettings: getSettings,
            getAddressSettings: getAddressSettings,
            refreshSettingsValue: refreshSettingsValue,
            getIntegrationSettings: getIntegrationSettings,
            resreshIntegrationSettingsValue: resreshIntegrationSettingsValue
        };

        var serviceBase = ngAuthSettings.apiServiceBaseUri;
        var endpoint = serviceBase + 'api/setting/';
        var settings = null;

        return service;

        ///////////////////////////////

        function getAll(filterParams) {
            var deferred = $q.defer();
            $http.get(endpoint + "all", {
                params: filterParams
            })
                .then(getComplete)
                .catch(getFailed);

            function getComplete(response) {
                deferred.resolve(response.data);
            }

            function getFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function get(id) {
            var deferred = $q.defer();

            $http.get(endpoint + id)
                .then(getComplete)
                .catch(getFailed);

            function getComplete(response) {
                deferred.resolve(response.data);
            }

            function getFailed(error) {
                logger.error('Failed in addRole() function' + error.data);
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function edit(customer) {
            var deferred = $q.defer();

            $http.put(endpoint + customer.Id, customer)
                .then(editComplete)
                .catch(editFailed);

            function editComplete(response) {
                deferred.resolve(response.data);
            }

            function editFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function getSettings() {
            var deferred = $q.defer();

            if (settings) {
                deferred.resolve(settings);
            } else {
                $http.get(serviceBase + 'api/setting')
                    .then(getSettingsComplete)
                    .catch(getSettingsFailed);
            }

            function getSettingsComplete(response) {
                settings = response;
                deferred.resolve(response.data);
            }

            function getSettingsFailed(error) {
                deferred.reject(error);
            }

            return deferred.promise;
        }

        function getAddressSettings() {
            var deferred = $q.defer();

            $http.get(serviceBase + 'api/setting/addresssettings')
                .then(getComplete)
                .catch(getFailed);

            function getComplete(response) {
                deferred.resolve(response.data);
            }

            function getFailed(error) {
                deferred.reject(error);
            }

            return deferred.promise;
        }

        function getIntegrationSettings() {
            var deferred = $q.defer();
            if (settings) {
                deferred.resolve(settings);
            } else {
                $http.get(serviceBase + 'api/setting/IntegrationSettings')
                    .then(getSettingsComplete)
                    .catch(getSettingsFailed);
            }

            function getSettingsComplete(response) {
                settings = response;
                deferred.resolve(response.data);
            }

            function getSettingsFailed(error) {
                deferred.reject(error);
            }

            return deferred.promise;
        }

        function resreshIntegrationSettingsValue() {
            getIntegrationSettings()
                .then(function (integrationsettings) {
                    SharedData.IntegrationSettings = integrationsettings;
                    var dataa = integrationsettings;

                    var keys = Object.keys(dataa);
                    for (var i = 0; i < keys.length; i++) {
                        SharedData.IntegrationSettings[keys[i]] = integrationsettings[keys[i]];
                    }
                });
        }

        function refreshSettingsValue() {
            getSettings()
                .then(function (settings) {
                    SharedData.Settings = settings;
                    var dataa = settings;
                    var keys = Object.keys(dataa);
                    for (var i = 0; i < keys.length; i++) {
                        Settings[keys[i]] = settings[keys[i]];
                    }
                });
        }

    }
})();