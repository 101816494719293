(function () {
    'use strict';

    angular
        .module('app.excel')
        .controller('ExcelImportController', ExcelImportController);

    ExcelImportController.$inject = ['$scope', '$stateParams', '$filter', '$location', 'logger', 'ExcelService', '$uibModal', '$uibModalStack'];

    function ExcelImportController($scope, $stateParams, $filter, $location, logger, ExcelService, $uibModal, $uibModalStack) {
        var vm = this;

        activate();

        function activate() {
            //console.log('ResultData', ResultData);
            //if (ResultData != null) {
            //    vm.ResultData = ResultData;
            //}
        }

        vm.excelImportFields = ExcelService.getExcelImportFields();

        vm.saveImport = function () {
            console.log("log", vm.excelImport.File);

            vm.openModal(vm.excelImport.File); 
        }


        // Modal

        vm.modalTitle = "Excel Import";
        vm.modalInstance = null;
        vm.openModal = function (File) {
            console.log("Files",File);
            vm.modalInstance = $uibModal.open({
                templateUrl: 'ModalContent.html',
                controller: 'ExcelModalController',
                controllerAs: 'vm',
                resolve: {
                    items: function () {
                        return {
                            File: File
                        };
                    }
                }
            });
        }

    }

    angular
        .module('app.excel')
        .controller('ExcelModalController', ExcelModalController);

    ExcelModalController.$inject = ['$scope', '$stateParams', '$filter', '$location', 'logger', 'ExcelService', '$uibModal', '$uibModalStack','$uibModalInstance','items'];

    function ExcelModalController($scope, $stateParams, $filter, $location, logger, ExcelService, $uibModal, $uibModalStack,$uibModalInstance, items) {
       
        var vm = this;

        vm.content = items;
       

        activate();

        function activate() {
            ExcelService.analysisFile(vm.content.File).then(function (data) {
                console.log("importttt"+data);
                vm.file = vm.content.File;
                vm.ProductCount = data.data.ProductCount;
                vm.DeleteCount = data.data.DeleteCount;
                vm.StockCount = data.data.StockCount;
                vm.DiscountCount = data.data.DiscountCount;
                vm.NotMatchField = data.data.NotMatchField;

                vm.loading = false;  
            });
        }

        
        vm.modalCancel = function () {
            $uibModalStack.dismissAll();
        }

        vm.approveImport = function () {
            vm.loading = true;
            ExcelService.importFile(vm.file, vm.filedelete).then(function () {
                vm.loading = false;
                vm.modalCancel();
                $location.path('/products/list');
            });
        }

    }
})();