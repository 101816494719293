(function () {
    'use strict';

    angular
        .module('app.blogPost')
        .factory('PostTagPostService', PostTagPostService);

    PostTagPostService.$inject = ['$http', '$q', 'ngAuthSettings', 'logger', 'config', 'Settings',  'BlogPostTagService'];

    function PostTagPostService($http, $q, ngAuthSettings, logger, config, Settings, BlogPostTagService) {
        var service = {
            getAll: getAll,
            get: get,
            add: add,
            edit: edit,
            del: del,
            getFields: getFields
        };
        var baseUrl = ngAuthSettings.apiServiceBaseUri;

        var serviceBaseUrl = baseUrl + 'api/PostTagPost/';

        var fields = [
            {
                key: 'PostTagId',
                type: 'horizontalSelect',
                templateOptions: {
                    required: true,
                    label: 'Etiket',
                    resource: 'form.tag',
                    options: [{ Name: '...' }]
                },
                controller: ['$scope', function ($scope) {
                    BlogPostTagService.getAll({ PageSize: -1 })
                        .then(function (response) {
                            $scope.to.options = response.Data;
                        });
                }]
            }
        ];

        return service;

        /////////////////////////////////////////

        function getFields() {
            return fields;
        }

        function getAll(filterParams) {
            var deferred = $q.defer();
            $http.get(serviceBaseUrl, {
                params: filterParams
            })
                .then(getComplete)
                .catch(getFailed);

            function getComplete(response) {
                deferred.resolve(response.data);
            }

            function getFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function get(Id) {
            var deferred = $q.defer();

            $http.get(serviceBaseUrl + Id)
                .then(getComplete)
                .catch(getFailed);

            function getComplete(response) {
                deferred.resolve(response.data);
            }

            function getFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function add(entity) {
            var deferred = $q.defer();
            $http.post(serviceBaseUrl, entity)
                .then(editComplete)
                .catch(editFailed);

            function editComplete(response) {
                deferred.resolve(response.data);
            }

            function editFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function edit(entity) {
            var deferred = $q.defer();

            $http.put(serviceBaseUrl + entity.Id, entity)
                .then(editComplete)
                .catch(editFailed);

            function editComplete(response) {
                deferred.resolve(response.data);
            }

            function editFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function del(entity) {
            var deferred = $q.defer();
            console.log('entity', entity);
            $http.delete(serviceBaseUrl + entity.PostId + '/' + entity.PostTagId, entity)
                .then(editComplete)
                .catch(editFailed);

            function editComplete(response) {
                deferred.resolve(response.data);
            }

            function editFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }
    }
})();