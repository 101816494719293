(function () {
    "use strict";

    angular.module("app.banks").factory("InstallmentService", Service);

    Service.$inject = ["$http", "$q", "ngAuthSettings"];

    function Service($http, $q, ngAuthSettings) {
        var service = {
            getAll: getAll,
            get: get,
            add: add,
            edit: edit,
            del: del,
            copyToRole: copyToRole,
            getFields: getFields
        };

        var serviceBase = ngAuthSettings.apiServiceBaseUri + "api/installments/";

        var fields = [
            {
                key: "Active",
                type: "floatCheckbox",
                templateOptions: {
                    label: "Aktif",
                    resource: "form.active",
                    placeholder: "Aktif",
                    class: "col-xs-3"
                }
            },
            {
                key: "Title",
                type: "floatInput",
                templateOptions: {
                    type: "text",
                    label: "Başlık",
                    resource: "form.title",
                    placeholder: "Başlık",
                    readonly: true,
                    class: "col-xs-8"
                }
            },
            {
                key: "Commission",
                type: "floatInput",
                templateOptions: {
                    type: "text",
                    label: "Komisyon",
                    resource: "form.commission",
                    placeholder: "Komisyon",
                    class: "col-xs-6"
                }
            },
            {
                key: "AccountingCommission",
                type: "floatInput",
                templateOptions: {
                    type: "text",
                    label: "Muhasebe Komisyonu",
                    resource: "form.accounting-commission",
                    placeholder: "Muhasebe Komisyonu",
                    class: "col-xs-6"
                }
            },
            {
                key: "BankInstallment",
                type: "floatInput",
                templateOptions: {
                    type: "number",
                    resource: "form.bank-installment",
                    label: "Bank Taksidi",
                    placeholder: "Bank Taksidi",
                    class: "col-xs-4"
                }
            },
            {
                key: "ViewInstallment",
                type: "floatInput",
                templateOptions: {
                    type: "number",
                    resource: "form.view-installment",
                    label: "Görünen Taksit",
                    placeholder: "Görünen Taksit",
                    class: "col-xs-4"
                }
            },
            {
                key: "MinPrice",
                type: "floatInput",
                templateOptions: {
                    type: "text",
                    label: "Komisyon",
                    resource: "form.commission",
                    placeholder: "Komisyon",
                    class: "col-xs-6"
                }
            },
            {
                key: "MaxPrice",
                type: "floatInput",
                templateOptions: {
                    type: "text",
                    label: "Komisyon",
                    resource: "form.commission",
                    placeholder: "Komisyon",
                    class: "col-xs-6"
                }
            }
        ];

        return service;

        /////////////////////////////////////////

        function getFields() {
            return fields;
        }

        function getAll(filterParams) {
            var deferred = $q.defer();

            $http
                .get(serviceBase, {
                    params: filterParams
                })
                .then(getComplete)
                .catch(getFailed);

            function getComplete(response) {
                deferred.resolve(response.data);
            }

            function getFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function get(Id) {
            var deferred = $q.defer();

            $http
                .get(serviceBase + Id)
                .then(getComplete)
                .catch(getFailed);

            function getComplete(response) {
                deferred.resolve(response.data);
            }

            function getFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function add(entity) {
            var deferred = $q.defer();

            $http
                .post(serviceBase, entity)
                .then(editComplete)
                .catch(editFailed);

            function editComplete(response) {
                deferred.resolve(response.data);
            }

            function editFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function edit(entity) {
            var deferred = $q.defer();

            $http
                .put(serviceBase + entity.Id, entity)
                .then(editComplete)
                .catch(editFailed);

            function editComplete(response) {
                deferred.resolve(response.data);
            }

            function editFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function copyToRole(bankId, roleId) {
            var deferred = $q.defer();

            $http
                .put(serviceBase + "CopyToRole/" + bankId + "/" + roleId)
                .then(editComplete)
                .catch(editFailed);

            function editComplete(response) {
                deferred.resolve(response.data);
            }

            function editFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function del(entity) {
            var deferred = $q.defer();

            $http
                .delete(serviceBase + entity.Id, entity)
                .then(delComplete)
                .catch(delFailed);

            function delComplete(response) {
                deferred.resolve(response.data);
            }

            function delFailed(error) {
                deferred.reject(error);
            }

            return deferred.promise;
        }
    }
})();
