(function () {
    'use strict';

    angular
        .module('app.warehouses')
        .controller('WarehousesEditController', EditController);

    EditController.$inject = ['$location', '$timeout', '$stateParams', 'WarehousesService', 'WarehouseLocationsService'];

    function EditController($location, $timeout, $stateParams, Service, WarehouseLocationsService) {
        /* jshint validthis:true */
        var vm = this;

        vm.loading = true;
        vm.title = '';
        vm.fields = Service.getFields();

        Service.get($stateParams.WarehouseId).then(function (data) {
            vm.entity = data;
            vm.loading = false;
            vm.getWarehouseLocations();
        });

        vm.edit = function () {
            if (vm.form.$valid) {
                vm.loading = true;
                Service.edit(vm.entity).then(function () {
                    vm.loading = false;
                    $location.path('/warehouses/detail/' + vm.entity.Id);
                });
            }
        };

        //////////////////////////////// WarehouseLocations

        vm.warehouseLocationFields = WarehouseLocationsService.getFields();
        vm.warehouseLocationEntity = {};

        vm.getWarehouseLocations = function () {
            WarehouseLocationsService.getAll({
                    PageSize: -1,
                    WarehouseId: vm.entity.Id
                })
                .then(function (data) {
                    vm.entity.WarehouseLocation = data.Data;
                    vm.entity.WarehouseLocation = vm.getSubLocationList(null);
                    $timeout(function () {
                        $(".location-list").treetable({
                            expandable: true
                        });
                        $(".location-list").treetable('expandAll');
                        vm.loading = false;
                    }, 500);
                })
        }

        vm.getSubLocationList = function (TopWarehouseLocationId) {
            var locationList = [];
            var subLocationsList = vm.entity.WarehouseLocation.filter(function (location) {
                return location.TopWarehouseLocationId === TopWarehouseLocationId;
            });
            for (var i = 0; i < subLocationsList.length; i++) {
                var item = subLocationsList[i];
                locationList.push(item);
                var subItems = vm.getSubLocationList(item.Id);
                locationList = locationList.concat(subItems);
            }
            return locationList;
        };

        vm.addWarehouseLocation = function (warehouse) {
            vm.warehouseLocationEntity = {
                WarehouseId: warehouse.Id,
                LocationList: vm.entity.WarehouseLocation
            };
        };

        vm.editWarehouseLocation = function (warehouseLocation) {
            vm.warehouseLocationEntity = angular.copy(warehouseLocation);
        };

        vm.deleteWarehouseLocation = function (warehouseLocation) {
            if (confirm("Silmek istediğinize emin misiniz?")) {
                vm.loading = true;
                WarehouseLocationsService.del(warehouseLocation).then(function (data) {
                    vm.getWarehouseLocations(vm.entity);
                });
            }
        };

        vm.warehouseLocationCancel = function () {
            vm.warehouseLocationEntity = null;
        };

        vm.warehouseLocationSubmit = function (warehouseLocation) {
            vm.loading = true;
            if (vm.warehouseLocationEntity.Id) {
                WarehouseLocationsService.edit(vm.warehouseLocationEntity)
                    .then(function () {
                        vm.getWarehouseLocations(vm.entity);
                    })
                    .catch(function () {
                        vm.getWarehouseLocations(vm.entity);
                    });
            } else {
                WarehouseLocationsService.add(vm.warehouseLocationEntity)
                    .then(function () {
                        vm.getWarehouseLocations(vm.entity);
                    })
                    .catch(function () {
                        vm.getWarehouseLocations(vm.entity);
                    });
            }
        };
    }
})();