(function () {
    'use strict';

    angular
        .module('app.productLists')
        .controller('ProductListsListController', ProductListsListController);

    ProductListsListController.$inject = ['$location', 'ProductListsService', 'NgTableParams'];

    function ProductListsListController($location, ProductListsService, NgTableParams) {
        var vm = this;

        activate();

        function activate() {
            vm.firstLoad = true;
            vm.tableParams = new NgTableParams(
                angular.extend({
                    page: 1,
                    count: 10
                }, $location.search()), {
                    getData: function (params) {
                        if (!vm.firstLoad) {
                            $location.search(params.url());
                        }
                        vm.firstLoad = false;
                        var filterData2 = params.filter();
                        var filterData = {};
                        filterData.PageIndex = params.page(),
                            filterData.PageSize = params.count(),
                            filterData.SortProperty = Object.keys(params.sorting())[0],
                            filterData.SortType = params.sorting()[Object.keys(params.sorting())[0]];
                        var keys = Object.keys(filterData2);
                        for (var i = 0; i < keys.length; i++) {
                            filterData[keys[i]] = filterData2[keys[i]];
                        }
                        return ProductListsService.getAll(filterData).then(function (data) {
                            params.total(data.FilteredCount);
                            return data.Data;
                        });
                    }
                });
        }

        vm.cancel = cancel;
        vm.del = del;
        vm.save = save;
        vm.add = add;

        ///////////////////////////////////

        function cancel(row) {
            vm.tableParams.reload();
        }

        function del(row) {
            if (confirm("Silmek istediğinize emin misiniz?")) {
                ProductListsService.del(row).then(function (data) {
                    vm.tableParams.reload();
                });
            }
        }

        function save(row) {
            if (row.Id) {
                ProductListsService.edit(row).then(function (data) {
                    vm.tableParams.reload();
                });
            } else {
                ProductListsService.add(row).then(function (data) {
                    vm.tableParams.reload();
                });
            }
        }

        function add() {
            vm.tableParams.data.unshift({
                Name: "",
                isEditing: true
            });
        }
    }
})();