(function () {
    'use strict';

    angular
        .module('app.blogPostCategory')
        .controller('BlogPostCategoryListController', BlogPostCategoryListController);

    BlogPostCategoryListController.$inject = ['$scope', '$location', 'BlogPostCategoryService', 'NgTableParams', '$timeout'];

    function BlogPostCategoryListController($scope, $location, BlogPostCategoryService, NgTableParams, $timeout) {
        var vm = this;

        function activate() {
            vm.getCategoryList();

            vm.firstLoad = true;
            vm.tableParams = new NgTableParams(
                angular.extend({ page: 1, count: 10 }, $location.search()),
                {
                    getData: function (params) {
                        if (!vm.firstLoad) {
                            $location.search(params.url());
                        }
                        vm.firstLoad = false;
                        var filterData2 = params.filter();
                        var filterData = {};
                        filterData.PageIndex = params.page(),
                            filterData.PageSize = params.count(),
                            filterData.SortProperty = Object.keys(params.sorting())[0],
                            filterData.SortType = params.sorting()[Object.keys(params.sorting())[0]];
                        var keys = Object.keys(filterData2);
                        for (var i = 0; i < keys.length; i++) {
                            filterData[keys[i]] = filterData2[keys[i]];
                        }
                        return BlogPostCategoryService.getCategories(filterData).then(function (data) {
                            params.total(data.FilteredCount);
                            return data.Data;
                        });
                    }
                });
        }

        vm.getCategoryList = function () {
            BlogPostCategoryService.getCategories({ PageSize: -1 })
                .then(function (data) {
                    vm.categories = data.Data;
                    $timeout(function () {
                        $(".category-list").treetable({ expandable: true });
                        $(".category-list").treetable('expandAll');
                    }, 0);
                });
        };

        vm.refresh = function () {
            vm.loading = true;
            vm.tableParams.reload();
            vm.loading = false;
        };

        activate();
    }
})();