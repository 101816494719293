(function () {
    'use strict';

    angular
        .module('app.shipmentPrice')
        .controller('EditController', EditController);

    EditController.$inject = ['$location', '$stateParams', 'ShipmentPriceService'];

    function EditController($location, $stateParams, Service) {
        /* jshint validthis:true */
        var vm = this;

        vm.loading = true;
        vm.title = '';

        Service.get($stateParams.Id).then(function (data) {
            vm.entity = data;
            vm.loading = false;
        });

        vm.fields = Service.getFields();

        vm.edit = function () {
            if (vm.form.$valid) {
                vm.loading = true;
                Service.edit(vm.entity).then(function () {
                    vm.loading = false;
                    $location.path('/shipmentPrice/detail/' + vm.entity.Id);
                });
            }
        };

        vm.cancel = function () {
            $location.path('/shipmentPrice/detail/' + vm.entity.Id);
        };
    }
})();