(function () {
    'use strict';

    var json = null;

    angular
        .module('app.settings')
        .controller('SettingsEinvoiceController', EinvoiceController);

    EinvoiceController.$inject = ['$scope', '$location', 'SettingsService', 'GeneralService', 'EInvoiceSettingService', '$uibModal','SharedData'];

    function EinvoiceController($scope, $location, SettingsService, GeneralService, EInvoiceSettingService, $uibModal, SharedData) {
        var vm = this;
        vm.SharedData = SharedData;
        vm.providers = [];
        vm.defaultProvider = null;
        vm.integrattionSett = {};
        vm.InvoiceAllData = null;
        vm.selectColor = null;
        vm.logoLink = null;
        vm.signLink = null;
        vm.freeTemplate = [];
        vm.vipTemplate = [];
        vm.preview = preview;
        vm.show = false;
        vm.selectTemplate = null;


        function activate() {
            vm.selectTemplate = null;
            vm.firstLoad = true;

            //console.log(vm.InvoiceAllData);
            
            vm.EInvoiceProviders = EInvoiceSettingService.getAllProviders().then(function (response) {
                vm.defaultProvider = response.filter(prov => prov.Default === true);
                vm.defaultProvider = vm.defaultProvider[0];
                vm.providers = response;

            });

            vm.IntegrationSettings = EInvoiceSettingService.getEInvoiceSettings().then(function (response1) {
                //console.log(response1);
                vm.integrattionSett = response1;
                //MergeData(vm.providers, vm.integrattionSett);
            });

            vm.EInvoiceTemplates = EInvoiceSettingService.getEInvoiceTemplates().then(function (response) {
                response.forEach(function (element) {
                    //console.log(element);
                    if (element.isPremium === false) {
                        vm.freeTemplate.push(element);
                    }
                    else {
                        vm.vipTemplate.push(element);
                    }
                });
            });

         
        }

        activate();

        vm.cancel = cancel;
        vm.save = save;
        vm.add = add;
        vm.MergeData = MergeData;
        vm.click = click;
        vm.saveProviderSettings = saveProviderSettings;
        vm.saveGlobalSettings = saveGlobalSettings;
        vm.saveDefaultXlst = saveDefaultXlst;
        vm.choiceProvider = choiceProvider;


        ///////////////////////////////////

        function MergeData(provider,setting) {
            var mergedata = { ...provider, ...setting }

            //console.log(mergedata);

            vm.InvoiceAllData = mergedata;

            return mergedata;
        }

        function click() {
            var json = JSON.stringify(vm.InvoiceAllData);

            //console.log(json);
        }

        vm.shutdown = function () {
            vm.loading = true;
            GeneralService.shutdown()
                .then(function () {
                    vm.loading = false;
                });
        };

        function cancel(row, rowForm) {
            vm.tableParams.reload();
        }

        function save(row) {

            //console.log(row);

            if (row) {
                EInvoiceSettingService.editInvoice(row).then(function (data) {
                    //console.log(data);
                    if (data.IsCompletedSuccessfully){
                        toastr.success("Ayarlariniz Kaydedildi.", "Fatura Ayarlari");
                    } else {
                        toastr.warning("Ayarlar kaydedilirken sorun olustu.", "Fatura Ayarlari");
                    }
                });
            } else {
                //add(val).then(function (data) {
                //    vm.tableParams.reload();

                //});
            }

        }

        function choiceProvider(row) {
            row.Default = true;
            saveProviderSettings(row);

        }

        function saveProviderSettings(row) {

            if (row) {
                EInvoiceSettingService.editInvoiceProvider(row).then(function (data) {

                    if(data.IsCompletedSuccessfully) {
                        toastr.success("Ayarlariniz Kaydedildi.", "Fatura Ayarlari");
                        //console.log(row);
                        if (row.Default === true) {
                            vm.defaultProvider = row;
                        }
                        else {
                            vm.defaultProvider = null;
                        }
                    } else {
                        toastr.warning("Ayarlar kaydedilirken sorun olustu.", "Fatura Ayarlari");
                    }

                });
            }

        }

        function saveGlobalSettings(row) {

            if (row) {
                EInvoiceSettingService.editInvoiceGlobalSett(row).then(function (data) {

                    if (data === true) {
                        toastr.success("Ayarlariniz Kaydedildi.", "Fatura Ayarlari");
                    } else {
                        toastr.warning("Ayarlar kaydedilirken sorun olustu.", "Fatura Ayarlari");
                    }

                });
            }

        }

        function preview(row) {
            var decodedString = b64DecodeUnicode(row.XSLTBase64);
            //console.log(decodedString);
            vm.modalTitle = "Fatura G�r�nt�leme";
            $uibModal.open({
                templateUrl: 'ModalContent.html',
                controller: 'SettingModalController',
                controllerAs: 'vm',
                windowClass: 'app-modal-window',
                resolve: {
                    items: function () {
                        return {
                            data: decodedString
                        };
                    }
                }
            });

        }

        function b64DecodeUnicode(str) {
            return decodeURIComponent(atob(str).split('').map(function (c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            }).join(''));
        }

        function saveDefaultXlst(row) {
            //console.log(row);

            EInvoiceSettingService.saveXsltDefault(row).then(function (data) {
                if (data === 1) {
                   // toastr.info("Basariyla Ayarlandi.", "Fatura Ayari");
                    vm.show = true;
                    vm.selectTemplate = row;

                }
                else {
                    //toastr.warning("Sablon Secilirken Sorun Olustu.", "Fatura Ayari");
                    vm.show = false;

                }
                vm.loading = false;

            })
                .catch(function () {
                    vm.loading = false;

                });
        }

        //function findDiff(arr) {
        //    var changed = []

        //    var values = Object.values(arr);

        //    diff(values, beforeData);

        //    function diff(arr, beforeData) {

        //        arr.shift();     

        //        arr.forEach((val, index) => {

        //            if (Object.entries(val).toString() !== Object.entries(beforeData[index]).toString()) {
        //                changed.push(val);
        //            }

        //        });

        //    };

        //    return changed;
        //}

        function add() {
            vm.tableParams.data.unshift({
                Name: "",
                isEditing: true
            });
        }

        vm.changeActivation = function (data, provider) {
            vm.loading = true;
            if (data == provider) {
                product.Active = true;
            } else {
                product.Active = false;
            }
            ProductsService
                .editProduct(product)
                .then(function (data) {
                    vm.tableParams.reload();
                    vm.loading = false;
                });
        };

        vm.settingAddImageFields = EInvoiceSettingService.getSettingAddImageFields();

        vm.ImageSaveByFileManager = function () {
            vm.loading = true;
            //console.log("vm.settingAddImages", vm.settingAddImages);
            EInvoiceSettingService.addImageByFileManager()
                .then(function (data) {
                    vm.loading = false;
                    logger.success("Kay�t i�lemi ba�ar�yla tamamlanm��t�r.");
                    $location.path('/filemanager');
                })
                .catch(function () {
                    vm.loading = false;
                });
        };

        vm.saveXslt = function () {

            vm.loading = true;

            var XsltConfig =
            {
                "LogoURL": vm.logoLink,
                "SignURL": vm.signLink,
                "Color": vm.selectColor,
                "Type": vm.selectTemplate.InvoiceType
            };

            json = JSON.stringify(XsltConfig);

            vm.data = json;

            vm.show = false;  
            vm.modalTitle = "Fatura G�r�nt�leme";
            $uibModal.open({
                templateUrl: 'ModalContent.html',
                controller: 'SettingModalController',
                controllerAs: 'vm',
                windowClass: 'app-modal-window',
                resolve: {
                    items: function () {
                        return {
                            data: json
                        };
                    }
                }
            });

        }
    }


    angular
        .module('app.settings')
        .controller('SettingModalController', SettingModalController);

    SettingModalController.$inject = ['$scope', '$stateParams', '$filter', '$location', 'logger', 'SettingsService', 'EInvoiceSettingService', '$sce','items'];

    function SettingModalController($scope, $stateParams, $filter, $location, logger, SettingsService, EInvoiceSettingService, $sce, items) {

        var vm = this;
        var parser = null;
        var xmlDoc = null;
     

        activate();
        vm.loading = true;

        function activate() {

            vm.data = items.data;

            //console.log(vm.data);

            parser = new DOMParser();
            xmlDoc = parser.parseFromString(vm.data, "text/xml");

            var html = xmlDoc.getElementsByTagName("html")[0];

            var outer = html.outerHTML; 

            //console.log(html.outerHTML);
            //console.log(outer);


            if (vm.data.includes("?xml") === true) {
                vm.loading = false;
                vm.content = $sce.trustAsHtml(outer);

            }
            else {
                EInvoiceSettingService.saveXsltConfig(vm.data).then(function (data) {

                    if (data !== null) {
                        toastr.success("Ayarlariniz Kaydedildi.", "Fatura Ayarlari");
                        vm.content = $sce.trustAsHtml(data);
                        vm.loading = false;
                    } else {
                        toastr.warning("Ayarlar kaydedilirken sorun olustu.", "Fatura Ayarlari");
                        vm.content = $sce.trustAsHtml(data);
                        vm.loading = false;
                    }

                });
            }
            vm.loading = false;

        }

    }

})();