(function () {
    'use strict';

    angular
        .module('app.reporting')
        .controller('VisitorReportingController', Controller);

    Controller.$inject = ['$scope', '$timeout', 'GeneralService'];

    function Controller($scope, $timeout, GeneralService) {
        var vm = this;

        activate();

        function activate() {
            GeneralService.getGoogleToken()
                .then(function (data) {
                    vm.googleToken = data;
                    if (vm.googleToken.Token) {
                        gapi.analytics.ready(function () {
                            gapi.analytics.auth.authorize({
                                'serverAuth': {
                                    'access_token': vm.googleToken.Token
                                }
                            });
                            vm.activateGoogleAnalytics();
                        });
                    }
                });
        }

        vm.dateOptions = {
            locale: 'tr',
            format: 'dd.MM.yyyy'
        };

        vm.activateGoogleAnalytics = function () {
            vm.VisitorCountStartDate = new Date();
            vm.VisitorCountStartDate.setDate(vm.VisitorCountStartDate.getDate() - 30);
            vm.VisitorCountEndDate = new Date();

            vm.AcquisitionCountStartDate = new Date();
            vm.AcquisitionCountStartDate.setDate(vm.AcquisitionCountStartDate.getDate() - 30);
            vm.AcquisitionCountEndDate = new Date();

            vm.GoalsStartDate = new Date();
            vm.GoalsStartDate.setDate(vm.GoalsStartDate.getDate() - 30);
            vm.GoalsEndDate = new Date();

            vm.BehaviourExitStartDate = new Date();
            vm.BehaviourExitStartDate.setDate(vm.BehaviourExitStartDate.getDate() - 30);
            vm.BehaviourExitEndDate = new Date();

            vm.SourceMediumStartDate = new Date();
            vm.SourceMediumStartDate.setDate(vm.SourceMediumStartDate.getDate() - 30);
            vm.SourceMediumEndDate = new Date();

            vm.CampaignsStartDate = new Date();
            vm.CampaignsStartDate.setDate(vm.CampaignsStartDate.getDate() - 30);
            vm.CampaignsEndDate = new Date();

            vm.PaidKeywordsStartDate = new Date();
            vm.PaidKeywordsStartDate.setDate(vm.PaidKeywordsStartDate.getDate() - 30);
            vm.PaidKeywordsEndDate = new Date();

            vm.OrganicKeywordsStartDate = new Date();
            vm.OrganicKeywordsStartDate.setDate(vm.OrganicKeywordsStartDate.getDate() - 30);
            vm.OrganicKeywordsEndDate = new Date();

            vm.AllPagesStartDate = new Date();
            vm.AllPagesStartDate.setDate(vm.AllPagesStartDate.getDate() - 30);
            vm.AllPagesEndDate = new Date();

            vm.ProductPerformancesStartDate = new Date();
            vm.ProductPerformancesStartDate.setDate(vm.ProductPerformancesStartDate.getDate() - 30);
            vm.ProductPerformancesEndDate = new Date();

            vm.BrowsersStartDate = new Date();
            vm.BrowsersStartDate.setDate(vm.BrowsersStartDate.getDate() - 30);
            vm.BrowsersEndDate = new Date();

            vm.MobileDevicesStartDate = new Date();
            vm.MobileDevicesStartDate.setDate(vm.MobileDevicesStartDate.getDate() - 30);
            vm.MobileDevicesEndDate = new Date();

            vm.AudienceCityStartDate = new Date();
            vm.AudienceCityStartDate.setDate(vm.AudienceCityStartDate.getDate() - 30);
            vm.AudienceCityEndDate = new Date();

            vm.DemographicAgeStartDate = new Date();
            vm.DemographicAgeStartDate.setDate(vm.DemographicAgeStartDate.getDate() - 30);
            vm.DemographicAgeEndDate = new Date();

            vm.DemographicGenderStartDate = new Date();
            vm.DemographicGenderStartDate.setDate(vm.DemographicGenderStartDate.getDate() - 30);
            vm.DemographicGenderEndDate = new Date();

            vm.activateVisitors();
            vm.activateActiveUser();
            vm.activateAcquisition();
            vm.activateBehaviourExit();
            vm.activateAudienceCity();
            vm.activateSourceMedium();
            $timeout(function () {
                vm.activateCampaigns();
                vm.activatePaidKeywords();
                vm.activateOrganicKeywords();
                vm.activateAllPages();
                vm.activateProductPerformances();
                vm.activateBrowsers();
                vm.activateMobileDevices();
                vm.activateDemographicAge();
                vm.activateDemographicGender();
            }, 5000);
        };

        vm.activateVisitors = function () {
            if (!vm.visitorChart) {
                vm.visitorChart = new gapi.analytics.googleCharts.DataChart({
                    query: {
                        'ids': 'ga:' + vm.googleToken.ViewCode,
                        'start-date': moment(vm.VisitorCountStartDate).format('YYYY-MM-DD'),
                        'end-date': moment(vm.VisitorCountEndDate).format('YYYY-MM-DD'),
                        'metrics': 'ga:sessions,ga:users',
                        'dimensions': 'ga:date'
                    },
                    chart: {
                        'container': 'visitor-chart-container',
                        'type': 'LINE',
                        'options': {
                            'width': '100%'
                        }
                    }
                });
            } else {
                vm.visitorChart.set({
                    query: {
                        'start-date': moment(vm.VisitorCountStartDate).format('YYYY-MM-DD'),
                        'end-date': moment(vm.VisitorCountEndDate).format('YYYY-MM-DD')
                    }
                });
            }
            vm.visitorChart.execute();
        };
        vm.activateActiveUser = function () {
            vm.getActiveUser();
            vm.activeUserTimeout = $timeout(function () {
                vm.activateActiveUser();
            }, 10000);
        };
        $scope.$on('$destroy', function () {
            $timeout.cancel(vm.activeUserTimeout);
        });
        vm.getActiveUser = function () {
            gapi.client.analytics.data.realtime.get({
                'ids': 'ga:' + vm.googleToken.ViewCode,
                'metrics': "rt:activeUsers",
                'dimensions': 'rt:medium,rt:pagePath'
            }).then(function (t) {
                vm.activeVisitorPage = t.result.rows;
                angular.element('#active-users-container').text(t.result.totalsForAllResults['rt:activeUsers']);
            });
        };
        vm.activateAcquisition = function () {
            if (!vm.acquisitionChart) {
                vm.acquisitionChart = new gapi.analytics.googleCharts.DataChart({
                    query: {
                        'ids': 'ga:' + vm.googleToken.ViewCode,
                        'start-date': moment(vm.AcquisitionCountStartDate).format('YYYY-MM-DD'),
                        'end-date': moment(vm.AcquisitionCountEndDate).format('YYYY-MM-DD'),
                        'metrics': 'ga:users',
                        'dimensions': 'ga:channelGrouping'
                    },
                    chart: {
                        'container': 'acquisition-chart-container',
                        type: 'PIE',
                        options: {
                            width: '100%',
                            pieHole: 4 / 9
                        }
                    }
                });
            } else {
                vm.acquisitionChart.set({
                    query: {
                        'start-date': moment(vm.AcquisitionCountStartDate).format('YYYY-MM-DD'),
                        'end-date': moment(vm.AcquisitionCountEndDate).format('YYYY-MM-DD'),
                    }
                });
            }
            vm.acquisitionChart.execute();
        };
        vm.activateBehaviourExit = function () {
            if (!vm.behaviourExitChart) {
                vm.behaviourExitChart = new gapi.analytics.googleCharts.DataChart({
                    query: {
                        'ids': 'ga:' + vm.googleToken.ViewCode,
                        'start-date': moment(vm.BehaviourExitStartDate).format('YYYY-MM-DD'),
                        'end-date': moment(vm.BehaviourExitEndDate).format('YYYY-MM-DD'),
                        'metrics': 'ga:exits',
                        'dimensions': 'ga:exitPagePath',
                        'sort': '-ga:exits',
                        'max-results': '10'
                    },
                    chart: {
                        'container': 'behaviour-exit-chart-container',
                        type: 'TABLE',
                        options: {
                            width: '100%'
                        }
                    }
                });
            } else {
                vm.behaviourExitChart.set({
                    query: {
                        'start-date': moment(vm.BehaviourExitStartDate).format('YYYY-MM-DD'),
                        'end-date': moment(vm.BehaviourExitEndDate).format('YYYY-MM-DD')
                    }
                });
            }
            vm.behaviourExitChart.execute();
        };
        vm.activateGoals = function () {
            if (!vm.goalsChart) {
                vm.goalsChart = new gapi.analytics.googleCharts.DataChart({
                    query: {
                        'ids': 'ga:' + vm.googleToken.ViewCode,
                        'start-date': moment(vm.GoalsStartDate).format('YYYY-MM-DD'),
                        'end-date': moment(vm.GoalsEndDate).format('YYYY-MM-DD'),
                        'metrics': 'ga:goalValueAll',
                        'dimensions': 'ga:goalCompletionLocation',
                        'sort': '-ga:users',
                        'max-results': '10'
                    },
                    chart: {
                        'container': 'goals-chart-container',
                        type: 'TABLE',
                        options: {
                            width: '100%'
                        }
                    }
                });
            } else {
                vm.goalsChart.set({
                    query: {
                        'start-date': moment(vm.GoalsStartDate).format('YYYY-MM-DD'),
                        'end-date': moment(vm.GoalsEndDate).format('YYYY-MM-DD')
                    }
                });
            }
            vm.goalsChart.execute();
        };
        vm.activateSourceMedium = function () {
            if (!vm.sourceMediumChart) {
                vm.sourceMediumChart = new gapi.analytics.googleCharts.DataChart({
                    query: {
                        'ids': 'ga:' + vm.googleToken.ViewCode,
                        'start-date': moment(vm.SourceMediumStartDate).format('YYYY-MM-DD'),
                        'end-date': moment(vm.SourceMediumEndDate).format('YYYY-MM-DD'),
                        'metrics': 'ga:users,ga:bounceRate,ga:avgSessionDuration,ga:goalCompletionsAll,ga:goalValueAll,ga:goalConversionRateAll',
                        'dimensions': 'ga:source,ga:medium',
                        'sort': '-ga:users',
                        'max-results': '10'
                    },
                    chart: {
                        'container': 'source-medium-chart-container',
                        type: 'TABLE',
                        options: {
                            width: '100%'
                        }
                    }
                });
            } else {
                vm.sourceMediumChart.set({
                    query: {
                        'start-date': moment(vm.SourceMediumStartDate).format('YYYY-MM-DD'),
                        'end-date': moment(vm.SourceMediumEndDate).format('YYYY-MM-DD')
                    }
                });
            }
            vm.sourceMediumChart.execute();
        };
        vm.activateCampaigns = function () {
            if (!vm.campaignsChart) {
                vm.campaignsChart = new gapi.analytics.googleCharts.DataChart({
                    query: {
                        'ids': 'ga:' + vm.googleToken.ViewCode,
                        'start-date': moment(vm.CampaignsStartDate).format('YYYY-MM-DD'),
                        'end-date': moment(vm.CampaignsEndDate).format('YYYY-MM-DD'),
                        'metrics': 'ga:users,ga:newUsers,ga:avgSessionDuration,ga:pageviewsPerSession,ga:goalCompletionsAll,ga:goalValueAll,ga:goalConversionRateAll',
                        'dimensions': 'ga:campaign',
                        'sort': '-ga:users',
                        'max-results': '10'
                    },
                    chart: {
                        'container': 'campaigns-chart-container',
                        type: 'TABLE',
                        options: {
                            width: '100%'
                        }
                    }
                });
            } else {
                vm.campaignsChart.set({
                    query: {
                        'start-date': moment(vm.CampaignsStartDate).format('YYYY-MM-DD'),
                        'end-date': moment(vm.CampaignsEndDate).format('YYYY-MM-DD')
                    }
                });
            }
            vm.campaignsChart.execute();
        };
        vm.activatePaidKeywords = function () {
            if (!vm.paidKeywordsChart) {
                vm.paidKeywordsChart = new gapi.analytics.googleCharts.DataChart({
                    query: {
                        'ids': 'ga:' + vm.googleToken.ViewCode,
                        'start-date': moment(vm.PaidKeywordsStartDate).format('YYYY-MM-DD'),
                        'end-date': moment(vm.PaidKeywordsEndDate).format('YYYY-MM-DD'),
                        'metrics': 'ga:users,ga:newUsers,ga:avgSessionDuration,ga:pageviewsPerSession,ga:goalCompletionsAll,ga:goalValueAll,ga:goalConversionRateAll',
                        'dimensions': 'ga:adMatchedQuery',
                        'sort': '-ga:users',
                        'max-results': '10'
                    },
                    chart: {
                        'container': 'paid-keywords-chart-container',
                        type: 'TABLE',
                        options: {
                            width: '100%'
                        }
                    }
                });
            } else {
                vm.paidKeywordsChart.set({
                    query: {
                        'start-date': moment(vm.PaidKeywordsStartDate).format('YYYY-MM-DD'),
                        'end-date': moment(vm.PaidKeywordsEndDate).format('YYYY-MM-DD')
                    }
                });
            }
            vm.paidKeywordsChart.execute();
        };
        vm.activateOrganicKeywords = function () {
            if (!vm.organicKeywordsChart) {
                vm.organicKeywordsChart = new gapi.analytics.googleCharts.DataChart({
                    query: {
                        'ids': 'ga:' + vm.googleToken.ViewCode,
                        'start-date': moment(vm.OrganicKeywordsStartDate).format('YYYY-MM-DD'),
                        'end-date': moment(vm.OrganicKeywordsEndDate).format('YYYY-MM-DD'),
                        'metrics': 'ga:users,ga:newUsers,ga:avgSessionDuration,ga:pageviewsPerSession,ga:goalCompletionsAll,ga:goalValueAll,ga:goalConversionRateAll',
                        'dimensions': 'ga:searchKeyword',
                        'sort': '-ga:users',
                        'max-results': '10'
                    },
                    chart: {
                        'container': 'organic-keywords-chart-container',
                        type: 'TABLE',
                        options: {
                            width: '100%'
                        }
                    }
                });
            } else {
                vm.organicKeywordsChart.set({
                    query: {
                        'start-date': moment(vm.OrganicKeywordsStartDate).format('YYYY-MM-DD'),
                        'end-date': moment(vm.OrganicKeywordsEndDate).format('YYYY-MM-DD')
                    }
                });
            }
            vm.organicKeywordsChart.execute();
        };
        vm.activateAllPages = function () {
            if (!vm.allPagesChart) {
                vm.allPagesChart = new gapi.analytics.googleCharts.DataChart({
                    query: {
                        'ids': 'ga:' + vm.googleToken.ViewCode,
                        'start-date': moment(vm.AllPagesStartDate).format('YYYY-MM-DD'),
                        'end-date': moment(vm.AllPagesEndDate).format('YYYY-MM-DD'),
                        'metrics': 'ga:bounceRate,ga:pageValue,ga:entrances,ga:pageviews,ga:uniquePageviews,ga:avgTimeOnPage,ga:exitRate',
                        'dimensions': 'ga:pageTitle',
                        'sort': '-ga:pageviews',
                        'max-results': '10'
                    },
                    chart: {
                        'container': 'all-pages-chart-container',
                        type: 'TABLE',
                        options: {
                            width: '100%'
                        }
                    }
                });
            } else {
                vm.allPagesChart.set({
                    query: {
                        'start-date': moment(vm.AllPagesStartDate).format('YYYY-MM-DD'),
                        'end-date': moment(vm.AllPagesEndDate).format('YYYY-MM-DD')
                    }
                });
            }
            vm.allPagesChart.execute();
        };
        vm.activateProductPerformances = function () {
            if (!vm.productPerformancesChart) {
                vm.productPerformancesChart = new gapi.analytics.googleCharts.DataChart({
                    query: {
                        'ids': 'ga:' + vm.googleToken.ViewCode,
                        'start-date': moment(vm.ProductPerformancesStartDate).format('YYYY-MM-DD'),
                        'end-date': moment(vm.ProductPerformancesEndDate).format('YYYY-MM-DD'),
                        'metrics': 'ga:itemQuantity,ga:uniquePurchases,ga:revenuePerItem,ga:itemRevenue,ga:itemsPerPurchase,ga:buyToDetailRate,ga:cartToDetailRate,ga:productRefundAmount',
                        'dimensions': 'ga:productName',
                        'sort': '-ga:productName',
                        'max-results': '10'
                    },
                    chart: {
                        'container': 'product-performances-chart-container',
                        type: 'TABLE',
                        options: {
                            width: '100%'
                        }
                    }
                });
            } else {
                vm.productPerformancesChart.set({
                    query: {
                        'start-date': moment(vm.ProductPerformancesStartDate).format('YYYY-MM-DD'),
                        'end-date': moment(vm.ProductPerformancesEndDate).format('YYYY-MM-DD')
                    }
                });
            }
            vm.productPerformancesChart.execute();
        };
        vm.activateBrowsers = function () {
            if (!vm.browsersChart) {
                vm.browsersChart = new gapi.analytics.googleCharts.DataChart({
                    query: {
                        'ids': 'ga:' + vm.googleToken.ViewCode,
                        'start-date': moment(vm.BrowsersStartDate).format('YYYY-MM-DD'),
                        'end-date': moment(vm.BrowsersEndDate).format('YYYY-MM-DD'),
                        'metrics': 'ga:users,ga:newUsers,ga:sessions,ga:bounceRate,ga:avgSessionDuration,ga:pageviewsPerSession,ga:transactions,ga:transactionsPerSession,ga:transactionRevenue',
                        'dimensions': 'ga:browser',
                        'sort': '-ga:users',
                        'max-results': '10'
                    },
                    chart: {
                        'container': 'browsers-chart-container',
                        type: 'TABLE',
                        options: {
                            width: '100%'
                        }
                    }
                });
            } else {
                vm.browsersChart.set({
                    query: {
                        'start-date': moment(vm.BrowsersStartDate).format('YYYY-MM-DD'),
                        'end-date': moment(vm.BrowsersEndDate).format('YYYY-MM-DD')
                    }
                });
            }
            vm.browsersChart.execute();
        };
        vm.activateMobileDevices = function () {
            if (!vm.mobileDevicesChart) {
                vm.mobileDevicesChart = new gapi.analytics.googleCharts.DataChart({
                    query: {
                        'ids': 'ga:' + vm.googleToken.ViewCode,
                        'start-date': moment(vm.MobileDevicesStartDate).format('YYYY-MM-DD'),
                        'end-date': moment(vm.MobileDevicesEndDate).format('YYYY-MM-DD'),
                        'metrics': 'ga:users,ga:newUsers,ga:sessions,ga:bounceRate,ga:avgSessionDuration,ga:pageviewsPerSession,ga:transactions,ga:transactionsPerSession,ga:transactionRevenue',
                        'dimensions': 'ga:mobileDeviceInfo',
                        'sort': '-ga:users',
                        'max-results': '10'
                    },
                    chart: {
                        'container': 'mobile-devices-chart-container',
                        type: 'TABLE',
                        options: {
                            width: '100%'
                        }
                    }
                });
            } else {
                vm.mobileDevicesChart.set({
                    query: {
                        'start-date': moment(vm.MobileDevicesStartDate).format('YYYY-MM-DD'),
                        'end-date': moment(vm.MobileDevicesEndDate).format('YYYY-MM-DD')
                    }
                });
            }
            vm.mobileDevicesChart.execute();
        };
        vm.activateAudienceCity = function () {
            if (!vm.audienceCityChart) {
                vm.audienceCityChart = new gapi.analytics.googleCharts.DataChart({
                    query: {
                        'ids': 'ga:' + vm.googleToken.ViewCode,
                        'start-date': moment(vm.AudienceCityStartDate).format('YYYY-MM-DD'),
                        'end-date': moment(vm.AudienceCityEndDate).format('YYYY-MM-DD'),
                        'metrics': 'ga:users,ga:newUsers,ga:sessions,ga:bounceRate,ga:avgSessionDuration,ga:pageviewsPerSession,ga:transactions,ga:transactionsPerSession,ga:transactionRevenue',
                        'dimensions': 'ga:country,ga:city',
                        'sort': '-ga:users',
                        'max-results': '10'
                    },
                    chart: {
                        'container': 'audience-city-chart-container',
                        type: 'TABLE',
                        options: {
                            width: '100%'
                        }
                    }
                });
            } else {
                vm.audienceCityChart.set({
                    query: {
                        'start-date': moment(vm.AudienceCityStartDate).format('YYYY-MM-DD'),
                        'end-date': moment(vm.AudienceCityEndDate).format('YYYY-MM-DD')
                    }
                });
            }
            vm.audienceCityChart.execute();
        };
        vm.activateDemographicAge = function () {
            if (!vm.demographicAgeChart) {
                vm.demographicAgeChart = new gapi.analytics.googleCharts.DataChart({
                    query: {
                        'ids': 'ga:' + vm.googleToken.ViewCode,
                        'start-date': moment(vm.DemographicAgeStartDate).format('YYYY-MM-DD'),
                        'end-date': moment(vm.DemographicAgeEndDate).format('YYYY-MM-DD'),
                        'metrics': 'ga:users',
                        'dimensions': 'ga:userAgeBracket'
                    },
                    chart: {
                        'container': 'demographic-age-chart-container',
                        type: 'COLUMN',
                        options: {
                            width: '100%',
                            pieHole: 4 / 9
                        }
                    }
                });
            } else {
                vm.demographicAgeChart.set({
                    query: {
                        'start-date': moment(vm.DemographicAgeStartDate).format('YYYY-MM-DD'),
                        'end-date': moment(vm.DemographicAgeEndDate).format('YYYY-MM-DD'),
                    }
                });
            }
            vm.demographicAgeChart.execute();
        };
        vm.activateDemographicGender = function () {
            if (!vm.demographicGenderChart) {
                vm.demographicGenderChart = new gapi.analytics.googleCharts.DataChart({
                    query: {
                        'ids': 'ga:' + vm.googleToken.ViewCode,
                        'start-date': moment(vm.DemographicGenderStartDate).format('YYYY-MM-DD'),
                        'end-date': moment(vm.DemographicGenderEndDate).format('YYYY-MM-DD'),
                        'metrics': 'ga:users',
                        'dimensions': 'ga:userGender'
                    },
                    chart: {
                        'container': 'demographic-gender-chart-container',
                        type: 'PIE',
                        options: {
                            width: '100%',
                            pieHole: 4 / 9
                        }
                    }
                });
            } else {
                vm.demographicGenderChart.set({
                    query: {
                        'start-date': moment(vm.DemographicGenderStartDate).format('YYYY-MM-DD'),
                        'end-date': moment(vm.DemographicGenderEndDate).format('YYYY-MM-DD'),
                    }
                });
            }
            vm.demographicGenderChart.execute();
        };
    }
})();