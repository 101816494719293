(function () {
    'use strict';

    angular
        .module('app.pages')
        .controller('PagesListController', PagesListController);

    PagesListController.$inject = ['$scope', '$location', 'PagesService', 'NgTableParams', 'GeneralService', 'CulturesService'];

    function PagesListController($scope, $location, PagesService, NgTableParams, GeneralService, CulturesService) {
        var vm = this;

        activate();

        function activate() {
            vm.firstLoad = true;
            vm.tableParams = new NgTableParams(
                angular.extend({ page: 1, count: 18, sorting: { Name: "asc" } }, $location.search()),
                {
                    getData: function (params) {
                        if (!vm.firstLoad) {
                            $location.search(params.url());
                        }
                        vm.firstLoad = false;
                        var filterData2 = params.filter();
                        var filterData = {};
                        filterData.PageIndex = params.page(),
                            filterData.PageSize = params.count(),
                            filterData.SortProperty = Object.keys(params.sorting())[0],
                            filterData.SortType = params.sorting()[Object.keys(params.sorting())[0]];
                        var keys = Object.keys(filterData2);
                        for (var i = 0; i < keys.length; i++) {
                            filterData[keys[i]] = filterData2[keys[i]];
                        }
                        return PagesService.getAll(filterData).then(function (data) {
                            params.total(data.FilteredCount);
                            vm.loading = false;
                            return data.Data;
                        });
                    }
                });

            vm.Cultures = CulturesService.getAll({ PageSize: -1 }).then(function (data) {
                vm.cultures = data.Data;
                return data.Data.map(function (item) { return { id: item.Id, title: item.Description }; });
            });
        }

        vm.refresh = function () {
            vm.loading = true;
            vm.tableParams.reload();
        };

        vm.clearCache = function () {
            vm.loading = true;
            GeneralService.clearCache()
                .then(function () {
                    vm.loading = false;
                });
        };

        vm.optimizeAllImage = function () {
            vm.loading = true;
            GeneralService.optimizeAllImage()
                .then(function () {
                    vm.loading = false;
                });
        };

        vm.copyPage = function (Id) {
            vm.loading = true;
            PagesService.copy(Id)
                .then(function () {
                    vm.refresh();
                });
        };

        vm.copyPageToCulture = function (Id, CultureId) {
            vm.loading = true;
            PagesService.copyToCulture(Id, CultureId)
                .then(function () {
                    vm.refresh();
                });
        };
    }
})();