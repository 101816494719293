(function () {
    'use strict';

    angular
        .module('app.pages')
        .run(appRun);

    appRun.$inject = ['routerHelper'];

    function appRun(routerHelper) {
        routerHelper.configureStates(getStates());
    }

    function getStates() {
        return [
            {
                state: 'pages',
                config: {
                    url: '/pages',
                    title: 'Sayfalar',
                    abstract: true,
                    templateUrl: 'app/pages/pages.html',
                    settings: {
                        nav: 4,
                        name: 'cms',
                        icon: 'fa fa-file',
                        content: 'İçerik Yönetimi',
                        resource: 'menu.pages'
                    }
                }
            },
            {
                state: 'pages.list',
                config: {
                    templateUrl: 'app/pages/pages.list.html',
                    controller: 'PagesListController',
                    controllerAs: 'vm',
                    url: '/list',
                    title: 'Sayfa Listesi',
                    settings: {
                        nav: 4,
                        name: 'pages.list',
                        topName: 'cms',
                        content: 'Sayfa Listesi',
                        breadcrumbIcon: 'list',
                        breadcrumb: 'Sayfa Listesi',
                        resource: 'menu.pages.list'
                    }
                }
            },
            {
                state: 'pages.add',
                config: {
                    templateUrl: 'app/pages/pages.add.html',
                    controller: 'PagesAddController',
                    controllerAs: 'vm',
                    url: '/add',
                    title: 'Sayfa Ekle',
                    settings: {
                        topName: 'pages.list',
                        content: 'Sayfa Ekle',
                        resource: 'menu.pages.add'
                    }
                }
            },
            {
                state: 'pages.edit',
                config: {
                    templateUrl: 'app/pages/pages.edit.html',
                    controller: 'PagesEditController',
                    controllerAs: 'vm',
                    url: '/edit/:Id',
                    title: 'Sayfa Düzenle',
                    settings: {
                        topName: 'pages.list',
                        content: 'Sayfa Düzenle',
                        resource: 'menu.pages.edit'
                    }
                }
            },
            {
                state: 'pages.delete',
                config: {
                    templateUrl: 'app/pages/pages.delete.html',
                    controller: 'PagesDeleteController',
                    controllerAs: 'vm',
                    url: '/delete/:Id',
                    title: 'Sayfa Sil'
                }
            },
            {
                state: 'pages.detail',
                config: {
                    templateUrl: 'app/pages/pages.detail.html',
                    controller: 'PagesDetailController',
                    controllerAs: 'vm',
                    url: '/detail/:Id',
                    title: 'Sayfa Detayı',
                    settings: {
                        topName: 'pages.list',
                        breadcrumbIcon: 'zoom_in',
                        resource: 'menu.pages.detail'
                    }
                }
            },
            {
                state: 'pages.build',
                config: {
                    templateUrl: 'app/pages/pages.build.html',
                    controller: 'PagesBuildController',
                    controllerAs: 'vm',
                    url: '/build/:Id',
                    title: 'Sayfa Tasarla',
                    settings: {
                        topName: 'pages.list',
                        breadcrumbIcon: 'build',
                        resource: 'menu.pages.build'
                    }
                }
            }
        ];
    }
})();