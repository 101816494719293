(function () {
    'use strict';

    angular
        .module('app.services')
        .run(appRun);

    appRun.$inject = ['routerHelper'];

    function appRun(routerHelper) {
        routerHelper.configureStates(getStates());
    }

    function getStates() {
        return [
            {
                state: 'services',
                config: {
                    url: '/services',
                    title: 'Servisler',
                    abstract: true,
                    templateUrl: 'app/services/services.html'
                }
            },
            {
                state: 'services.list',
                config: {
                    templateUrl: 'app/services/services.list.html',
                    controller: 'ServicesListController',
                    controllerAs: 'vm',
                    url: '/list',
                    title: 'Servis Listesi'
                }
            }
        ];
    }
})();