(function () {
    'use strict';

    angular
        .module('app.filemanager')
        .run(appRun);

    appRun.$inject = ['routerHelper'];

    function appRun(routerHelper) {
        routerHelper.configureStates(getStates());
    }

    function getStates() {
        return [
            {
                state: 'filemanager',
                config: {
                    url: '/filemanager',
                    title: 'Dosyalar',
                    templateUrl: 'app/filemanager/filemanager.html',
                    settings: {
                        nav: 1,
                        name: 'filemanager',
                        icon: 'fa fa-bar-chart',
                        content: 'Dashboard',
                        breadcrumb: 'Dashboard',
                        breadcrumbIcon: 'folder',
                        resource: 'menu.filemanager',
                        topName: 'system'
                    }
                }
            }
        ];
    }
})();