(function () {
    'use strict';

    angular
        .module('app.spares')
        .run(appRun);

    appRun.$inject = ['routerHelper'];

    function appRun(routerHelper) {
        routerHelper.configureStates(getStates());
    }

    function getStates() {
        return [
            {
                state: 'spares',
                config: {
                    url: '/spares',
                    title: 'Yedek Parçalar',
                    abstract: true,
                    templateUrl: 'app/spares/spares.html'
                }
            },
                {
                    state: 'spares.list',
                    config: {
                        templateUrl: 'app/spares/spares.list.html',
                        controller: 'SparesListController',
                        controllerAs: 'vm',
                        url: '/list',
                        title: 'Yedek Parça Listesi',
                        settings: {
                            nav: 10,
                            name: 'spares.list',
                            topName:'services',
                            content: 'Yedek Parça Listesi'
                        }
                    }
                }
        ];
    }
})();

