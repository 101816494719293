(function () {
    "use strict";

    angular.module("app.layout").controller("Shell", Shell);

    Shell.$inject = [
        "$timeout",
        "SharedData",
        "$location",
        "localStorageService",
        "AccountService",
        "Settings",
        "SettingsService",
        "$translate"
    ];

    function Shell(
        $timeout,
        SharedData,
        $location,
        localStorageService,
        AccountService,
        Settings,
        SettingsService,
        $translate) {
        /*jshint validthis: true */
        var shellvm = this;
        shellvm.SharedData = SharedData;
        shellvm.showPage = false;
        $timeout(function () {
            var $layout = $("body").data("lte.layout");
            if ($layout) {
                $layout.activate();
            }
        }, 1000);
        shellvm.SharedData = SharedData;
        shellvm.SharedData.mainLoading = true;
        AccountService.getUserInfo()
            .then(function (data) {
                SharedData.UserInfo = data;
                shellvm.SharedData.mainLoading = false;
                shellvm.showPage = true;
                setSettings();
            })
            .catch(function () {
                shellvm.SharedData.mainLoading = false;
                shellvm.showPage = true;
                $location.path('/account/loginorsignup');
            });

        function setSettings() {

            SettingsService.getSettings()
                .then(function (settings) {
                    SharedData.Settings = settings;
                    var keys = Object.keys(settings);
                    for (var i = 0; i < keys.length; i++) {
                        Settings[keys[i]] = settings[keys[i]];
                    }

                    $translate
                        .use(localStorageService.get('culture') || Settings.CurrentCulture || 'tr')
                        .then(function () {
                            $translate.refresh();
                        });
                });


            SettingsService.resreshIntegrationSettingsValue();
        }
    }
})();