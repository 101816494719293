(function () {
    'use strict';

    angular
        .module('app.salespoints')
        .factory('SalespointsService', Service);

    Service.$inject = ['$http', '$q', 'Settings', 'ngAuthSettings', 'logger', 'config', 'ProductsGeneralService', 'GeneralService', 'CustomerAddressService', 'SalespointTypesService', 'MediaService'];

    function Service($http, $q, Settings, ngAuthSettings, logger, config, ProductsGeneralService, GeneralService, CustomerAddressService, SalespointTypesService, MediaService) {
        var service = {
            getAll: getAll,
            get: get,
            add: add,
            edit: edit,
            del: del,
            getFields: getFields
        };

        var serviceBase = ngAuthSettings.apiServiceBaseUri + 'api/salespoints/';
        console.log(serviceBase);
        var fields = [{
                key: 'Name',
                type: 'horizontalInput',
                templateOptions: {
                    required: true,
                    type: 'text',
                    label: 'Adı',
                    resource: 'form.name',
                    placeholder: 'Adı'
                }
            },
            {
                key: "Phone",
                type: "horizontalPhone",
                templateOptions: {
                    label: 'Telefon',
                    resource: 'form.phone',
                    required:false
                }
            },
            {
                key: "Phone2",
                type: "horizontalPhone",
                templateOptions: {
                    label: 'Telefon 2',
                    resource: 'form.phone2'
                }
            },
            {
                key: 'Email',
                type: 'horizontalEmail',
                templateOptions: {
                    resource: 'form.email',
                    label: 'Eposta'
                }
            },
            {
                key: 'Description',
                type: 'horizontalTinyMCE',
                templateOptions: {
                    label: 'Açıklama',
                    resource: 'form.description',
                    tinymceOptions: {
                        plugins: 'image code imagetools advlist autolink lists link image charmap print preview hr anchor pagebreak searchreplace wordcount visualblocks visualchars code fullscreen insertdatetime media nonbreaking save table contextmenu directionality emoticons template paste textcolor colorpicker textpattern imagetools codesample toc lineheight',
                        toolbar1: 'undo redo | insert | styleselect | bold italic | sizeselect | bold italic | fontselect |  fontsizeselect lineheightselect | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image',
                        toolbar2: 'print preview media | forecolor backcolor emoticons | codesample',
                        image_advtab: true,
                        image_dimensions: false,
                        templates: [{
                                title: 'Test template 1',
                                content: 'Test 1'
                            },
                            {
                                title: 'Test template 2',
                                content: 'Test 2'
                            }
                        ],
                        content_css: [
                            Settings.WebServer + '/themes/' + Settings.ThemeName + '/dist/css/mazakacore-ecommerce.css'
                        ],
                        image_title: true,

                        //automatic_uploads: true,
                        //images_upload_url: '/api/Media',
                        file_picker_types: 'image',
                        relative_urls: false,
                        forced_root_block: "",
                        height: "400",
                        fontsize_formats: "2px 4px 6px 8px 10px 12px 14px 18px 24px 36px",
                        lineheight_formats: "2px 4px 6px 8px 10px 12px 14px 18px 24px 36px",
                        images_upload_credentials: true,
                        entity_encoding: "raw",
                        file_picker_callback: function (cb, value, meta) {
                            var input = document.createElement('input');
                            input.setAttribute('type', 'file');
                            input.setAttribute('accept', 'image/*');
                            input.onchange = function () {
                                var file = this.files[0];
                                var id = 'blobid' + (new Date()).getTime();
                                var blobCache = tinymce.activeEditor.editorUpload.blobCache;
                                var blobInfo = blobCache.create(id, file);
                                blobCache.add(blobInfo);
                                cb(blobInfo.blobUri(), {
                                    title: file.name
                                });
                            };
                            input.click();
                        },
                        images_upload_handler: function (blobInfo, success, failure) {
                            var formData = new FormData();
                            formData.append('File', blobInfo.blob(), blobInfo.filename());
                            MediaService.add(formData).then(function (data) {
                                var name = Settings.MediaServer + '/' + data.FileName;
                                success(name);
                            }, failure);
                        },
                        setup: function (ed) {
                            if ($('#' + ed.id).closest("fieldset").prop('disabled')) {
                                ed.settings.readonly = true;
                            }
                        }
                    }
                }
            },
            {
                key: 'SalesPointTypeId',
                type: 'horizontalSelect',
                templateOptions: {
                    label: 'Satış Noktası Tipi',
                    resource: 'form.salespoint-type',
                    required: true,
                    options: [{
                        Name: '...'
                    }]
                },
                controller: ['$scope', function ($scope) {
                    SalespointTypesService.getAll({
                        PageSize: -1
                    }).then(function (response) {
                        $scope.to.options = response.Data;
                    });
                }]
            },
            {
                key: 'DisplayOrder',
                type: 'horizontalInput',
                templateOptions: {
                    type: 'number',
                    label: 'Sıra',
                    resource: 'form.display-order',
                    placeholder: 'Sıra'
                }
            },
            {
                key: 'ShowLocationList',
                type: 'horizontalCheckbox',
                templateOptions: {
                    label: 'Listede Göster',
                    resource: 'form.show-location-list',
                    placeholder: 'Listede Göster'
                }
            },
            {
                key: "MediaId",
                type: "horizontalSingleImage",
                templateOptions: {
                    label: "Resim",
                    resource: 'form.image',
                    valueModel: "Media.FullPath"
                }
            },
            {
                key: 'BayiDurum',
                type: 'horizontalInput',
                templateOptions: {
                    label: 'Bayi Durum',
                    resource: 'form.bayi-durum'
                }
            },
            {
                key: 'Yetkili',
                type: 'horizontalInput',
                templateOptions: {
                    label: 'Yetkili',
                    resource: 'form.yetkili'
                }
            },
            {
                key: 'CariAd',
                type: 'horizontalInput',
                templateOptions: {
                    label: 'Cari Adı',
                    resource: 'form.cari-ad'
                }
            },
            {
                key: 'CariKod',
                type: 'horizontalInput',
                templateOptions: {
                    label: 'Cari Kodu',
                    resource: 'form.cari-kodu'
                }
            },
            {
                key: 'MobilePhone',
                type: 'horizontalPhone',
                templateOptions: {
                    label: 'Cep Telefonu',
                    resource: 'form.mobile-phone'
                }
            },
            {
                key: 'MobilePhone2',
                type: 'horizontalPhone',
                templateOptions: {
                    label: 'Cep Telefonu 2',
                    resource: 'form.mobile-phone2'
                }
            },
            {
                key: 'Statu',
                type: 'horizontalInput',
                templateOptions: {
                    label: 'Statu',
                    resource: 'form.statu'
                }
            },
            {
                key: 'InsertDurum',
                type: 'horizontalInput',
                templateOptions: {
                    label: 'Insert Durum',
                    resource: 'form.insert-durum'

                }
            },
            {
                key: 'InsertMiktar',
                type: 'horizontalInput',
                templateOptions: {
                    label: 'Insert Miktar',
                    resource: 'form.insert-miktar',
                    numberMask: 2
                }
            },
            {
                key: 'EgitimPortaliKullaniciAdi',
                type: 'horizontalInput',
                templateOptions: {
                    label: 'Eğitim Portalı Kullanıcı Adı',
                    resource: 'form.egitim-portali-kullanici-adi'
                }
            },
            {
                key: 'EgitimPortaliSifre',
                type: 'horizontalInput',
                templateOptions: {
                    label: 'Eğitim Portalı Şifre',
                    resource: 'form.egitim-portali-sifre'
                }
            },
            {
                key: 'BayiBilgiFoyuDurum',
                type: 'horizontalInput',
                templateOptions: {
                    label: 'Bayi Bilgi Foyu Durum',
                    resource: 'form.bayi-bilgi-foyu-durum'
                }
            },
            {
                key: 'BayiBilgiFoyuMiktar',
                type: 'horizontalInput',
                templateOptions: {
                    label: 'Bayi Bilgi Foyu Miktar',
                    resource: 'form.bayi-bilgi-foyu-miktar',
                    numberMask: 2
                }
            },
            {
                key: 'PerakendeListeDurum',
                type: 'horizontalInput',
                templateOptions: {
                    label: 'Perakende Liste Durum',
                    resource: 'form.perakende-liste-durum'
                }
            },
            {
                key: 'PerakendeListeMiktar',
                type: 'horizontalInput',
                templateOptions: {
                    label: 'Perakende Liste Miktar',
                    resource: 'form.perakende-liste-miktar',
                    numberMask: 2
                }
            },
            {
                key: 'ToptanListeDurum',
                type: 'horizontalInput',
                templateOptions: {
                    resource: 'form.toptan-liste-durum',
                    label: 'Toptan Liste Durum'
                }
            },
            {
                key: 'ToptanListeMiktar',
                type: 'horizontalInput',
                templateOptions: {
                    label: 'Toptan Liste Miktar',
                    resource: 'form.toptan-liste-miktar',
                    numberMask: 2
                }
            },
            {
                key: 'ToplamM2',
                type: 'horizontalInput',
                templateOptions: {
                    label: 'Toplam M2',
                    resource: 'form.toplam-m2',
                    numberMask: 2
                }
            },
            {
                key: 'BaseM2',
                type: 'horizontalInput',
                templateOptions: {
                    label: 'Base M2',
                    resource: 'form.base-m2',
                    numberMask: 2
                }
            },
            {
                key: 'AcilisTarihi',
                type: 'horizontalDatepicker',
                templateOptions: {
                    label: 'Açılış Tarihi',
                    resource: 'form.acilis-tarihi'
                }
            },
            {
                key: 'PasiflikTarihi',
                type: 'horizontalDatepicker',
                templateOptions: {
                    label: 'Pasiflik Tarihi',
                    resource: 'form.pasiflik-tarihi'
                }
            },
            {
                key: 'Plaka',
                type: 'horizontalInput',
                templateOptions: {
                    label: 'Plaka',
                    resource: 'form.plaka'
                }
            },
            {
                key: 'Active',
                type: 'horizontalCheckbox',
                defaultValue: true,
                templateOptions: {
                    label: 'Yayında',
                    resource: 'form.active',
                    placeholder: 'Yayında'
                }
            },
            {
                key: 'MailPassword',
                type: 'horizontalInput',
                templateOptions: {
                    label: 'Mail Password',
                    resource: 'form.mail-password'
                }
            },
            {
                key: 'MailNewPassword',
                type: 'horizontalInput',
                templateOptions: {
                    label: 'Mail New Password',
                    resource: 'form.mail-new-password'
                }
            },
            {
                key: 'KarmaMunhasir',
                type: 'horizontalInput',
                templateOptions: {
                    label: 'Karma Münhasır',
                    resource: 'form.karma-munhasir'
                }
            },
            {
                key: 'Faks',
                type: 'horizontalPhone',
                templateOptions: {
                    label: 'Fax',
                    resource: 'form.fax-number'
                }
            },
            {
                key: 'UserAddress',
                wrapper: 'fieldset',
                templateOptions: {
                    label: 'Adres',
                    resource: 'form.address'
                },
                fieldGroup: CustomerAddressService.getCustomerAddressFields()
            }
        ];

        return service;

        /////////////////////////////////////////

        function getFields() {
            return fields;
        }

        function getAll(filterParams) {
            var deferred = $q.defer();

            $http.get(serviceBase, {
                    params: filterParams
                })
                .then(getComplete)
                .catch(getFailed);

            function getComplete(response) {
                deferred.resolve(response.data);
            }

            function getFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function get(Id) {
            var deferred = $q.defer();

            $http.get(serviceBase + Id)
                .then(getProductComplete)
                .catch(getProductFailed);

            function getProductComplete(response) {
                deferred.resolve(response.data);
            }

            function getProductFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function add(entity) {
            var deferred = $q.defer();

            $http.post(serviceBase, entity)
                .then(addComplete)
                .catch(addFailed);

            function addComplete(response) {
                deferred.resolve(response.data);
            }

            function addFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function edit(entity) {
            var deferred = $q.defer();

            $http.put(serviceBase + entity.Id, entity)
                .then(editComplete)
                .catch(editFailed);

            function editComplete(response) {
                deferred.resolve(response.data);
            }

            function editFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function del(entity) {
            var deferred = $q.defer();

            $http.delete(serviceBase + entity.Id, entity)
                .then(deleteComplete)
                .catch(deleteFailed);

            function deleteComplete(response) {
                deferred.resolve(response.data);
            }

            function deleteFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }
    }
})();