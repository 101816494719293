(function () {
    'use strict';

    angular
      .module('app.errors')
      .controller('ErrorsListController', ErrorsListController);

    ErrorsListController.$inject=['$scope', 'ErrorsService', 'uiGridConstants', 'uiGridGroupingConstants'];

    function ErrorsListController($scope, ErrorsService, uiGridConstants, uiGridGroupingConstants) {
        var vm = this;

        vm.error = ErrorsService.geterrors();
        vm.ErrorsList = {
            paginationPageSizes: [10, 25, 50, 75],
            paginationPageSize: 10,
            data: vm.error,
            rowHeight: 39,
            enableVerticalScrollbar: 2,
            enableHorizontalScrollbar: 2,
            enableGroupHeaderSelection: true,
            columnDefs: [
                { name: 'id', displayName: 'Detay', cellTemplate: '<div class="ui-grid-cell-contents"><a href="/errors/{{COL_FIELD}}"><i class="material-icons md-18 mdc-text-grey-600">zoom_in</i></a></div>' },
                { name: 'Adi', displayName: 'Adı' },
                { name: 'Kodu', displayName: 'Kodu' },
                { name: 'Tipi', displayName: 'Tip' }
            ]
        };

        activate();

        function activate() {
        }
    }
})();
