(function () {
    'use strict';

    angular.module('app.xmlModule')
        .run(appRun);

    appRun.$inject = ['routerHelper'];

    function appRun(routerHelper) {
        routerHelper.configureStates(getStates());
    }


    function getStates() {
        return [
            {
                state: 'xmlModule',
                config: {
                    url: '/xmlModule',
                    title: 'XML Modülü',
                    abstract: true,
                    templateUrl: 'app/xmlModule/xmlModule.html'
                }
            },
            {
                state: 'xmlModule.addSettings',
                config: {
                    templateUrl: 'app/xmlModule/xmlModule.addSettings.html',
                    controller: 'xmlModuleController',
                    controllerAs: 'vm',
                    url: '/addSettings',
                    title: 'XML Ayarı Ekle',
                    settings: {
                        nav: 5,
                        name: 'xmlModule.addSettings',
                        topName: 'xml',
                        content: 'XML Ayarı Ekle',
                        resource: 'menu.xmlModule.addSettings'
                    }
                }
            },
            {
                state: 'xmlModule.addAccess',
                config: {
                    templateUrl: 'app/xmlModule/xmlModule.addAccess.html',
                    controller: 'xmlModuleController',
                    controllerAs: 'vm',
                    url: '/addAccess',
                    title: 'XML Kullanıcısı Oluştur',
                    settings: {
                        nav: 5,
                        name: 'xmlModule.addAccess',
                        topName: 'xml',
                        content: 'XML Kullanıcısı Oluştur',
                        resource: 'menu.xmlModule.addAccess'
                    }
                }
            },
            {
                state: 'xmlModule.SettingsList',
                config: {
                    templateUrl: 'app/xmlModule/xmlModule.SettingsList.html',
                    controller: 'xmlModuleController',
                    controllerAs: 'vm',
                    url: '/settingsList',
                    title: 'XML Ayar Listesi',
                    settings: {
                        nav: 5,
                        name: 'xmlModule.SettingsList',
                        topName: 'xml',
                        content: 'XML Ayar Listesi',
                        resource: 'menu.xmlModule.SettingsList'
                    }
                }
            },

            {
                state: 'xmlModule.AccessList',
                config: {
                    templateUrl: 'app/xmlModule/xmlModule.AccessList.html',
                    controller: 'xmlModuleController',
                    controllerAs: 'vm',
                    url: '/accessList',
                    title: 'XML Kullanıcı Listesi',
                    settings: {
                        nav: 5,
                        name: 'xmlModule.AccessList',
                        topName: 'xml',
                        content: 'XML Kullanıcı Listesi',
                        resource: 'menu.xmlModule.AccessList'
                    }
                }
            },
            {
                state: 'xmlModule.AccessUserList',
                config: {
                    templateUrl: 'app/xmlModule/xmlModule.AccessUserList.html',
                    controller: 'xmlModuleController',
                    controllerAs: 'vm',
                    url: '/accessUserList',
                    title: 'XML Kullanıcı Yetki İşlemleri',
                    settings: {
                        nav: 5,
                        name: 'xmlModule.AccessUserList',
                        topName: 'xml',
                        content: 'XML Kullanıcı Yetki İşlemleri',
                        resource: 'menu.xmlModule.AccessUserList'
                    }
                }
            },
            {
                state: 'xmlModule.editSettings',
                config: {
                    templateUrl: 'app/xmlModule/xmlModule.editSettings.html',
                    controller: 'xmlModuleController',
                    controllerAs: 'vm',
                    url: '/editSettings/:XMLId',
                    title: 'XML Şablonu Düzenleme',
                    settings: {
                        nav: 5,
                        name: 'xmlModule.editSettings',
                        topName: 'xml',
                        content: 'XML Kullanıcı Yetki İşlemleri',
                        resource: 'menu.xmlModule.editTemplate'
                    }
                }
            }
        ];
    }
})();