(function () {
    'use strict';

    angular
        .module('app.ggIntegration')
        .controller('GGAddMatchCategoryController', GGAddMatchCategoryController);

    GGAddMatchCategoryController.$inject = ['$scope', '$stateParams', '$location', 'GGIntegrationService', 'NgTableParams'];

    function GGAddMatchCategoryController($scope, $stateParams, $location, GGIntegrationService, NgTableParams) {
        /* jshint validthis:true */
        var vm = this;

        vm.settings = globalSettings;
        activate();

        function activate() {
            vm.fields = GGIntegrationService.gggetMatchCategoryFields();
            console.log('vm.fields', vm.fields);
        }

        vm.addMatchCategory = function () {
            if (vm.form.$valid) {
                vm.loading = true;
                console.log("ilk gelen", vm.entity.GGCategories1);
                vm.entity.IntegrationCategoryFullText = vm.entity.GGCategories1[0].name;
                vm.entity.GGCategoryCode = vm.entity.GGCategories1[0].id;
                vm.entity.IntegrationCategoryName = vm.entity.GGCategories1[0].name;
                

                vm.entity.EComCategoryId = vm.entity.EComCategoryId[0].id;


                GGIntegrationService.ggaddMatchCategory(vm.entity).then(function (data) {
                    vm.loading = false;
                    $location.path('/ggIntegration/ggmatchcategorylist');
                },
                    function (data) {
                        vm.loading = false;
                    });
                vm.loading = false;
            }
        };
    }
})();
