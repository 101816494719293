(function () {
    'use strict';

    angular
        .module('app.customers')
        .controller('CustomerDebtListController', Controller);

    Controller.$inject = ['$stateParams', 'OrdersService', 'NgTableParams'];

    function Controller($stateparams, OrdersService, NgTableParams) {
        var vm = this;

        vm.customerDebts = [{ Name: "Yükleniyor..." }];
        vm.UserId = $stateparams.UserId;

        activate();

        function activate() {

            vm.tableParams = new NgTableParams(
                angular.extend({ page: 1, count: 18, sorting: { OrderDate: "desc" } }),
                {
                    getData: function (params) {
                        vm.firstLoad = false;
                        var filterData2 = params.filter();
                        var filterData = {};
                        filterData.PageIndex = params.page();
                        filterData.PageSize = params.count();
                        filterData.SortProperty = Object.keys(params.sorting())[0];
                        filterData.SortType = params.sorting()[Object.keys(params.sorting())[0]];
                        filterData.CustomerId = vm.UserId;
                        filterData.SalesOrderTypeId = "167f2802-fe76-44c3-b5c7-a6ceaa6c9428";
                        filterData.IncludeProperties = ["Customer", "Status", "Bank", "SalesPerson"]; // "Customer,Status,Bank,SalesPerson";
                        var keys = Object.keys(filterData2);
                        for (var i = 0; i < keys.length; i++) {
                            filterData[keys[i]] = filterData2[keys[i]];
                        }
                        return OrdersService.getOrders(filterData).then(function (data) {
                            params.total(data.FilteredCount);
                            return data.Data;
                        });
                    }
                });
        }

        vm.delete = function (order) {
            OrdersService.deleteOrder(order)
                .then(function () {
                    activate();
                });
        };
    }
})();