(function () {
    'use strict';

    angular
        .module('app.consulting')
        .controller('AppointmentsEditController', EditController);

    EditController.$inject = ['$location', '$stateParams', 'AppointmentsService', 'UserTagsService', 'UserTagUsersService'];

    function EditController($location, $stateParams, Service, UserTagsService, UserTagUsersService) {
        /* jshint validthis:true */
        var vm = this;

        function activate() {
            vm.loading = true;
            vm.fields = Service.getFields();
            Service.get($stateParams.Id)
                .then(function (data) {
                    vm.entity = data;
                    vm.getUserTags();
                    vm.loading = false;
                });
        }

        vm.edit = function () {
            if (vm.form.$valid) {
                vm.loading = true;
                Service.edit(vm.entity)
                    .then(function () {
                        vm.loading = false;
                        $location.path('/consulting/appointments/list');
                    });
            }
        };

        vm.cancel = function () {
            $location.path('/consulting/appointments/list');
        };

        vm.userTags = [];
        vm.userTagItems = [];
        vm.userTagList = [];
        vm.userTagRefresh = function (search, options, model) {
            vm.userTagList = [{}];
            UserTagsService.getAll({
                    Name: search
                })
                .then(function (response) {
                    console.log('response :>> ', response);
                    vm.userTagList = vm.userTagItems.concat(response.Data);
                });
            console.log('search, options, model :>> ', search, options, model);
        }
        vm.onSelectUserTagCallback = function ($item, $model) {
            console.log('$item, $model :>> ', $item, $model);
            UserTagUsersService.add({
                    UserId: vm.entity.UserId,
                    UserTagId: $item.Id
                })
                .then(function (response) {
                    console.log('response :>> ', response);
                    vm.getUserTags();
                });
        }

        vm.onRemoveUserTagCallback = function ($item, $model) {
            console.log(' remove $item, $model :>> ', $item, $model);
            UserTagUsersService.del({
                    UserId: vm.entity.UserId,
                    UserTagId: $item.Id
                })
                .then(function (response) {
                    console.log('remove response :>> ', response);
                    vm.getUserTags();
                });
        }

        vm.getUserTags = function () {
            UserTagUsersService.getAll({
                    UserId: vm.entity.UserId
                })
                .then(function (response) {
                    console.log('response :>> ', response);
                    vm.userTagItems = response.Data.map(function (item) {
                        return item.UserTag
                    });
                    vm.userTagList = vm.userTagItems;
                    vm.userTags = response.Data.map(function (item) {
                        return item.UserTagId
                    });
                });
        }

        activate();
    }
})();