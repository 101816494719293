
(function () {
    'use strict';

    angular
      .module('app.account')
      .factory('TokenService',TokenService );

    TokenService.$inject = ['config'];

    function TokenService(config) {
        var service = {
            deleteRefreshTokens: deleteRefreshTokens,
            getRefreshTokens: getRefreshTokens
        };

        var serviceBase = ngAuthSettings.apiServiceBaseUri;

        return service;

        function deleteRefreshTokens() {
            return $http.delete(serviceBase + 'api/refreshtokens/?tokenid=' + tokenid).then(function (results) {
                return results;
            });
        }

        function getRefreshTokens() {
            return $http.get(serviceBase + 'api/refreshtokens').then(function (results) {
                return results;
            });
        }

    }
})();
