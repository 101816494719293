(function () {
    'use strict';

    angular
        .module('app.pages')
        .controller('PagesEditController', PagesEditController);

    PagesEditController.$inject = ['$scope', '$stateParams', '$location', 'logger', 'PagesService'];

    function PagesEditController($scope, $stateParams, $location, logger, PagesService) {
        var vm = this;

        vm.loading = true;

        PagesService.get($stateParams.Id).then(function (data) {
            vm.entity = data;
            vm.loading = false;
        });

        vm.fields = PagesService.getFields();

        vm.edit = function () {
            if (vm.form.$valid) {
                vm.loading = true;
                PagesService.edit(vm.entity).then(function (data) {
                    vm.loading = false;
                    $location.path('/pages/detail/' + vm.entity.Id);
                });
            }
        };

        vm.cancel = function () {
            $location.path('/pages/detail/' + vm.entity.Id);
        };
    }
})();