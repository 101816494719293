/* global toastr:false, moment:false */
(function () {
    'use strict';

    angular
        .module('app.core')
        .factory('SharedData', function () {
            return {
                UserInfo: null,
                Settings: {},
                IntegrationSettings: {}
            }
        });
})();