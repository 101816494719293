(function () {
    'use strict';

    angular
        .module('app.consulting')
        .factory('AppointmentsService', Service);

    Service.$inject = ['$http', '$q', 'ngAuthSettings', 'ExpertiseService'];

    function Service($http, $q, ngAuthSettings, ExpertiseService) {
        var service = {
            getAll: getAll,
            get: get,
            add: add,
            edit: edit,
            del: del,
            getFields: getFields,
            getStatus: getStatus
        };

        var serviceBase = ngAuthSettings.apiServiceBaseUri + 'api/appointments/';

        var fields = [{
                key: 'Link',
                type: 'floatInput',
                templateOptions: {
                    label: 'Link',
                    resource: 'form.link',
                    placeholder: 'Link'
                },
                "expressionProperties": {
                    "templateOptions.disabled": function ($viewValue, $modelValue, scope, d, e) {
                        return (scope.model.AppointmentStatusId === "0a2e201d-1dca-4990-aef3-5be227eeb865" || // Onay Bekliyor
                            scope.model.AppointmentStatusId === "b773ccd6-937d-4cc2-8896-8ae15ad627ce" || // Reddedildi
                            scope.model.AppointmentStatusId === "b99f3c94-1240-4886-a461-0d04e485ef73" || // Tamamlandı
                            scope.model.AppointmentStatusId === "7520c147-5647-45cd-9783-2ed3f47b28d9" || // İptal Edildi
                            scope.model.AppointmentStatusId === "c5877a60-1f8b-44d5-adbc-fb175e853a0e"); // Ertelendi
                    }
                }
            },
            {
                key: 'Advice',
                type: 'floatTextarea',
                templateOptions: {
                    label: 'Tavsiye',
                    resource: 'form.advice',
                    placeholder: 'Tavsiye'
                },
                "expressionProperties": {
                    "templateOptions.disabled": function ($viewValue, $modelValue, scope, d, e) {
                        return (scope.model.AppointmentStatusId === "0a2e201d-1dca-4990-aef3-5be227eeb865" || // Onay Bekliyor
                            scope.model.AppointmentStatusId === "b773ccd6-937d-4cc2-8896-8ae15ad627ce" || // Reddedildi
                            scope.model.AppointmentStatusId === "b99f3c94-1240-4886-a461-0d04e485ef73" || // Tamamlandı
                            scope.model.AppointmentStatusId === "7520c147-5647-45cd-9783-2ed3f47b28d9" || // İptal Edildi
                            scope.model.AppointmentStatusId === "c5877a60-1f8b-44d5-adbc-fb175e853a0e"); // Ertelendi
                    }
                }
            },
            {
                key: 'AppointmentStatusId',
                type: 'floatSelect',
                templateOptions: {
                    label: 'Durumu',
                    resource: 'form.status',
                    options: [{
                        Name: '...'
                    }]
                },
                controller: ['$scope', function ($scope) {
                    getStatus()
                        .then(function (response) {
                            $scope.to.options = response;
                        });
                }]
            },
            {
                key: 'Note',
                type: 'floatTextarea',
                templateOptions: {
                    label: 'Not',
                    resource: 'form.note',
                    placeholder: 'Not'
                },
                "expressionProperties": {
                    "templateOptions.disabled": function ($viewValue, $modelValue, scope, d, e) {
                        return (scope.model.AppointmentStatusId === "0a2e201d-1dca-4990-aef3-5be227eeb865" || // Onay Bekliyor
                            scope.model.AppointmentStatusId === "b773ccd6-937d-4cc2-8896-8ae15ad627ce" || // Reddedildi
                            scope.model.AppointmentStatusId === "b99f3c94-1240-4886-a461-0d04e485ef73" || // Tamamlandı
                            scope.model.AppointmentStatusId === "7520c147-5647-45cd-9783-2ed3f47b28d9" || // İptal Edildi
                            scope.model.AppointmentStatusId === "c5877a60-1f8b-44d5-adbc-fb175e853a0e"); // Ertelendi
                    }
                }
            },
            {
                key: 'ConsultantEndNote',
                type: 'floatTextarea',
                templateOptions: {
                    label: 'Danışman Değerlendirme Notu',
                    resource: 'form.consultant-endnote',
                    placeholder: 'Danışman Değerlendirme Notu'
                },
                "expressionProperties": {
                    "templateOptions.disabled": function ($viewValue, $modelValue, scope, d, e) {
                        return (scope.model.AppointmentStatusId === "0a2e201d-1dca-4990-aef3-5be227eeb865" || // Onay Bekliyor
                            scope.model.AppointmentStatusId === "b773ccd6-937d-4cc2-8896-8ae15ad627ce" || // Reddedildi
                            scope.model.AppointmentStatusId === "23e66124-5bb9-4e24-976e-00fa7386b0b4" || // Onaylandı
                            scope.model.AppointmentStatusId === "7520c147-5647-45cd-9783-2ed3f47b28d9" || // İptal Edildi
                            scope.model.AppointmentStatusId === "c5877a60-1f8b-44d5-adbc-fb175e853a0e"); // Ertelendi
                    }
                }
            },
            {
                key: "ConsultantRate",
                type: "floatRadio",
                className: "float-radio-horizontal",
                templateOptions: {
                    label: 'Danışman Değerlendirme Puanı',
                    resource: 'form.consultant-rate',
                    placeholder: 'Danışman Değerlendirme Puanı',
                    options: [{
                            "Id": "1",
                            "Name": "1"
                        },
                        {
                            "Id": "2",
                            "Name": "2"
                        },
                        {
                            "Id": "3",
                            "Name": "3"
                        },
                        {
                            "Id": "4",
                            "Name": "4"
                        },
                        {
                            "Id": "5",
                            "Name": "5"
                        }
                    ]
                },
                "expressionProperties": {
                    "templateOptions.disabled": function ($viewValue, $modelValue, scope, d, e) {
                        return (scope.model.AppointmentStatusId === "0a2e201d-1dca-4990-aef3-5be227eeb865" || // Onay Bekliyor
                            scope.model.AppointmentStatusId === "b773ccd6-937d-4cc2-8896-8ae15ad627ce" || // Reddedildi
                            scope.model.AppointmentStatusId === "23e66124-5bb9-4e24-976e-00fa7386b0b4" || // Onaylandı
                            scope.model.AppointmentStatusId === "7520c147-5647-45cd-9783-2ed3f47b28d9" || // İptal Edildi
                            scope.model.AppointmentStatusId === "c5877a60-1f8b-44d5-adbc-fb175e853a0e"); // Ertelendi
                    }
                }
            }
        ];

        return service;

        /////////////////////////////////////////

        function getFields() {
            return fields;
        }

        function getAll(filterParams) {
            var deferred = $q.defer();

            $http.get(serviceBase, {
                    params: filterParams
                })
                .then(getComplete)
                .catch(getFailed);

            function getComplete(response) {
                deferred.resolve(response.data);
            }

            function getFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function get(Id) {
            var deferred = $q.defer();

            $http.get(serviceBase + Id)
                .then(getComplete)
                .catch(getFailed);

            function getComplete(response) {
                deferred.resolve(response.data);
            }

            function getFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function add(entity) {
            var deferred = $q.defer();

            $http.post(serviceBase, entity)
                .then(addComplete)
                .catch(addFailed);

            function addComplete(response) {
                deferred.resolve(response.data);
            }

            function addFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function edit(entity) {
            var deferred = $q.defer();

            $http.put(serviceBase + entity.Id, entity)
                .then(editComplete)
                .catch(editFailed);

            function editComplete(response) {
                deferred.resolve(response.data);
            }

            function editFailed(error) {
                deferred.reject(error);
            }

            return deferred.promise;
        }

        function del(entity) {
            var deferred = $q.defer();

            $http.delete(serviceBase + entity.Id, entity)
                .then(deleteComplete)
                .catch(deleteFailed);

            function deleteComplete(response) {
                deferred.resolve(response.data);
            }

            function deleteFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function getStatus() {
            var deferred = $q.defer();

            $http.get(serviceBase + "statuses")
                .then(getComplete)
                .catch(getFailed);

            function getComplete(response) {
                deferred.resolve(response.data);
            }

            function getFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }
    }
})();