(function() {
  "use strict";

  angular.module("app.contracts", [
    // Angular modules
    "ngRoute",

    // Custom modules
    "treeControl"
    // 3rd Party Modules
  ]);
})();
