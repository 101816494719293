(function () {
    'use strict';

    angular
        .module('app.shipmentProvider')
        .controller('ShipmentProviderAddController', ShipmentProviderAddController);

    ShipmentProviderAddController.$inject = ['$location', 'ShipmentProviderService'];

    function ShipmentProviderAddController($location, Service) {
        /* jshint validthis:true */
        var vm = this;

        vm.entity = {};

        vm.fields = Service.getFields();

        vm.add = function () {
            if (vm.form.$valid) {
                vm.loading = true;
                Service.add(vm.entity).then(function (data) {
                    vm.loading = false;
                    $location.path('/shipmentProvider/list');
                });
            }
        };
    }
})();