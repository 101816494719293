(function () {
	"use strict";

	angular
		.module('app.reporting')
		.factory('SalesReportService', SalesReportService);

	SalesReportService.$inject = ['$http', '$q', 'ngAuthSettings'];

	function SalesReportService($http, $q, ngAuthSettings) {

		var service = {

			getSummaryReport: SummaryReport
		};


		var serviceBase = ngAuthSettings.apiServiceBaseUri;
		var endpoint = serviceBase + 'api/SalesReports/';

		return service;


		function SummaryReport(filterParams) {

			console.log("istek SummaryReport geldi")

			var deferred = $q.defer();
			$http.get(endpoint + 'SummaryReport', {
				params: filterParams
			})
				.then(getComplete)
				.catch(getFailed);

			function getComplete(response) {
				deferred.resolve(response.data);
			}

			function getFailed(error) {
				deferred.reject(error);
			}
			return deferred.promise;
		}
	}
})();