(function () {
    'use strict';

    angular.module('app.productLists', [
        // Angular modules 
        'ngRoute'

        // Custom modules 

        // 3rd Party Modules

    ]);
})();