(function () {
    'use strict';

    angular.module('app.ggIntegration', [
        // Angular modules
        'ngRoute',

        // Custom modules
        'treeControl'
        // 3rd Party Modules

    ]);
})();