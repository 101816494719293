(function () {
    'use strict';

    var UUID = null;

    angular
        .module('app.invoices')
        .controller('IncomeList2Controller', IncomeList2Controller);

    IncomeList2Controller.$inject = ['$scope', '$location', 'InvoiceService', 'NgTableParams', 'GeneralService', '$uibModal'];

    function IncomeList2Controller($scope, $location, InvoiceService, NgTableParams, GeneralService, $uibModal) {

        var vm = this;

        

        activate();


        function activate() {

            vm.filterModel = {};
            vm.firstLoad = true;
            vm.showFilter = false;
            vm.InvoiceTypes = [];
            vm.totalPrice = 0;
            vm.totalTax = 0;
            vm.globalData;
            vm.tableParams = new NgTableParams(
                angular.extend({
                    page: 1,
                    count: 10,
                    sorting: {
                        InvoiceNumber: "desc"
                    }
                }, {}), {
                getData: function (params) {
                    if (!vm.firstLoad) {
                        $location.search(params.url());
                    }
                    vm.firstLoad = false;
                    var filterData2 = params.filter();
                    var filterData = {};
                    filterData.PageIndex = params.page();
                    filterData.PageSize = params.count();
                    filterData.SortProperty = Object.keys(params.sorting())[0];
                    filterData.SortType = params.sorting()[Object.keys(params.sorting())[0]];
                    var baseFilterKeys = Object.keys(filterData2);
                    for (var i = 0; i < baseFilterKeys.length; i++) {
                        filterData[baseFilterKeys[i]] = filterData2[baseFilterKeys[i]];
                    }

                    var filterModelKeys = Object.keys(vm.filterModel);
                    for (var i = 0; i < filterModelKeys.length; i++) {
                        filterData[filterModelKeys[i]] = vm.filterModel[filterModelKeys[i]];
                    }

                    vm.totalAmount = 0;
                    vm.invoiceCount = 0;

                    return InvoiceService.getIncomeInvoices(filterData).then(function (data) {

                        vm.invoiceCount = 0;
                        vm.invoiceCount = data.Data.length;

                        vm.totalPrice = 0;
                        for (var i = 0; i < data.Data.length; i++) {

                            vm.totalPrice += data.Data[i].InvoiceTotal;
                        }
                        vm.totalPrice = vm.totalPrice.toFixed(2);

                        vm.totalTax = 0;
                        for (var i = 0; i < data.Data.length; i++) {

                            vm.totalTax += data.Data[i].TaxTotal;
                        }
                        vm.totalTax = vm.totalTax.toFixed(2);                                                 
                        
                        params.total(data.FilteredCount);
                        vm.globalData = data.Data;
                        return data.Data;
                    });
                }
            });
        }
        vm.loadSidebar = function () {
            var unChecked = vm.tableParams.data.filter(x => x.IsChecked !=true);

                vm.invoiceCount = 0;
                vm.invoiceCount = unChecked.length;

                vm.totalPrice = 0;
                for (var i = 0; i < unChecked.length; i++) {

                    vm.totalPrice += unChecked[i].InvoiceTotal;
                }
                vm.totalPrice = vm.totalPrice.toFixed(2);

                vm.totalTax = 0;
                for (var i = 0; i < unChecked.length; i++) {

                    vm.totalTax += unChecked[i].TaxTotal;
                }
                vm.totalTax = vm.totalTax.toFixed(2);

            
        }
        vm.SelectAll = function () {
            if (clicked != true) {
                var nonChecked = vm.tableParams.data.filter(x => x.IsChecked != true);
                for (var i = 0; i < nonChecked.length; i++) {
                    nonChecked[i].IsChecked = true;
                }
                clicked = true;
            }
            else {
                var nonChecked = vm.tableParams.data.filter(x => x.IsChecked == true);
                for (var i = 0; i < nonChecked.length; i++) {
                    nonChecked[i].IsChecked = undefined;
                }
                clicked = false;
            }
            vm.updateSidebar();
        }

        vm.updateSidebar = function () {
            var Checked = vm.tableParams.data.filter(x => x.IsChecked == true);

            if (Checked.length > 0) {
                vm.invoiceCount = 0;
                vm.invoiceCount = Checked.length;

                vm.totalPrice = 0;
                for (var i = 0; i < Checked.length; i++) {

                    vm.totalPrice += Checked[i].InvoiceTotal;
                }
                vm.totalPrice = vm.totalPrice.toFixed(2);

                vm.totalTax = 0;
                for (var i = 0; i < Checked.length; i++) {

                    vm.totalTax += Checked[i].TaxTotal;
                }
                vm.totalTax = vm.totalTax.toFixed(2);
            }
            else {
                vm.loadSidebar();
            }
        }
        vm.RefreshInvoice = function () {

            vm.loading = true;

            activate();
            vm.tableParams.reload();
            vm.loading = false;


        };
        var clicked = false;
        

        vm.acceptInvoices = function () {
            var selectedData = vm.tableParams.data.filter(x => x.IsChecked == true);

            for (var i = 0; i < selectedData.length; i++) {
                InvoiceService.acceptInvoices(selectedData[i].UUID)
                    .then(function (response) {
                        window.location.reload();
                        vm.loading = false;
                    })
                    .catch(function () {
                        vm.loading = false;
                    });
            }
        }
        
        vm.rejectInvoices = function () {
            var selectedData = vm.tableParams.data.filter(x => x.IsChecked == true);

            for (var i = 0; i < selectedData.length; i++) {
                InvoiceService.rejectInvoices(selectedData[i].UUID)
                    .then(function (response) {
                        window.location.reload();
                        vm.loading = false;
                    })
                    .catch(function () {
                        vm.loading = false;
                    });
            }
        }
        vm.accept = function (UUID) {

            vm.loading = true;

            var answer =
            {
                "UUID": UUID,
                "answerType": "ACCEPTED",
                "answerNote": "Kabul edildi",
                "isDirectSend": "true"
            };

            var jsonAnswer = JSON.stringify(answer);


            InvoiceService.setIncomeEInvoiceAnswer(jsonAnswer).then(function (response) {

                vm.loading = false;

                if (response.SetPurchaseInvoiceAnswerWithInvoiceUUIDResult.OperationCompleted) {
                    toastr.success(response.SetPurchaseInvoiceAnswerWithInvoiceUUIDResult.Description, "İşlem Başarılıyla Gerçekleştirilmiştir.");
                } else {
                    toastr.error(response.SetPurchaseInvoiceAnswerWithInvoiceUUIDResult.Description, "Hata Meydana Geldi. ");
                }

            });

        }

        vm.reject = function (UUID) {

            vm.loading = true;

            var answer =
            {
                "UUID": UUID,
                "answerType": "REJECTED",
                "answerNote": "Red edildi",
                "isDirectSend": "true"
            };

            var jsonAnswer = JSON.stringify(answer);

            InvoiceService.setIncomeEInvoiceAnswer(jsonAnswer).then(function (response) {

                vm.loading = false;

                if (response.SetPurchaseInvoiceAnswerWithInvoiceUUIDResult.OperationCompleted) {
                    toastr.success(response.SetPurchaseInvoiceAnswerWithInvoiceUUIDResult.Description, "İşlem Başarılıyla Gerçekleştirilmiştir.");
                } else {
                    toastr.error(response.SetPurchaseInvoiceAnswerWithInvoiceUUIDResult.Description, "Hata Meydana Geldi. ");
                }

            });
        }
        vm.download = function (UUID) {
            InvoiceService.downloadIncomeInvoice(UUID).then(function (data) {
                window.open("data:application/pdf;base64," + data);
                return data;
            });
        }

        vm.hashCode = function (str) {
            // java String#hashCode
            var hash = 0;
            for (var i = 0; i < str.length; i++) {
                hash = str.charCodeAt(i) + ((hash << 5) - hash);
            }
            return hash;
        }

        vm.intToRGB = function (i) {
            var c = (i & 0x00FFFFFF)
                .toString(16)
                .toUpperCase();

            return "00000".substring(0, 6 - c.length) + c;
        }


        vm.InvoiceDateFilter = {
            InvoiceDateMin: { id: 'ng-table/date.html', placeholder: 'Min Tarih' },
            InvoiceDateMax: { id: 'ng-table/date.html', placeholder: 'Max Tarih' }
        };

        vm.stateAnswer = [{ id: '0', title: 'Hepsi' }, { id: '1', title: 'Başarıyla alıcıya iletildi' }, { id: '2', title: 'Cevap Bekliyor' }, { id: '3', title: 'Cevap işlemi yapılmaz' }, { id: '4', title: 'Diğer' }];


        vm.InvoiceStateFilter = {
            StateAnswer: { id: 'ng-table/invoce_state.html', placeholder: "Fatura Cevap Tipi", model: " vm.stateAnswer" }
        }



        vm.showAlert = function (uuid) {

            UUID = uuid;

            // Modal

            vm.modalTitle = "Fatura Görüntüleme";
            $uibModal.open({
                templateUrl: 'ModalContent.html',
                controller: 'IncomeModalController',
                controllerAs: 'vm',
                windowClass: 'app-modal-window'
            }).then(function () {
                vm.RefreshInvoice();
            });


        }
    }



    angular
        .module('app.invoices')
        .controller('IncomeModalController', InvoiceModalController);

    InvoiceModalController.$inject = ['$scope', '$stateParams', '$filter', '$location', 'logger', 'InvoiceService', '$sce'];

    function InvoiceModalController($scope, $stateParams, $filter, $location, logger, InvoiceService, $sce) {

        var vm = this;

        vm.jsonAnswer = null;

        vm.uuid = UUID;

        activate();
        vm.loading = true;

        function activate() {

            InvoiceService.getIncomeEfaturaHtml(UUID).then(function (response) {

                vm.content = $sce.trustAsHtml(response);
                vm.loading = false;

            });
        }

       

        vm.accept = function (uuid, sendType) {

            vm.loading = true;

            var answer =
            {
                "UUID": uuid,
                "answerType": "ACCEPTED",
                "answerNote": "Kabul edildi",
                "isDirectSend": sendType
            };

            vm.jsonAnswer = JSON.stringify(answer);

            console.log(vm.jsonAnswer);

            InvoiceService.setIncomeEInvoiceAnswer(vm.jsonAnswer).then(function (response) {

                vm.loading = false;

                if (response.SetPurchaseInvoiceAnswerWithInvoiceUUIDResult.OperationCompleted) {
                    toastr.success(response.SetPurchaseInvoiceAnswerWithInvoiceUUIDResult.Description, "İşlem Başarılıyla Gerçekleştirilmiştir.");
                } else {
                    toastr.error(response.SetPurchaseInvoiceAnswerWithInvoiceUUIDResult.Description, "Hata Meydana Geldi. ");
                }

            });

        }

        vm.reject = function (uuid, sendType) {

            vm.loading = true;

            var answer =
            {
                "UUID": uuid,
                "answerType": "REJECTED",
                "answerNote": "Red edildi",
                "isDirectSend": sendType
            };

            vm.jsonAnswer = JSON.stringify(answer);

            console.log(vm.jsonAnswer);

            InvoiceService.setIncomeEInvoiceAnswer(vm.jsonAnswer).then(function (response) {

                vm.loading = false;

                if (response.SetPurchaseInvoiceAnswerWithInvoiceUUIDResult.OperationCompleted) {
                    toastr.success(response.SetPurchaseInvoiceAnswerWithInvoiceUUIDResult.Description, "İşlem Başarılıyla Gerçekleştirilmiştir.");
                } else {
                    toastr.error(response.SetPurchaseInvoiceAnswerWithInvoiceUUIDResult.Description, "Hata Meydana Geldi. ");
                }

            });
        }
    }


})();