(function () {
    "use strict";

    angular.module("app.orders").controller("DirectsListController", OrdersListController);

    OrdersListController.$inject = ["$scope", "$location", "$stateParams", "OrdersService", "NgTableParams"];

    function OrdersListController($scope, $location, $stateParams, OrdersService, NgTableParams) {
        var vm = this;

        activate();

        function activate() {
            vm.refresh = function () {
                vm.loading = true;
                vm.tableParams.reload();
                vm.loading = false;
            };

            vm.firstLoad = true;
            vm.tableParams = new NgTableParams(
                angular.extend({ page: 1, count: 18, sorting: { OrderDate: "desc" } }, $location.search()),
                {
                    getData: function (params) {
                        if (!vm.firstLoad) {
                            $location.search(params.url());
                        }
                        vm.firstLoad = false;
                        var filterData2 = params.filter();
                        var filterData = {}; // params.filter();
                        filterData.PageIndex = params.page();
                        filterData.PageSize = params.count();
                        filterData.SortProperty = Object.keys(params.sorting())[0];
                        filterData.SortType = params.sorting()[Object.keys(params.sorting())[0]];
                        filterData.SalesOrderTypeId = "faf9ca70-f140-4569-9cfc-330a939bb3d6";
                        //filterData.IncludeProperties = "Payment";
                        filterData.SelectFields =
                            "Id,Code,OrderDate,Customer.Name,Customer.Surname,OrderDate,Total,TotalDiscount,Status.Name,Description,Bank.PaymentType,Bank.Installment,Payment";
                        var keys = Object.keys(filterData2);
                        for (var i = 0; i < keys.length; i++) {
                            filterData[keys[i]] = filterData2[keys[i]];
                        }
                        return OrdersService.getOrders(filterData).then(function (data) {
                            params.total(data.FilteredCount);
                            return data.Data;
                        });
                    }
                }
            );
            vm.totalDirects = "...";
            vm.totalExpectedDirects = "...";
            OrdersService.getDirectsDashboard().then(function (data) {
                vm.totalDirects = data.TotalDirects;
                vm.totalExpectedDirects = data.TotalExpectedDirects;
            });
        }

        vm.TotalFilter = {
            TotalMin: {
                id: "ng-table/money.html",
                placeholder: "Minimum Ücret"
            },
            TotalMax: {
                id: "ng-table/money.html",
                placeholder: "Maximum Ücret"
            }
        };
        vm.DateFilter = {
            OrderDateMin: {
                id: "ng-table/date.html",
                placeholder: "Minimum Tarih"
            },
            OrderDateMax: {
                id: "ng-table/date.html",
                placeholder: "Maximum Tarih"
            }
        };

        vm.Status = OrdersService.getOrderStatuses().then(function (response) {
            return response.map(function (item) {
                return { id: item.Id, title: item.Name };
            });
        });

        vm.getLastPayment = function (order) {
            return order.Payment.sort(function (a, b) {
                x = new Date(a.InsertDate);
                y = new Date(b.InsertDate);
                return x > y ? -1 : x < y ? 1 : 0;
            })[0];
        }

        vm.getInstallmentWithBankInstallment = function (installmentList, bankInstallment) {
            return installmentList.find(function (x) {
                return x.BankInstallment === bankInstallment;
            });
        };
    }
})();
