(function () {
    'use strict';

    angular
        .module('app.customers')
        .controller('CustomersListController', CustomersListController);

    CustomersListController.$inject = ['$scope', '$location', 'CustomersService', 'NgTableParams', 'CustomerGeneralService', 'CustomerAddressService'];

    function CustomersListController($scope, $location, CustomersService, NgTableParams, CustomerGeneralService, CustomerAddressService) {
        var vm = this;

        activate();

        function activate() {

            vm.refresh = function () {
                vm.loading = true;
                vm.tableParams.reload();
                vm.loading = false;
            };

            vm.Types = CustomerGeneralService.getUserTypes().then(function (response) {
                return response.map(function (item) {
                    return {
                        id: item.Id,
                        title: item.Name
                    };
                });
            });

            vm.firstLoad = true;
            vm.tableParams = new NgTableParams(
                angular.extend({
                    page: 1,
                    count: 10,
                        sorting: {
                            InsertDate: "desc"
                        }
                }, $location.search()), {
                    getData: function (params) {
                        if (!vm.firstLoad) {
                            $location.search(params.url());
                        }
                        vm.firstLoad = false;
                        var filterData2 = params.filter();
                        var filterData = {};
                        filterData.PageIndex = params.page();
                        filterData.PageSize = params.count();
                        filterData.SortProperty = Object.keys(params.sorting())[0];
                        filterData.SortType = params.sorting()[Object.keys(params.sorting())[0]];
                        var keys = Object.keys(filterData2);
                        for (var i = 0; i < keys.length; i++) {
                            filterData[keys[i]] = filterData2[keys[i]];
                        }
                        return CustomersService.getCustomers(filterData).then(function (data) {
                            params.total(data.FilteredCount);
                            console.log("asdas ", data.Data);
                            return data.Data;
                        });
                    }
                });

            vm.UserType = [{
                id: 'CORPORATE',
                title: 'Kurumsal'
            }, {
                id: 'INDIVIDUAL',
                title: 'Bireysel'
            }];
        }

        vm.prepareAllAddress = function () {
            vm.loading = true;
            CustomerAddressService.prepareAllAddress()
                .then(function (response) {
                    vm.loading = false;
                })
        }
    }
})();