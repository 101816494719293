
(function () {
    'use strict';

    angular
      .module('app.customers')
      .controller('CustomerAddressDetailController', CustomerAddressDetailController);

    CustomerAddressDetailController.$inject=['$stateParams', '$location', 'CustomerAddressService' ];

    function CustomerAddressDetailController($stateParams, $location, CustomerAddressService) {
        var vm = this;

        vm.title = '';
        vm.address = {};

        activate();

        function activate() {
            CustomerAddressService.getCustomerAddress($stateParams.UserId, $stateParams.AddressId).then(function (data) {
                vm.address = data;
            });
        }

        vm.deleteAddress = function () {
            if (confirm("Silmek istediğinize emin misiniz?")) {
                CustomerAddressService.deleteCustomerAddress(vm.address).then(function (data) {
                    $location.path('/customers/' + vm.address.UserId + '/addresses');
                });
            }

        };
    }
})();
