(function () {
    'use strict';

    angular
        .module('app.cultures')
        .factory('CulturesService', Service);

    Service.$inject = ['$http', '$q', 'ngAuthSettings'];

    function Service($http, $q, ngAuthSettings) {
        var service = {
            getAll: getAll,
            get: get,
            add: add,
            edit: edit,
            del: del,
            getSupportedCultures: getSupportedCultures
        };

        var serviceBase = ngAuthSettings.apiServiceBaseUri;
        var endpoint = serviceBase + 'api/cultures/';

        return service;

        ///////////////////////////////

        function getAll(filterParams) {
            var deferred = $q.defer();
            $http.get(endpoint, {
                params: filterParams
            })
                .then(getComplete)
                .catch(getFailed);

            function getComplete(response) {
                deferred.resolve(response.data);
            }

            function getFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function get(id) {
            var deferred = $q.defer();

            $http.get(endpoint + id)
                .then(getComplete)
                .catch(getFailed);

            function getComplete(response) {
                deferred.resolve(response.data);
            }

            function getFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function add(culture) {
            var deferred = $q.defer();

            $http.post(endpoint, culture)
                .then(addComplete)
                .catch(addFailed);

            function addComplete(response) {
                deferred.resolve(response.data);
            }

            function addFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function edit(culture) {
            var deferred = $q.defer();

            $http.put(endpoint + culture.Id, culture)
                .then(editComplete)
                .catch(editFailed);

            function editComplete(response) {
                deferred.resolve(response.data);
            }

            function editFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function del(culture) {
            var deferred = $q.defer();

            $http.delete(endpoint + culture.Id, culture)
                .then(deleteComplete)
                .catch(deleteFailed);

            function deleteComplete(response) {
                deferred.resolve(response.data);
            }

            function deleteFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function getSupportedCultures() {
            var deferred = $q.defer();
            $http.get(endpoint + "SupportedCutures")
                .then(getComplete)
                .catch(getFailed);

            function getComplete(response) {
                deferred.resolve(response.data);
            }

            function getFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }
    }
})();