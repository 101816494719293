(function () {
    'use strict';

    angular
        .module('app.designTheme')
        .controller('DesignThemeController', Controller);

    Controller.$inject = ['OrdersService'];

    function Controller(Service) {
        /*jshint validthis: true */
        var vm = this;
        vm.entity = null;
        vm.sample = "bir";

        activate();

        function activate() {
        }

        vm.new = function () {
            vm.entity = {};
        };

        vm.edit = function () {
            vm.entity = { Id: 1 };
        };

        vm.cancel = function () {
            vm.entity = null;
        };

        vm.save = function (entity) {
            if (entity.Id) {
                Service.edit(entity);
            } else {
                Service.save(entity);
            }
        };
    }
})();