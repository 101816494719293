(function () {
    'use strict';

    angular
        .module('app.blogPost')
        .controller('BlogPostEditController', BlogPostEditController);

    BlogPostEditController.$inject = ['$scope', '$stateParams', '$filter', '$templateCache', '$location', '$route', 'Settings', 'BlogPostService', 'PostMediasService', 'BlogPostTagService', 'PostTagPostService', 'FileUploader', 'localStorageService', 'NgTableParams'];

    function BlogPostEditController($scope, $stateParams, $filter, $templateCache, $location, $route, Settings, BlogPostService, PostMediasService, BlogPostTagService, PostTagPostService, FileUploader, localStorageService, NgTableParams) {
        var vm = this;
        activate();

        function activate() {
            vm.Settings = Settings;
            vm.loading = true; 

            BlogPostService.getPost($stateParams.PostId)
                .then(function (data) {
                    vm.entity = data;
                    vm.entity.refresh = true;
                    vm.getPostMedia();

                    vm.loading = false;
                });

            vm.fields = BlogPostService.getPostFields();

            vm.seofields = BlogPostService.getSeoFields();

            vm.postMediaFields = PostMediasService.getFields();
        }

        vm.edit = function () {
            if (vm.form.$valid) {
                vm.loading = true;
                vm.entity.PostMedia = null;
                BlogPostService.editPost(vm.entity).then(function () {
                    vm.loading = false;
                    activate();
                });
            }
        };

        vm.delete = function () {
            if (confirm("Silmek istediğinize emin misiniz?")) {
                BlogPostService.deletePost(vm.entity).then(function (data) {
                    $location.path('/blogpost/list');
                });
            }
        };

        vm.getPostMedia = function () {
            PostMediasService.getAll(vm.entity.Id)
                .then(function (data) {
                    vm.postMedia = data;
                });
        };

        vm.editMedia = function (postMedia) {
            vm.postMediaEntity = postMedia;
        };

        vm.addMedia = function () {
            vm.postMediaEntity = { PostId: vm.entity.Id };
        };

        vm.postMediaSubmit = function () {
            if (vm.postMediaForm.$valid) {
                if (vm.postMediaEntity.Id) {
                    PostMediasService.edit(vm.postMediaEntity)
                        .then(function () {
                            vm.loading = false;
                            vm.postMediaEntity = null;
                            vm.getPostMedia();
                        });
                } else {
                    PostMediasService.add(vm.postMediaEntity)
                        .then(function () {
                            vm.loading = false;
                            vm.postMediaEntity = null;
                            vm.getPostMedia();
                        });
                }
            }
        };

        vm.postMediaCancel = function () {
            vm.postMediaEntity = null;
        };

        vm.removeFile = function (item) {
            BlogPostService.removePostMedia(vm.entity.Id, item.MediaId).then(function (data) {
                vm.getPostMedia();
            });
        };

        vm.cancel = function () {
            $location.path('/blogpost/detail/' + vm.entity.Id);
        };    

        //////////////////////////////// PostTag

        vm.tagFields = PostTagPostService.getFields();
        vm.tagEntity = {};

        vm.getTags = function (Post) {
            PostTagPostService.getAll({ PostId: Post.Id, pageSize: -1 })
                .then(function (data) {
                    vm.entity.PostTag = data.Data;
                    vm.tagEntity = null;
                    vm.loading = false;
                    $location.path('/blogpost/list');
                });
        };

        vm.addTag = function (post) {
            vm.tagEntity = {
                PostId: post.Id
            };
        };

        vm.editTag = function (tag) {
            vm.tagEntity = angular.copy(tag);
        };

        vm.deleteTag = function (tag) {
            if (confirm("Silmek istediğinize emin misiniz?")) {
                vm.loading = true;
                console.log('tag', tag);
                PostTagPostService.del(tag).then(function (data) {
                    vm.getTags(vm.entity);
                });
            }
        };

        vm.tagCancel = function () {
            vm.tagEntity = null;
        };

        vm.tagSubmit = function (tag) {
            vm.loading = true;
            if (vm.tagEntity.Id) {
                PostTagPostService.edit(vm.tagEntity)
                    .then(function () {
                        vm.getTags(vm.entity);
                    })
                    .catch(function () {
                        vm.getTags(vm.entity);
                    });
            } else {
                PostTagPostService.add(vm.tagEntity)
                    .then(function () {
                        vm.getTags(vm.entity);
                    })
                    .catch(function () {
                        vm.getTags(vm.entity);
                    });
            }
        };

    }
})();