(function () {
    'use strict';

    angular
        .module('app.dashboard')
        .controller('Dashboard', Dashboard);

    Dashboard.$inject = ['OrdersService', 'CustomersService', 'ProductsService'];

    function Dashboard(OrdersService, CustomersService, ProductsService) {
        /*jshint validthis: true */
        var vm = this;
        vm.customerCount = "...";
        vm.orderCount = "...";
        vm.productCount = "...";
        vm.totalOrderPrice = "...";
        activate();
        
        function activate() {
            CustomersService.getCustomers({
                    PageSize: 1,
                    PageIndex: 1
                })
                .then(function (data) {
                    vm.customerCount = data.Count;
                });

            OrdersService.getOrders({
                    PageSize: 1,
                    PageIndex: 1,
                    StatusId: '23c943e8-b0ea-4e5f-9942-ed0faa821292',
                    SelectFields: 'Id'
                })
                .then(function (data) {
                    vm.orderCount = data.FilteredCount;
                });

            OrdersService.getTotalPrice()
                .then(function (data) {
                    vm.totalOrderPrice = parseFloat(data).toFixed(2);
                });
            
            OrdersService.getThisMonthTotalPrice()
                .then(function (data) {
                    vm.thisMonthTotalOrderPrice = parseFloat(data).toFixed(2);
                });

            ProductsService.getProducts({
                    PageSize: 1,
                    PageIndex: 1
                })
                .then(function (data) {
                    vm.productCount = data.Count;
                });
        }
    }
})();