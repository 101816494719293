(function () {
    'use strict';

    angular.module('app.stockHistories')
        .run(appRun);

    appRun.$inject = ['routerHelper'];

    function appRun(routerHelper) {
        routerHelper.configureStates(getStates());
    }

    function getStates() {
        return [
            {
                state: 'stockHistory',
                config: {
                    url: '/stockHistories',
                    title: 'Depo Stokları',
                    abstract: true,
                    templateUrl: 'app/stockHistories/stockHistories.html'
                }
            },
            {
                state: 'stockHistory.list',
                config: {
                    templateUrl: 'app/stockHistories/stockHistories.list.html',
                    controller: 'StockHistoriesListController',
                    controllerAs: 'vm',
                    url: '/list',
                    title: 'Depo Stok Listesi',
                    settings: {
                        nav: 33,
                        name: 'stockHistory.list',
                        topName: 'sales',
                        content: 'Depo Stok Listesi',
                        resource: 'menu.stockHistory.list'
                    }
                }
            },
            {
                state: 'stockHistory.add',
                config: {
                    templateUrl: 'app/stockHistories/stockHistories.add.html',
                    controller: 'StockHistoriesAddController',
                    controllerAs: 'vm',
                    url: '/add',
                    title: 'Yeni Stok Hareketi',
                    settings: {
                        resource: 'menu.add-stockHistory'
                    }
                }
            },
            {
                state: 'stockHistory.edit',
                config: {
                    templateUrl: 'app/stockHistories/stockHistories.edit.html',
                    controller: 'StockHistoriesEditController',
                    controllerAs: 'vm',
                    url: '/edit/:StockHistoryId',
                    title: 'Stok Hareketi Düzenle',
                    settings: {
                        resource: 'menu.edit-stockHistory'
                    }
                }
            },
            {
                state: 'stockHistory.detail',
                config: {
                    templateUrl: 'app/stockHistories/stockHistories.detail.html',
                    controller: 'StockHistoriesDetailController',
                    controllerAs: 'vm',
                    url: '/detail/:StockHistoryId',
                    title: 'Stok Hareketi Detayı',
                    settings: {
                        resource: 'menu.stockHistory-detail'
                    }
                }
            }
        ];
    }
})();