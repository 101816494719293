
(function () {
    'use strict';

    angular
      .module('app.filter')
      .filter('arrayMultiplyAdd', arrayMultiplyAddFilter);

    function arrayMultiplyAddFilter() {
        return function (data, args) {
            if (typeof (data) === 'undefined' || typeof (args) === 'undefined') {
                return 0;
            }

            var sum = 0;
            for (var i = data.length - 1; i >= 0; i--) {
                var summ = 1;
                for (var j = 0; j < args.length; j++) {
                    summ *= Number(data[i][args[j]]);
                }
                sum += parseFloat(summ);
            }

            return sum;
        };
    }
})();

