(function () {
    'use strict';

    angular
        .module('app.getProducts')
        .controller('MarketPlaceController', MarketPlaceController);

    MarketPlaceController.$inject = ['$scope', '$stateParams', '$filter', '$location', 'logger', 'GetProductsService', 'NgTableParams', '$uibModal', '$uibModalStack', 'ProductsGeneralService', 'OptionService', 'ProductsService'];

    function MarketPlaceController($scope, $stateParams, $filter, $location, logger, GetProductsService, NgTableParams, $uibModal, $uibModalStack, ProductsGeneralService, OptionService, ProductsService) {
        var vm = this;
        activate();
        vm.categoryDisable = true;
        vm.GetProductPage = true;
        vm.CategoryDetailsDisable = false;
        vm.OptionDetailsDisable = false;
        vm.ProductDetailsDisable = false;
        function activate() {
            //  vm.loading = false;
        }
        vm.marketPlaceFields = GetProductsService.getMarketPlaceFields();

        vm.StartGetCategory = function () {
            if (Object.keys(vm.marketPlace).length > 0) {
                vm.categoryDisable = false;
            }
        };

        vm.StartGetProduct = function () {
            GetProductsService.TransferProduct(vm.marketPlaceFields).then(function (data) {
                vm.openModal(data);
                vm.loading = false;
            });
        };

        vm.StartGetProductDetails = function (item) {

            //  console.log("items", item.formly_1_floatInput_Rate_1.$viewValue); 
            vm.Rate = item.formly_1_floatInput_Rate_1.$viewValue;
            vm.CategoryDetailsDisable = true;
            vm.GetProductPage = false;
            vm.loadingDetails = true;

            vm.tableParams.reload();
            ProductsGeneralService.getCategories().then(function (response) {
                vm.LocalCategoryList = getTreeData(response, null);
            });

        }


        function getTreeData(options, topProp) {
            var data = options.filter(obj => obj["TopCategoryId"] === topProp);
            if (data.length <= 0) {
                return [];
            }
            return data
                .map(obj => {
                    var rObj = {};
                    rObj.id = obj["Id"];
                    rObj.name = obj["Name"];
                    rObj.children = getTreeData(options, obj.Id) || [];
                    return rObj;
                });
        }

        vm.MarketOptionList = [];

        vm.MarketProductList = [];

        vm.tableParams = new NgTableParams(
            angular.extend({
                page: 1,
                count: 10,
                sorting: {
                    Name: "asc"
                }
            }, {}), {
            getData: function (params) {
                if (!vm.firstLoad) {
                    $location.search(params.url());
                }
                vm.firstLoad = false;
                var filterData2 = params.filter();
                var filterData = {};
                filterData.PageIndex = params.page(),
                    filterData.PageSize = params.count(),
                    filterData.SortProperty = Object.keys(params.sorting())[0],
                    filterData.SortType = params.sorting()[Object.keys(params.sorting())[0]];
                var keys = Object.keys(filterData2);
                for (var i = 0; i < keys.length; i++) {
                    filterData[keys[i]] = filterData2[keys[i]];
                }
                filterData.MarketPlace = vm.marketPlaceFields;

                return GetProductsService.GetAllProduct(filterData).then(function (data) {

                    params.total(data.FilteredCount);
                    vm.loadingDetails = false;
                    vm.MarketOptionList = data.Data;
                    //vm.OptionTableParams.reload();
                    vm.MarketProductList = data.Data;

                    // console.log("get Data" ,data.Data);
                    return data.Data;
                });
            }
        });


        vm.getOptionList = function () {
            OptionService.getAll({ PageSize: -1 }).then(function (data) {
                vm.optionList = data.Data;
            });

            var filterData = {};
            filterData.PageSize = 10000;
            ProductsService.getProducts(filterData).then(function (data) {
                vm.LocalProductsList = getTreeData1(data.Data,null);

            });

        };

        function getTreeData1(options, topProp) {
            var data = options.filter(obj => obj["BaseProductId"] === topProp);
            if (data.length <= 0) {
                return [];
            }
            return data
                .map(obj => {
                    var rObj = {};
                    rObj.id = obj["Id"];
                    rObj.name = obj["Name"];
                    rObj.children = getTreeData(options, obj.Id) || [];
                    return rObj;
                });
        }

        vm.baseProductFilter = function (item) {
            if (item.BaseProductId === null) return true;
            return false;
        }


        vm.Detailscancel = function () {

            vm.GetProductPage = true;
            vm.CategoryDetailsDisable = false;
            vm.OptionDetailsDisable = false;
            vm.OptionValueDetailsDisable = false;
            vm.ProductDetailsDisable = false;
        }


        vm.ReturnCategory = function () {

            vm.GetProductPage = false;
            vm.CategoryDetailsDisable = true;
            vm.OptionDetailsDisable = false;
            vm.OptionValueDetailsDisable = false;

        }

        vm.ReturnOption = function () {

            vm.GetProductPage = false;
            vm.CategoryDetailsDisable = false;
            vm.OptionDetailsDisable = true;
            vm.OptionValueDetailsDisable = false;

        }

        vm.ReturnOptionValue = function () {
            vm.GetProductPage = false;
            vm.CategoryDetailsDisable = false;
            vm.OptionDetailsDisable = true;
            vm.OptionValueDetailsDisable = true;
            vm.ProductDetailsDisable = false;
        }

        vm.OptionNext = function () {
            vm.CategoryDetailsDisable = false;
            vm.OptionDetailsDisable = true;
            vm.OptionValueDetailsDisable = false;
        }



        vm.CatNext = function () {

            console.log();
            vm.CategoryDetailsDisable = false;
            vm.getOptionList();
            vm.OptionDetailsDisable = true;
            vm.OptionValueDetailsDisable = false;

        }

        vm.NextoptionValue = function (item) {

            vm.OptionValueDetailsDisable = true;
            vm.OptionDetailsDisable = false;
        }

        vm.NextProducts = function (item) {

            vm.OptionValueDetailsDisable = false;
            vm.ProductDetailsDisable = true;

        }


        vm.AddAll = function (item) {


            console.log("All items", item);

            GetProductsService.GetAllProductSave(item).then(function (data) {

                console.log("");
            });


        }

        vm.cancel = function () {
            console.log("dismiss");
            $uibModalStack.dismissAll();
        }
    }
})();

