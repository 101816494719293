(function () {
    'use strict';

    angular
        .module('app.customers')
        .controller('CustomerNotesController', Controller);

    Controller.$inject = ['$stateParams', 'AppointmentsService'];

    function Controller($stateparams, AppointmentsService) {
        var vm = this;

        vm.customerAppointments = [{ Name: "Yükleniyor..." }];
        vm.UserId = $stateparams.UserId;

        activate();

        function activate() {
            AppointmentsService.getAll({
                UserId: vm.UserId
            })
            .then(function (data) {
                vm.customerAppointments = data.Data;
            });
        }
    }
})();