(function () {
    'use strict';

    angular
        .module('app.n11Integration')
        .controller('MatchCategoryFilterListController', MatchCategoryFilterListController);

    MatchCategoryFilterListController.$inject = ['$location', '$stateParams', 'N11IntegrationService', 'NgTableParams', '$uibModal'];

    function MatchCategoryFilterListController($location,$stateParams, N11IntegrationService, NgTableParams, $uibModal) {
        /* jshint validthis:true */
        var vm = this;
        vm.settings = globalSettings;
        activate();
        function activate() {
            vm.firstLoad = true;
            vm.tableParams = new NgTableParams(
                angular.extend({ page: 1, count: 10 }, $location.search()),
                {
                    getData: function () {
                        return N11IntegrationService.getMatchCategoryFilterListByCategory($stateParams.Id).then(function (data) {
                            return data;
                        });
                    }
                });
        }
    }
})();