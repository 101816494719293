(function () {
    'use strict';

    angular
        .module('app.xmlModule')
        .controller('xmlModuleController', xmlModuleController);

    xmlModuleController.$inject = ['$scope', '$stateParams', '$location', 'xmlModuleService', 'NgTableParams'];

    function xmlModuleController($scope, $stateParams, $location, xmlModuleService, NgTableParams) {
        /* jshint validthis:true */
        var vm = this;

        vm.settings = globalSettings;
        vm.xmlSettings;
        vm.xmlsettingsList;
        vm.xmlaccessList;
        vm.xmlaccessUserList;
        activate();



        function activate() {
            vm.xmlaccessUserList = xmlModuleService.getXMLAccessUserList().then(function (data) {
                vm.xmlaccessUserList = data;
            });
            vm.getSettings = xmlModuleService.getXMLSettings($stateParams.XMLId).then(function (data) {
                vm.entity = data;
                if ($stateParams.XMLId) {
                    vm.entity.Category = xmlModuleService.GetXMLSettingsAndCategory($stateParams.XMLId).then(function (data) {

                        vm.entity.Categories = data.Category.map(function (item) {
                            return item.Id;
                        });
                        return data.Category;

                    })
                }



            });
            vm.fields = xmlModuleService.getXMLSettingsFields();
            vm.accessFields = xmlModuleService.getXMLAccessFields();
            vm.accessUserFields = xmlModuleService.getXMLAccessUserFields();
            vm.settingsCategoryFields = xmlModuleService.getXMLSettingsCategoryFields();

            vm.xmlsettingsList =
                xmlModuleService.getXMLSettingsList().then(function (data) {
                    return data;
                });
            vm.xmlaccessList =
                xmlModuleService.getXMLAccessList().then(function (data) {
                    return data;
                });

        }
        vm.deleteSettings = function (id) {
            xmlModuleService.deleteXMLSettings(id).then(function (data) {

                vm.xmlsettingsList =
                    xmlModuleService.getXMLSettingsList().then(function (data) {
                        return data;
                    });
            });
        }
        vm.removeAccess = function (id) {
            xmlModuleService.removeXMLAccess(id).then(function (data) {

                location.reload();

            });
        }
        vm.deleteUser = function (id) {
            xmlModuleService.deleteXMLUser(id).then(function (data) {

                location.reload();
            });
        }

        vm.addSettings = function () {
            xmlModuleService.addXMLSettings(vm.entityAdd).then(function (data) {
                window.history.back();
            });
        };
        vm.updateSettings = function () {
            xmlModuleService.updateXMLSettings(vm.entity).then(function (data) {
                NgTableParams.reload();
            });
        };
        vm.addSettingsCategory = function () {

            xmlModuleService.addXMLSettingsCategory(vm.entity).then(function (data) {
                window.history.back();
            });
        };


        vm.addAccess = function () {
            xmlModuleService.addXMLAccess(vm.entityAccessAdd).then(function (data) {
                window.history.back();
            });
        };
        vm.addUserAccess = function () {
            xmlModuleService.addXMLUserAccess(vm.entityAccessUser).then(function (data) {
                location.reload();
            });
        };


        vm.getXMLSettingsCategory = function () {
            xmlModuleService.getXMLSettingsCategory(vm.entity.Id).then(function (data) {
                vm.entity.Categories = data;
            });
        }


    }
})();
