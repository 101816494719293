(function () {
    'use strict';

    angular
        .module('app.layout')
        .controller('HeaderController', HeaderController);

    HeaderController.$inject = ['$scope', '$route', '$state', 'routerHelper', 'Settings', 'SharedData', 'GeneralService', 'ResourcesService', 'localStorageService', '$translate'];

    function HeaderController($scope, $route, $state, routerHelper, Settings, SharedData, GeneralService, ResourcesService, localStorageService, $translate) {
        /*jshint validthis: true */
        var vm = this;
        vm.SharedData = SharedData;
        vm.states = routerHelper.getStates();
        vm.currentState = $state.current;
        vm.Settings = Settings;
        activate();

        function activate() {
            $('[data-toggle="control-sidebar"]').controlSidebar();
            getNavRoutes();
            vm.currentCulture = localStorageService.get('culture');
        }

        $scope.$watch(function () {
            return $state.$current;
        }, function (newVal, oldVal) {
            vm.topState = null;
            vm.currentState = newVal;
            if (vm.currentState.self && vm.currentState.self.settings && vm.currentState.self.settings.topName) {
                vm.topState = vm.states.find(r => r.name === vm.currentState.self.settings.topName && !r.abstract);
            }
        });

        ResourcesService.getCultures()
            .then(function (data) {
                vm.cultures = data;
            });

        vm.updateCulture = function (culture) {
            localStorageService.set('culture', culture);
            $translate.use(culture)
                .then(function () {
                    $translate.refresh();
                });
            vm.currentCulture = culture;
        };

        function getNavRoutes() {
            vm.navRoutes = vm.states.filter(function (r) {
                return r.settings && r.settings.name && r.settings.nav && !r.settings.topName;
            }).sort(function (r1, r2) {
                return r1.settings.nav - r2.settings.nav;
            });
        }

        vm.getStateUrl = function (stateName) {
            return $state.href(stateName, {}, {
                absolute: false
            });
        };

        vm.reset = function () {
            vm.topState = null;
        };

        vm.feedback = {};
        vm.sendFeedback = function () {
            vm.feedbackLoading = true;
            vm.feedbackMessage = null;
            GeneralService
                .sendFeedback(vm.feedback)
                .then(function () {
                    vm.feedbackMessage = "Mesajınız alındı";
                    vm.feedback = {};
                    vm.feedbackLoading = true;
                })
        }
    }
})();