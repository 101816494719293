(function() {
    "use strict";

    angular.module("app.production").factory("ProductOptionValuesService", Service);

    Service.$inject = [
        "$http",
        "$location",
        "$q",
        "ngAuthSettings",
        "logger",
        "config",
        "Settings",
        "ProductsGeneralService",
        "OptionValuesService"
    ];

    function Service(
        $http,
        $location,
        $q,
        ngAuthSettings,
        logger,
        config,
        Settings,
        ProductsGeneralService,
        OptionValuesService
    ) {
        var service = {
            getAll: getAll,
            add: add,
            edit: edit,
            del: del,
            getFields: getFields
        };
        var baseUrl = ngAuthSettings.apiServiceBaseUri;
        var serviceBaseUrl = baseUrl + "api/ProductOptionValues/";

        var fields = [
            {
                key: "OptionId",
                type: "horizontalSelect",
                templateOptions: {
                    label: "Özellik",
                    resource: "form.option",
                    options: [{ Name: "..." }]
                },
                controller: [
                    "$scope",
                    function($scope) {
                        ProductsGeneralService.getOptions().then(function(response) {
                            $scope.to.options = response;
                        });
                    }
                ]
            },
            {
                key: "OptionValueId",
                type: "horizontalSelect",
                templateOptions: {
                    label: "Değer",
                    resource: "form.option-value",
                    labelProp: "Value",
                    options: [{ Value: "Önce Özellik Seçmelisiniz..." }]
                },
                controller: [
                    "$scope",
                    function($scope) {
                        $scope.$watch("model.OptionId", function(newValue, oldValue) {
                            if (newValue) {
                                OptionValuesService.getOptionValue(newValue).then(function(response) {
                                    $scope.to.options = response;
                                });
                            }
                        });
                    }
                ]
            },
            {
                key: "DisplayOrder",
                type: "horizontalInput",
                templateOptions: {
                    type: "number",
                    resource: "form.display-order",
                    label: "Sıra",
                    placeholder: "Sıra"
                }
            },
            {
                key: 'Default',
                type: 'horizontalCheckbox',
                templateOptions: {
                    label: 'Varsayılan',
                    resource: 'form.default',
                    placeholder: 'Varsayılan'
                }
            },
            {
                key: 'UseForSubVariantProduct',
                type: 'horizontalCheckbox',
                defaultValue: true,
                templateOptions: {
                    label: 'Varyant Ürünleri için Kullan',
                    resource: 'form.use-for-sub-variant-product',
                    placeholder: 'Varyant Ürünleri için Kullan'
                }
            },
        ];

        return service;

        /////////////////////////////////////////

        function getFields() {
            return fields;
        }

        function getAll(productId, IsSpesific) {
            var deferred = $q.defer();

            $http
                .get(serviceBaseUrl + productId + "?PageSize=-1&IsSpesific=" + IsSpesific)
                .then(getComplete)
                .catch(getFailed);

            function getComplete(response) {
                deferred.resolve(response.data);
            }

            function getFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function add(entity) {
            var deferred = $q.defer();

            $http
                .post(serviceBaseUrl, entity)
                .then(editComplete)
                .catch(editFailed);

            function editComplete(response) {
                deferred.resolve(response.data);
            }

            function editFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function edit(entity) {
            var deferred = $q.defer();

            $http
                .put(serviceBaseUrl + entity.Id, entity)
                .then(editComplete)
                .catch(editFailed);

            function editComplete(response) {
                deferred.resolve(response.data);
            }

            function editFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function del(entity) {
            var deferred = $q.defer();

            $http
                .delete(serviceBaseUrl + entity.Id, entity)
                .then(editComplete)
                .catch(editFailed);

            function editComplete(response) {
                deferred.resolve(response.data);
            }

            function editFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }
    }
})();