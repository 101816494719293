(function () {
    'use strict';

    angular
        .module('app.blogPostCategory')
        .controller('BlogPostCategoryEditController', BlogPostCategoryEditController);

    BlogPostCategoryEditController.$inject = ['$location', '$stateParams', 'BlogPostCategoryService', 'Upload'];

    function BlogPostCategoryEditController($location, $stateParams, BlogPostCategoryService, Upload) {
        var vm = this;

        vm.loading = true;
        vm.title = '';

        BlogPostCategoryService.getCategory($stateParams.CategoryId).then(function (data) {
            vm.entity = data;
            vm.loading = false;
        });

        vm.fields = BlogPostCategoryService.getCategoryFields();

        vm.edit = function () {
            if (vm.form.$valid) {
                vm.loading = true;
                vm.entity.Options = null;
                BlogPostCategoryService.editCategory(vm.entity).then(function () {
                    vm.loading = false;
                    $location.path('/blogpostcategory/detail/' + vm.entity.Id);
                });
            }
        };

        vm.options = {
            formState: {
                upload: function (fs) {
                    console.log(fs);
                    vm.files = fs;
                }
            }
        };

        vm.upload = function ($files) {
            console.log("$files", $files, Upload);
        };

        vm.cancel = function () {
            $location.path('/blogpostcategory/detail/' + vm.entity.Id);
        };
    }
})();