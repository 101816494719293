(function () {
    'use strict';

    angular
        .module('app.n11Integration')
        .controller('EditMatchCategoryFilterValueController', EditMatchCategoryFilterValueController);

    EditMatchCategoryFilterValueController.$inject = ['$scope', '$stateParams','$location', 'N11IntegrationService', 'NgTableParams'];

    function EditMatchCategoryFilterValueController($scope, $stateParams,$location, N11IntegrationService, NgTableParams) {
        /* jshint validthis:true */
        var vm = this;

        vm.settings = globalSettings;
        activate();

        function activate() {
            vm.firstLoad = true;
            vm.tableParams = new NgTableParams(
                angular.extend({ page: 1, count: 10 }, $location.search()),
                {
                    getData: function () {
                        return N11IntegrationService.getMatchCategoryFilter($stateParams.Id)
                            .then(function (data) {
                                return N11IntegrationService.getCategoryFilterValue(data.IntegrationCategoryAttrId)
                                    .then(function (result) {
                                        return result;
                                    });
                            });
                    }
                });

            N11IntegrationService.getMatchCategoryFilter($stateParams.Id)
                .then(function (data) {
                    N11IntegrationService.getOptionValue(data.OptionId).then(function (result) {
                        vm.entity = result;
                        vm.entity.refresh = true;
                        vm.loading = false;
                    });
            });


            vm.MatchCategoryFilterId = $stateParams.Id;
        }

        vm.selectFilterValue = function (filter, option) {
            vm.loading = true;
            vm.catAttrValue = {};
            vm.catAttrValue.OptionValueId = option;
            vm.catAttrValue.MatchCategoryFilterId = $stateParams.Id;
            vm.catAttrValue.IntegrationCategoryAttrValueId = filter.Id;
            vm.catAttrValue.IntegrationCategoryAttrValueName = filter.Name;
            N11IntegrationService.editMatchCategoryFilterValue(vm.catAttrValue).then(function (data) {
                vm.loading = false;
                $location.path('/n11Integration/editmatchcategoryfiltervalue/' + data.MatchCategoryFilterId);
            });
        };

    }
})();