(function () {
    'use strict';

    angular.module('app.salespointTypes')
        .run(appRun);

    appRun.$inject = ['routerHelper'];

    function appRun(routerHelper) {
        routerHelper.configureStates(getStates());
    }

    function getStates() {
        return [
            {
                state: 'salespointTypes',
                config: {
                    url: '/salespointTypes',
                    title: 'Bayi Tipleri',
                    abstract: true,
                    templateUrl: 'app/salespointTypes/salespointTypes.html'
                }
            },
            {
                state: 'salespointTypes.list',
                config: {
                    templateUrl: 'app/salespointTypes/salespointTypes.list.html',
                    controller: 'SalespointTypesListController',
                    controllerAs: 'vm',
                    url: '/list',
                    title: 'Bayi Tip Listesi',
                    settings: {
                        topName: 'sales',
                        content: 'Bayi Tip Listesi',
                        nav: 33,
                        name: 'salespointtypes.list',
                        resource: 'menu.salespointtypes.list'
                    }
                }
            }
        ];
    }
})();