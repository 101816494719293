(function () {
    'use strict';

    angular
        .module('app.orders')
        .controller('OrdersEditController', OrdersEditController);

    OrdersEditController.$inject = ['$scope', '$stateParams', '$filter', '$location', 'CustomersService', 'OrdersService', 'OrderProductsService', 'ProductsService', 'uiGridConstants', 'uiGridGroupingConstants'];

    function OrdersEditController($scope, $stateParams, $filter, $location, CustomersService, OrdersService, OrderProductsService, ProductsService, uiGridConstants, uiGridGroupingConstants) {
        var vm = this;

        activate();

        function activate() {
            var h = angular.element(window).height() - 40 - 40 - 30 - 20;
            angular.element('#order-info').css("min-height", h);
            angular.element('#order-add').css("min-height", h);

            vm.orderFields = OrdersService.getOrderFields();

            vm.order = {};

            OrdersService.getOrder($stateParams.OrderId).then(function (data) {
                vm.order = data;
                vm.customer = vm.order.Customer;
                //vm.calculateTotal();
                vm.loading = false;
            });
        }

        vm.addOrderProduct = function () {
            var newProduct = {
                Quantity: 1,
                UnitPrice: 0,
                Tax: 0.18
            };
            vm.order.OrderProduct.push(newProduct);
        };

        vm.removeOrderProduct = function (orderProduct) {
            if (orderProduct.Id) {
                vm.loading = true;
                OrderProductsService.deleteOrderProduct(orderProduct.Id).then(function (data) {
                    var index = vm.order.OrderProduct.indexOf(orderProduct);
                    vm.order.OrderProduct.splice(index, 1);
                    vm.loading = false;
                });
            } else {
                var index = vm.order.OrderProduct.indexOf(orderProduct);
                vm.order.OrderProduct.splice(index, 1);
            }
        };

        vm.editOrder = function () {
            if (vm.form.$valid) {
                vm.loading = true;
                OrdersService.editOrder(vm.order).then(function (data) {
                    vm.loading = false;
                    $location.path('/orders/' + data.Id);
                });
            }
        };

        vm.getProduct = function (val) {
            return ProductsService.getProducts({ Name: val }).then(function (response) {
                return response.Data;
            });
        };

        vm.setProduct = function ($item, orderProduct) {
            orderProduct.ProductId = $item.Id;
            orderProduct.UnitPrice = $item.Price;
        };

        vm.getSubTotal = function () {
            var total = 0;
            if (vm.order.OrderProduct) {
                for (var i = 0; i < vm.order.OrderProduct.length; i++) {
                    var product = vm.order.OrderProduct[i];
                    total += product.Quantity * product.UnitPrice;
                }
            }

            return total;
        };

        vm.getTaxTotal = function () {
            var total = 0;
            if (vm.order.OrderProduct) {
                for (var i = 0; i < vm.order.OrderProduct.length; i++) {
                    var product = vm.order.OrderProduct[i];
                    total += product.Quantity * product.UnitPrice * product.Tax;
                }
            }
            return total;
        };

        vm.getTotal = function () {
            var total = 0;
            if (vm.order.OrderProduct) {
                for (var i = 0; i < vm.order.OrderProduct.length; i++) {
                    var product = vm.order.OrderProduct[i];
                    total += product.Quantity * product.UnitPrice * (parseFloat(product.Tax) + 1.00);
                }
            }
            return total;
        };

        vm.getProductTotal = function (product) {
            return product.Quantity * product.UnitPrice * (parseFloat(product.Tax) + 1.00);
        };

        vm.getProductTax = function (product, tax) {
            return $filter('currency')(product.Quantity * product.UnitPrice * parseFloat(tax));
        };
    }
})();