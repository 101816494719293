(function () {
    'use strict';

    angular
        .module('app.excel')
        .controller('ExcelExportController', ExcelExportController);

    ExcelExportController.$inject = ['$scope', '$stateParams', '$filter', '$location', 'logger', 'ExcelService', '$uibModal', '$uibModalStack'];

    function ExcelExportController($scope, $stateParams, $filter, $location, logger, ExcelService, $uibModal, $uibModalStack) {
        var vm = this;

        activate();

        function activate() {
            vm.excelExport = {};
            vm.excelExport.Category = [];
        }

        vm.excelExportFields = ExcelService.getExcelExportFields();
        vm.excelFilterFields = ExcelService.getExcelFilterFields();


        vm.exportExcel = function () {
            console.log("vm.excelExport", vm.excelExport);
            vm.loading = true;
            ExcelService.exportFile(vm.excelExport).then(function (result, a, b, c) {
                console.log('excel result', result, a, b, c);
                vm.loading = false;
            });
        };

        vm.export = function () {
            console.log("vm.excelExport", vm.excelExport);
            vm.loading = true;
            vm.excelExport.propDelete = false;
            vm.excelExport.Category = vm.excelExport.Category || [];
            vm.excelExport.CategoryMapFilter = vm.excelExport.Category.map(function (item) {
                return item.id;
            });
            setTimeout(function () {
                $("#exportForm").submit();
            }, 1000);
            vm.loading = false;
        };

        vm.exportWithDelete = function () {
            console.log("vm.excelExportWithDelete", vm.excelExport);
            vm.loading = true;
            vm.excelExport.propDelete = true;
            vm.excelExport.Category = vm.excelExport.Category || [];
            vm.excelExport.CategoryMapFilter = vm.excelExport.Category.map(function (item) {
                return item.id;
            });
            setTimeout(function () {
                $("#exportForm").submit();
            }, 1000);
            vm.loading = false;
        };

        vm.modalInstance = null;
        vm.openModal = function () {
            vm.modalInstance = $uibModal.open({
                templateUrl: 'ModalContent.html',
                controller: ["$scope","$uibModalInstance",function ($scope, $uibModalInstance) {
                    $scope.ok = function () {
                        $uibModalInstance.close();
                    };

                    $scope.cancel = function () {
                        $uibModalInstance.dismiss('cancel');
                    };
                }  ]
            });
        }

        vm.cancel = function () {
            console.log("dismiss");
            $uibModalStack.dismissAll();
        }
    }
})();

