(function () {
    'use strict';

    angular
        .module('app.production')
        .controller('ProductsCombiningController', ProductsCombiningController);

    ProductsCombiningController.$inject = [
        '$scope',
        '$location',
        'ProductsService',
        'ProductsGeneralService',
        'NgTableParams',
        'Settings',
        'CulturesService',
        'SharedData',
        'OptionService',
        'OptionValuesService',
        '$state',
        '$uibModal'
    ];

    function ProductsCombiningController(
        $scope,
        $location,
        ProductsService,
        ProductsGeneralService,
        NgTableParams,
        Settings,
        CulturesService,
        SharedData,
        OptionService,
        OptionValuesService,
        $state,
        $uibModal) {
        var vm = this;
        vm.labels = [
            'label-success',
            'label-warning',
            'label-info',
            'label-primary',
            'label-danger',
            'label-default'
        ];
        activate();

        function activate() {
            vm.refresh = function () {
                vm.loading = true;
                vm.tableParams.reload();
                vm.loading = false;
            };

            vm.settings = Settings;
            vm.SharedData = SharedData;
            vm.showFilter = true;
            vm.filterModel = {};
            vm.filterModel.CultureId = null;
            vm.active = function (Id) {
                vm.loading = true;
                ProductsService.activeProduct(Id).then(function (data) {
                    vm.tableParams.reload();
                    vm.loading = false;
                });
            };

            vm.passive = function (Id) {
                vm.loading = true;
                ProductsService.passiveProduct(Id).then(function (data) {
                    vm.tableParams.reload();
                    vm.loading = false;
                });
            };

            vm.BaseType = ProductsGeneralService.getBaseTypes().then(function (data) {
                return data.map(function (item) {
                    return {
                        id: item.Id,
                        title: item.Name
                    };
                });
            });

            vm.cultureFilterParams = {
                CultureId: {
                    id: 'select',
                    placeholder: 'Dil'
                }
            };

            vm.getFilterParams = function (name, type, placeholder) {
                var json = '{"' + name + '": { "id": "' + type + '", "placeholder": "' + placeholder + '" }}';
                return JSON.parse(json);
            };

            ProductsGeneralService.getCategories()
                .then(function (data) {
                    vm.Categories = getTreeData(data, null);
                });

            ProductsGeneralService.getProductBrands()
                .then(function (data) {
                    vm.Brands = data;
                });

            OptionService.getAll({
                PageSize: -1
            })
                .then(function (data) {
                    vm.Options = data.Data;
                });

            vm.getOptionValue = function () {
                OptionValuesService.getAll(vm.filterModel.OptionId)
                    .then(function (data) {
                        vm.OptionValues = data;
                    });
            }

            function getTreeData(options, topProp) {
                var data = options.filter(obj => obj["TopCategoryId"] === topProp);
                if (data.length <= 0) {
                    return [];
                }
                return data
                    .map(obj => {
                        var rObj = {};
                        rObj.id = obj["Id"];
                        rObj.name = obj["Name"];
                        rObj.children = getTreeData(options, obj.Id);
                        return rObj;
                    });
            }

            vm.Cultures = CulturesService.getAll({
                PageSize: -1
            }).then(function (data) {
                vm.cultures = data.Data;

                return data.Data.map(function (item) {
                    return {
                        id: item.Id,
                        title: item.Description
                    };
                });
            });

            vm.PriceFilter = {
                MinPrice: {
                    id: 'ng-table/money.html',
                    placeholder: 'Minimum Fiyat'
                },
                MaxPrice: {
                    id: 'ng-table/money.html',
                    placeholder: 'Maximum Fiyat'
                }
            };

            vm.mainProductGroupFilter = {
                IsMainProductGroup: {
                    id: 'ng-table/checkbox.html'
                }
            };
            vm.MainProductGroupFilterData = [{
                id: true,
                title: 'Ana Grup Ürünü'
            },
            {
                id: false,
                title: 'Alt Grup Ürün'
            }
            ];

            vm.mainVariantProductFilter = {
                IsManinVariantProduct: {
                    id: 'ng-table/checkbox.html'
                }
            };

            vm.categoryFilter = {
                CategoryListId: {
                    id: 'ng-table/category-filter.html'
                }
            };

            vm.MainVariantProductFilterData = [{
                id: true,
                title: 'Ana Varyant Ürünü'
            },
            {
                id: false,
                title: 'Alt Varyant Ürün'
            }
            ];



            vm.firstLoad = true;
            vm.tableParams = new NgTableParams({}, {
                dataset: []
            });
            vm.tableParams = new NgTableParams(
                angular.extend({
                    page: 1,
                    count: 10,
                    sorting: {
                        Name: "asc"
                    }
                }, {}), {
                getData: function (params) {
                    if (!vm.firstLoad) {
                        $location.search(params.url());
                    }
                    vm.firstLoad = false;
                    var filterData2 = params.filter();
                    var filterData = {};
                    filterData.PageIndex = params.page();
                    filterData.PageSize = params.count();
                    filterData.SortProperty = Object.keys(params.sorting())[0];
                    filterData.SortType = params.sorting()[Object.keys(params.sorting())[0]];
                    var baseFilterKeys = Object.keys(filterData2);
                    for (var i = 0; i < baseFilterKeys.length; i++) {
                        filterData[baseFilterKeys[i]] = filterData2[baseFilterKeys[i]];
                    }
                    if (filterData2.CategoryListId) {
                        filterData.CategoryListId = filterData2.CategoryListId.map(function (item) {
                            return item.id
                        });
                    }
                    var filterModelKeys = Object.keys(vm.filterModel);
                    for (var i = 0; i < filterModelKeys.length; i++) {
                        filterData[filterModelKeys[i]] = vm.filterModel[filterModelKeys[i]];
                    }
                    if (vm.filterModel.CategoryListId) {
                        filterData.CategoryListId = vm.filterModel.CategoryListId.map(function (item) {
                            return item.id
                        });
                    }
                    return ProductsService.getProducts(filterData).then(function (data) {
                        params.total(data.FilteredCount);
                        return data.Data;
                    });
                }
            });
        }

        vm.getAllProductMedia = function (ProductId) {
            for (var row of vm.tableParams.data) {
                if (row.Id !== ProductId) {
                    row.openPopover = false;
                }
            }
            vm.imageLoading = true;
            vm.selectedProductImages = null;
            ProductsService.getAllProductMedia(ProductId)
                .then(function (data) {
                    vm.selectedProductImages = data;
                    vm.imageLoading = false;
                });
        };

        vm.getOtherCulture = function (Product) {
            if (!vm.cultures || vm.cultures.length === 0) {
                return;
            }
            if (!Product.Culture) {
                return vm.cultures;
            }
            var otherCultures = vm.cultures.filter(function (item) {
                return item.Id !== Product.Culture.Id;
            });
            return otherCultures;
        };

        vm.changeActivation = function (product) {
            vm.loading = true;
            if (product.Active) {
                ProductsService.activeProduct(product.Id).then(function (data) {
                    vm.tableParams.reload();
                    vm.loading = false;
                });
            } else {
                ProductsService.passiveProduct(product.Id).then(function (data) {
                    vm.tableParams.reload();
                    vm.loading = false;
                });
            }
        };

        vm.copyProductToCulture = function (ProductId, CultureId) {
            vm.loading = true;
            ProductsService.copyToCulture(ProductId, CultureId)
                .then(function (data) {
                    vm.loading = false;
                    $state.go('products.edit', {
                        ProductId: data.Id
                    });
                }, function () {
                    vm.loading = false;
                });
        };

        vm.copyProduct = function (ProductId) {
            vm.loading = true;
            ProductsService.copy(ProductId)
                .then(function (data) {
                    vm.loading = false;
                    $state.go('products.edit', {
                        ProductId: data.Id
                    });
                }, function () {
                    vm.loading = false;
                });
        };

        ///// Util

        vm.hashCode = function (str) {
            // java String#hashCode
            var hash = 0;
            for (var i = 0; i < str.length; i++) {
                hash = str.charCodeAt(i) + ((hash << 5) - hash);
            }
            return hash;
        }

        vm.intToRGB = function (i) {
            var c = (i & 0x00FFFFFF)
                .toString(16)
                .toUpperCase();

            return "00000".substring(0, 6 - c.length) + c;
        }

        vm.getMainImage = function (product) {
            if (!product.ProductMedia) {
                return 'no-image.png';
            }
            var mainMedia =
                product.ProductMedia.find(function (media) {
                    return media.IsMain === true;
                }) ||
                product.ProductMedia[0];
            if (mainMedia) {
                return mainMedia.Media.FullPath;
            } else {
                return 'no-image.png';
            }
        };

        var slides = $scope.slides = [];
        var currIndex = 0;
        $scope.addSlide = function () {
            var newWidth = 600 + slides.length + 1;
            slides.push({
                image: '//unsplash.it/' + newWidth + '/300',
                text: ['Nice image', 'Awesome photograph', 'That is so cool', 'I love that'][slides.length % 4],
                id: currIndex++
            });
        };

        $scope.randomize = function () {
            var indexes = generateIndexesArray();
            assignNewIndexesToSlides(indexes);
        };

        vm.FilterList = [];
        vm.FilterListData = [];
        vm.Filtertitle = "";
        vm.OptionList = []
        vm.addNewColumn = function (OptionData) {


            if (OptionData.OptionId) {
                vm.OptionList.push(OptionData)
                for (var i = 0; i < 10; i++) {
                    if (vm.FilterList[i] == OptionData.OptionId) {
                        break;
                    }
                    if (vm.FilterList[i] == undefined) {
                        vm.FilterList[i] = OptionData.OptionId;
                        vm.Filtertitle = OptionData.Name;
                        OptionValuesService.getAll(OptionData.OptionId)
                            .then(function (data) {
                                vm.FilterListData[i] = data
                            });
                        break;
                    }
                }

            }
        }

        vm.Combining = function (Alldata) {

            var retList = $.grep(Alldata.data, function (element) { return element.IsSelected == true; })

            if (retList.length > 1) {
                
                for (var i = 0; i < (retList.length); i++) {
                    for (var j = 0; j < 10; j++) {
                        if (retList[i].TempProductVariantValue[j] != null) {
                            var option = JSON.parse(retList[i].TempProductVariantValue[j]);
                            retList[i].TempProductVariantValue[j] = option;
                        }
                    }
                }
                
                vm.openModal(retList, vm.OptionList);
            } else {
                toastr.warning("Ürün birleştirme yapmak için en az iki ürün seçiniz", "Ürün Birleştirme");
            }

        }

        for (var i = 0; i < 4; i++) {
            $scope.addSlide();
        }
        vm.modalTitle = "Ürün Birleştirme";
        vm.modalInstance = null;
        vm.openModal = function (data, optionlist) {
            vm.modalInstance = $uibModal.open({
                templateUrl: 'ModalCombiningContent.html',
                controller: 'ModalCombiningController',
                controllerAs: 'vm',
                resolve: {
                    items: function () {
                        return {
                            ProductData: data,
                            OptionData: optionlist

                        };
                    }
                }
            });
        }
    }

    angular
        .module('app.production')
        .controller('ModalCombiningController', ModalCombiningController);

    ModalCombiningController.$inject = [
        '$scope',
        '$stateParams',
        '$filter',
        '$location',
        'logger',
        'GetProductsService',
        '$uibModal',
        '$uibModalStack',
        '$uibModalInstance',
        'items',
        'ProductsService',
        'CulturesService'];

    function ModalCombiningController(
        $scope,
        $stateParams,
        $filter,
        $location,
        logger,
        GetProductsService,
        $uibModal,
        $uibModalStack,
        $uibModalInstance,
        items,
        ProductsService,
        CulturesService) {

        var vm = this;
        vm.content = items;
        activate();
        vm.modalTitle = "Ürün Birleştirme";
        function activate() {
            vm.CombiningProductData = vm.content.ProductData;
            vm.ProductVariant = vm.content.OptionData;
            for (var i = 0; i < vm.CombiningProductData.length; i++) {
                for (var j = 0; j < vm.content.OptionData.length; j++) {

                    vm.CombiningProductData[i].ProductVariant.push(vm.content.OptionData[j]);
                }

            }

        }

       

        vm.CombiningMain = function (CombiningProductData) {


            if (vm.IsMainProduct != 'new') {
                for (var i = 0; i < CombiningProductData.length; i++) {
                    CombiningProductData[i].BaseProductId = vm.MainProduct.Id;
                }

                for (var j = 0; j < vm.ProductVariant.length; j++) {
                    vm.MainProduct.ProductVariant.push(vm.ProductVariant[j]);
                }

                CombiningProductData.push(vm.MainProduct);
                for (var i = 0; i < (CombiningProductData.length - 1); i++) {

                    for (var j = 0; j < 10; j++) {
                        if (CombiningProductData[i].TempProductVariantValue[j] != undefined) {
                            console.log("opt   ", CombiningProductData[i].TempProductVariantValue[j]);
                            var option = CombiningProductData[i].TempProductVariantValue[j];
                            console.log("option", option);
                            var optionvalue = { 'OptionId': option.OptionId, 'OptionValueId': option.Id };
                            CombiningProductData[i].ProductOptionValue.push(optionvalue)
                        }
                    }
                }

                ProductsService.ProductCombining(CombiningProductData)
                    .then(function (result) {
                        location.reload(); 
                    });
            }

            else {

                vm.MainProduct.BaseTypeId = CombiningProductData[0].BaseTypeId;
                vm.MainProduct.CurrencyId = CombiningProductData[0].CurrencyId;
                vm.MainProduct.Active = true;
                vm.MainProduct.UseSubProductForVariant = true;
                ProductsService.addProduct(vm.MainProduct)
                    .then(function (data) {
                        for (var i = 0; i < CombiningProductData.length; i++) {
                            CombiningProductData[i].BaseProductId = data.Id;
                        }

                        for (var j = 0; j < vm.ProductVariant.length; j++) {
                            data.ProductVariant.push(vm.ProductVariant[j]);
                        }
                        CombiningProductData.push(data);

                        for (var i = 0; i < (CombiningProductData.length - 1); i++) {

                            for (var j = 0; j < 10; j++) {
                                if (CombiningProductData[i].TempProductVariantValue[j] != null) {
                                    var option = CombiningProductData[i].TempProductVariantValue[j];
                                    var optionvalue = { 'OptionId': option.OptionId, 'OptionValueId': option.Id };
                                    CombiningProductData[i].ProductOptionValue.push(optionvalue)
                                }
                            }
                        }

                        ProductsService.ProductCombining(CombiningProductData)
                            .then(function (result) {
                                location.reload(); 
                            });

                    });
            }

        }

        vm.refreshFilterProduct = function (search) {
            var params = {
                SearchKey: search,
                PageSize: 18,
                PageIndex: 1,
                SortProperty: 'Name',
                SortType: 'asc'
            };
            vm.filteredProductList = [{
                Id: '',
                Name: 'Yükleniyor...'
            }];
            return ProductsService.getProducts(params)
                .then(function (response) {
                    if (response.Data.length > 0) {
                        vm.filteredProductList = response.Data;
                    } else {
                        vm.filteredProductList = [{
                            Id: '',
                            Name: 'Bulunamadı (' + search + ')'
                        }];
                    }
                });
        }


        vm.Cultures = CulturesService.getAll({
            PageSize: -1
        }).then(function (data) {
            vm.cultures = data.Data;

            return data.Data.map(function (item) {
                return {
                    id: item.Id,
                    title: item.Description
                };
            });
        });

    }

})();