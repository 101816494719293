(function () {
    'use strict';

    angular.module('app.core', [
        /*
         * Angular modules
         */
        'ngAnimate',
        'ngMessages',
        'ngSanitize',
        /*
         * Our reusable cross app code modules
         */
        'blocks.exception',
        'blocks.logger',
        'blocks.router',
        'app.account',
        /*
         * 3rd Party modules
         */
        'ui.router', // 'ngplus'
        'formly',
        'formlyBootstrap',
        'ngFileUpload',
        'pascalprecht.translate',
        'FileManagerApp',
        'multi-select-tree'
        //'ui.select'
    ]);
})();