(function () {
    'use strict';

    angular
        .module('app.pages')
        .controller('PagesBuildController', Controller);

    Controller.$inject = ['$scope', '$stateParams', '$location', 'PagesService', 'PageSectionsService', '$timeout', 'localStorageService', 'GeneralService', 'Settings', '$sce', '$q'];

    function Controller($scope, $stateParams, $location, PagesService, PageSectionsService, $timeout, localStorageService, GeneralService, Settings, $sce, $q) {
        var vm = this;

        function activate() {
            vm.entity = {};

            $timeout(function () {
                var h = angular.element(window).height() - 40 - 40 - 30 - 20 - 44 - 42;
                angular.element('#page-tree').css("height", h);
                angular.element('#section-detail').css("height", h);
                angular.element('#section-copy').css("height", h);
            }, 100)

            vm.selectedSection = {};
            vm.collapseList = [];
            vm.treeOptions = {
                beforeDrop: function (e) {
                    try {
                        var sourceValue = e.source.nodeScope.$modelValue;
                        var destValue = e.dest.nodesScope.node ? e.dest.nodesScope.node : undefined;
                        var newList = angular.copy(destValue.Section);
                        newList = newList.filter(x => x.Id !== sourceValue.Id);
                        newList.splice(e.dest.index, 0, sourceValue);
                        sourceValue.TopSectionId = destValue.Id;
                        vm.loading = true;
                        var promise_array = [];
                        for (var i = 0; i < newList.length; i++) {
                            newList[i].Line = i;
                            var promise = PageSectionsService.edit(newList[i]);
                            promise_array.push(promise);
                        }
                        $q.all(promise_array)
                            .then(function () {
                                vm.loadPageData();
                            });
                        return true;
                    } catch (e) {
                        return false;
                    }
                },
                toggle: function (collapsed, nodeScope) {
                    if (collapsed) {
                        vm.collapseList.push(nodeScope.$modelValue.Id);
                    } else {
                        vm.collapseList = vm.collapseList.filter(function (value) {
                            return value != nodeScope.$modelValue.Id;
                        });
                    }
                }
            };

            vm.treedata = [{
                id: 1,
                type: 'main',
                text: 'Yükleniyor...',
                icon: "note",
                Section: []
            }];

            vm.fields = PagesService.getFields();

            vm.sectionFields = PagesService.getSectionFields();

            vm.loadPageData();

            vm.absoluteUrl = vm.trustSrc(Settings.WebServer);
        }

        vm.loadPageData = function () {
            PagesService.get($stateParams.Id)
                .then(function (data) {
                    vm.entity = data;
                    for (const section of data.Section) {
                        section.collapsed = vm.collapseList.find(function (value) {
                            return value == section.Id;
                        }) != null;
                    }
                    vm.treedata[0].Name = data.Name;
                    vm.treedata[0].Active = true;
                    vm.treedata[0].collapsed = false;
                    vm.treedata[0].IsMain = true;
                    vm.treedata[0].Section = data.Section.filter(function (item) {
                            return item.TopSectionId === null;
                        })
                        .sort(function (a, b) {
                            return a.Line - b.Line
                        });
                    vm.prepareAllSection(vm.treedata[0].Section);
                    vm.absoluteUrl = vm.trustSrc(Settings.WebServer + '/' + vm.entity.Url);
                    vm.loading = false;
                });
        };

        vm.collapseAll = function () {
            $scope.$broadcast('angular-ui-tree:collapse-all');
            vm.collapseList = vm.entity.Section.map(function(item) { return item.Id });
        };

        vm.expandAll = function () {
            $scope.$broadcast('angular-ui-tree:expand-all');
            vm.collapseList = [];
        };

        vm.trustSrc = function (src) {
            return $sce.trustAsResourceUrl(src);
        };

        vm.prepareAllSection = function (sectionList) {
            for (var section of sectionList) {
                section.Section = vm.entity.Section.filter(function (item) {
                        return item.TopSectionId === section.Id;
                    })
                    .sort(function (a, b) {
                        return a.Line - b.Line
                    });
                vm.prepareAllSection(section.Section);
            }
        };

        vm.refreshIframe = function () {
            document.getElementById('preview-frame').src = document.getElementById('preview-frame').src;
        };

        vm.delete = function () {
            if (confirm("Silmek istediğinize emin misiniz?")) {
                PagesService.del(vm.entity).then(function (data) {
                    $location.path('/pages/list');
                });
            }
        };

        vm.editSection = function () {
            if (vm.form.$valid) {
                vm.loading = true;
                vm.selectedSection.Data = JSON.stringify(vm.selectedSectionData);
                PageSectionsService.edit(vm.selectedSection)
                    .then(function () {
                        vm.loading = false;
                        vm.loadPageData();
                        vm.selectedSection = {};
                        vm.selectedSectionData = {};
                        vm.form.$setUntouched();
                    });
            }
        };

        vm.copySection = function (node) {
            vm.loading = true;
            PageSectionsService.copy(node.Id)
                .then(function () {
                    vm.loading = false;
                    vm.loadPageData();
                    vm.selectedSection = {};
                    vm.selectedSectionData = {};
                    vm.form.$setUntouched();
                });
        };

        vm.prepareCopySectionToPage = function (node) {
            vm.prepareCopySectionId = node.Id;
            vm.loading = true;
            PagesService.getAll({
                    PageSize: -1
                })
                .then(function (data) {
                    vm.loading = false;
                    vm.pageList = data.Data;
                });
        };

        vm.copySectionToPage = function (pageId) {
            vm.loading = true;
            PageSectionsService.copyToPage(vm.prepareCopySectionId, pageId)
                .then(function () {
                    vm.loading = false;
                    vm.loadPageData();
                    vm.selectedSection = {};
                    vm.selectedSectionData = {};
                    vm.prepareCopySectionId = null;
                });
        };

        vm.cancelSection = function () {
            vm.loading = false;
            vm.selectedSection = {};
            vm.selectedSectionData = {};
            vm.prepareCopySectionId = null;
        };

        vm.selectSection = function (node) {
            if (vm.selectedSection.Id && vm.selectedSection.Id == node.Id) {
                vm.selectedSection = {};
                return;
            }
            vm.selectedSection = null;
            vm.selectedSection = angular.copy(node);
            vm.selectedSectionData = {};
            vm.selectedSectionData = JSON.parse(vm.selectedSection.Data);
            vm.selectedSection.Settings = {};
            vm.selectedSection.Settings = JSON.parse(vm.selectedSection.Template.Settings);
        };

        vm.prepareAddSection = function (node) {
            vm.selectedSection = {
                PageId: vm.entity.Id,
                TopSectionId: node.Id
            };
            vm.selectedSectionData = {};
        };

        vm.saveSection = function () {
            if (vm.form.$valid) {
                vm.loading = true;
                vm.selectedSection.Data = JSON.stringify(vm.selectedSectionData);
                PageSectionsService.add(vm.selectedSection).then(function () {
                    vm.loading = false;
                    vm.loadPageData();
                    vm.selectedSection = {};
                    vm.selectedSectionData = {};
                    vm.form.$setUntouched();
                });
            }
        };

        vm.deleteSection = function () {
            if (confirm("Silmek istediğinize emin misiniz?")) {
                vm.loading = true;
                PageSectionsService.del(vm.selectedSection).then(function (data) {
                    vm.loading = false;
                    vm.loadPageData();
                    vm.selectedSection = {};
                    vm.selectedSectionData = {};
                    vm.form.$setUntouched();
                });
            }
        };

        vm.clearCache = function () {
            vm.loading = true;
            GeneralService.clearCache()
                .then(function () {
                    vm.loading = false;
                });
        };

        activate();
    }
})();