(function () {
    'use strict';

    angular
        .module('app.n11Integration')
        .controller('EditMatchCategoryController', EditMatchCategoryController);

    EditMatchCategoryController.$inject = ['$scope', '$stateParams','$location', 'N11IntegrationService', 'NgTableParams'];

    function EditMatchCategoryController($scope, $stateParams,$location, N11IntegrationService, NgTableParams) {
        /* jshint validthis:true */
        var vm = this;

        vm.settings = globalSettings;
        activate();

        function activate() {
            vm.fields = N11IntegrationService.getMatchCategoryFields();
            console.log('vm.fields', vm.fields);

            N11IntegrationService.getMatchCategory($stateParams.Id)
                .then(function (data) {
                    vm.entity = data;
                    vm.entity.refresh = true;
                    vm.loading = false;
                });
        }    

        vm.editMatchCategory = function () {
            if (vm.form.$valid) {
                vm.loading = true;
                N11IntegrationService.editMatchCategory(vm.entity).then(function () {
                    vm.loading = false;
                    activate();
                });
            }
        };

    }
})();