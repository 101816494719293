
(function () {
    'use strict';

    angular
      .module('app.deliveries')
      .controller('DeliveriesAddressController', DeliveriesAddressController);

    DeliveriesAddressController.$inject=['$stateParams', 'CustomerAddressService' ]

    function DeliveriesAddressController($stateparams, CustomerAddressService) {
        var vm = this;

        vm.title = '';
        vm.customerAddresses = [{ Name: "Yükleniyor..." }];

        activate();

        function activate() {
            CustomerAddressService.getCustomerAddresses(vm.UserId).then(function (data) {
                vm.customerAddresses = data;
            });
        }

        vm.deleteAddress = function (address) {
            if (confirm("Silmek istediğinize emin misiniz?")) {
                CustomerAddressService.deleteCustomerAddress(address).then(function (data) {
                    var index = vm.customerAddresses.indexOf(address);
                    vm.customerAddresses.splice(index, 1);
                    //$location.path('/customers/' + vm.address.UserId + '/addresses');
                });
            }

        };
    }
})();
