(function () {
    'use strict';

    angular
        .module('app.xmlModule')
        .factory('xmlModuleService', xmlModuleService);

    xmlModuleService.$inject = ['$http', '$q', 'ngAuthSettings', 'logger', 'config', 'ProductsService', 'ProductsGeneralService', 'CategoriesService'];

    function xmlModuleService($http, $q, ngAuthSettings, logger, config, ProductsService, ProductsGeneralService, CategoriesService) {
        var service = {


            addXMLSettings: addXMLSettings,
            addXMLAccess: addXMLAccess,
            getXMLSettingsList: getXMLSettingsList,
            getXMLSettingsFields: getXMLSettingsFields,
            getXMLAccessFields: getXMLAccessFields,
            getXMLAccessList: getXMLAccessList,
            getXMLAccessUserList: getXMLAccessUserList,
            deleteXMLSettings: deleteXMLSettings,
            removeXMLAccess: removeXMLAccess,
            deleteXMLUser: deleteXMLUser,
            getXMLAccessUserFields: getXMLAccessUserFields,
            addXMLUserAccess: addXMLUserAccess,
            getXMLSettings: getXMLSettings,
            updateXMLSettings: updateXMLSettings,
            getXMLSettingsCategoryFields: getXMLSettingsCategoryFields,
            addXMLSettingsCategory: addXMLSettingsCategory,
            GetXMLSettingsAndCategory: GetXMLSettingsAndCategory

        };

        var serviceBase = ngAuthSettings.apiServiceBaseUri + 'api/XML/';


        var XMLSettingsFields = [
            {
                key: 'Active',
                type: 'floatCheckbox',
                templateOptions: {
                    required: false,
                    type: 'floatCheckbox',
                    label: 'Aktif',
                    checked: "checked",
                    resource: 'form.active',
                    placeholder: 'Aktif'
                }
            },
            {
                key: 'Name',
                type: 'floatInput',
                templateOptions: {
                    required: true,
                    type: 'text',
                    label: 'Adı',
                    resource: 'form.name',
                    placeholder: 'Adı'
                }
            },
            {
                key: 'NormalizeName',
                type: 'floatInput',
                templateOptions: {
                    required: true,
                    type: 'text',
                    label: 'Kodu',
                    resource: 'form.code',
                    placeholder: 'Kodu'
                }
            }
        ];

        var XMLAccessFields = [
            {
                key: 'Active',
                type: 'floatCheckbox',
                templateOptions: {
                    required: false,
                    type: 'floatCheckbox',
                    label: 'Aktif',
                    resource: 'form.active',
                    placeholder: 'Aktif'
                }
            },
            {
                key: 'IpAddress',
                type: 'floatInput',
                templateOptions: {
                    required: true,
                    type: 'text',
                    label: 'Ip Adresi',
                    resource: 'form.IpAddress',
                    placeholder: 'Ip Adresi'
                }
            },
            {
                key: 'APIKey',
                type: 'floatInput',
                templateOptions: {
                    required: true,
                    type: 'text',
                    label: 'API Key',
                    resource: 'form.APIKey',
                    placeholder: 'API Key'
                }
            },
            {
                key: 'Description',
                type: 'floatInput',
                templateOptions: {
                    required: true,
                    type: 'text',
                    label: 'Açıklama Alanı',
                    resource: 'form.Description',
                    placeholder: 'Açıklama Alanı'
                }
            }
        ];
        var XMLAccessUserFields =
            [
                {
                    key: 'XMLAccess',
                    type: 'floatTreeSelect',
                    templateOptions: {
                        label: 'XML Kullanıcısı',
                        labelProp: 'Description',
                        resource: 'form.xmlUser',
                        required: true,
                        placeholder: 'Kullanıcı Seçiniz ',
                        options: { Name: 'Yükleniyor...' }
                    },
                    controller: ['$scope', function ($scope) {
                        getXMLAccessList()
                            .then(function (response) {
                                $scope.to.options = response;
                            });
                    }]
                },
                {
                    key: 'XMLSettings',
                    type: 'floatTreeSelect',
                    templateOptions: {
                        label: 'XML Şablonu',
                        labelProp: 'Name',
                        resource: 'form.xmlTemplate',
                        required: true,
                        placeholder: 'XML Seçiniz ',
                        options: { Name: 'Yükleniyor...' }
                    },
                    controller: ['$scope', function ($scope) {
                        getXMLSettingsList()
                            .then(function (response) {
                                $scope.to.options = response;
                            });
                    }]
                }
            ];

        var XMLSettingsCategoryFields = [
            {
                key: "Categories",
                type: "floatTreeMultiSelect",
                templateOptions: {
                    label: "Kategori",
                    resource: "form.categories",
                    topProp: 'TopCategoryId',
                    addTop: false,
                    required: true,
                    placeholder: "Kategori Seçiniz ",
                    options: [{
                        Name: "Yükleniyor..."
                    }]
                },
                controller: [
                    "$scope",
                    function ($scope) {
                        $scope.first = true;
                        $scope.$watch("model.Categories", function (newValue, oldValue) {
                            if ($scope.first && newValue) {
                                $scope.first = false;
                                var filterParams = {
                                    PageSize: -1,
                                    SelectFields: 'Id,Name,TopCategoryId'
                                };
                                CategoriesService.getCategories(filterParams)
                                    .then(function (response) {
                                        $scope.to.options = response.Data;
                                    });
                            }
                        });
                    }
                ]
            }
        ];
        return service;

        function getXMLSettingsFields() {
            return XMLSettingsFields;
        }
        function getXMLAccessFields() {
            return XMLAccessFields;
        }
        function getXMLAccessUserFields() {
            return XMLAccessUserFields;
        }
        function getXMLSettingsCategoryFields() {
            return XMLSettingsCategoryFields;
        }


        /////////////////////////////////////////
        function getXMLSettings(id) {
            var deferred = $q.defer();
            $http.get(serviceBase + "GetXMLSettings/" + id)
                .then(getComplete)
                .catch(getFailed);

            function getComplete(response) {

                deferred.resolve(response.data);
            }

            function getFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }
        function GetXMLSettingsAndCategory(id) {
            var deferred = $q.defer();
            $http.get(serviceBase + "GetXMLSettingsAndCategory/" + id)
                .then(getComplete)
                .catch(getFailed);

            function getComplete(response) {
                deferred.resolve(response.data);
            }

            function getFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function addXMLSettings(entity) {
            var deferred = $q.defer();
            $http.post(serviceBase + "AddXMLSettings/", entity)
                .then(postComplete)
                .catch(postFailed);

            function postComplete(response) {
                deferred.resolve(response.data);
            }

            function postFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }
        function updateXMLSettings(entity) {
            var deferred = $q.defer();
            $http.post(serviceBase + "UpdateXMLSettings/", entity)
                .then(postComplete)
                .catch(postFailed);

            function postComplete(response) {
                deferred.resolve(response.data);
            }

            function postFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }
        function addXMLSettingsCategory(entity) {
            var entity2 = {
                XMLSettingsId: entity.Id,
                Category: entity.Categories
            }
            console.warn(entity2);
            var deferred = $q.defer();
            $http.post(serviceBase + "addSettingsCategory/", entity2)
                .then(postComplete)
                .catch(postFailed);

            function postComplete(response) {
                deferred.resolve(response.data);
            }

            function postFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }
        // function getXMLSettingsCategory(id) {

        //    var deferred = $q.defer();
        //    $http.get(serviceBase + "getSettingsCategory/"+id)
        //        .then(getComplete)
        //        .catch(getFailed);

        //    function getComplete(response) {
        //        deferred.resolve(response.data);
        //    }

        //    function getFailed(error) {
        //        deferred.reject(error);
        //    }
        //    return deferred.promise;
        //}

        function addXMLAccess(entity) {
            var deferred = $q.defer();
            $http.post(serviceBase + "addXMLAccess/", entity)
                .then(postComplete)
                .catch(postFailed);

            function postComplete(response) {
                deferred.resolve(response.data);
            }

            function postFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }
        function addXMLUserAccess(entity) {
            if (entity.XMLAccess[0]) {
                var entity2 = {
                    XMLAccess: entity.XMLAccess[0],
                    XMLSettings: entity.XMLSettings[0]
                }
            }
            else {
                entity2 = entity;
            }


            var deferred = $q.defer();
            $http.post(serviceBase + "addXMLUserAccess/", entity2)
                .then(postComplete)
                .catch(postFailed);

            function postComplete(response) {
                deferred.resolve(response.data);
            }

            function postFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }
        function deleteXMLSettings(id) {
            var deferred = $q.defer();
            $http.post(serviceBase + "DeleteXMLSettings/" + id)
                .then(postComplete)
                .catch(postFailed);

            function postComplete(response) {
                deferred.resolve(response.data);
            }

            function postFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }
        function removeXMLAccess(id) {
            var deferred = $q.defer();
            $http.post(serviceBase + "removeXMLAccess/" + id)
                .then(postComplete)
                .catch(postFailed);

            function postComplete(response) {
                deferred.resolve(response.data);
            }

            function postFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }
        function deleteXMLUser(id) {
            var deferred = $q.defer();
            $http.post(serviceBase + "deleteXMLUser/" + id)
                .then(postComplete)
                .catch(postFailed);

            function postComplete(response) {
                deferred.resolve(response.data);
            }

            function postFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function getXMLSettingsList() {
            var deferred = $q.defer();

            $http.get(serviceBase + "GetXMLSettingsList")
                .then(postComplete)
                .catch(postFailed);

            function postComplete(response) {
                deferred.resolve(response.data);
            }

            function postFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }
        function getXMLAccessList() {
            var deferred = $q.defer();

            $http.get(serviceBase + "GetXMLAccessList")
                .then(postComplete)
                .catch(postFailed);

            function postComplete(response) {
                deferred.resolve(response.data);
            }

            function postFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }
        function getXMLAccessUserList() {
            var deferred = $q.defer();

            $http.get(serviceBase + "getAccessUser")
                .then(postComplete)
                .catch(postFailed);

            function postComplete(response) {
                deferred.resolve(response.data);
            }

            function postFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        //
    }
})();