(function () {
    'use strict';

    angular
      .module('app.deliveries')
      .controller('DeliveriesEditController', DeliveriesEditController);

    DeliveriesEditController.$inject=['$scope', '$stateParams', 'DeliveriesService', 'CustomersService', 'CustomerAddressService', 'uiGridConstants', 'uiGridGroupingConstants', 'FileUploader', '$location', '$anchorScroll']

    function DeliveriesEditController($scope , $stateParams, DeliveriesService, CustomersService, CustomerAddressService, uiGridConstants, uiGridGroupingConstants, FileUploader, $location, $anchorScroll) {
        var vm = this;

        vm.oneAtATime = true;

        vm.status = {
            isCustomHeaderOpen: false,
            isFirstOpen: true
        };

        activate();

        function activate() {

            var h = angular.element(window).height() - 40 - 40 - 30 - 20;
            angular.element('#customer-info').css("min-height", h);
            angular.element('#customer-search').css("min-height", h);

            vm.tabIndex = 0;

        }

        vm.setDelivery = {
            Code: 'TS478562'
        };
        vm.title = 'Teslimat Düzenle';

        vm.customerFields = CustomersService.getCustomerFields();

        vm.deliveryFields = DeliveriesService.getDeliveryFields();

        DeliveriesService.getDelivery($stateParams.id)
           .then(function (response) {
               vm.customer = response.Customer;
               vm.setDelivery = response;
               vm.gridDeliveryGroupsOptions.data = response.Products;

               DeliveriesService.getUndeliveriedOrder(response.Customer.Id)
                      .then(function (response) {
                          vm.gridDeliveryOptions.data = response;
                          vm.gridDeliveryGroupsOptions.data = response.Products;
                      });
            });

        //region GetOrder

        vm.searchFilterOrder = function () {
            vm.loading = true;
            OrdersService.getOrders(vm.searchFilter).then(function (data) {
                vm.loading = false;
                vm.gridDeliveryOptions.data = data.Data;
            });
        }       

        vm.selectedOrder = [];

        vm.gridSelectedOrder = {
            data: vm.selectedOrder,
            rowHeight: 39,
            enableVerticalScrollbar: 2,
            enableHorizontalScrollbar: 2,
            enableFullRowSelection: false,
            enableGrouping: false,
            enableFiltering: true,
            onRegisterApi: function (gridApi) {
                vm.gridSelectedOrder = gridApi;
                vm.gridSelectedOrder.grid.registerDataChangeCallback(function () {
                });
            },
            columnDefs: [
                {
                    name: 'Code', displayName: 'Sipariş Kodu',
                    grouping: { groupPriority: 0 }, sort: {
                        priority: 0, direction: 'asc'
                    },
                },
                { name: 'Customer', displayName: 'Müşteri Adı', cellTemplate: '<div class="ui-grid-cell-contents" >{{row.entity.Customer.Name}} {{row.entity.Customer.Surname}}</div>' },
                { name: 'DeliveryName', displayName: 'Teslimat Adı' },
                { name: 'ProjectName', displayName: 'Proje Adı' },
                { name: 'Description', displayName: 'Açıklama' },
            ]
        }

        vm.delivery = [];

        vm.gridDeliveryOptions = {
            data: vm.delivery,
            rowHeight: 39,
            enableVerticalScrollbar: 2,
            enableHorizontalScrollbar: 2,
            enableFullRowSelection: false,
            enableGrouping: false,
            enableFiltering: true,
            onRegisterApi: function (gridApi) {
                vm.gridDeliveryApi = gridApi;
                vm.gridDeliveryApi.grid.registerDataChangeCallback(function () {
                    //vm.gridDeliveryApi.treeBase.expandAllRows();
                });
            },
            columnDefs: [
                {
                    name: 'Code', displayName: 'Sipariş Kodu',
                    grouping: { groupPriority: 0 }, sort: {
                        priority: 0, direction: 'asc'
                    },
                },
                { name: 'Customer', displayName: 'Müşteri Adı', cellTemplate: '<div class="ui-grid-cell-contents" >{{row.entity.Customer.Name}} {{row.entity.Customer.Surname}}</div>' },
                { name: 'DeliveryName', displayName: 'Teslimat Adı' },
                { name: 'ProjectName', displayName: 'Proje Adı' },
                { name: 'Description', displayName: 'Açıklama' },
            ]
        }

        vm.groupedDelivery = [];


        vm.siparisSec = function () {
            var allData = vm.gridSelectedOrder.data;
            var product = [];
            var productClose = [];
            for (var i = 0; i < allData.length; i++) {
                var item = allData[i];
                for (var y = 0; y < item.OrderProduct.length; y++) {
                    if (item.OrderProduct[y].Quantity > item.OrderProduct[y].DeliveriedQuantity) {
                        product = product.concat(item.OrderProduct[y])
                    }
                    else {
                        productClose = productClose.concat(item.OrderProduct[y])
                    }
                }
            }
            vm.gridProductsOptionsClose.data = productClose;
            vm.gridProductsOptions.data = product;

            vm.gridProductsApi.grid.refresh();
            vm.tabIndex = 1;
            vm.gridSelectedOrder.selection.clearSelectedRows();
        }

        vm.siparisAktar = function () {

            vm.isDisabled = true;

            var selected = vm.gridDeliveryApi.selection.getSelectedRows();

            vm.gridSelectedOrder.data = (vm.gridSelectedOrder.data || []).concat(selected);
            for (var i = 0; i < selected.length; i++) {
                var item = selected[i];
                vm.gridSelectedOrder.selection.selectRow(item);
                for (var j = 0; j < item.Customer.UserAddresses.length; j++) {
                    vm.newDelivery.AddressList.push(item.Customer.UserAddresses[j]);
                }
            }

            vm.gridProductsApi.grid.refresh();
            vm.gridDeliveryApi.selection.clearSelectedRows();
        };


        vm.UrunSecimi = function () {
            vm.gridProductsApi.grid.refresh();
            vm.tabIndex = 1;
        };

        //vm.siparisGrupla = function () {
        //    var selected = vm.gridDeliveryApi.selection.getSelectedRows();
        //    var product = [];
        //    for (var i = 0; i < selected.length; i++) {
        //        var item = selected[i];
        //        product = product.concat(item.OrderProduct)
        //    }

        //    vm.gridProductsOptions.data = product;
        //    vm.gridProductsApi.grid.refresh();
        //    vm.tabIndex = 1;
        //    vm.gridDeliveryApi.selection.clearSelectedRows();
        //}


        vm.gridProductsOptions = {
            data: vm.groupedDelivery,
            rowHeight: 39,
            isRowSelectable: function (row) {
                //if (!row.entity.DeliveriedQuantity) {
                //    return true;
                //}
                if (row.entity.Quantity > row.entity.DeliveriedQuantity) {
                    return true;
                } else {
                    return false;
                }
            },
            enableVerticalScrollbar: 2,
            enableHorizontalScrollbar: 2,
            enableFullRowSelection: false,
            enableGrouping: false,
            onRegisterApi: function (gridApi) {
                vm.gridProductsApi = gridApi;
                vm.gridProductsApi.grid.registerDataChangeCallback(function () {
                    //vm.gridProductsApi.treeBase.expandAllRows();
                });
            },
            columnDefs: [
                {
                    name: 'Code', displayName: 'Ürün Kodu',
                    grouping: { groupPriority: 0 }, sort: {
                        priority: 0, direction: 'asc'
                    },
                },
                { name: 'Name', displayName: 'Ürün Adı' },
                { name: 'Category', displayName: 'Kategori' },
                { name: 'Variant', displayName: 'Variant' },
                { name: 'Quantity', displayName: 'Adet' },
                { name: 'DeliveriedQuantity', displayName: 'Teslim Edilen Adet' }

            ]
        };

        vm.groupedDeliveryClose = [];
        vm.gridProductsOptionsClose = {
            data: vm.groupedDeliveryClose,
            rowHeight: 39,
            isRowSelectable: function (row) {
                //if (!row.entity.DeliveriedQuantity) {
                //    return true;
                //}
                if (row.entity.Quantity > row.entity.DeliveriedQuantity) {
                    return true;
                } else {
                    return false;
                }
            },
            enableVerticalScrollbar: 2,
            enableHorizontalScrollbar: 2,
            enableFullRowSelection: false,
            enableGrouping: false,
            onRegisterApi: function (gridApi) {
                vm.gridProductsApi = gridApi;
                vm.gridProductsApi.grid.registerDataChangeCallback(function () {
                    //vm.gridProductsApi.treeBase.expandAllRows();
                });
            },
            columnDefs: [
                {
                    name: 'Code', displayName: 'Ürün Kodu',
                    grouping: { groupPriority: 0 }, sort: {
                        priority: 0, direction: 'asc'
                    },
                    cellClass: function (grid, row, col, rowRenderIndex, colRenderIndex) {
                        if (row.entity.DeliveryQuantity && row.entity.Quantity === row.entity.DeliveryQuantity) {
                            return 'red-cell';
                        }
                    }
                },
                { name: 'Name', displayName: 'Ürün Adı' },
                { name: 'Category', displayName: 'Kategori' },
                { name: 'Variant', displayName: 'Variant', cellTemplate: '<div><a style="color:#333333!important" uib-tooltip="{{row.entity.Variant}}" tooltip-placement="right" >{{row.entity.Variant}}</a></div>' },
                { name: 'Quantity', displayName: 'Adet' },
                { name: 'DeliveriedQuantity', displayName: 'Teslim Edilen Adet' }

            ]
        }

        vm.groupedProducts = [];
        vm.urunGrupla = function () {
            var selected = vm.gridProductsApi.selection.getSelectedRows();
            for (var i in selected) {
                selected[i].GrupKod = "";
                selected[i].DeliveryQuantity = selected[i].Quantity;
            }
            vm.gridProductsApi.core.notifyDataChange(uiGridConstants.dataChange.EDIT);
            //vm.gridProductsApi.selection.on.rowSelectionChanged($scope, function (row) {
            //    if (row.isSelected) {
            //        var Quantity = row.entity.Quantity;
            //    }
            //});

            vm.groupedProducts = vm.groupedProducts.concat(selected);
            vm.gridDeliveryGroupsOptions.data = vm.groupedProducts;
            //vm.gridProductsOptions.data = vm.gridProductsOptions.data.filter(function (i) {
            //    var selectedd = vm.groupedProducts.filter(function (j) {
            //        return j.Id === i.Id
            //    })
            //    return selectedd.length <= 0
            //})
            vm.gridDeliveryGroupsApi.grid.refresh();
            vm.tabIndex = 2;

            vm.gridProductsApi.selection.clearSelectedRows();


        }


        vm.gridDeliveryGroupsOptions = {
            data: vm.groupedProducts,
            rowHeight: 39,
            enableVerticalScrollbar: 2,
            enableHorizontalScrollbar: 2,
            enableFullRowSelection: false,
            enableGrouping: true,
            onRegisterApi: function (gridApi) {
                vm.gridDeliveryGroupsApi = gridApi;
                vm.gridDeliveryGroupsApi.grid.registerDataChangeCallback(function () {
                    vm.gridDeliveryGroupsApi.treeBase.expandAllRows();
                });
            },
            columnDefs: [
                {
                    name: 'GrupKod', displayName: 'Grup Kodu',
                    grouping: {
                        groupPriority: 0,
                    },
                    sort: {
                        priority: 0, direction: 'asc'
                    },
                    cellTemplate: '<div ng-if="row.groupHeader && grid.getCellValue(row, col).split(\' \')[0]">{{COL_FIELD CUSTOM_FILTERS}}<button type="button" ng-click="grid.appScope.vm.GrupCoz(grid.getCellValue(row, col))">Grubu Çöz</button></div>'
                },
                {
                    name: 'Code', displayName: 'Ürün Kodu',
                },
                { name: 'Name', displayName: 'Ürün Adı' },
                { name: 'Category', displayName: 'Kategori' },
                { name: 'Variant', displayName: 'Variant' },
                { name: 'Quantity', displayName: 'Adet' },
                {
                    name: 'DeliveryQuantity', displayName: 'Teslimat Adet',
                    cellTemplate: '<input style="padding:10px!important;" type="number" value="1" min="1" max="{{row.entity.Quantity}}" name="input" ng-model="row.entity.DeliveryQuantity">'
                },
                { name: 'Location', displayName: 'Lokasyon', cellTemplate: '<select style="width:100%;height:100%;border:none;" ng-model="row.entity.Location"><option ng-repeat="item in grid.appScope.vm.savedLocationList">{{item.Name}}</option></select>' },

            ]
        }

        vm.grouped = [];
        vm.GroupCount = 0;
        vm.Grupla = function () {
            var selected = vm.gridDeliveryGroupsApi.selection.getSelectedRows();
            for (var i in selected) {
                selected[i].GrupKod = "Grup" + vm.GroupCount;
            }
            vm.gridDeliveryGroupsApi.grid.refresh();
            vm.GroupCount++;

            vm.gridDeliveryGroupsApi.selection.on.rowSelectionChanged($scope, function (row) {
                if (row.isSelected) {
                    var DeliveryQuantity = row.entity.DeliveryQuantity;
                }
            });

            vm.gridDeliveryGroupsApi.selection.clearSelectedRows();
        }

        vm.GrupCoz = function (groupName) {
            var groupNameSplit = groupName.split(' ')[0];
            for (var i = 0; i < vm.gridDeliveryGroupsOptions.data.length; i++) {
                var item = vm.gridDeliveryGroupsOptions.data[i];
                if (item.GrupKod === groupNameSplit) {
                    item.GrupKod = "";
                }
            }
            vm.gridDeliveryGroupsApi.grid.refresh();
        }

        vm.Location = function () {
            vm.loading = true;
            var locationList = vm.locationlist.map(function (item) {
                return {
                    Name: item.text
                }
            })
            CustomerAddressService.addLocation(vm.setDelivery.CustomerId, vm.setDelivery.AddressId, locationList).then(function (data) {
                vm.loading = false;
                vm.savedLocationList = data;
            });
        }

        $scope.$watch('vm.setDelivery.AddressId', function (newValue, oldValue) {
            if (newValue) {
                var address = vm.setDelivery.Addresses.find(function (item) {
                    return item.Id === vm.setDelivery.AddressId;
                })
                vm.savedLocationList = address.UserAddressLocation;
                vm.locationlist = address.UserAddressLocation.map(function (item) {
                    return { text: item.Name };
                })
                CustomersService.getCustomerByAddressId(newValue).then(function (data) {
                    vm.newDelivery.CustomerId = data.Id;
                });
            }
        });

        
        vm.editDelivery = function () {
            vm.loading = true;

            vm.setDelivery.Products = vm.groupedProducts.map(function (select) {
                if (select.Quantity >= select.DeliveryQuantity + select.DeliveriedQuantity) {
                    return {
                        ProductId: select.Id,
                        DeliveryQuantity: select.DeliveryQuantity,
                        DeliveriedQuantity: select.DeliveryQuantity + select.DeliveriedQuantity,
                        Code: select.GrupKod,
                        Name: select.Name,
                        Category: select.Category,
                        Quantity: select.Quantity,
                        Location: select.Location,
                        Variant: select.Variant,
                        Ticket: select.Ticket
                    }
                }
                else {
                    return alert('Teslim Edilen Tutar Adetten Fazla Olamaz!');
                }
            });
            DeliveriesService.editDelivery(vm.setDelivery).then(function (data) {

                vm.loading = false;
                //vm.groupDelivery.push(data);
                $location.path('/deliveries/detail/' + vm.setDelivery.Id + '');
                vm.gridProductsApi.core.notifyDataChange(uiGridConstants.dataChange.EDIT);
                vm.tabIndex = 3;
            });
        }

        vm.Cancel = function () {
            $location.path('/deliveries/list');
        };

        vm.printBarcode = function (deliveryProduct) {
            var popupWin = window.open('', '_blank', 'width=900,height=600');
            popupWin.document.open();
            for (var i = 0; i < deliveryProduct.Ticket ; i++) {
                popupWin.document.write('<html><head></head><body onload="window.print()"><table class="table"><thead class="table-header bg-gray"><tr><th>Ürün Kodu</th><th>Ürün Adı</th><th>Kategori</th><th>Variant</th><th>Teslimat Adet</th><th>Lokasyon</th><th>Ticket</th></tr></thead><tbody class="table-body"><tr><td>' + deliveryProduct.Code + '</td><td>' + deliveryProduct.Name + '</td><td>' + deliveryProduct.Category + '</td><td>' + deliveryProduct.Variant + '</td><td>' + deliveryProduct.DeliveryQuantity + '</td><td>' + deliveryProduct.Location + '</td></tr></tbody></table></body></html>');
            }
            popupWin.document.close();
        }


        vm.EditGroups = [];

        vm.GroupEdit = function () {
            var selectedrow = vm.gridDeliveryGroupsApi.selection.getSelectedRows();
            vm.EditGroups = selectedrow;
            vm.gridProductsOptions.data = vm.gridProductsOptions.data.concat(vm.EditGroups);
            var fark = vm.gridDeliveryGroupsOptions.data.filter(function (i) {
                var selected = vm.EditGroups.filter(function (j) {
                    return j.id === i.id
                })
                return selected.length <= 0
            })
            vm.gridDeliveryGroupsOptions.data = fark;
            vm.gridDeliveryGroupsApi.grid.refresh();
        }

        vm.getTableHeight = function () {
            var rowHeight = 65; // your row height
            var headerHeight = 39; // your header height
            return {
                height: (vm.gridProductsOptions.data.length * rowHeight + headerHeight) + "px"
            };
        };

        //vm.Tamamla = function () {
        //    $location.path('/deliveries/list');
        //}



        //var uploader = vm.uploader = new FileUploader({
        //    url: 'upload.php'
        //});

        //uploader.filters.push({
        //    name: 'imageFilter',
        //    fn: function (item /*{File|FileLikeObject}*/, options) {
        //        var type = '|' + item.type.slice(item.type.lastIndexOf('/') + 1) + '|';
        //        return '|jpg|png|jpeg|bmp|gif|'.indexOf(type) !== -1;
        //    }
        //});

        //uploader.onWhenAddingFileFailed = function (item /*{File|FileLikeObject}*/, filter, options) {
        //    console.info('onWhenAddingFileFailed', item, filter, options);
        //};
        //uploader.onAfterAddingFile = function (fileItem) {
        //    console.info('onAfterAddingFile', fileItem);
        //};
        //uploader.onAfterAddingAll = function (addedFileItems) {
        //    console.info('onAfterAddingAll', addedFileItems);
        //};
        //uploader.onBeforeUploadItem = function (item) {
        //    console.info('onBeforeUploadItem', item);
        //};
        //uploader.onProgressItem = function (fileItem, progress) {
        //    console.info('onProgressItem', fileItem, progress);
        //};
        //uploader.onProgressAll = function (progress) {
        //    console.info('onProgressAll', progress);
        //};
        //uploader.onSuccessItem = function (fileItem, response, status, headers) {
        //    console.info('onSuccessItem', fileItem, response, status, headers);
        //};
        //uploader.onErrorItem = function (fileItem, response, status, headers) {
        //    console.info('onErrorItem', fileItem, response, status, headers);
        //};
        //uploader.onCancelItem = function (fileItem, response, status, headers) {
        //    console.info('onCancelItem', fileItem, response, status, headers);
        //};
        //uploader.onCompleteItem = function (fileItem, response, status, headers) {
        //    console.info('onCompleteItem', fileItem, response, status, headers);
        //};
        //uploader.onCompleteAll = function () {
        //    console.info('onCompleteAll');
        //};
    }
})();