(function () {
    'use strict';

    angular.module('app.trendyolIntegration')
        .run(appRun);

    appRun.$inject = ['routerHelper'];

    function appRun(routerHelper) {
        routerHelper.configureStates(getStates());
    } 


    function getStates() {
        return [
            {
                state: 'trendyolIntegration',
                config: {
                    url: '/trendyolIntegration',
                    title: 'trendyol Entegrasyonu',
                    abstract: true,
                    templateUrl: 'app/trendyolIntegration/trendyolIntegration.html',
                    settings: {
                        name: 'integration',
                        nav: 200,
                        icon: 'fa fa-cogs',
                        resource: 'menu.integration',
                        content: 'Entegrasyonlar'
                    }
                }
            },
            {
                state: 'trendyolIntegration.trendyolmatchcategorylist',
                config: {
                    templateUrl: 'app/trendyolIntegration/trendyolIntegration.trendyolmatchcategorylist.html',
                    controller: 'TrendyolMatchCategoryListController',
                    controllerAs: 'vm',
                    url: '/trendyolmatchcategorylist',
                    title: 'Eşleşmiş Kategori Listesi',
                    settings: {
                        nav: 10,
                        name: 'trendyolIntegration.trendyolmatchcategorylist',
                        content: 'Eşleşmiş Kategori Listesi',
                        resource: 'menu.trendyolIntegration.trendyolmatchcategorylist'
                    }
                }
            },
            {
                state: 'trendyolIntegration.trendyoladdmatchcategory',
                config: {
                    templateUrl: 'app/trendyolIntegration/trendyolIntegration.trendyoladdmatchcategory.html',
                    controller: 'TrendyolAddMatchCategoryController',
                    controllerAs: 'vm',
                    url: '/trendyoladdmatchcategory',
                    title: 'Kategori Eşleştir',
                    settings: {
                        nav: 10,
                        name: 'trendyolIntegration.trendyoladdmatchcategory',
                        content: 'Kategori Eşleştir',
                        resource: 'menu.trendyolIntegration.trendyoladdmatchcategory'
                    }
                }
            },
            {
                state: 'trendyolIntegration.trendyoleditmatchcategory',
                config: {
                    templateUrl: 'app/trendyolIntegration/trendyolIntegration.trendyoleditmatchcategory.html',
                    controller: 'TrendyolEditMatchCategoryController',
                    controllerAs: 'vm',
                    url: '/trendyoleditmatchcategory/:Id',
                    title: 'Eşleşmiş Kategori Düzenle',
                    settings: {
                        nav: 10,
                        name: 'trendyolIntegration.trendyoleditmatchcategory',
                        content: 'Eşleşmiş Kategori Düzenle',
                        resource: 'menu.trendyolIntegration.trendyoleditmatchcategory'
                    }
                }
            },
            {
                state: 'trendyolIntegration.trendyoleditmatchcategoryfilter',
                config: {
                    templateUrl: 'app/trendyolIntegration/trendyolIntegration.trendyoleditmatchcategoryfilter.html',
                    controller: 'TrendyolEditMatchCategoryFilterController',
                    controllerAs: 'vm',
                    url: '/trendyoleditmatchcategoryfilter/:Id',
                    title: 'Eşleşmiş Kategori Filtre Düzenle',
                    settings: {
                        nav: 10,
                        name: 'trendyolIntegration.trendyoleditmatchcategoryfilter',
                        content: 'Eşleşmiş Kategori Filtre Düzenle',
                        resource: 'menu.trendyolIntegration.editmatchcategoryfilter'
                    }
                }
            },
            {
                state: 'trendyolIntegration.trendyolmatchcategoryfilterlist',
                config: {
                    templateUrl: 'app/trendyolIntegration/trendyolIntegration.trendyolmatchcategoryfilterlist.html',
                    controller: 'TrendyolMatchCategoryFilterListController',
                    controllerAs: 'vm',
                    url: '/trendyolmatchcategoryfilterlist/:Id',
                    title: 'Eşleşmiş Kategori Filtre Listesi',
                    settings: {
                        nav: 10,
                        name: 'trendyolIntegration.trendyolmatchcategoryfilterlist',
                        content: 'Eşleşmiş Kategori Filtre Listesi',
                        resource: 'menu.trendyolIntegration.matchcategoryfilterlist'
                    }
                }
            },
            {
                state: 'trendyolIntegration.trendyoleditmatchcategoryfiltervalue',
                config: {
                    templateUrl: 'app/trendyolIntegration/trendyolIntegration.trendyoleditmatchcategoryfiltervalue.html',
                    controller: 'TrendyolEditMatchCategoryFilterValueController',
                    controllerAs: 'vm',
                    url: '/trendyoleditmatchcategoryfiltervalue/:Id',
                    title: 'Eşleşmiş Kategori Filtre Değeri Düzenle',
                    settings: {
                        nav: 10,
                        name: 'trendyolIntegration.trendyoleditmatchcategoryfiltervalue',
                        content: 'Eşleşmiş Kategori Filtre Değeri Düzenle',
                        resource: 'menu.trendyolIntegration.editmatchcategoryfiltervalue'
                    }
                }
            },
            {
                state: 'trendyolIntegration.trendyolmatchcategoryfiltervaluelist',
                config: {
                    templateUrl: 'app/trendyolIntegration/trendyolIntegration.trendyolmatchcategoryfiltervaluelist.html',
                    controller: 'TrendyolMatchCategoryFilterValueListController',
                    controllerAs: 'vm',
                    url: '/matchcategoryfiltervaluelist/:Id',
                    title: 'Eşleşmiş Kategori Filtre Değeri Listesi',
                    settings: {
                        nav: 10,
                        name: 'trendyolIntegration.trendyolmatchcategoryfiltervaluelist',
                        content: 'Eşleşmiş Kategori Filtre Değeri Listesi',
                        resource: 'menu.ggIntegration.matchcategoryfiltervaluelist'
                    }
                }
            }
        ];
    }
})();