(function () {
    'use strict';

    angular.module('app.customers', [
        'smart-table',
        'treeControl',
        'ui.bootstrap',
        'ui.grid',
        'ui.grid.pagination',
        'ui.mask',
        'formly',
        'formlyBootstrap',
        'ngMap',
        'ui.select'
    ]);
})();