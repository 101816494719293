(function () {
    'use strict';

    angular
        .module('app.excel')
        .controller('ExcelFtpImageController', ExcelFtpImageController);

    ExcelFtpImageController.$inject = ['$scope', '$stateParams', '$filter', '$location', 'logger', 'ExcelService', '$uibModal', '$uibModalStack'];

    function ExcelFtpImageController($scope, $stateParams, $filter, $location, logger, ExcelService, $uibModal, $uibModalStack) {
        var vm = this;

        activate();

        function activate() {
        }

        vm.excelFtpFields = ExcelService.getExcelFtpFields();

        vm.ImageSaveByFtp = function () {
            vm.loading = true;
            console.log("vm.excelFtp", vm.excelFtp);
            ExcelService.addImageByFtp(vm.excelFtp)
                .then(function (data) {
                    vm.loading = false;
                    logger.success("Kayıt işlemi başarıyla tamamlanmıştır.");
                    $location.path('/filemanager');
                })
                .catch(function () {
                    vm.loading = false;
                });
        };

    }
})();