(function () {
    'use strict';

    angular
        .module('app.ggIntegration')
        .factory('GGIntegrationService', GGIntegrationService);

    GGIntegrationService.$inject = ['$http', '$q', 'ngAuthSettings', 'logger', 'config', 'ProductsService', 'ProductsGeneralService'];

    function GGIntegrationService($http, $q, ngAuthSettings, logger, config, ProductsService, ProductsGeneralService) {
        var service = {

            //Option

            getOption: getOption,
            getOptionValue: getOptionValue,

            //MatchCategory

            gggetMatchCategoryFields: gggetMatchCategoryFields,
            gggetAllMatchCategory: gggetAllMatchCategory,
            gggetMatchCategory: gggetMatchCategory,
            ggaddMatchCategory: ggaddMatchCategory,
            ggeditMatchCategory: ggeditMatchCategory,
            ggdelMatchCategory: ggdelMatchCategory,

            //MatchCategory Filter

            gggetMatchCategoryFilterFields: gggetMatchCategoryFilterFields,
            gggetMatchCategoryFilter: gggetMatchCategoryFilter,
            ggeditMatchCategoryFilter: ggeditMatchCategoryFilter,

            //MatchCategory Filter Value

            gggetMatchCategoryFilterValueFields: gggetMatchCategoryFilterValueFields,
            gggetMatchCategoryFilterValue: gggetMatchCategoryFilterValue,
            ggeditMatchCategoryFilterValue: ggeditMatchCategoryFilterValue,

            // TransferCategory
            gggetTopCategories: gggetTopCategories,
            gggetSubCategories: gggetSubCategories,
            getCategoryFilter: getCategoryFilter,
            getCategoryFilterValue: getCategoryFilterValue,
            gggetMatchCategoryFilterListByCategory: gggetMatchCategoryFilterListByCategory,
            gggetMatchCategoryFilterValueList: gggetMatchCategoryFilterValueList,
            ggtransferCategory: ggtransferCategory,
            ggtransferAllCategory: ggtransferAllCategory

        };

        var serviceBase = ngAuthSettings.apiServiceBaseUri + 'api/ggIntegration/';

        var templateFields = [
            {
                key: 'Name',
                type: 'floatInput',
                templateOptions: {
                    required: true,
                    type: 'text',
                    label: 'Adı',
                    resource: 'form.name',
                    placeholder: 'Adı'
                }
            },
            {
                key: 'SalesPrice',
                type: 'floatSelect',
                templateOptions: {
                    required: true,
                    type: 'text',
                    label: 'Satış Fiyatı',
                    resource: 'form.sales-price',
                    placeholder: 'Satış Fiyatı',
                    class: 'col-md-2',
                    options: [
                        {
                            Name: 'Ürün Fiyatı',
                            Id: 'Ürün Fiyatı'
                        },
                        {
                            Name: 'N11 Fiyatı',
                            Id: 'N11 Fiyatı'
                        }
                    ]
                }
            },
            {
                key: 'SalesPriceCalcType',
                type: 'floatSelect',
                templateOptions: {
                    required: true,
                    type: 'text',
                    label: 'Satış Fiyatı Hesap Biçimi',
                    class: 'col-md-2',
                    options: [
                        {
                            Name: 'X',
                            Id: 'X',
                        },
                        {
                            Name: '/',
                            Id: '/',
                        }
                    ]
                }
            },
            {
                key: 'SalesPriceQuantity',
                type: 'floatInput',
                templateOptions: {
                    required: true,
                    type: 'text',
                    label: 'Satış Fiyat Ayarlama Miktarı',
                    placeholder: 'Satış Fiyat Ayarlama Miktarı',
                    class: 'col-md-2',
                    numberMask: 2
                }
            },
            {
                key: 'DiscountRate',
                type: 'floatInput',
                templateOptions: {
                    type: 'text',
                    label: '% İndirim Oranı',
                    resource: 'form.discount-rate',
                    placeholder: '% İndirim Oranı'
                }
            },
            {
                key: 'SalesStartDate',
                type: 'floatDatepicker',
                templateOptions: {
                    type: 'text',
                    label: 'Satışa Başlama Tarihi',
                    resource: 'form.sales-start-date',
                    placeholder: 'Satışa Başlama Tarihi'
                }
            },
            {
                key: 'SalesEndDate',
                type: 'floatDatepicker',
                templateOptions: {
                    type: 'text',
                    label: 'Satışın Bitiş Tarihi',
                    resource: 'form.sales-end-date',
                    placeholder: 'Satışın Bitiş Tarihi'
                }
            },
            {
                key: 'SubTitle',
                type: 'floatInput',
                templateOptions: {
                    type: 'text',
                    label: 'Alt Başlık',
                    resource: 'form.subtitle',
                    placeholder: 'Alt Başlık'
                }
            },
            {
                key: 'CargoTime',
                type: 'floatInput',
                templateOptions: {
                    required: true,
                    type: 'number',
                    label: 'Kargo Süresi',
                    resource: 'form.cargo-time',
                    placeholder: 'Kargo Süresi'
                }
            },
            {
                key: 'IntegrationShipmentTemplateName',
                type: 'floatSelect',
                templateOptions: {
                    type: 'text',
                    label: 'Teslimat Şablonu',
                    resource: 'form.deliveries-template',
                    placeholder: 'Teslimat Şablonu'
                },
                controller: ['$scope', function ($scope) {
                    getShipmentTemplate()
                        .then(function (response) {
                            var templates = response.map(x => {
                                var obj = {};
                                obj["Id"] = x.Name;
                                obj["Name"] = x.Name;
                                return obj;
                            });
                            $scope.to.options = templates;
                        });
                }]
            },
        ];

        var matchcategoryFields = [
            {
                key: 'IntegrationTemplateId',
                type: 'floatSelect',
                templateOptions: {
                    label: 'Şablonlar',
                    resource: 'form.templates',
                    required: true,
                    placeholder: 'Şablon Seçiniz ',
                    options: [{ Name: 'Yükleniyor...' }]
                },
                controller: ['$scope', function ($scope) {
                    ProductsGeneralService.getIntegrationTemplates()
                        .then(function (response) {
                            console.log("response", response);
                            $scope.to.options = response;
                        });
                }]
            },
            {
                key: 'ShipmentIntegrationTemplateId',
                type: 'floatSelect',
                templateOptions: {
                    label: 'Teslimat Şablonu Seçiniz',
                    resource: 'form.shipment.templates',
                    required: true,
                    placeholder: 'Şablon Seçiniz ',
                    options: [{ Name: 'Yükleniyor...' }]
                },
                controller: ['$scope', function ($scope) {
                    ProductsGeneralService.getShipmentTemplates()
                        .then(function (response) {
                            console.log("response", response);
                            $scope.to.options = response;
                        });
                }]
            },
            {
                key: 'EComCategoryId',
                type: 'floatTreeSelect',
                templateOptions: {
                    label: 'E-Ticaret Kategorileri',
                    resource: 'form.ecommerce-categories',
                    required: true,
                    placeholder: 'Kategori Seçiniz ',
                    topProp:'TopCategoryId',
                    options: [{ Name: 'Yükleniyor...' }]
                },
                controller: ['$scope', function ($scope) {
                    ProductsGeneralService.getCategories()
                        .then(function (response) {
                            console.log(response);
                            $scope.to.options = response;
                        });
                }]
            },
            {
                key: 'GGCategories1',
                type: 'floatTreeSelect',
                templateOptions: {
                    label: 'GittiGidiyor Kategorileri',
                    resource: 'form.gg-topcategories',
                    required: true,
                    placeholder: 'Gittigidiyor Kategorisi Seçiniz ',
                    class: 'col-md-6',
                    valueProp: 'categoryCode',
                    labelProp: 'categoryName',
                    options: [{ categoryName: 'Yükleniyor...' }]
                },
                controller: ['$scope', function ($scope) {
                    gggetTopCategories()
                        .then(function (data) {
                           // console.log("cat", data.categories.category);


                            $scope.to.options = data.categories.category;
                        });
                }]
            }
        ];

        return service;

        /////////////////////////////////////////



        //Match Category

        function gggetMatchCategoryFields() {
            return matchcategoryFields;
        }

        function gggetAllMatchCategory(filterParams) {
            var deferred = $q.defer();

            $http.get(serviceBase + "matchcategory", {
                params: filterParams
            })
                .then(getComplete)
                .catch(getFailed);

            function getComplete(response) {
                deferred.resolve(response.data);
            }

            function getFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function gggetMatchCategory(id) {
            var deferred = $q.defer();

            $http.get(serviceBase + "ggmatchcategory/" + id)
                .then(getComplete)
                .catch(getFailed);

            function getComplete(response) {
                deferred.resolve(response.data);
            }

            function getFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function ggaddMatchCategory(matchcategory) {
            console.log("matchcategory", matchcategory);
            var deferred = $q.defer();

            $http.post(serviceBase + "matchcategory/add", matchcategory)
                .then(addComplete)
                .catch(addFailed);

            function addComplete(response) {
                deferred.resolve(response.data);
            }

            function addFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function ggeditMatchCategory(matchcategory) {
            var deferred = $q.defer();

            $http.put(serviceBase + "matchcategory/" + matchcategory.Id, matchcategory)
                .then(editComplete)
                .catch(editFailed);

            function editComplete(response) {
                deferred.resolve(response.data);
            }

            function editFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function ggdelMatchCategory(matchcategory) {
            var deferred = $q.defer();

            $http.delete(serviceBase + "matchcategory/" + matchcategory.Id)
                .then(deleteComplete)
                .catch(deleteFailed);

            function deleteComplete(response) {
                deferred.resolve(response.data);
            }

            function deleteFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        //

        //Match Category Filter

        function gggetMatchCategoryFilterFields() {
            return matchcategoryFilterFields;
        }

        function gggetMatchCategoryFilter(id) {
            var deferred = $q.defer();

            $http.get(serviceBase + "matchcategoryfilter/" + id)
                .then(getComplete)
                .catch(getFailed);

            function getComplete(response) {
                deferred.resolve(response.data);
            }

            function getFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function gggetMatchCategoryFilterListByCategory(id) {
            var deferred = $q.defer();

            $http.get(serviceBase + "matchcategoryfilterlistbycategory/" + id)
                .then(getComplete)
                .catch(getFailed);

            function getComplete(response) {
                deferred.resolve(response.data);
            }

            function getFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function ggeditMatchCategoryFilter(matchcategory) {
            var deferred = $q.defer();

            $http.put(serviceBase + "matchcategoryfilter/" + matchcategory.Id, matchcategory)
                .then(editComplete)
                .catch(editFailed);

            function editComplete(response) {
                deferred.resolve(response.data);
            }

            function editFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        //

        //Match Category Filter Value

        function gggetMatchCategoryFilterValueFields() {
            return matchcategoryFields;
        }

        function gggetMatchCategoryFilterValue(id) {
            var deferred = $q.defer();

            $http.get(serviceBase + "matchcategoryfiltervalue/" + id)
                .then(getComplete)
                .catch(getFailed);

            function getComplete(response) {
                deferred.resolve(response.data);
            }

            function getFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function gggetMatchCategoryFilterValueList(id) {
            var deferred = $q.defer();

            $http.get(serviceBase + "matchcategoryfiltervaluelist/" + id)
                .then(getComplete)
                .catch(getFailed);

            function getComplete(response) {
                deferred.resolve(response.data);
            }

            function getFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function ggeditMatchCategoryFilterValue(matchcategory) {
            var deferred = $q.defer();

            $http.put(serviceBase + "matchcategoryfiltervalue/" + matchcategory.Id, matchcategory)
                .then(editComplete)
                .catch(editFailed);

            function editComplete(response) {
                deferred.resolve(response.data);
            }

            function editFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        //

        //Transfer Category

        function gggetTopCategories() {
            var deferred = $q.defer();
            console.log("top list", serviceBase + "GGTopCategories");
            $http.get(serviceBase + "GGTopCategories")
                .then(postComplete)
                .catch(postFailed);

            function postComplete(response) {
                deferred.resolve(response.data);
            }

            function postFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function gggetSubCategories(CatId) {
            var deferred = $q.defer();

            $http.get(serviceBase + "GGSubCategories/" + CatId)
                .then(postComplete)
                .catch(postFailed);

            function postComplete(response) {
                deferred.resolve(response.data);
            }

            function postFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function getCategoryFilter(CatId) {
            var deferred = $q.defer();
            $http.get(serviceBase + "CategoryFilter/" + CatId)
                .then(postComplete)
                .catch(postFailed);

            function postComplete(response) {
                deferred.resolve(response.data);
            }

            function postFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function getCategoryFilterValue(CatCode,FilterName) {
            var deferred = $q.defer();
            $http.get(serviceBase + "CategoryFilterValue/" + CatCode + "/" + FilterName)
                .then(postComplete)
                .catch(postFailed);

            function postComplete(response) {
                deferred.resolve(response.data);
            }

            function postFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function ggtransferCategory(matchcategory) {
            var deferred = $q.defer();

            $http.post(serviceBase + "TransferCategory/", matchcategory)
                .then(postComplete)
                .catch(postFailed);

            function postComplete(response) {
                deferred.resolve(response.data);
            }

            function postFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function ggtransferAllCategory() {
            var deferred = $q.defer();

            $http.post(serviceBase + "TransferAllCategory/")
                .then(postComplete)
                .catch(postFailed);

            function postComplete(response) {
                deferred.resolve(response.data);
            }

            function postFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        //Option

        function getOption() {
            var deferred = $q.defer();

            $http.get(serviceBase + "option")
                .then(getComplete)
                .catch(getFailed);

            function getComplete(response) {
                deferred.resolve(response.data);
            }

            function getFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function getOptionValue(id) {
            var deferred = $q.defer();

            $http.get(serviceBase + "optionvalue/" + id)
                .then(getComplete)
                .catch(getFailed);

            function getComplete(response) {
                deferred.resolve(response.data);
            }

            function getFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        //

    }
})();