(function () {
    'use strict';

    angular
        .module('app.filter')
        .filter('arrayAdd', arrayAddFilter);

    function arrayAddFilter() {
        return function (data, key) {
            if (typeof (data) === 'undefined' || typeof (key) === 'undefined') {
                return 0;
            }

            var sum = 0;
            for (var i = data.length - 1; i >= 0; i--) {
                sum += parseInt(data[i][key]);
            }

            return sum;
        };
    }
})();