(function () {
    'use strict';

    angular
        .module('app.customers')
        .controller('CustomerAddressAddController', CustomerAddressAddController);

    CustomerAddressAddController.$inject = ['$stateParams', '$location', 'CustomerAddressService'];

    function CustomerAddressAddController($stateParams, $location, CustomerAddressService) {
        var vm = this;

        vm.title = '';
        vm.address = { UserId: $stateParams.UserId };
        vm.addressFields = CustomerAddressService.getCustomerAddressFields();

        vm.saveAddress = function () {
            if (vm.form.$valid) {
                CustomerAddressService.addCustomerAddress(vm.address).then(function (data) {
                    $location.path('/customers/' + vm.address.UserId + '/addresses/' + data.Id);
                });
            }
        };

        activate();

        function activate() { }
    }
})();