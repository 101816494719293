(function () {
    'use strict';

    angular
        .module('app.customers')
        .controller('CustomerAddressEditController', CustomerAddressEditController);

    CustomerAddressEditController.$inject = ['$stateParams', '$location', 'CustomerAddressService'];

    function CustomerAddressEditController($stateParams, $location, CustomerAddressService) {
        var vm = this;

        vm.title = '';
        vm.address = { UserId: $stateParams.UserId };
        vm.addressFields = CustomerAddressService.getCustomerAddressFields();

        vm.editAddress = function () {
            if (vm.form.$valid) {
                CustomerAddressService.editCustomerAddress(vm.address).then(function (data) {
                    $location.path('/customers/' + vm.address.UserId + '/addresses/' + data.Id);
                });
            }
        };

        activate();

        function activate() {
            CustomerAddressService.getCustomerAddress($stateParams.UserId, $stateParams.AddressId).then(function (data) {
                vm.address = data;
            });
        }
    }
})();