(function () {
    "use strict";

    angular
        .module("app.core")
        .run(formlyConfigureRun)
        .config(formlyConfigure);

    formlyConfigureRun.$inject = ["formlyConfig", "formlyValidationMessages"];

    function formlyConfigureRun(formlyConfig, formlyValidationMessages) {
        formlyConfig.extras.errorExistsAndShouldBeVisibleExpression = "fc.$touched || form.$submitted";

        formlyValidationMessages.addStringMessage("required", "Bu alan zorunludur.");
        formlyValidationMessages.addStringMessage("email", "Yanlış mail formatı");
        formlyValidationMessages.addStringMessage("date", "Yanlış tarih formatı");
        formlyValidationMessages.addStringMessage("maxlength", "Maximum karakter sınırı aşıldı");
    }

    formlyConfigure.$inject = ["formlyConfigProvider", "$windowProvider"];

    function formlyConfigure(formlyConfigProvider, $windowProvider) {
        formlyConfigProvider.setWrapper({
            name: "horizontalBootstrapLabel",
            template: [
                '<label for="{{::id}}" class="col-md-3 control-label">',
                '<span ng-if="!to.resource">{{to.label}}</span>',
                '<span ng-if="to.resource">{{to.resource | translate}}</span>',
                '<span style="color:red">{{to.required ? "*" : ""}}</span>',
                "</label>",
                '<div class="col-md-9 input-wrapper">',
                "<formly-transclude></formly-transclude>",
                '<span ng-if="to.helpText" class="help-block">{{to.helpText}}</span>',
                "</div>"
            ].join(" ")
        });

        formlyConfigProvider.setWrapper({
            name: "horizontalBootstrapHasError",
            template: `<div class="form-group" ng-class="{'has-error': showError}">
                          <formly-transclude></formly-transclude>
                       </div>`
        });

        formlyConfigProvider.setWrapper({
            name: "validation",
            types: ["horizontalInput", "horizontalEmail", "horizontalDatepicker", "horizontalTextarea"],
            template: [
                "<formly-transclude></formly-transclude>",
                '<div class="validation"',
                '  ng-if="showError"',
                '  ng-messages="fc.$error">',
                '  <div ng-message="{{::name}}" ng-repeat="(name, message) in ::options.validation.messages">',
                "    {{message(fc.$viewValue, fc.$modelValue, this)}}",
                "  </div>",
                "</div>"
            ].join(" ")
        });

        formlyConfigProvider.setWrapper({
            name: "panel",
            template: `<div class="panel panel-primary">
                          <div class="panel-heading px-nested-panel-heading clearfix">
                            <strong class="control-label" ng-if="options.templateOptions.label">
                              {{options.templateOptions.label}}
                            </strong>
                          </div>
                          <div class="panel-body px-nested-panel-body">
                            <formly-transclude></formly-transclude>
                          </div>
                       </div>`
        });

        formlyConfigProvider.setWrapper({
            name: "accordion",
            template: `<uib-accordion class="{{::options.templateOptions.classes}}" close-others="oneAtATime">
                           <div uib-accordion-group class="panel-default" heading="{{options.templateOptions.label}}" is-open="options.templateOptions.open" is-disabled="options.templateOptions.disabled">
                               <formly-transclude></formly-transclude>
                           </div>
                       </uib-accordion>`
        });

        formlyConfigProvider.setWrapper({
            name: "fieldset",
            template: `<fieldset class="{{::options.templateOptions.classes}}">
                          <legend ng-if="options.templateOptions.label">
                            <span ng-if="!options.templateOptions.resource">{{options.templateOptions.label}}</span>
                            <span ng-if="options.templateOptions.resource">{{options.templateOptions.resource | translate}}</span>
                          </legend>
                          <formly-transclude></formly-transclude>
                       </fieldset>`
        });

        formlyConfigProvider.setType({
            name: "horizontalCheckbox",
            template: `<div class="checkbox">
	                        <label>
		                        <input type="checkbox" class="formly-field-checkbox" ng-model="model[options.key]">
                                <span ng-if="!to.resource">{{to.label}}</span>
                                <span ng-if="to.resource">{{to.resource | translate}}</span>
		                        {{to.required ? '*' : ''}}
	                        </label>
                        </div>`,
            wrapper: ["horizontalBootstrapLabel", "horizontalBootstrapHasError"],
            apiCheck: check => ({
                templateOptions: {
                    label: check.string
                }
            })
        });

        formlyConfigProvider.setType({
            name: "horizontalInput",
            extends: "input",
            wrapper: ["horizontalBootstrapLabel", "horizontalBootstrapHasError"],
            template: '<input class="form-control" ng-model="model[options.key]" placeholder="{{to.resource | translate}}">',
            defaultOptions: {
                ngModelAttrs: {
                    mask: {
                        attribute: "ui-mask"
                    },
                    moneyMask: {
                        attribute: "ui-money-mask"
                    },
                    numberMask: {
                        attribute: "ui-number-mask"
                    },
                    uiNegativeNumber: {
                        attribute: "ui-negative-number"
                    },
                    maskPlaceholder: {
                        attribute: "ui-mask-placeholder"
                    },
                    percentageMask: {
                        attribute: "ui-percentage-mask"
                    },
                    readonly: {
                        bound: "ng-readonly",
                        attribute: "ng-readonly"
                    }
                },
                templateOptions: {
                    maskPlaceholder: ""
                }
            }
        });

        formlyConfigProvider.setType({
            name: "horizontalRangeInput",
            extends: "input",
            wrapper: ["horizontalBootstrapLabel", "horizontalBootstrapHasError"],
            template: '<div class="col-md-2">{{(model[options.key]*100/12).toFixed(2)}} / {{model[options.key]}}</div><div class="col-md-10"><input type="range" ng-model="model[options.key]" placeholder="{{to.resource | translate}}"></div>'
        });

        formlyConfigProvider.setType({
            name: "file-upload",
            extends: "input",
            wrapper: ["horizontalBootstrapLabel", "horizontalBootstrapHasError"],
            template: '<input type="file" ngf-select="formState.upload($files)" ng-model="model[options.key]" />'
        });

        formlyConfigProvider.setType({
            name: "horizontalSingleImage",
            wrapper: ["horizontalBootstrapLabel", "horizontalBootstrapHasError"],
            template: '<img ng-src="{{imgSrc}}" ng-if="imgSrc" style="max-height:200px;max-width:200px;" /><button ng-if="imgSrc" ng-click="cancelImage()" style="position:absolute;top:0;left:5px;background:white;border:1px solid black;"> X </button><button ng-if="!imgSrc" type="button" class="btn btn-default" ng-click="selectFile()" translate="form.select-image">Select Image</button>',
            controller: [
                "$scope",
                "$uibModal",
                "Settings",
                function ($scope, $uibModal, Settings) {
                    $scope.$watch("model", function (newValue, oldValue) {
                        if (newValue) {
                            var imgSrc = $scope.getProperty($scope.model, $scope.to.valueModel);
                            if (imgSrc) {
                                $scope.imgSrc = Settings.MediaServer + "/" + imgSrc;
                            }
                        }
                    });

                    var $uibModalInstance;
                    $scope.selectFile = function () {
                        $uibModalInstance = $uibModal.open({
                            windowClass: "filemanager-modal",
                            template: '<div class="modal-body" id="modal-body-{{name}}">' +
                                "<angular-filemanager></angular-filemanager>" +
                                "</div>"
                        });

                        function pickItem(evt) {
                            var item = evt.detail.item;
                            if (!Array.isArray(item)) {
                                var name = globalSettings.MediaServer + "/" + item.path.join("/") + "/" + item.name;
                                $scope.imgSrc = name;
                                $scope.model[$scope.options.key] =
                                    $scope.to.valueKey === "FileName" ? item.name :
                                    $scope.to.valueKey === "FullPath" ? "/" + item.path.join("/") + "/" + item.name :
                                    item.content;
                            }
                            $uibModalInstance.dismiss();
                            window.removeEventListener("onPickItem", pickItem);
                        }
                        window.addEventListener("onPickItem", pickItem, false);
                    };

                    $scope.cancelImage = function () {
                        $scope.imgSrc = null;
                        $scope.model[$scope.options.key] = null;
                    };

                    $scope.getProperty = function (object, propertyName) {
                        if (!object) {
                            return "";
                        }
                        if (!propertyName) {
                            return "";
                        }
                        var parts = propertyName.split("."),
                            length = parts.length,
                            i,
                            property = object || this;
                        for (i = 0; i < length; i++) {
                            if (property) {
                                property = property[parts[i]];
                            }
                        }
                        return property;
                    };
                }
            ]
        });

        formlyConfigProvider.setType({
            name: "horizontalMultipleImage",
            wrapper: ["horizontalBootstrapLabel", "horizontalBootstrapHasError"],
            template: '<img ng-src="{{imgSrc}}" ng-if="imgSrc" style="max-height:200px;max-width:200px;" /><button ng-if="imgSrc" ng-click="cancelImage()" style="position:absolute;top:0;left:5px;background:white;border:1px solid black;"> X </button><button type="button" ng-if="!(imgSrc || imgSrcList.length)" class="btn btn-default" ng-click="selectFile()" translate="form.select-image">Select Image</button>',
            controller: [
                "$scope",
                "$uibModal",
                "Settings",
                "fileManagerConfig",
                function ($scope, $uibModal, Settings, fileManagerConfig) {
                    $scope.$watch("model", function (newValue) {
                        if (newValue) {
                            var imgSrc = $scope.getProperty($scope.model, $scope.to.valueModel);
                            if (imgSrc) {
                                $scope.imgSrc = Settings.MediaServer + "/" + imgSrc;
                            }
                        }
                    });

                    var $uibModalInstance;
                    $scope.selectFile = function () {
                        globalSettings.currentFolder = $uibModalInstance = $uibModal.open({
                            windowClass: "filemanager-modal",
                            template: '<div class="modal-body" id="modal-body-{{name}}">' +
                                "<angular-filemanager></angular-filemanager>" +
                                "</div>"
                        });

                        function pickItem(evt) {
                            var item = evt.detail.item;
                            fileManagerConfig.currentPath = evt.detail.currentPath;
                            if (!Array.isArray(item)) {
                                var name = globalSettings.MediaServer + "/" + item.path.join("/") + "/" + item.name;
                                $scope.imgSrc = name;
                                $scope.model[$scope.options.key] =
                                    $scope.to.valueKey === "FileName" ? [item.name] : [{
                                        id: item.content
                                    }];
                            } else {
                                var names = item.map(function (item) {
                                    return (
                                        globalSettings.MediaServer + "/" + item.model.path.join("/") + "/" + item.model.name
                                    );
                                });
                                $scope.imgSrcList = names;
                                var ids = item.map(function (item) {
                                    return {
                                        id: item.model.content,
                                        src: globalSettings.MediaServer +
                                            "/" +
                                            item.model.path.join("/") +
                                            "/" +
                                            item.model.name
                                    };
                                });
                                $scope.model[$scope.options.key] = ids;
                            }
                            $uibModalInstance.dismiss();
                            window.removeEventListener("onPickItem", pickItem);
                        }
                        window.addEventListener("onPickItem", pickItem, false);
                    };

                    $scope.cancelImage = function () {
                        $scope.imgSrc = null;
                        $scope.model[$scope.options.key] = null;
                    };

                    $scope.getProperty = function (object, propertyName) {
                        var parts = propertyName.split("."),
                            length = parts.length,
                            i,
                            property = object || this;

                        for (i = 0; i < length; i++) {
                            property = property[parts[i]];
                        }

                        return property;
                    };
                }
            ]
        });

        formlyConfigProvider.setType({
            name: "horizontalSingleMedia",
            wrapper: ["horizontalBootstrapLabel", "horizontalBootstrapHasError"],
            template: '<button ng-if="mediaName" ng-show="!formState.readOnly" ng-click="cancelImage()" style="background:white;border:1px solid black;"> X </button> <span ng-if="mediaName" id="{{id}}">{{mediaName}}</span> <button ng-if="!imgSrc" type="button" class="btn btn-default" ng-click="selectFile()" translate="form.select-file">Select File</button>',
            controller: [
                "$scope",
                "$uibModal",
                "Settings",
                function ($scope, $uibModal, Settings) {
                    setTimeout(function () {
                        var mediaName = $scope.getProperty($scope.model, $scope.to.valueModel);
                        if (mediaName) {
                            $scope.mediaName = mediaName;
                        }
                        if (
                            $("#" + $scope.id)
                            .closest("fieldset")
                            .prop("disabled")
                        ) {
                            $scope.formState.readOnly = true;
                        }
                    }, 1000);

                    var $uibModalInstance;
                    $scope.selectFile = function () {
                        $uibModalInstance = $uibModal.open({
                            windowClass: "filemanager-modal",
                            template: '<div class="modal-body" id="modal-body-{{name}}">' +
                                "<angular-filemanager></angular-filemanager>" +
                                "</div>"
                        });

                        function pickItem(evt) {
                            var item = evt.detail.item;
                            $scope.mediaName = item.name;
                            $scope.model[$scope.options.key] = item.content;
                            $uibModalInstance.dismiss();
                            window.removeEventListener("onPickItem", pickItem);
                        }
                        window.addEventListener("onPickItem", pickItem, false);
                    };

                    $scope.cancelImage = function () {
                        $scope.mediaName = null;
                        $scope.model[$scope.options.key] = null;
                    };

                    $scope.getProperty = function (object, propertyName) {
                        var parts = propertyName.split("."),
                            length = parts.length,
                            i,
                            property = object || this;

                        for (i = 0; i < length; i++) {
                            property = property ? property[parts[i]] : "";
                        }

                        return property;
                    };
                }
            ]
        });

        formlyConfigProvider.setType({
            name: "typeahead",
            template: '<input type="text" ng-model="model[options.key]" uib-typeahead="item.Name for item in to.options | filter:$viewValue | limitTo:8" class="form-control">',
            wrapper: ["horizontalBootstrapLabel", "horizontalBootstrapHasError"]
        });

        formlyConfigProvider.setType({
            name: "async-ui-select",
            wrapper: ["horizontalBootstrapLabel", "horizontalBootstrapHasError"],
            template: `<ui-select ng-model="model[options.key]" required="{{to.required}}" disabled="{{to.disabled}}" theme="bootstrap">
                            <ui-select-match placeholder="{{to.placeholder}}" allow-clear="true">{{$select.selected[to.labelProp] }}</ui-select-match>
                            <ui-select-choices repeat="option[to.valueProp] as option in to.options | filter: $select.search"
                                refresh="to.refresh($select.search, options)">
                            <div ng-bind-html="option[to.labelProp] | highlight: $select.search"></div>
                            </ui-select-choices>
                        </ui-select>`
        });

        formlyConfigProvider.setType({
            name: "async-ui-select-multiple",
            wrapper: ["horizontalBootstrapLabel", "horizontalBootstrapHasError"],
            template: `<ui-select multiple ng-model="model[options.key]" required="{{to.required}}" disabled="{{to.disabled}}" theme="bootstrap">
                            <ui-select-match placeholder="{{to.placeholder}}">{{$item[to.labelProp] }}</ui-select-match>
                            <ui-select-choices repeat="option[to.valueProp] as option in to.options | filter: $select.search"
                                refresh="to.refresh($select.search, options, model)"
                                refresh-delay="{{to.refreshDelay}}">
                                <div ng-bind-html="option[to.labelProp] | highlight: $select.search"></div>
                            </ui-select-choices>
                        </ui-select>`
        });

        formlyConfigProvider.setType({
            name: "horizontalFileUpload",
            extends: "input",
            wrapper: ["horizontalBootstrapLabel", "horizontalBootstrapHasError"],
            defaultOptions: {
                ngModelAttrs: {
                    multiple: {
                        bound: "ng-multiple",
                        attribute: "multiple"
                    }
                }
            }
        });

        formlyConfigProvider.setType({
            name: "horizontalMapZoomSelect",
            extends: "select",
            wrapper: ["horizontalBootstrapLabel", "horizontalBootstrapHasError"],
            template: '<select multiple ng-model="vm.selectedValues" ng-change="vm.selectionsChanged()"><option ng-repeat="c in vm.cities" selected value="{{c.id}}">{{c.name}}</option></select>'
        });

        formlyConfigProvider.setType({
            name: "horizontalTag",
            wrapper: ["horizontalBootstrapLabel", "horizontalBootstrapHasError"],
            template: '<tags-input placeholder="{{to.placeholder}}" ng-model="model[options.key]"></tags-input>'
        });

        formlyConfigProvider.setType({
            name: "horizontalTextarea",
            extends: "textarea",
            wrapper: ["horizontalBootstrapLabel", "horizontalBootstrapHasError"],
            defaultOptions: {
                ngModelAttrs: {
                    readonly: {
                        bound: "ng-readonly",
                        attribute: "ng-readonly"
                    }
                }
            }
        });

        formlyConfigProvider.setType({
            name: "horizontalTinyMCE",
            wrapper: ["horizontalBootstrapLabel", "horizontalBootstrapHasError"],
            template: '<textarea ui-tinymce="to.tinymceOptions" ng-model="model[options.key]">',
            defaultOptions: {
                templateOptions: {
                    tinymceOptions: {
                        image_advtab: true,
                        image_dimensions: false,
                        image_title: true,
                        relative_urls: false,
                        forced_root_block: "",
                        height: "400",
                        fontsize_formats: "2px 4px 6px 8px 10px 12px 14px 18px 24px 36px",
                        lineheight_formats: "2px 4px 6px 8px 10px 12px 14px 18px 24px 36px",
                        images_upload_credentials: true,
                        plugins: "image code imagetools advlist autolink lists link image charmap print preview hr anchor pagebreak searchreplace wordcount visualblocks visualchars code fullscreen insertdatetime media nonbreaking save table contextmenu directionality emoticons template paste textcolor colorpicker textpattern imagetools codesample toc lineheight",
                        toolbar1: " styleselect sizeselect fontselect fontsizeselect lineheightselect  | bold italic strikethrough forecolor backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image media codesample",
                        content_css: [
                            globalSettings.WebServer + "/themes/" + globalSettings.ThemeName + "/dist/css/site.css"
                        ],
                        entity_encoding: "raw",
                        file_picker_callback: function (cb, value, meta) {
                            this.settings.activateFileManager(cb, value, meta);
                        },
                        setup: function (ed) {
                            if (
                                $("#" + ed.id)
                                .closest("fieldset")
                                .prop("disabled")
                            ) {
                                ed.settings.readonly = true;
                            }
                        }
                    }
                }
            },
            controller: [
                "$scope",
                "$uibModal",
                "Settings",
                function ($scope, $uibModal, Settings) {
                    var $uibModalInstance;
                    $scope.to.tinymceOptions.activateFileManager = function (cb) {
                        $scope.tinycb = cb;

                        $uibModalInstance = $uibModal.open({
                            windowClass: "filemanager-modal",
                            template: '<div class="modal-body" id="modal-body-{{name}}">' +
                                "<angular-filemanager></angular-filemanager>" +
                                "</div>"
                        });

                        function pickItem(evt) {
                            var item = evt.detail.item;
                            $scope.tinycb(Settings.MediaServer + "/" + item.path.join("/") + "/" + item.name, {
                                title: item.name
                            });
                            $uibModalInstance.dismiss();
                            window.removeEventListener("onPickItem", pickItem);
                        }
                        window.addEventListener("onPickItem", pickItem, false);
                    };
                }
            ]
        });

        formlyConfigProvider.setType({
            name: "horizontalRadio",
            extends: "radio",
            wrapper: ["horizontalBootstrapLabel", "horizontalBootstrapHasError"]
        });

        formlyConfigProvider.setType({
            name: "horizontalOptionSelect",
            extends: "select",
            defaultOptions: {
                templateOptions: {
                    valueProp: "Id",
                    labelProp: "Name"
                }
            },
            wrapper: ["horizontalBootstrapLabel", "horizontalBootstrapHasError"],
            controller: [
                "$scope",
                "OptionService",
                function ($scope, OptionService) {
                    OptionService.getAll({
                        PageSize: -1
                    }).then(function (response) {
                        var data = response.Data;
                        data = [{
                            Name: ""
                        }].concat(data);
                        $scope.to.options = data;
                    });
                }
            ]
        });

        formlyConfigProvider.setType({
            name: "horizontalCustomServiceSelect",
            extends: "select",
            defaultOptions: {
                templateOptions: {
                    valueProp: "Id",
                    labelProp: "Name"
                }
            },
            wrapper: ["horizontalBootstrapLabel", "horizontalBootstrapHasError"],
            controller: [
                "$scope",
                "$injector",
                function ($scope, $injector) {
                    var service = $injector.get($scope.to.serviceName);
                    service.getAll({
                        PageSize: -1
                    }).then(function (response) {
                        var data = response.Data;
                        data = [{
                            Name: ""
                        }].concat(data);
                        $scope.to.options = data;
                    });
                }
            ]
        });

        formlyConfigProvider.setType({
            name: "horizontalCustomServiceSelectMultiple",
            extends: "select",
            defaultOptions: {
                templateOptions: {
                    valueProp: "Id",
                    labelProp: "Name"
                },
                ngModelAttrs: {
                    true: {
                        value: "multiple"
                    }
                }
            },
            wrapper: ["horizontalBootstrapLabel", "horizontalBootstrapHasError"],
            controller: [
                "$scope",
                "$injector",
                function ($scope, $injector) {
                    var service = $injector.get($scope.to.serviceName);
                    service.getAll({
                        PageSize: -1
                    }).then(function (response) {
                        $scope.to.options = response.Data;
                    });
                }
            ]
        });

        formlyConfigProvider.setType({
            name: "horizontalRelatedProductTypeSelect",
            extends: "select",
            defaultOptions: {
                templateOptions: {
                    valueProp: "Id",
                    labelProp: "Name"
                }
            },
            wrapper: ["horizontalBootstrapLabel", "horizontalBootstrapHasError"],
            controller: [
                "$scope",
                "RelatedProductTypeService",
                function ($scope, OptionService) {
                    OptionService.getAll({
                        PageSize: -1
                    }).then(function (response) {
                        var data = response.Data;
                        data = [{
                            Name: ""
                        }].concat(data);
                        $scope.to.options = data;
                    });
                }
            ]
        });

        formlyConfigProvider.setType({
            name: "horizontalMultipleOptionSelect",
            extends: "select",
            defaultOptions: {
                templateOptions: {
                    valueProp: "Id",
                    labelProp: "Name"
                },
                ngModelAttrs: {
                    true: {
                        value: "multiple"
                    }
                }
            },
            wrapper: ["horizontalBootstrapLabel", "horizontalBootstrapHasError"],
            controller: [
                "$scope",
                "OptionService",
                function ($scope, OptionService) {
                    OptionService.getAll({
                        PageSize: -1
                    }).then(function (response) {
                        var data = response.Data;
                        data = [{
                            Name: ""
                        }].concat(data);
                        $scope.to.options = data;
                    });
                }
            ]
        });

        formlyConfigProvider.setType({
            name: "horizontalSelect",
            extends: "select",
            template: ` <select class="form-control" ng-model="model[options.key]">
                            <option ng-if="!to.required" value="">{{to.emptyText}}</option>
                        </select>`,
            defaultOptions: {
                templateOptions: {
                    valueProp: "Id",
                    labelProp: "Name"
                }
            },
            wrapper: ["horizontalBootstrapLabel", "horizontalBootstrapHasError"]
        });

        formlyConfigProvider.setType({
            name: "horizontalMultiSelect",
            extends: "select",
            defaultOptions: {
                templateOptions: {
                    valueProp: "Id",
                    labelProp: "Name"
                },
                ngModelAttrs: {
                    true: {
                        value: "multiple"
                    }
                }
            },
            wrapper: ["horizontalBootstrapLabel", "horizontalBootstrapHasError"]
        });

        formlyConfigProvider.setType({
            name: "horizontalTreeMultiSelect",
            defaultOptions: {
                templateOptions: {
                    valueProp: "Id",
                    labelProp: "Name"
                },
                ngModelAttrs: {
                    true: {
                        value: "multiple"
                    }
                }
            },
            wrapper: ["horizontalBootstrapLabel", "horizontalBootstrapHasError"],
            template: `<multi-select-tree data-input-model="data" multi-select="true"
                        data-output-model="model[options.key]" data-default-label="{{to.placeholder}}"
                        data-select-only-leafs="true"></multi-select-tree>`,

            controller: [
                "$scope",
                "$timeout",
                function ($scope, $timeout) {
                    $scope.$watch("to.options", function (newValue, oldValue) {
                        if ($scope.to.options && $scope.to.options.length > 0) {
                            $scope.data = getTreeData($scope.to.options, null);
                        }
                    });

                    function getTreeData(options, TopCategoryId) {
                        var data = options.filter(obj => obj.TopCategoryId === TopCategoryId);
                        if (data.length <= 0) {
                            return [];
                        }
                        return data.map(obj => {
                            var rObj = {};
                            rObj.id = obj.Id;
                            rObj.name = obj.Name;
                            if ($scope.model[$scope.options.key] && $scope.model[$scope.options.key].indexOf(obj.Id) > -1) {
                                rObj.selected = true;
                            }
                            rObj.children = getTreeData(options, obj.Id);
                            return rObj;
                        });
                    }
                }
            ]
        });

        formlyConfigProvider.setType({
            name: "horizontalEmail",
            extends: "horizontalInput",
            defaultOptions: {
                templateOptions: {
                    type: "email",
                    addonLeft: {
                        class: "fa fa-envelope-o"
                    }
                }
            }
        });

        formlyConfigProvider.setType({
            name: "horizontalPhone",
            extends: "horizontalInput",
            defaultOptions: {
                templateOptions: {
                    type: "text",
                    addonLeft: {
                        class: "fa fa-phone"
                    },
                    mask: "9(999) 999 9999"
                }
            }
        });

        formlyConfigProvider.setType({
            name: "horizontalSelectMap",
            wrapper: ["horizontalBootstrapLabel", "horizontalBootstrapHasError"],
            defaultOptions: {
                ngModelAttrs: {
                    gmOptions: {
                        bound: "gm-map-options"
                    }
                }
            },
            controller: function ($scope) {
                $scope.houses = [{
                    id: 0,
                    name: "House A",
                    lat: 38,
                    lng: 35
                }];

                $scope.$watch("center", function (newValue, oldValue) {
                    if (newValue) {
                        $scope.model[$scope.options.key] = newValue.lat() + "," + newValue.lng();
                        $scope.houses = [{
                            id: 0,
                            name: "House A",
                            lat: newValue.lat(),
                            lng: newValue.lng()
                        }];
                    }
                });
            },
            template: '<gm-map gm-map-id="\'simpleMap\'" gm-center="center" gm-zoom="zoom" ng-model="model[options.key]" gm-bounds="bounds" gm-map-type-id="mapTypeId" style="width:100%;height:300px;" class="map" >' +
                '<gm-markers gm-position="{lat: object.lat, lng: object.lng}" gm-objects="houses" gm-id="object.id"></gm-markers>' +
                "</gm-map>"
        });

        var attributes = [
            "date-disabled",
            "custom-class",
            "show-weeks",
            "starting-day",
            "init-date",
            "min-mode",
            "max-mode",
            "format-day",
            "format-month",
            "format-year",
            "format-day-header",
            "format-day-title",
            "format-month-title",
            "year-range",
            "shortcut-propagation",
            "datepicker-popup",
            "show-button-bar",
            "current-text",
            "clear-text",
            "close-text",
            "close-on-date-selection",
            "datepicker-append-to-body"
        ];

        var bindings = ["datepicker-mode", "min-date", "max-date"];

        var ngModelAttrs = {};

        angular.forEach(attributes, function (attr) {
            ngModelAttrs[camelize(attr)] = {
                attribute: attr
            };
        });

        angular.forEach(bindings, function (binding) {
            ngModelAttrs[camelize(binding)] = {
                bound: binding
            };
        });

        function camelize(string) {
            string = string.replace(/[\-_\s]+(.)?/g, function (match, chr) {
                return chr ? chr.toUpperCase() : "";
            });

            // Ensure 1st char is always lowercase
            return string.replace(/^([A-Z])/, function (match, chr) {
                return chr ? chr.toLowerCase() : "";
            });
        }

        formlyConfigProvider.setType({
            name: "horizontalDatepicker",
            template: '<div class="input-group">' +
                '<div class="input-group-addon" ng-disabled="to.disabled"><i class="fa fa-calendar"></i></div>' +
                '<input  type="text" id="{{::id}}" name="{{::id}}" ng-model="model[options.key]" class="form-control" ng-click="datepicker.open($event)" uib-datepicker-popup="{{to.datepickerOptions.format}}" is-open="datepicker.opened" datepicker-options="to.datepickerOptions" />' +
                "</div>",
            wrapper: ["horizontalBootstrapLabel", "horizontalBootstrapHasError"],
            defaultOptions: {
                ngModelAttrs: ngModelAttrs,
                templateOptions: {
                    datepickerOptions: {
                        locale: "tr",
                        format: "dd.MM.yyyy"
                    }
                }
            },
            controller: [
                "$scope",
                function ($scope) {
                    $scope.datepicker = {};

                    $scope.datepicker.opened = false;

                    $scope.datepicker.open = function ($event) {
                        $scope.datepicker.opened = !$scope.datepicker.opened;
                    };
                }
            ]
        });

        formlyConfigProvider.setType({
            name: "horizontalMenuBuilder",
            wrapper: ["horizontalBootstrapLabel", "horizontalBootstrapHasError"],
            template: `<div class="row">
                            <script type="text/ng-template" id="submenu_renderer.html">
                                <div class="tree-node">
                                    <div class="tree-node-content">
                                        <a data-nodrag ng-click="toggle(this)"><span class="fa" ng-class="{'fa-chevron-right': collapsed,'fa-chevron-down': !collapsed}"></span></a>
                                        <input type="text" ng-model="node.title" placeholder="Başlık" />
                                        <input type="text" ng-model="node.url" placeholder="Link" />
                                        <a data-nodrag ng-click="remove(this)"><span class="glyphicon glyphicon-remove"></span></a>
                                        <span ui-tree-handle><span class="fa fa-list"></span></span>
                                    </div>
                                </div>
                                <ol ui-tree-nodes="" ng-model="node.nodes" ng-class="{hidden: collapsed}">
                                    <li ng-repeat="node in node.nodes" ui-tree-node ng-include="'submenu_renderer.html'"></li>
                                </ol>
                            </script>
                            <div class="col-sm-12">
                                <form>
                                    <input type="text" ng-model="newnode.title" placeholder="Başlık" />
                                    <input type="text" ng-model="newnode.url" placeholder="Link" />
                                    <select ng-model="newnode.type">
                                        <option selected value="link">Link</option>
                                        <option value="categories">Kategories</option>
                                    </select>
                                    <button type="button" ng-click="newItem()">Ekle</button>
                                </form>
                            </div>
                            <hr />
                            <div class="col-sm-12">
                                <div ui-tree id="menu-tree" data-clone-enabled="true">
                                    <ol ui-tree-nodes="" ng-model="model[options.key]">
                                        <li ng-repeat="node in model[options.key]" ui-tree-node ng-include="'submenu_renderer.html'"></li>
                                    </ol>
                                </div>
                            </div>
                        </div>`,
            controller: [
                "$scope",
                function ($scope) {
                    $scope.remove = function (scope) {
                        scope.remove();
                    };

                    $scope.toggle = function (scope) {
                        scope.toggle();
                    };

                    $scope.newnode = {
                        type: "link",
                        nodes: []
                    };
                    $scope.newItem = function () {
                        $scope.newnode.id = new Date().getTime();
                        $scope.model[$scope.options.key].push($scope.newnode);
                        $scope.newnode = {
                            type: "link",
                            nodes: []
                        };
                    };
                    if (!$scope.model[$scope.options.key]) {
                        $scope.model[$scope.options.key] = [];
                    }
                }
            ]
        });
    }
})();