(function () {
    'use strict';

    angular
        .module('app.account')
        .controller('AccountEditController', AccountEditController);

    AccountEditController.$inject = ['$scope', '$stateParams', '$location', '$timeout', 'AccountService'];

    function AccountEditController($scope, $stateParams, $location, $timeout, AccountService) {
        var vm = this;

        vm.loading = true;
        vm.title = '';

        AccountService.getUser($stateParams.id).then(function (data) {
            vm.edituser = data;
            vm.edituser.mode = "edit";
            vm.loading = false;
        });

        vm.userFields = AccountService.getUserFields();

        vm.userEdit = function () {
            if (vm.form.$valid) {
                vm.loading = true;
                AccountService.editUser(vm.edituser).then(function () {
                    vm.loading = false;
                    $location.path('/account/detail/' + vm.edituser.Id);
                });
            }
        };

        vm.Cancel = function () {
            $location.path('/account/detail/' + vm.edituser.Id);
        };
    }
})();