(function () {
    'use strict';

    angular.module('app.markettemplates')
        .run(appRun);

    appRun.$inject = ['routerHelper'];

    function appRun(routerHelper) {
        routerHelper.configureStates(getStates());
    }

    function getStates() {
        return [
            {
                state: 'markettemplates',
                config: {
                    url: '/markettemplates',
                    title: 'Şablonlar',
                    abstract: true,
                    templateUrl: 'app/markettemplates/markettemplates.html'
                }
            },
            {
                state: 'markettemplates.shipmenttemplates',
                config: {
                    templateUrl: 'app/markettemplates/markettemplates.shipmenttemplates.html',
                    controller: 'ShipmentTemplateListController',
                    controllerAs: 'vm',
                    url: '/shipmenttemplates',
                    title: 'Şablon Listesi',
                    settings: {
                        nav: 10,
                        name: 'markettemplates.shipmenttemplates',
                        content: 'Şablon Listesi',
                        resource: 'menu.templates.shipmenttemplates'
                    }
                }
            },
            {
                state: 'markettemplates.addshipmenttemplates',
                config: {
                    templateUrl: 'app/markettemplates/markettemplates.addshipmenttemplates.html',
                    controller: 'AddShipmentTemplateController',
                    controllerAs: 'vm',
                    url: '/addshipmenttemplates',
                    title: 'Teslimat Şablonu Oluştur',
                    settings: {
                        nav: 10,
                        name: 'markettemplates.addshipmenttemplates',
                        content: 'Teslimat Şablonu Oluştur',
                        resource: 'menu.templates.addshipmenttemplates'
                    }
                }
            },
            {
                state: 'markettemplates.editshipmenttemplates',
                config: {
                    templateUrl: 'app/markettemplates/markettemplates.editshipmenttemplates.html',
                    controller: 'EditShipmentTemplateController',
                    controllerAs: 'vm',
                    url: '/editshipmenttemplates/:Id',
                    title: 'Şablon Düzenle',
                    settings: {
                        nav: 10,
                        name: 'markettemplates.editshipmenttemplates',
                        content: 'Şablon Düzenle',
                        resource: 'menu.templates.editshipmenttemplates'
                    }
                }
            }
            
        ];
    }
})();