(function () {
    'use strict';

    angular
        .module('app.shipmentPrice')
        .factory('ShipmentPriceService', Service);

    Service.$inject = ['$http', '$q', 'ngAuthSettings', 'ShipmentProviderService', 'config'];

    function Service($http, $q, ngAuthSettings, ShipmentProviderService, config) {
        var service = {
            getAll: getAll,
            get: get,
            add: add,
            edit: edit,
            del: del,
            getFields: getFields,
            getShipmentProviders: getShipmentProviders
        };

        var baseUrl = ngAuthSettings.apiServiceBaseUri;
        var serviceBase = baseUrl + 'api/ShipmentPrice/';

        var fields = [

            {
                key: 'ShipmentProviderId',
                type: 'horizontalSelect',
                templateOptions: {
                    required: true,
                    label: 'Kargo Firması',
                    resource: 'form.shipment-provider',
                    options: [{ Name: 'Yükleniyor...' }]
                },
                controller: ['$scope', function ($scope) {
                    ShipmentProviderService.getAll({ PageSize: -1 })
                        .then(function (response) {
                            $scope.to.options = response.Data
                        });
                }]
            },
            {
                key: 'MinimumDS',
                type: 'horizontalInput',
                templateOptions: {
                    required: true,
                    type: 'text',
                    label: 'Minimum Desi',
                    resource: 'form.minimum-ds',
                    placeholder: 'Minimum Desi',
                    numberMask: 2
                }
            },
            {
                key: 'MaximumDS',
                type: 'horizontalInput',
                templateOptions: {
                    required: true,
                    type: 'text',
                    label: 'Maximum Desi',
                    resource: 'form.maximum-ds',
                    placeholder: 'Maximum Desi',
                    numberMask: 2
                }
            },
            {
                key: 'Price',
                type: 'horizontalInput',
                templateOptions: {
                    required: true,
                    type: 'text',
                    label: 'Ücret',
                    resource: 'form.price',
                    placeholder: 'Ücret',
                    moneyMask: 2
                }
            }
        ];

        return service;

        /////////////////////////////////////////

        function getShipmentProviders() {
            var deferred = $q.defer();

            $http.get(baseUrl + "/ShipmentProviders")
                .then(getComplete)
                .catch(getFailed);

            function getComplete(response) {
                deferred.resolve(response.data);
            }

            function getFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function getFields() {
            return fields;
        }

        function getAll(filterParams) {
            var deferred = $q.defer();

            $http.get(serviceBase, {
                params: filterParams
            })
                .then(getComplete)
                .catch(getFailed);

            function getComplete(response) {
                deferred.resolve(response.data);
            }

            function getFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function get(Id) {
            var deferred = $q.defer();

            $http.get(serviceBase + Id)
                .then(getComplete)
                .catch(getFailed);

            function getComplete(response) {
                deferred.resolve(response.data);
            }

            function getFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function add(entity) {
            var deferred = $q.defer();

            $http.post(serviceBase, entity)
                .then(addComplete)
                .catch(addFailed);

            function addComplete(response) {
                deferred.resolve(response.data);
            }

            function addFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function edit(entity) {
            var deferred = $q.defer();

            $http.put(serviceBase + entity.Id, entity)
                .then(editComplete)
                .catch(editFailed);

            function editComplete(response) {
                deferred.resolve(response.data);
            }

            function editFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function del(entity) {
            var deferred = $q.defer();

            $http.delete(serviceBase + entity.Id, entity)
                .then(deleteComplete)
                .catch(deleteFailed);

            function deleteComplete(response) {
                deferred.resolve(response.data);
            }

            function deleteFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }
    }
})();