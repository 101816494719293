(function () {
    'use strict';

    angular
        .module('app.excel')
        .controller('ExcelStockImportController', ExcelStockImportController);

    ExcelStockImportController.$inject = ['$scope', '$stateParams', '$filter', '$location', 'logger', 'ExcelService', '$uibModal', '$uibModalStack'];

    function ExcelStockImportController($scope, $stateParams, $filter, $location, logger, ExcelService, $uibModal, $uibModalStack) {
        var vm = this;

        activate();

        function activate() {
        }

        vm.excelStockImportFields = ExcelService.getExcelStockImportFields();

        vm.saveStockImport = function () {
            console.log("log", vm.excelStockImport);
            vm.openModal(vm.excelStockImport.File);
        }

        // Modal

        vm.modalTitle = "Excel Stok Import";
        vm.modalInstance = null;
        vm.openModal = function (File) {
            console.log("File", File);
            vm.modalInstance = $uibModal.open({
                templateUrl: 'ModalContent.html',
                controller: 'ModalStockController',
                controllerAs: 'vm',
                resolve: {
                    items: function () {
                        return {
                            File: File
                        };
                    }
                }
            });
        }
    }

    angular
        .module('app.excel')
        .controller('ModalStockController', ModalStockController);

    ModalStockController.$inject = ['$scope', '$stateParams', '$filter', '$location', 'logger', 'ExcelService', '$uibModal', '$uibModalStack', '$uibModalInstance', 'items'];

    function ModalStockController($scope, $stateParams, $filter, $location, logger, ExcelService, $uibModal, $uibModalStack, $uibModalInstance, items) {

        var vm = this;
        vm.content = items;
        activate();

        function activate() {
            ExcelService.analysisStockFile(vm.content.File).then(function (data) {
                vm.file = vm.content.File;
                vm.StockCount = data.data.StockCount;
                vm.NotMatchField = data.data.NotMatchField;

                vm.loading = false;
            });
        }


        vm.modalCancel = function () {
            $uibModalStack.dismissAll();
        }

        vm.approveStockImport = function () {
            vm.loading = true;
            ExcelService.importStockFile(vm.file, vm.filedelete).then(function () {
                vm.loading = false;
                vm.modalCancel();
                $location.path('/stockHistories/list');
            });
        }
    }
})();