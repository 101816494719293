(function () {
    'use strict';

    angular
        .module('app.blogPostCategory')
        .controller('BlogPostCategoryDetailController', BlogPostCategoryDetailController);

    BlogPostCategoryDetailController.$inject = ['$location', '$stateParams', '$http', 'BlogPostCategoryService', '$uibModal', 'localStorageService', 'Settings'];

    function BlogPostCategoryDetailController($location, $stateParams, $http, BlogPostCategoryService, $uibModal, localStorageService, Settings) {
        var vm = this;
        vm.Settings = Settings;

        BlogPostCategoryService.getCategory($stateParams.CategoryId).then(function (data) {
            vm.entity = data;
            vm.getMedia();
        });

        vm.fields = BlogPostCategoryService.getCategoryFields();

        vm.delete = function () {
            if (confirm("Silmek istediğinize emin misiniz?")) {
                BlogPostCategoryService.deleteCategory(vm.entity).then(function (data) {
                    $location.path('/blogpostcategory/list');
                });
            }
        };
    }
})();