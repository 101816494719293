(function () {
    'use strict';

    angular
        .module('app.blogPostTag')
        .controller('PostTagsDetailController', PostTagsDetailController);

    PostTagsDetailController.$inject = ['$location', '$stateParams', 'BlogPostTagService'];

    function PostTagsDetailController($location, $stateParams, BlogPostTagService) {

        var vm = this;

        BlogPostTagService.get($stateParams.Id)
            .then(function (data) {
                vm.entity = data;
            });

        vm.fields = BlogPostTagService.getFields();

        vm.delete = function () {
            if (confirm("Silmek istediğinize emin misiniz?")) {
                BlogPostTagService.del(vm.entity).then(function (data) {
                    $location.path('/blogposttag/list');
                });
            }
        };
    }
})();