(function () {
    'use strict';

    angular
        .module('app.sellers')
        .controller('SellersAddController', SellersAddController);

    SellersAddController.$inject = ['$scope', '$location', 'logger', 'SellersService'];

    function SellersAddController($scope, $location, logger, SellersService) {
        var vm = this;

        activate();
        function activate() {
            var h = angular.element(window).height() - 40 - 40 - 30 - 20;
            angular.element('#customer-info').css("height", h);
            angular.element('#customer-detail').css("height", h);
        }

        vm.entity = {};

        vm.fields = SellersService.getFields();

        vm.save = function () {
            if (vm.form.$valid) {
                vm.loading = true;
                SellersService.add(vm.entity).then(function (data) {
                    vm.loading = false;
                    $location.path('/sellers/detail/' + data.Id);
                });
            }
        };
    }
})();