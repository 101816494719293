(function () {
    'use strict';

    angular.module('app.ggIntegration')
        .run(appRun);

    appRun.$inject = ['routerHelper'];

    function appRun(routerHelper) {
        routerHelper.configureStates(getStates());
    }

    function getStates() {
        return [
            {
                state: 'ggIntegration',
                config: {
                    url: '/ggIntegration',
                    title: 'GittiGidiyor Entegrasyonu',
                    abstract: true,
                    templateUrl: 'app/ggIntegration/ggIntegration.html',
                    settings: {
                        name: 'integration',
                        nav: 200,
                        icon: 'fa fa-cogs',
                        resource: 'menu.integration',
                        content: 'Entegrasyonlar'
                    }
                }
            },
            {
                state: 'ggIntegration.ggmatchcategorylist',
                config: {
                    templateUrl: 'app/ggIntegration/ggIntegration.ggmatchcategorylist.html',
                    controller: 'GGMatchCategoryListController',
                    controllerAs: 'vm',
                    url: '/ggmatchcategorylist',
                    title: 'Eşleşmiş Kategori Listesi',
                    settings: {
                        nav: 10,
                        name: 'ggIntegration.ggmatchcategorylist',
                        content: 'Eşleşmiş Kategori Listesi',
                        resource: 'menu.ggIntegration.ggmatchcategorylist'
                    }
                }
            },
            {
                state: 'ggIntegration.ggaddmatchcategory',
                config: {
                    templateUrl: 'app/ggIntegration/ggIntegration.ggaddmatchcategory.html',
                    controller: 'GGAddMatchCategoryController',
                    controllerAs: 'vm',
                    url: '/ggaddmatchcategory',
                    title: 'Kategori Eşleştir',
                    settings: {
                        nav: 10,
                        name: 'ggIntegration.ggaddmatchcategory',
                        content: 'Kategori Eşleştir',
                        resource: 'menu.ggIntegration.ggaddmatchcategory'
                    }
                }
            },
            {
                state: 'ggIntegration.ggeditmatchcategory',
                config: {
                    templateUrl: 'app/ggIntegration/ggIntegration.ggeditmatchcategory.html',
                    controller: 'GGEditMatchCategoryController',
                    controllerAs: 'vm',
                    url: '/ggeditmatchcategory/:Id',
                    title: 'Eşleşmiş Kategori Düzenle',
                    settings: {
                        nav: 10,
                        name: 'ggIntegration.ggeditmatchcategory',
                        content: 'Eşleşmiş Kategori Düzenle',
                        resource: 'menu.ggIntegration.ggeditmatchcategory'
                    }
                }
            },
                       {
                state: 'ggIntegration.ggeditmatchcategoryfilter',
                config: {
                    templateUrl: 'app/ggIntegration/ggIntegration.ggeditmatchcategoryfilter.html',
                    controller: 'GGEditMatchCategoryFilterController',
                    controllerAs: 'vm',
                    url: '/editmatchcategoryfilter/:Id',
                    title: 'Eşleşmiş Kategori Filtre Düzenle',
                    settings: {
                        nav: 10,
                        name: 'ggIntegration.ggeditmatchcategoryfilter',
                        content: 'Eşleşmiş Kategori Filtre Düzenle',
                        resource: 'menu.ggIntegration.editmatchcategoryfilter'
                    }
                }
            },
            {
                state: 'ggIntegration.ggmatchcategoryfilterlist',
                config: {
                    templateUrl: 'app/ggIntegration/ggIntegration.ggmatchcategoryfilterlist.html',
                    controller: 'GGMatchCategoryFilterListController',
                    controllerAs: 'vm',
                    url: '/matchcategoryfilterlist/:Id',
                    title: 'Eşleşmiş Kategori Filtre Listesi',
                    settings: {
                        nav: 10,
                        name: 'ggIntegration.ggmatchcategoryfilterlist',
                        content: 'Eşleşmiş Kategori Filtre Listesi',
                        resource: 'menu.ggIntegration.matchcategoryfilterlist'
                    }
                }
            },
            {
                state: 'ggIntegration.ggeditmatchcategoryfiltervalue',
                config: {
                    templateUrl: 'app/ggIntegration/ggIntegration.ggeditmatchcategoryfiltervalue.html',
                    controller: 'GGEditMatchCategoryFilterValueController',
                    controllerAs: 'vm',
                    url: '/editmatchcategoryfiltervalue/:Id',
                    title: 'Eşleşmiş Kategori Filtre Değeri Düzenle',
                    settings: {
                        nav: 10,
                        name: 'ggIntegration.ggeditmatchcategoryfiltervalue',
                        content: 'Eşleşmiş Kategori Filtre Değeri Düzenle',
                        resource: 'menu.ggIntegration.editmatchcategoryfiltervalue'
                    }
                }
            },
            {
                state: 'ggIntegration.ggmatchcategoryfiltervaluelist',
                config: {
                    templateUrl: 'app/ggIntegration/ggIntegration.ggmatchcategoryfiltervaluelist.html',
                    controller: 'GGMatchCategoryFilterValueListController',
                    controllerAs: 'vm',
                    url: '/matchcategoryfiltervaluelist/:Id',
                    title: 'Eşleşmiş Kategori Filtre Değeri Listesi',
                    settings: {
                        nav: 10,
                        name: 'ggIntegration.ggmatchcategoryfiltervaluelist',
                        content: 'Eşleşmiş Kategori Filtre Değeri Listesi',
                        resource: 'menu.ggIntegration.matchcategoryfiltervaluelist'
                    }
                }
            }
        ];
    }
})();