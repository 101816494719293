(function () {
    'use strict';

    angular
        .module('app.orders')
        .controller('ProductsDetailController', ProductsDetailController);

    ProductsDetailController.$inject = ['$scope', '$stateParams', '$filter', '$http', '$location', 'logger', 'Settings', 'ProductsService', 'FileUploader', 'localStorageService', 'NgTableParams', 'ProductOptionValuesService'];

    function ProductsDetailController($scope, $stateParams, $filter, $http, $location, logger, Settings, ProductsService, FileUploader, localStorageService, NgTableParams, ProductOptionValuesService) {
        var vm = this;

        vm.tableProductAttributeParams = new NgTableParams(
            { page: 1, count: 10 },
            {
                getData: function (params) {
                    if (!vm.firstLoad) {
                        $location.search(params.url());
                    }
                    vm.firstLoad = false;
                    var filterData2 = params.filter();
                    var filterData = {};
                    filterData.PageIndex = params.page(),
                        filterData.PageSize = params.count(),
                        filterData.SortProperty = Object.keys(params.sorting())[0],
                        filterData.SortType = params.sorting()[Object.keys(params.sorting())[0]];
                    var keys = Object.keys(filterData2);
                    for (var i = 0; i < keys.length; i++) {
                        filterData[keys[i]] = filterData2[keys[i]];
                    }
                    return ProductOptionValuesService.getAll($stateParams.ProductId, true).then(function (data) {
                        return data;
                    });
                },
                counts: []
            });

        vm.tableProductOptionValueParams = new NgTableParams(
            {
                page: 1,
                count: 10,
                group: "Option.Name"
            },
            {
                getData: function (params) {
                    if (!vm.firstLoad) {
                        $location.search(params.url());
                    }
                    vm.firstLoad = false;
                    var filterData2 = params.filter();
                    var filterData = {};
                    filterData.PageIndex = params.page(),
                        filterData.PageSize = params.count(),
                        filterData.SortProperty = Object.keys(params.sorting())[0],
                        filterData.SortType = params.sorting()[Object.keys(params.sorting())[0]];
                    var keys = Object.keys(filterData2);
                    for (var i = 0; i < keys.length; i++) {
                        filterData[keys[i]] = filterData2[keys[i]];
                    }
                    return ProductOptionValuesService.getAll($stateParams.ProductId, false).then(function (data) {
                        return data;
                    });
                },
                counts: []
            });

        vm.uploader = new FileUploader({});

        vm.Settings = Settings;

        vm.uploader.filters.push({
            name: 'imageFilter',
            fn: function (item, options) {
                var type = '|' + item.type.slice(item.type.lastIndexOf('/') + 1) + '|';
                return '|jpg|png|jpeg|bmp|gif|'.indexOf(type) !== -1;
            }
        });

        vm.seofields = ProductsService.getSeoFields();

        vm.uploader.onSuccessItem = function (fileItem, response) {
            fileItem.MediaId = response.Id;
        };
        vm.loading = true;
        ProductsService.getProduct($stateParams.ProductId).then(function (data) {
            vm.entity = data;
            vm.entity.ProductCategory = vm.entity.ProductCategory || [];
            vm.entity.Categories = vm.entity.ProductCategory.map(function (item) {
                return item.Id;
            });
            vm.entity.ProductRoles = vm.entity.ProductRoles || [];
            vm.entity.Roles = vm.entity.ProductRoles.map(function (item) {
                return item.Role.Id;
            });
            vm.uploader.url = 'api/Product/' + data.Id + '/Media';
            var authData = localStorageService.get('authorizationData');
            vm.uploader.headers["Authorization"] = 'Bearer ' + authData.token;
            for (var i = 0; i < data.ProductMedia.length; i++) {
                var dummy = new FileUploader.FileItem(vm.uploader, {});
                dummy.progress = 100;
                dummy.isUploaded = true;
                dummy.isSuccess = true;
                vm.uploader.queue.push(dummy);
            }
            vm.loading = false;
        });

        vm.fields = ProductsService.getProductFields();

        vm.delete = function () {
            if (confirm("Silmek istediğinize emin misiniz?")) {
                ProductsService.deleteProduct(vm.entity).then(function (data) {
                    $location.path('/products/list');
                });
            }
        };

        vm.removeFile = function (item) {
            ProductsService.removeProductMedia(vm.entity.Id, item.MediaId).then(function (data) {
                vm.uploader.removeFromQueue(item);
            });
        };
    }
})();