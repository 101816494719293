(function () {
    "use strict";

    angular.module("app.account").controller("LoginOrSignupController", LoginOrSignupController);

    LoginOrSignupController.$inject = [
        "$rootScope",
        "$location",
        "SharedData",
        "AccountService",
        "SettingsService",
        "ResourcesService",
        "$translate",
        "localStorageService",
        "Settings",
    ];

    function LoginOrSignupController(
        $rootScope,
        $location,
        SharedData,
        AccountService,
        SettingsService,
        ResourcesService,
        $translate,
        localStorageService,
        Settings
    ) {
        var vm = this;

        vm.title = "";

        activate();

        function activate() {
            AccountService.logOut();
            ResourcesService.getCultures().then(function (data) {
                vm.cultures = data;
                $translate.use(localStorageService.get("culture") || Settings.CurrentCulture || "tr").then(function () {
                    $translate.refresh();
                });
            });
        }

        vm.selectedCulture = localStorageService.get("culture") || Settings.CurrentCulture || "tr";
        vm.updateCulture = function () {
            localStorageService.set("culture", vm.selectedCulture);
            $translate.use(vm.selectedCulture).then(function () {
                $translate.refresh();
            });
        };

        vm.savedSuccessfully = false;
        vm.signupMessage = "";

        vm.registration = {
            Email: "",
            Password: "",
            ConfirmPassword: ""
        };

        vm.signUp = function () {
            if (vm.formSignup.$valid) {
                AccountService.saveRegistration(vm.registration).then(
                    function (response) {
                        vm.savedSuccessfully = true;
                        vm.signupMessage = "Kullanıcı başarıyla kaydedildi. Artık giriş yapabilirsiniz.";
                    },
                    function (response) {
                        var errors = [];
                        for (var key in response.data) {
                            for (var i = 0; i < response.data[key].length; i++) {
                                errors.push(response.data[key][i]);
                            }
                        }
                        vm.signupMessage = "Failed to register user due to:" + errors.join("</br>");
                    }
                );
            }
        };

        vm.loginData = {
            Email: "",
            Password: "",
            useRefreshTokens: true
        };

        vm.loginMessage = "";

        vm.login = function () {
            if (vm.formLogin.$valid) {
                AccountService.login(vm.loginData)
                    .then(
                        function () {
                            $rootScope.$broadcast("loggedin");
                            AccountService.getUserInfo()
                                .then(function (data) {
                                    SharedData.UserInfo = data;
                                    SettingsService.refreshSettingsValue();
                                    SettingsService.resreshIntegrationSettingsValue();
                                    $location.path('/');
                                })
                        },
                        function (err) {
                            vm.loginMessage = "message." + err.data.error_description;
                        }
                    );
            }
        };
    }
})();