(function () {
    'use strict';

    angular
        .module('app.reviews')
        .factory('ReviewsService', Service);

    Service.$inject = ['$http', '$q', 'ngAuthSettings', 'logger', 'config'];

    function Service($http, $q, ngAuthSettings, logger, config) {
        var service = {
            getAll: getAll,
            get: get,
            add: add,
            edit: edit,
            del: del,
            getFields: getFields,
            approve: approve,
            showmainpage: showmainpage
        };

        var serviceBase = ngAuthSettings.apiServiceBaseUri + 'api/Review/';

        var fields = [
            {
                key: 'Name',
                type: 'horizontalInput',
                templateOptions: {
                    required: true,
                    type: 'text',
                    label: 'Adı',
                    placeholder: 'Adı'
                }
            },
            {
                key: 'IsApproved',
                type: 'horizontalCheckbox',
                templateOptions: {
                    label: 'Onay',
                    placeholder: 'Onay'
                }
            },
            {
                key: 'ShowMainPage',
                type: 'horizontalCheckbox',
                templateOptions: {
                    label: 'Ana Sayfada Göster',
                    placeholder: 'Ana Sayfada Göster'
                }
            },
            {
                key: 'ReviewTitle',
                type: 'horizontalInput',
                templateOptions: {
                    type: 'text',
                    label: 'Yorum Başlığı',
                    placeholder: 'Yorum Başlığı'
                }
            },
            {
                key: 'ReviewText',
                type: 'horizontalTextarea',
                templateOptions: {
                    label: 'Yorum İçeriği',
                    placeholder: 'Yorum İçeriği'
                }
            }
        ];

        return service;

        /////////////////////////////////////////

        function getFields() {
            return fields;
        }

        function getAll(filterParams) {
            var deferred = $q.defer();

            $http.get(serviceBase, {
                params: filterParams
            })
                .then(getComplete)
                .catch(getFailed);

            function getComplete(response) {
                deferred.resolve(response.data);
            }

            function getFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function get(Id) {
            var deferred = $q.defer();

            $http.get(serviceBase + Id)
                .then(getComplete)
                .catch(getFailed);

            function getComplete(response) {
                deferred.resolve(response.data);
            }

            function getFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function add(entity) {
            var deferred = $q.defer();

            $http.post(serviceBase, entity)
                .then(addComplete)
                .catch(addFailed);

            function addComplete(response) {
                deferred.resolve(response.data);
            }

            function addFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function edit(entity) {
            var deferred = $q.defer();

            $http.put(serviceBase + entity.Id, entity)
                .then(editComplete)
                .catch(editFailed);

            function editComplete(response) {
                deferred.resolve(response.data);
            }

            function editFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function del(entity) {
            var deferred = $q.defer();

            $http.delete(serviceBase + entity.Id, entity)
                .then(deleteComplete)
                .catch(deleteFailed);

            function deleteComplete(response) {
                deferred.resolve(response.data);
            }

            function deleteFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function approve(Id, approved) {
            var deferred = $q.defer();

            $http.post(serviceBase + "Approve/" + Id, { Approved: approved })
                .then(addComplete)
                .catch(addFailed);

            function addComplete(response) {
                deferred.resolve(response.data);
            }

            function addFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function showmainpage(Id,review) {
            console.log("review", review);
            var deferred = $q.defer();

            $http.post(serviceBase + "ShowMainPage/" + Id, { ShowMainPage: review })
                .then(addComplete)
                .catch(addFailed);

            function addComplete(response) {
                deferred.resolve(response.data);
            }

            function addFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }
    }
})();