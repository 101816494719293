(function () {
    'use strict';

    angular
        .module('app.production')
        .factory('RelatedProductService', Service);

    Service.$inject = ['$http', '$q', 'ngAuthSettings', 'RelatedProductTypeService', 'ProductsService'];

    function Service($http, $q, ngAuthSettings, RelatedProductTypeService, ProductsService) {
        var service = {
            getAll: getAll,
            get: get,
            add: add,
            edit: edit,
            del: del,
            getFields: getFields
        };

        var serviceBase = ngAuthSettings.apiServiceBaseUri;
        var endpoint = serviceBase + 'api/relatedProducts/';

        var customerFields = [{
                key: 'RelatedProductTypeId',
                type: 'floatSelect',
                templateOptions: {
                    required: true,
                    label: 'İlişkili Ürün',
                    resource: 'form.related-product-type',
                    options: [{
                        Name: '...'
                    }],
                    class: "col-xs-6"
                },
                controller: ['$scope', function ($scope) {
                    RelatedProductTypeService.getAll({
                            PageSize: -1
                        })
                        .then(function (response) {
                            $scope.to.options = response.Data;
                        });
                }]
            },
            {
                key: 'RelatedId',
                type: 'float-async-ui-select',
                templateOptions: {
                    required: true,
                    label: 'Ürün',
                    resource: 'form.related-product',
                    options: [],
                    placeholder: '...',
                    valueProp: 'Id',
                    labelProp: 'Name',
                    class: "col-xs-6",
                    refresh: function (text, options) {
                        var params = {
                            Name: text,
                            PageSize: 10,
                            PageIndex: 1,
                            SortProperty: 'Name',
                            SortType: 'asc'
                        };
                        return ProductsService.getProducts(params)
                            .then(function (response) {
                                if (response.Data.length > 0) {
                                    options.templateOptions.options = response.Data;
                                } else {
                                    options.templateOptions.options = [{
                                        Id: '',
                                        Name: 'Bulunamadı (' + text + ')'
                                    }];
                                }
                            });
                    },
                    refreshDelay: 2
                }
            }
        ];

        return service;

        ///////////////////////////////

        function getAll(filterParams) {
            var deferred = $q.defer();
            $http.get(endpoint, {
                    params: filterParams
                })
                .then(getComplete)
                .catch(getFailed);

            function getComplete(response) {
                deferred.resolve(response.data);
            }

            function getFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function get(id) {
            var deferred = $q.defer();

            $http.get(endpoint + id)
                .then(getComplete)
                .catch(getFailed);

            function getComplete(response) {
                deferred.resolve(response.data);
            }

            function getFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function add(customer) {
            var deferred = $q.defer();

            $http.post(endpoint, customer)
                .then(addComplete)
                .catch(addFailed);

            function addComplete(response) {
                deferred.resolve(response.data);
            }

            function addFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function edit(customer) {
            var deferred = $q.defer();

            $http.put(endpoint + customer.Id, customer)
                .then(editComplete)
                .catch(editFailed);

            function editComplete(response) {
                deferred.resolve(response.data);
            }

            function editFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function del(customer) {
            var deferred = $q.defer();

            $http.delete(endpoint + customer.Id, customer)
                .then(deleteComplete)
                .catch(deleteFailed);

            function deleteComplete(response) {
                deferred.resolve(response.data);
            }

            function deleteFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function getFields() {
            return customerFields;
        }
    }
})();