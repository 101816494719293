(function () {
    'use strict';

    angular
        .module('app.stockHistories')
        .controller('StockHistoriesDetailController', DetailController);

    DetailController.$inject = [
        '$location',
        '$stateParams',
        'NgTableParams',
        'StockHistoriesService',
        'StockItemsService',
        'WarehouseLocationsService'
    ];

    function DetailController(
        $location,
        $stateParams,
        NgTableParams,
        Service,
        StockItemsService,
        WarehouseLocationsService) {
        /* jshint validthis:true */
        var vm = this;

        Service.get($stateParams.StockHistoryId)
            .then(function (data) {
                vm.entity = data;
                vm.getOutStockItems();
                vm.getInStockItems();
                WarehouseLocationsService.getAll({
                        WarehouseId: vm.entity.WarehouseId
                    })
                    .then(function (data) {
                        vm.WarehouseLocationList = data.Data;
                    });

                StockItemsService.getStockItemStatus()
                    .then(function (data) {
                        vm.StockItemStatus = data;
                    })
            });

        vm.fields = Service.getFields();

        vm.delete = function () {
            if (confirm("Silmek istediğinize emin misiniz?")) {
                Service.del(vm.entity).then(function (data) {
                    $location.path('/stockHistories/list');
                });
            }
        };

        vm.refresh = function () {
            vm.loading = true;
            vm.outTableParams.reload();
            vm.loading = false;
        };

        vm.getOutStockItems = function () {
            vm.outTableParams = new NgTableParams({}, {
                getData: function (params) {
                    vm.firstLoad = false;
                    var filterData2 = params.filter();
                    var filterData = {};
                    filterData.PageSize = -1;
                    var keys = Object.keys(filterData2);
                    for (var i = 0; i < keys.length; i++) {
                        filterData[keys[i]] = filterData2[keys[i]];
                    }
                    filterData.StockHistoryId = vm.entity.Id;
                    return StockItemsService.getAll(filterData).then(function (data) {
                        params.total(data.FilteredCount);
                        params.count(data.FilteredCount);
                        return data.Data;
                    });
                }
            });
        }

        vm.getInStockItems = function () {
            vm.inTableParams = new NgTableParams({}, {
                getData: function (params) {
                    vm.firstLoad = false;
                    var filterData2 = params.filter();
                    var filterData = {
                        PageSize: -1,
                        SortProperty: 'Code',
                        SortType: 'asc',
                        ProductId: vm.entity.ProductId,
                        WarehouseId: vm.entity.WarehouseId,
                        StockItemStatusId: '26fdb071-fe3e-4813-9a0b-e2f2a794ce7a'
                    };
                    filterData.PageSize = -1;
                    var keys = Object.keys(filterData2);
                    for (var i = 0; i < keys.length; i++) {
                        filterData[keys[i]] = filterData2[keys[i]];
                    }
                    return StockItemsService.getAll(filterData).then(function (data) {
                        params.total(data.FilteredCount);
                        params.count(data.FilteredCount);
                        return data.Data;
                    });
                }
            });
        }

        vm.onSelectStockItem = function (stockItem) {
            vm.loading = true;
            stockItem.StockHistoryStockItem = [{
                StockHistoryId: vm.entity.Id
            }];
            stockItem.Product = null;
            stockItem.Warehouse = null;
            stockItem.WarehouseLocationId = null;
            stockItem.WarehouseLocation = null;
            stockItem.StockItemStatusId = "36c01122-6544-4fed-bb8a-2b0303145e4c";
            stockItem.StockItemStatus = null;
            stockItem.SalesOrderProduct = null;
            StockItemsService.edit(stockItem)
                .then(function () {
                    vm.outTableParams.reload();
                    vm.inTableParams.reload();
                });
        }

        vm.cancel = function (row, rowForm) {
            vm.outTableParams.reload();
        }

        vm.del = function (row) {
            if (confirm("Silmek istediğinize emin misiniz?")) {
                StockItemsService.del(row).then(function (data) {
                    vm.outTableParams.reload();
                });
            }
        }

        vm.save = function (row, rowForm) {
            if (row.Id) {
                if (vm.entity.Product.ProductStockTrackingTypeId == '766b9c7a-4ecb-470c-b867-7be65b6070ea') {
                    var params = {
                        Code: row.Code
                    };
                    StockItemsService.getAll(params)
                        .then(function (allStockItems) {
                            if (allStockItems.Data.length > 0) {
                                var thisStockItem = allStockItems.Data.find(function (item) {
                                    return item.Id = row.Id
                                });
                                if (thisStockItem) {
                                    if (confirm("Bu ürün seri numarası ile takip ediliyor ve bu seri numarasında envanter bulunmaktadır. Yinede devam etmek istiyor musunuz?")) {
                                        StockItemsService.add(row)
                                            .then(function (data) {
                                                vm.outTableParams.reload();
                                            });
                                    }
                                } else {
                                    StockItemsService.edit(row)
                                        .then(function (data) {
                                            vm.outTableParams.reload();
                                        });
                                }
                            } else {
                                StockItemsService.edit(row)
                                    .then(function (data) {
                                        vm.outTableParams.reload();
                                    });
                            }
                        });
                } else {
                    StockItemsService.edit(row)
                        .then(function (data) {
                            vm.outTableParams.reload();
                        });
                }
            } else {
                if (vm.entity.Product.ProductStockTrackingTypeId == '766b9c7a-4ecb-470c-b867-7be65b6070ea') {
                    var params = {
                        Code: row.Code
                    };
                    StockItemsService.getAll(params)
                        .then(function (allStockItems) {
                            if (allStockItems.Data.length > 0) {
                                if (confirm("Bu ürün seri numarası ile takip ediliyor ve bu seri numarasında envanter bulunmaktadır. Yinede devam etmek istiyor musunuz?")) {
                                    StockItemsService.add(row)
                                        .then(function (data) {
                                            vm.outTableParams.reload();
                                        });
                                }
                            } else {
                                StockItemsService.add(row)
                                    .then(function (data) {
                                        vm.outTableParams.reload();
                                    });
                            }
                        });
                } else {
                    StockItemsService.add(row)
                        .then(function () {
                            vm.outTableParams.reload();
                        });
                }
            }
        }

        // vm.refreshStockItem = function (text) {
        //     var params = {
        //         Code: text,
        //         PageSize: 18,
        //         PageIndex: 1,
        //         SortProperty: 'Code',
        //         SortType: 'asc',
        //         ProductId: vm.entity.ProductId,
        //         WarehouseId: vm.entity.WarehouseId,
        //         StockItemStatusId: '26fdb071-fe3e-4813-9a0b-e2f2a794ce7a'
        //     };
        //     vm.refreshStockItemList = [{
        //         Id: '',
        //         Code: 'Yükleniyor...'
        //     }];
        //     return StockItemsService.getAll(params)
        //         .then(function (response) {
        //             if (response.Data.length > 0) {
        //                 vm.refreshStockItemList = response.Data;
        //             } else {
        //                 vm.refreshStockItemList = [{
        //                     Id: '',
        //                     Code: 'Bulunamadı (' + text + ')'
        //                 }];
        //             }
        //         });
        // }

        // vm.onSelectStockItemCallback = function ($item) {
        //     vm.loading = true;
        //     $item.StockHistoryStockItem = [{
        //         StockHistoryId: vm.entity.Id
        //     }];
        //     $item.Product = null;
        //     $item.Warehouse = null;
        //     $item.WarehouseLocationId = null;
        //     $item.WarehouseLocation = null;
        //     $item.StockItemStatusId = "36c01122-6544-4fed-bb8a-2b0303145e4c";
        //     $item.StockItemStatus = null;
        //     $item.SalesOrderProduct = null;
        //     StockItemsService.edit($item)
        //         .then(function () {
        //             vm.outTableParams.reload();
        //         });
        // }

        vm.add = function () {
            vm.outTableParams.data.unshift({
                ProductId: vm.entity.ProductId,
                WarehouseId: vm.entity.WarehouseId,
                Cost: vm.entity.Cost,
                StockHistoryStockItem: [{
                    StockHistoryId: vm.entity.Id
                }],
                isEditing: true
            });
        }

    }
})();