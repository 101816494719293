
(function () {
    'use strict';

    angular
        .module('app.spares')
        .factory('SparesService', SparesService);

    SparesService.$inject = ['config'];

    function SparesService(config) {
        var service = {

            getspares: getspares,
            getspare: getspare
        };

        var spares = [
            { id: 1, Adi: "YedekParça1", Kodu: "1256355745", BagliUrunAdi: "KoltukTakimi", BagliUrunKodu: "125653663559", Fiyati: "35.4", Boyutu: "35x32x4" },
            { id: 2, Adi: "YedekParça2", Kodu: "4451124445", BagliUrunAdi: "KoltukTakimi", BagliUrunKodu: "213556858798", Fiyati: "35.4", Boyutu: "35x32x4" },
            { id: 3, Adi: "YedekParça3", Kodu: "1256355745", BagliUrunAdi: "KoltukTakimi", BagliUrunKodu: "125653663559", Fiyati: "56.9", Boyutu: "35x35x4" },
            { id: 4, Adi: "YedekParça4", Kodu: "1256355745", BagliUrunAdi: "KoltukTakimi", BagliUrunKodu: "125653663559", Fiyati: "35.4", Boyutu: "35x32x4" },
            { id: 5, Adi: "YedekParça5", Kodu: "4451124445", BagliUrunAdi: "KoltukTakimi", BagliUrunKodu: "125653663559", Fiyati: "35.4", Boyutu: "35x32x4" },
            { id: 6, Adi: "YedekParça6", Kodu: "2556685477", BagliUrunAdi: "KoltukTakimi", BagliUrunKodu: "125653663559", Fiyati: "78.4", Boyutu: "35x32x4" },
            { id: 7, Adi: "YedekParça7", Kodu: "2556685477", BagliUrunAdi: "KoltukTakimi", BagliUrunKodu: "125653663559", Fiyati: "35.4", Boyutu: "35x32x4" },
            { id: 8, Adi: "YedekParça8", Kodu: "1256355745", BagliUrunAdi: "KoltukTakimi", BagliUrunKodu: "213556858798", Fiyati: "35.5", Boyutu: "35x32x4" },
            { id: 9, Adi: "YedekParça9", Kodu: "1256355745", BagliUrunAdi: "KoltukTakimi", BagliUrunKodu: "125653663559", Fiyati: "35.4", Boyutu: "35x32x7" },
            { id: 10, Adi: "YedekParça10", Kodu: "1256355745", BagliUrunAdi: "KoltukTakimi", BagliUrunKodu: "125653663559", Fiyati: "35.4", Boyutu: "35x32x4" },
            { id: 11, Adi: "YedekParça11", Kodu: "1256355745", BagliUrunAdi: "KoltukTakimi", BagliUrunKodu: "125653663559", Fiyati: "35.4", Boyutu: "35x32x4" },
            { id: 12, Adi: "YedekParça12", Kodu: "2556685477", BagliUrunAdi: "KoltukTakimi", BagliUrunKodu: "125653663559", Fiyati: "35.4", Boyutu: "35x32x4" },
            { id: 13, Adi: "YedekParça13", Kodu: "1256355745", BagliUrunAdi: "KoltukTakimi", BagliUrunKodu: "125653663559", Fiyati: "35.4", Boyutu: "35x32x4" },
            { id: 14, Adi: "YedekParça14", Kodu: "2556685477", BagliUrunAdi: "KoltukTakimi", BagliUrunKodu: "125653663559", Fiyati: "35.4", Boyutu: "35x32x4" }
        ];

        function getspares() {
            return spares;
        }

        function getspare(id) {
            return spares.find(function (c) { return c.id >= id; });
        }

        return service;
    }
})();
