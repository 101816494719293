(function () {
    'use strict';

    angular
        .module('app.account')
        .run(appRun);

    appRun.$inject = ['routerHelper'];

    function appRun(routerHelper) {
        routerHelper.configureStates(getStates());
    }

    function getStates() {
        return [
            {
                state: 'account',
                config: {
                    url: '/account',
                    title: 'Hesap',
                    abstract: true,
                    templateUrl: 'app/account/account.html',
                    settings: {
                        name: 'system',
                        nav: 200,
                        icon: 'fa fa-cogs',
                        resource: 'menu.system',
                        content: 'Sistem'
                    }
                }
            },
            {
                state: 'account.loginorsignup',
                config: {
                    templateUrl: 'app/account/account.loginorsignup.html',
                    controller: 'LoginOrSignupController',
                    controllerAs: 'vm',
                    url: '/loginorsignup',
                    title: 'Giriş Yap veya Kaydol',
                    settings: {
                        topName: 'system',
                        breadcrumbIcon: 'security',
                        breadcrumb: 'Giriş Yap'
                    }
                }
            },
            {
                state: 'account.list',
                config: {
                    templateUrl: 'app/account/account.list.html',
                    controller: 'AccountListController',
                    controllerAs: 'vm',
                    url: '/list',
                    title: 'Kullanıcılar',
                    settings: {
                        name: 'account.list',
                        topName: 'system',
                        nav: 2,
                        content: 'Kulllanıcı Listesi',
                        resource: 'menu.account.list'
                    }
                }
            },
            {
                state: 'account.add',
                config: {
                    templateUrl: 'app/account/account.add.html',
                    controller: 'AccountAddController',
                    controllerAs: 'vm',
                    url: '/add',
                    title: 'Kullanıcı Ekle',
                    settings: {
                        topName: 'account.list',
                        resource: 'menu.add-account'
                    }
                }
            },
            {
                state: 'account.addrole',
                config: {
                    templateUrl: 'app/account/account.addrole.html',
                    controller: 'AccountAddRoleController',
                    controllerAs: 'vm',
                    url: '/addrole/:id',
                    title: 'Kullanıcıya Rol Ekle'
                }
            },
            {
                state: 'account.delete',
                config: {
                    templateUrl: 'app/account/account.delete.html',
                    controller: 'AccountDeleteController',
                    controllerAs: 'vm',
                    url: '/delete/:id',
                    title: 'Kullanıcı Sil'
                }
            },
            {
                state: 'account.edit',
                config: {
                    templateUrl: 'app/account/account.edit.html',
                    controller: 'AccountEditController',
                    controllerAs: 'vm',
                    url: '/edit/:id',
                    title: 'Kullanıcı Düzenle'
                }
            },
            {
                state: 'account.authentication',
                config: {
                    templateUrl: 'app/account/account.authentication.html',
                    controller: 'AccountAuthenticationController',
                    controllerAs: 'vm',
                    url: '/authentication',
                    title: 'Kullanıcı Yetki'
                }
            },
            {
                state: 'account.detail',
                config: {
                    templateUrl: 'app/account/account.detail.html',
                    controller: 'AccountDetailController',
                    controllerAs: 'vm',
                    url: '/detail/:id',
                    title: 'Kullanıcı Detayı'
                }
            }
        ];
    }
})();