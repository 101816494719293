(function () {
    'use strict';

    angular
        .module('app.layout')
        .controller('ControlSidebar', ControlSidebar);

    ControlSidebar.$inject = ['$scope', '$rootScope', '$timeout', '$location', 'AccountService', 'Settings', 'ResourcesService', '$translate', 'localStorageService', 'SharedData'];

    function ControlSidebar($scope, $rootScope, $timeout, $location, AccountService, Settings, ResourcesService, $translate, localStorageService, SharedData) {
        /*jshint validthis: true */
        var vm = this;
        vm.selectedCulture = localStorageService.get('culture') || Settings.CurrentCulture;
        vm.changePasswordModel = {};

        $timeout(function () {
            $('[data-toggle="control-sidebar"]').controlSidebar();
        });

        vm.User = SharedData.UserInfo;

        ResourcesService.getCultures()
            .then(function (data) {
                vm.cultures = data;
            });

        vm.changePassword = function () {
            vm.passwordChangeLoading = true;
            AccountService.changePassword(vm.changePasswordModel)
                .then(function () {
                    vm.passwordChangeLoading = false;
                });
        };

        vm.logOut = function () {
            AccountService.logOut();
            vm.User = {};
            SharedData.UserInfo = null;
            $location.path('/account/loginorsignup');
        };
    }
})();