(function () {
    'use strict';

    angular.module('app.warehouses')
        .run(appRun);

    appRun.$inject = ['routerHelper'];

    function appRun(routerHelper) {
        routerHelper.configureStates(getStates());
    }

    function getStates() {
        return [
            {
                state: 'warehouses',
                config: {
                    url: '/warehouses',
                    title: 'Bayi Depoları',
                    abstract: true,
                    templateUrl: 'app/warehouses/warehouses.html',
                    settings: {
                        nav: 3,
                        name: 'inventory',
                        icon: 'fa fa-cubes',
                        content: 'Ürün',
                        resource: 'menu.inventory'
                    }
                }
            },
            {
                state: 'warehouses.list',
                config: {
                    templateUrl: 'app/warehouses/warehouses.list.html',
                    controller: 'WarehousesListController',
                    controllerAs: 'vm',
                    url: '/list',
                    title: 'Depo Listesi',
                    settings: {
                        nav: 33,
                        name: 'warehouses.list',
                        topName: 'sales',
                        content: 'Depo Listesi',
                        resource: 'menu.warehouses.list'
                    }
                }
            },
            {
                state: 'warehouses.add',
                config: {
                    templateUrl: 'app/warehouses/warehouses.add.html',
                    controller: 'WarehousesAddController',
                    controllerAs: 'vm',
                    url: '/add',
                    title: 'Depo Ekle',
                    settings: {
                        resource: 'menu.add-warehouses'
                    }
                }
            },
            {
                state: 'warehouses.edit',
                config: {
                    templateUrl: 'app/warehouses/warehouses.edit.html',
                    controller: 'WarehousesEditController',
                    controllerAs: 'vm',
                    url: '/edit/:WarehouseId',
                    title: 'Depo Düzenle',
                    settings: {
                        resource: 'menu.edit-warehouses'
                    }
                }
            },
            {
                state: 'warehouses.detail',
                config: {
                    templateUrl: 'app/warehouses/warehouses.detail.html',
                    controller: 'WarehousesDetailController',
                    controllerAs: 'vm',
                    url: '/detail/:WarehouseId',
                    title: 'Depo Detayı',
                    settings: {
                        resource: 'menu.warehouses-detail'
                    }
                }
            }
        ];
    }
})();