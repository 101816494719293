(function () {
    'use strict';

    angular
        .module('app.sellers')
        .run(appRun);

    appRun.$inject = ['routerHelper'];

    function appRun(routerHelper) {
        routerHelper.configureStates(getStates());
    }

    function getStates() {
        return [
            {
                state: 'sellers',
                config: {
                    url: '/sellers',
                    title: 'Satıcıler',
                    abstract: true,
                    templateUrl: 'app/sellers/sellers.html'
                }
            },
            {
                state: 'sellers.list',
                config: {
                    templateUrl: 'app/sellers/sellers.list.html',
                    controller: 'SellersListController',
                    controllerAs: 'vm',
                    url: '/list',
                    title: 'Satıcı Listesi',
                    settings: {
                        //nav: 40,
                        //name: 'sellers.list',
                        topName: 'sales',
                        content: 'Satıcı Listesi'
                    }
                }
            },
            {
                state: 'sellers.add',
                config: {
                    templateUrl: 'app/sellers/sellers.add.html',
                    controller: 'SellersAddController',
                    controllerAs: 'vm',
                    url: '/add',
                    title: 'Satıcı Ekle'
                }
            },
            {
                state: 'sellers.edit',
                config: {
                    templateUrl: 'app/sellers/sellers.edit.html',
                    controller: 'SellersEditController',
                    controllerAs: 'vm',
                    url: '/edit/:Id',
                    title: 'Satıcı Düzenle'
                }
            },
            {
                state: 'sellers.delete',
                config: {
                    templateUrl: 'app/sellers/sellers.delete.html',
                    controller: 'SellersDeleteController',
                    controllerAs: 'vm',
                    url: '/delete/:Id',
                    title: 'Satıcı Sil'
                }
            },
            {
                state: 'sellers.detail',
                config: {
                    templateUrl: 'app/sellers/sellers.detail.html',
                    controller: 'SellersDetailController',
                    controllerAs: 'vm',
                    url: '/detail/:Id',
                    title: 'Satıcı Detayı'
                }
            }
        ];
    }
})();