(function () {
    'use strict';

    angular
        .module('app.dashboard')
        .component('directPay', {
            templateUrl: 'app/dashboard/dashboard.directpay.html',
            controller: DirectPay
        });

    DirectPay.$inject = ['OrdersService', 'SharedData'];

    function DirectPay(OrdersService,SharedData) {
        /*jshint validthis: true */
        var vm = this;
        vm.SharedData = SharedData;

        vm.loading = true;

        var filteredData = { };
       
        filteredData.SalesOrderTypeId = "faf9ca70-f140-4569-9cfc-330a939bb3d6";
        filteredData.SortProperty = "OrderDate";
        filteredData.SortType = "desc";

        OrdersService.getOrders(filteredData)
            .then(function (data) {
                vm.directPaylist = data.Data;
                vm.loading = false;
            });

        vm.Netsim = function (OrderId) {
            OrdersService.NetsimAccounting(OrderId)
                .then(function (response) {
                    if (response === "Ok") {
                        toastr.info("Başarıyla İşlendi.", "Muhasebeleştirme");
                    }
                    else {
                        toastr.warning(response, "Muhasebeleştirme");
                    }
                    vm.loading = false;
                })
                .catch(function () {
                    vm.loading = false;
                });
        }

    }
})();