(function() {
    'use strict';

    angular
        .module('app.messageTemplates')
        .controller('MessageTemplatesListController', ListController);

    ListController.$inject = ['$location', 'MessageTemplatesService', 'NgTableParams'];

    function ListController($location, Service, NgTableParams) {
        /* jshint validthis:true */
        var vm = this;

        activate();

        function activate() {
            vm.entity = null;

            vm.fields = Service.getFields();

            vm.firstLoad = true;
            vm.tableParams = new NgTableParams(
                angular.extend({ page: 1, count: 10 }, $location.search()),
                {
                    getData: function(params) {
                        if (!vm.firstLoad) {
                            $location.search(params.url());
                        }
                        vm.firstLoad = false;
                        var filterData2 = params.filter();
                        var filterData = {};
                        filterData.PageIndex = params.page();
                        filterData.PageSize = params.count();
                        filterData.SortProperty = Object.keys(params.sorting())[0];
                        filterData.SortType = params.sorting()[Object.keys(params.sorting())[0]];
                        var keys = Object.keys(filterData2);
                        for (var i = 0; i < keys.length; i++) {
                            filterData[keys[i]] = filterData2[keys[i]];
                        }
                        return Service.getAll(filterData).then(function(data) {
                            params.total(data.FilteredCount);
                            return data.Data;
                        });
                    }
                });
        }

        vm.prepareEdit = function (data) {
            vm.entity = angular.copy(data);
        };

        vm.cancel = function () {
            vm.entity = {};
        };

        vm.edit = function () {
            vm.loading = true;
            Service.edit(vm.entity)
                .then(function () {
                    vm.tableParams.reload();
                    vm.entity = {};
                    vm.loading = false;
                })
                .catch(function () {
                    vm.tableParams.reload();
                    vm.entity = {};
                    vm.loading = false;
                });
        };
    }
})();