(function () {
    'use strict';

    angular
        .module('app.production')
        .factory('ProductMediasService', Service);

    Service.$inject = ['$http', '$q', 'Settings', 'ngAuthSettings', 'logger', 'config', 'ProductsGeneralService', 'GeneralService', 'CustomerAddressService', 'VendortypesService', 'MediaService'];

    function Service($http, $q, Settings, ngAuthSettings, logger, config,  ProductsGeneralService, GeneralService, CustomerAddressService, VendortypesService, MediaService) {
        var service = {
            getAll: getAll,
            get: get,
            add: add,
            edit: edit,
            del: del,
            copyProductMediaVariant: copyProductMediaVariant,
            getFields: getFields
        };

        var serviceBase = ngAuthSettings.apiServiceBaseUri + 'api/products/';

        var fields = [
            {
                key: "MediaList",
                type: "horizontalMultipleImage",
                templateOptions: {
                    label: "Resim",
                    resource: 'form.image',
                    valueModel: "Media.FileName"
                }
            },
            {
                key: 'DisplayOrder',
                type: 'floatInput',
                templateOptions: {
                    type: 'number',
                    resource: 'form.display-order',
                    label: 'Sıra',
                    placeholder: 'Sıra',
                    class: "col-xs-3"
                }
            },
            {
                key: 'IsMain',
                type: 'floatCheckbox',
                templateOptions: {
                    label: 'Ana Resim',
                    resource: 'form.is-main-media',
                    placeholder: 'Ana Resim',
                    class: "col-xs-3"
                }
            },
            {
                key: 'ShowSlider',
                type: 'floatCheckbox',
                templateOptions: {
                    label: 'Sliderda Göster',
                    resource: 'form.show-slider',
                    placeholder: 'Sliderda Göster',
                    class: "col-xs-3"
                }
            },
            {
                key: 'IsTechnical',
                type: 'floatCheckbox',
                templateOptions: {
                    label: 'Teknik Resim',
                    resource: 'form.is-technical-media',
                    placeholder: 'Teknik Resim',
                    class: "col-xs-3"
                }
            }
        ];

        return service;

        /////////////////////////////////////////

        function getFields() {
            return fields;
        }

        function getAll(ProductId) {
            var url = serviceBase + ProductId + '/ProductMedia';
            var deferred = $q.defer();

            $http.get(url)
                .then(getComplete)
                .catch(getFailed);

            function getComplete(response) {
                deferred.resolve(response.data);
            }

            function getFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function copyProductMediaVariant(ProductId,deleteOld) {
            var url = serviceBase + 'CopyProductMediaToVariant/'+ ProductId +"/"+deleteOld;
            var deferred = $q.defer();

            $http.post(url)
                .then(getComplete)
                .catch(getFailed);

            function getComplete(response) {
                deferred.resolve(response.data);
            }

            function getFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }


        function get(Id) {
            var deferred = $q.defer();

            $http.get(serviceBase + Id)
                .then(getProductComplete)
                .catch(getProductFailed);

            function getProductComplete(response) {
                deferred.resolve(response.data);
            }

            function getProductFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function add(entity) {
            var url = serviceBase + entity.ProductId + "/ProductMedia";
            var deferred = $q.defer();

            $http.post(url, entity)
                .then(addComplete)
                .catch(addFailed);

            function addComplete(response) {
                deferred.resolve(response.data);
            }

            function addFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function edit(entity) {
            var url = serviceBase + entity.ProductId + "/ProductMedia";
            var deferred = $q.defer();

            $http.put(url, entity)
                .then(editComplete)
                .catch(editFailed);

            function editComplete(response) {
                deferred.resolve(response.data);
            }

            function editFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function del(entity) {
            var deferred = $q.defer();

            $http.delete(serviceBase, entity)
                .then(deleteComplete)
                .catch(deleteFailed);

            function deleteComplete(response) {
                deferred.resolve(response.data);
            }

            function deleteFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }
    }
})();