(function () {
    'use strict';

    angular
        .module('app.production')
        .run(appRun);

    appRun.$inject = ['routerHelper'];

    function appRun(routerHelper) {
        routerHelper.configureStates(getStates());
    }

    function getStates() {
        return [
            {
                state: 'products',
                config: {
                    url: '/products',
                    title: 'Ürünler',
                    abstract: true,
                    templateUrl: 'app/production/products.html',
                    settings: {
                        nav: 3,
                        name: 'products',
                        icon: 'fa fa-cubes',
                        content: 'Ürün',
                        resource: 'menu.product'
                    }
                }
            },
            {
                state: 'products.list',
                config: {
                    templateUrl: 'app/production/products.list.html',
                    controller: 'ProductsListController',
                    controllerAs: 'vm',
                    url: '/list',
                    title: 'Ürün Listesi',
                    settings: {
                        nav: 3,
                        name: 'products.list',
                        topName: 'products',
                        content: 'Ürün Listesi',
                        breadcrumbIcon: 'local_mall',
                        breadcrumb: 'Ürün Listesi',
                        resource: 'menu.products.list'
                    }
                }
            },
            {
                state: 'products.add',
                config: {
                    templateUrl: 'app/production/products.add.html',
                    controller: 'ProductsAddController',
                    controllerAs: 'vm',
                    url: '/add',
                    title: 'Ürün Ekle',
                    settings: {
                        nav: 3,
                        name: 'products.list',
                        topName: 'products.list',
                        icon: 'add',
                        content: 'Ekle',
                        breadcrumbIcon: 'add',
                        breadcrumb: 'Ürün Ekle',
                        resource: 'menu.products.add'
                    }
                }
            },
            {
                state: 'products.edit',
                config: {
                    templateUrl: 'app/production/products.edit.html',
                    controller: 'ProductsEditController',
                    controllerAs: 'vm',
                    url: '/edit/:ProductId',
                    title: 'Ürün Düzenle',
                    settings: {
                        topName: 'products.list',
                        breadcrumbIcon: 'edit',
                        breadcrumb: 'Ürün Düzenle',
                        resource: 'menu.products.edit'
                    }
                }
            },
            {
                state: 'products.detail',
                config: {
                    templateUrl: 'app/production/products.detail.html',
                    controller: 'ProductsDetailController',
                    controllerAs: 'vm',
                    url: '/detail/:ProductId',
                    title: 'Ürün Detay',
                    settings: {
                        topName: 'products.list',
                        breadcrumbIcon: 'assignment',
                        breadcrumb: 'Ürün Detayı',
                        resource: 'menu.products.detail'
                    }
                }
            },
            {
                state: 'products.combining',
                config: {
                    templateUrl: 'app/production/products.combining.html',
                    controller: 'ProductsCombiningController',
                    controllerAs: 'vm',
                    url: '/combining',
                    title: 'Ürün Birleştirme',
                    settings: {
                        nav: 3,
                        name: 'products.combining',
                        topName: 'product',
                        content: 'Ürün Birleştirme',
                        breadcrumbIcon: 'local_mall',
                        breadcrumb: 'Ürün Birleştirme',
                        resource: 'menu.products.combining'
                    }
                }
            }
        ];
    }
})();