
(function () {
    'use strict';

    angular
      .module('app.account')
      .factory('AccountGeneralService', AccountGeneralService);

    AccountGeneralService.$inject = ['$http', '$q', 'ngAuthSettings'];

    function AccountGeneralService($http, $q, ngAuthSettings) {
        var service = {
            getGenders: getGenders,
            getMaritalStatus: getMaritalStatus,
            getEducationalStatus: getEducationalStatus,
            getJobs: getJobs,
            getDepartments: getDepartments,
            getCode: getCode
        };

        var serviceBase = ngAuthSettings.apiServiceBaseUri;

        return service;

        function getCode() {
            var deferred = $q.defer();

            $http.get(serviceBase + 'api/user/general/code')
                .then(getGendersComplete)
                .catch(getGendersFailed);

            function getGendersComplete(response) {
                deferred.resolve(response.data);
            }

            function getGendersFailed(error) {
                deferred.reject(error);
            }

            return deferred.promise;
        }

        function getDepartments() {
            var deferred = $q.defer();

            $http.get(serviceBase + 'api/user/general/department')
                .then(getDepartmentsComplete)
                .catch(getDepartmentsFailed);

            function getDepartmentsComplete(response) {
                deferred.resolve(response.data);
            }

            function getDepartmentsFailed(error) {
                deferred.reject(error);
            }

            return deferred.promise;
        }

        function getGenders() {
            var deferred = $q.defer();

            $http.get(serviceBase + 'api/user/general/gender')
                .then(getGendersComplete)
                .catch(getGendersFailed);

            function getGendersComplete(response) {
                deferred.resolve(response.data);
            }

            function getGendersFailed(error) {
                deferred.reject(error);
            }

            return deferred.promise;
        }

        function getMaritalStatus() {
            var deferred = $q.defer();

            $http.get(serviceBase + 'api/user/general/maritalstatus')
                .then(getMaritalStatusComplete)
                .catch(getMaritalStatusFailed);

            function getMaritalStatusComplete(response) {
                deferred.resolve(response.data);
            }

            function getMaritalStatusFailed(error) {
                deferred.reject(error);
            }

            return deferred.promise;
        }

        function getEducationalStatus() {
            var deferred = $q.defer();

            $http.get(serviceBase + 'api/user/general/educationalstatus')
                .then(getEducationalStatusComplete)
                .catch(getEducationalStatusFailed);

            function getEducationalStatusComplete(response) {
                deferred.resolve(response.data);
            }

            function getEducationalStatusFailed(error) {
                deferred.reject(error);
            }

            return deferred.promise;
        }

        function getJobs() {
            var deferred = $q.defer();

            $http.get(serviceBase + 'api/user/general/job')
                .then(getJobsComplete)
                .catch(getJobsFailed);

            function getJobsComplete(response) {
                deferred.resolve(response.data);
            }

            function getJobsFailed(error) {
                deferred.reject(error);
            }

            return deferred.promise;
        }
    }
})();
