(function () {
    "use strict";

    angular
        .module("app.orders")
        .controller("DeliveriesController", DeliveriesController);

    DeliveriesController.$inject = ["StockItemsService"];

    function DeliveriesController(StockItemsService) {
        var vm = this;

        activate();

        function activate() {
            vm.loading = true;
            vm.deliveryList = [];
            var filterData = {
                PageSize: -1,
                StockItemStatusId: '26fdb071-fe3e-4813-9a0b-e2f2a794ce7a',
                SortProperty: 'InsertDate',
                SortType: 'desc',
                IsAddedSalesOrder: true
            };
            StockItemsService.getAll(filterData)
                .then(function (data) {
                    vm.deliveryList = data.Data;
                    vm.loading = false;
                });
        }
    }
})();