(function () {
    'use strict';

    angular
        .module('app.excel')
        .controller('ExcelCategoryImportController', ExcelCategoryImportController);

    ExcelCategoryImportController.$inject = ['$scope', '$stateParams', '$filter', '$location', 'logger', 'ExcelService', '$uibModal', '$uibModalStack'];

    function ExcelCategoryImportController($scope, $stateParams, $filter, $location, logger, ExcelService, $uibModal, $uibModalStack) {
        var vm = this;

        activate();

        function activate() {
        }

        vm.excelCategoryImportFields = ExcelService.getExcelCategoryImportFields();

        vm.saveCategoryImport = function () {
            console.log("log", vm.excelCategoryImport);
            vm.openModal(vm.excelCategoryImport.File); 
        }


        // Modal

        vm.modalTitle = "Excel Kategori Import";
        vm.modalInstance = null;
        vm.openModal = function (File) {
            console.log("File",File);
            vm.modalInstance = $uibModal.open({
                templateUrl: 'ModalContent.html',
                controller: 'ModalCategoryController',
                controllerAs: 'vm',
                resolve: {
                    items: function () {
                        return {
                            File: File
                        };
                    }
                }
            });
        }

    }

    angular
        .module('app.excel')
        .controller('ModalCategoryController', ModalCategoryController);

    ModalCategoryController.$inject = ['$scope', '$stateParams', '$filter', '$location', 'logger', 'ExcelService', '$uibModal', '$uibModalStack','$uibModalInstance','items'];

    function ModalCategoryController($scope, $stateParams, $filter, $location, logger, ExcelService, $uibModal, $uibModalStack,$uibModalInstance, items) {

        var vm = this;
        vm.content = items;
        activate();

        function activate() {
            ExcelService.analysisCategoryFile(vm.content.File).then(function (data) {
                vm.file = vm.content.File;
                vm.CategoryCount = data.data.CategoryCount;
                vm.NotMatchField = data.data.NotMatchField;

                vm.loading = false;  
            });
        }

        
        vm.modalCancel = function () {
            $uibModalStack.dismissAll();
        }

        vm.approveCategoryImport = function () {
            vm.loading = true;
            ExcelService.importCategoryFile(vm.file, vm.filedelete).then(function () {
                vm.loading = false;
                vm.modalCancel();
                $location.path('/categories/list');
            });
        }

    }
})();