(function () {
    'use strict';

    angular
        .module('app.excel')
        .run(appRun);

    appRun.$inject = ['routerHelper'];

    function appRun(routerHelper) {
        routerHelper.configureStates(getStates());
    }

    function getStates() {
        return [
            {
                state: 'excel',
                config: {
                    url: '/excel',
                    title: 'Excel',
                    abstract: true,
                    templateUrl: 'app/excel/excel.html',
                    settings: {
                        nav: 3,
                        name: 'excel',
                        icon: 'fa fa-excel',
                        content: 'Excel',
                        resource: 'menu.excel'
                    }
                }
            },
            {
                state: 'excel.import',
                config: {
                    templateUrl: 'app/excel/excel.import.html',
                    controller: 'ExcelImportController',
                    controllerAs: 'vm',
                    url: '/import',
                    title: 'Excel Import',
                    settings: {
                        name: 'excel.import',
                        content: 'Excel Import',
                        breadcrumbIcon: 'local_mall',
                        breadcrumb: 'Excel Import',
                        resource: 'menu.excel.import'
                    }
                }
            },
            {
                state: 'excel.export',
                config: {
                    templateUrl: 'app/excel/excel.export.html',
                    controller: 'ExcelExportController',
                    controllerAs: 'vm',
                    url: '/export',
                    title: 'Excel Export',
                    settings: {
                        name: 'excel.export',
                        content: 'Excel Export',
                        breadcrumbIcon: 'local_mall',
                        breadcrumb: 'Excel Export',
                        resource: 'menu.excel.export'
                    }
                }
            },
            {
                state: 'excel.ftpimage',
                config: {
                    templateUrl: 'app/excel/excel.ftpimage.html',
                    controller: 'ExcelFtpImageController',
                    controllerAs: 'vm',
                    url: '/ftpimage',
                    title: 'Excel Ftp Image',
                    settings: {
                        name: 'excel.ftpimage',
                        content: 'Excel Ftp Image',
                        breadcrumbIcon: 'local_mall',
                        breadcrumb: 'Excel Ftp Image',
                        resource: 'menu.excel.ftpimage'
                    }
                }
            },
            {
                state: 'excel.addimage',
                config: {
                    templateUrl: 'app/excel/excel.addimage.html',
                    controller: 'ExcelAddImageController',
                    controllerAs: 'vm',
                    url: '/addimage',
                    title: 'Excel Add Image',
                    settings: {
                        name: 'excel.addimage',
                        content: 'Excel Add Image',
                        breadcrumbIcon: 'local_mall',
                        breadcrumb: 'Excel Add Image',
                        resource: 'menu.excel.addimage'
                    }
                }
            },
            {
                state: 'excel.categoryimport',
                config: {
                    templateUrl: 'app/excel/excel.categoryimport.html',
                    controller: 'ExcelCategoryImportController',
                    controllerAs: 'vm',
                    url: '/categoryimport',
                    title: 'Excel Kategori Import',
                    settings: {
                        name: 'excel.categoryimport',
                        content: 'Excel Kategori Import',
                        breadcrumbIcon: 'local_mall',
                        breadcrumb: 'Excel Kategori Import',
                        resource: 'menu.excel.categoryimport'
                    }
                }
            },
            {
                state: 'excel.categoryexport',
                config: {
                    templateUrl: 'app/excel/excel.categoryexport.html',
                    controller: 'ExcelCategoryExportController',
                    controllerAs: 'vm',
                    url: '/categoryexport',
                    title: 'Excel Kategori Export',
                    settings: {
                        name: 'excel.categoryexport',
                        content: 'Excel Kategori Export',
                        breadcrumbIcon: 'local_mall',
                        breadcrumb: 'Excel Kategori Export',
                        resource: 'menu.excel.categoryexport'
                    }
                }
            },
            {
                state: 'excel.salespointimport',
                config: {
                    templateUrl: 'app/excel/excel.salespointimport.html',
                    controller: 'ExcelSalesPointImportController',
                    controllerAs: 'vm',
                    url: '/salespointimport',
                    title: 'Excel Bayi Import',
                    settings: {
                        name: 'excel.salespointimport',
                        content: 'Excel Bayi Import',
                        breadcrumbIcon: 'local_mall',
                        breadcrumb: 'Excel Bayi Import',
                        resource: 'menu.excel.salespointimport'
                    }
                }
            },
            {
                state: 'excel.salespointexport',
                config: {
                    templateUrl: 'app/excel/excel.salespointexport.html',
                    controller: 'ExcelSalesPointExportController',
                    controllerAs: 'vm',
                    url: '/salespointexport',
                    title: 'Excel Bayi Export',
                    settings: {
                        name: 'excel.salespointexport',
                        content: 'Excel Bayi Export',
                        breadcrumbIcon: 'local_mall',
                        breadcrumb: 'Excel Bayi Export',
                        resource: 'menu.excel.salespointexport'
                    }
                }
            },
            {
                state: 'excel.stockimport',
                config: {
                    templateUrl: 'app/excel/excel.stockimport.html',
                    controller: 'ExcelStockImportController',
                    controllerAs: 'vm',
                    url: '/stockimport',
                    title: 'Excel Stok Import',
                    settings: {
                        name: 'excel.stockimport',
                        content: 'Excel Stok Import',
                        breadcrumbIcon: 'local_mall',
                        breadcrumb: 'Excel Stok Import',
                        resource: 'menu.excel.stockimport'
                    }
                }
            },
            {
                state: 'excel.stockexport',
                config: {
                    templateUrl: 'app/excel/excel.stockexport.html',
                    controller: 'ExcelStockExportController',
                    controllerAs: 'vm',
                    url: '/stockexport',
                    title: 'Excel Stok Export',
                    settings: {
                        name: 'excel.stocktexport',
                        content: 'Excel Stok Export',
                        breadcrumbIcon: 'local_mall',
                        breadcrumb: 'Excel Stok Export',
                        resource: 'menu.excel.stockexport'
                    }
                }
            },
            {
                state: 'excel.orderexport',
                config: {
                    templateUrl: 'app/excel/excel.orderexport.html',
                    controller: 'ExcelOrderExportController',
                    controllerAs: 'vm',
                    url: '/orderexport',
                    title: 'Excel Sipariş Export',
                    settings: {
                        name: 'excel.orderexport',
                        content: 'Excel Sipariş Export',
                        breadcrumbIcon: 'local_mall',
                        breadcrumb: 'Excel Sipariş Export',
                        resource: 'menu.excel.orderexport'
                    }
                }
            }
        ];
    }
})();