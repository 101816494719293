(function () {
    'use strict';

    angular.module('app.carts')
        .run(appRun);

    appRun.$inject = ['routerHelper'];

    function appRun(routerHelper) {
        routerHelper.configureStates(getStates());
    }

    function getStates() {
        return [
            {
                state: 'carts',
                config: {
                    url: '/carts',
                    title: 'Sepet Listesi',
                    abstract: true,
                    templateUrl: 'app/carts/carts.html'
                }
            },
            {
                state: 'carts.list',
                config: {
                    templateUrl: 'app/carts/carts.list.html',
                    controller: 'CartsListController',
                    controllerAs: 'vm',
                    url: '/list',
                    title: 'Sepet Listesi',
                    settings: {
                        nav: 33,
                        name: 'cart.list',
                        topName: 'sales',
                        content: 'Sepet Listesi',
                        resource: 'menu.cart.list'
                    }
                }
            },
            {
                state: 'carts.add',
                config: {
                    templateUrl: 'app/carts/carts.add.html',
                    controller: 'CartsAddController',
                    controllerAs: 'vm',
                    url: '/add',
                    title: 'Yeni Sepet',
                    settings: {
                        topName: 'carts.list',
                        resource: 'menu.add-cart'
                    }
                }
            },
            {
                state: 'carts.edit',
                config: {
                    templateUrl: 'app/carts/carts.edit.html',
                    controller: 'CartsEditController',
                    controllerAs: 'vm',
                    url: '/edit/:CartId',
                    title: 'Sepet Düzenle',
                    settings: {
                        topName: 'carts.list',
                        resource: 'menu.edit-cart'
                    }
                }
            },
            {
                state: 'carts.detail',
                config: {
                    templateUrl: 'app/carts/carts.detail.html',
                    controller: 'CartsDetailController',
                    controllerAs: 'vm',
                    url: '/detail/:CartId',
                    title: 'Sepet Detayı',
                    settings: {
                        topName: 'carts.list',
                        resource: 'menu.cart-detail'
                    }
                }
            }
        ];
    }
})();