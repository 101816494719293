(function () {
    "use strict";

    angular.module("app.core").config(formlyConfigure);

    formlyConfigure.$inject = ["formlyConfigProvider", "$windowProvider"];

    function formlyConfigure(formlyConfigProvider, $windowProvider) {
        formlyConfigProvider.setWrapper({
            name: "floatBootstrapLabel",
            template: `<formly-transclude></formly-transclude>
                        <label for="{{::id}}">
                            <span ng-if="!to.resource">{{to.label}}</span>
                            <span ng-if="to.resource">{{to.resource | translate}}</span>
                            <span style="color:red">{{to.required ? "*" : ""}}</span>
                        </label>`,
        });

        formlyConfigProvider.setWrapper({
            name: "floatBootstrapHasError",
            template: `<div class="{{to.class?to.class:'col-xs-12'}} {{::to.templateClass}}">
                            <div class="form-label-group {{to.addonLeftClass?'float-addon-left':''}}" ng-class="{'has-error': showError}">
                                <formly-transclude></formly-transclude>
                            </div>
                        </div>`,
        });

        formlyConfigProvider.setWrapper({
            name: "floatValidation",
            types: ["floatInput", "floatSelect", "floatDatepicker"],
            template: [
                "<formly-transclude></formly-transclude>",
                '<div class="validation"',
                '  ng-if="showError"',
                '  ng-messages="fc.$error">',
                '  <div ng-message="{{::name}}" ng-repeat="(name, message) in ::options.validation.messages">',
                "    {{message(fc.$viewValue, fc.$modelValue, this)}}",
                "  </div>",
                "</div>",
            ].join(" "),
        });

        formlyConfigProvider.setType({
            name: "floatInput",
            extends: "input",
            wrapper: ["floatBootstrapLabel", "floatBootstrapHasError"],
            template:
                '<i class="addon-left {{::to.addonLeftClass}}" ng-if="to.addonLeftClass"></i><input class="form-control" ng-model="model[options.key]" placeholder="{{to.resource | translate}}">',
            defaultOptions: {
                ngModelAttrs: {
                    mask: {
                        attribute: "ui-mask",
                    },
                    moneyMask: {
                        attribute: "ui-money-mask",
                    },
                    numberMask: {
                        attribute: "ui-number-mask",
                    },
                    uiNegativeNumber: {
                        attribute: "ui-negative-number",
                    },
                    maskPlaceholder: {
                        attribute: "ui-mask-placeholder",
                    },
                    percentageMask: {
                        attribute: "ui-percentage-mask",
                    },
                    readonly: {
                        bound: "ng-readonly",
                        attribute: "ng-readonly",
                    },
                },
                templateOptions: {
                    maskPlaceholder: "",
                },
            },
        });

        formlyConfigProvider.setType({
            name: "floatRangeInput",
            wrapper: ["floatBootstrapHasError"],
            template: `<div class="float-range-input">
                            <div class="range-input">
                                <div class="col-md-3 no-padding">(%{{(model[options.key]*100/12).toFixed(2)}}) {{model[options.key]}}</div><div class="col-md-9 no-padding"><input type="range" ng-model="model[options.key]" placeholder="{{to.resource | translate}}"></div>
                            </div>
                            <label for="{{::id}}">
                                <span ng-if="!to.resource">{{to.label}}</span>
                                <span ng-if="to.resource">{{to.resource | translate}}</span>
                                <span style="color:red">{{to.required ? "*" : ""}}</span>
                            </label>
                        </div>`,
        });

        formlyConfigProvider.setType({
            name: "floatTextarea",
            extends: "textarea",
            wrapper: ["floatBootstrapLabel", "floatBootstrapHasError"],
            defaultOptions: {
                ngModelAttrs: {
                    readonly: {
                        bound: "ng-readonly",
                        attribute: "ng-readonly",
                    },
                },
            },
        });

        formlyConfigProvider.setType({
            name: "floatSelect",
            template: `<select ng-model="model[options.key]" class="form-control" theme="bootstrap">
							<option ng-if="to.required !== true" value="" refresh="to.refresh($select.search, options, model)" refresh-delay="1000"> Seçiniz... </option>
							<option value="{{option[to.valueProp]}}" ng-repeat="(key, option) in to.options" 
																								refresh="to.refresh($select.search, options, model)" refresh-delay="1000">
								{{option[to.labelProp]}}
							</option>
						</select>`,
            defaultOptions: {
                templateOptions: {
                    valueProp: "Id",
                    labelProp: "Name",
                },
            },
            wrapper: ["floatBootstrapLabel", "floatBootstrapHasError"],
        });

        var attributes = [
            "date-disabled",
            "custom-class",
            "show-weeks",
            "starting-day",
            "init-date",
            "min-mode",
            "max-mode",
            "format-day",
            "format-month",
            "format-year",
            "format-day-header",
            "format-day-title",
            "format-month-title",
            "year-range",
            "shortcut-propagation",
            "datepicker-popup",
            "show-button-bar",
            "current-text",
            "clear-text",
            "close-text",
            "close-on-date-selection",
            "datepicker-append-to-body",
        ];

        var bindings = ["datepicker-mode", "min-date", "max-date"];

        var ngModelAttrs = {};

        angular.forEach(attributes, function (attr) {
            ngModelAttrs[camelize(attr)] = {
                attribute: attr,
            };
        });

        angular.forEach(bindings, function (binding) {
            ngModelAttrs[camelize(binding)] = {
                bound: binding,
            };
        });

        function camelize(string) {
            string = string.replace(/[\-_\s]+(.)?/g, function (match, chr) {
                return chr ? chr.toUpperCase() : "";
            });

            // Ensure 1st char is always lowercase
            return string.replace(/^([A-Z])/, function (match, chr) {
                return chr ? chr.toLowerCase() : "";
            });
        }

        formlyConfigProvider.setType({
            name: "floatDatepicker",
            template:
                '<input  type="text" id="{{::id}}" name="{{::id}}" ng-model="model[options.key]" class="form-control" ng-click="datepicker.open($event)" uib-datepicker-popup="{{to.datepickerOptions.format}}" is-open="datepicker.opened" datepicker-options="to.datepickerOptions" />' +
                '<i class="fa fa-calendar form-control-feedback"></i>',
            wrapper: ["floatBootstrapLabel", "floatBootstrapHasError"],
            defaultOptions: {
                ngModelAttrs: ngModelAttrs,
                templateOptions: {
                    datepickerOptions: {
                        locale: "tr",
                        format: "dd.MM.yyyy",
                    },
                },
            },
            controller: [
                "$scope",
                function ($scope) {
                    $scope.datepicker = {};
                    $scope.datepicker.opened = false;
                    $scope.datepicker.open = function ($event) {
                        $scope.datepicker.opened = !$scope.datepicker.opened;
                    };
                    $scope.setFirst = false;
                    $scope.$watch("model[options.key]", function (newValue, oldValue) {
                        if (newValue && !$scope.setFirst) {
                            $scope.model[$scope.options.key] = moment(newValue).toDate();
                            $scope.setFirst = true;
                        }
                    });
                },
            ],
        });

        formlyConfigProvider.setType({
            name: "floatCheckbox",
            template: `<div class="checkbox">
                            <label>
                                <input type="checkbox" class="formly-field-checkbox" ng-model="model[options.key]">
                                <span ng-if="!to.resource">{{to.label}}</span>
                                <span ng-if="to.resource">{{to.resource | translate}}</span>
                                {{to.required ? '*' : ''}}
                            </label>
                        </div>`,
            wrapper: ["floatBootstrapHasError"],
            apiCheck: (check) => ({
                templateOptions: {
                    label: check.string,
                },
            }),
        });

        formlyConfigProvider.setType({
            name: "floatRadio",
            defaultOptions: {
                templateOptions: {
                    valueProp: "Id",
                    labelProp: "Name",
                },
            },
            template: `<div class="{{to.class?to.class:'col-xs-12'}} {{::to.templateClass}}">
                            <div class="form-group">
                                <label for="{{::id}}">
                                    <span ng-if="!to.resource">{{to.label}}</span>
                                    <span ng-if="to.resource">{{to.resource | translate}}</span>
                                    <span style="color:red">{{to.required ? "*" : ""}}</span>
                                </label>
                                <div class="radio-group">
                                    <div ng-repeat="(key, option) in to.options" class="radio">
                                        <label>
                                        <input type="radio"
                                                id="{{id + '_'+ $index}}"
                                                tabindex="0"
                                                ng-value="option[to.valueProp || 'value']"
                                                ng-model="model[options.key]">
                                        {{option[to.labelProp || 'name']}}
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>`,
            wrapper: ["floatBootstrapHasError"],
        });

        formlyConfigProvider.setType({
            name: "floatEmail",
            extends: "floatInput",
            defaultOptions: {
                templateOptions: {
                    type: "email",
                    addonLeftClass: "fa fa-envelope-o",
                },
            },
        });

        formlyConfigProvider.setType({
            name: "floatPhone",
            extends: "floatInput",
            defaultOptions: {
                templateOptions: {
                    type: "text",
                    addonLeftClass: "fa fa-phone",
                    mask: "(999) 999 9999",
                },
            },
        });

        formlyConfigProvider.setType({
            name: "floatMultiSelect",
            extends: "select",
            defaultOptions: {
                templateOptions: {
                    valueProp: "Id",
                    labelProp: "Name",
                },
                ngModelAttrs: {
                    true: {
                        value: "multiple",
                    },
                },
            },
            wrapper: ["floatBootstrapLabel", "floatBootstrapHasError"],
        });

        formlyConfigProvider.setType({
            name: "floatTreeMultiSelect",
            defaultOptions: {
                templateOptions: {
                    valueProp: "Id",
                    labelProp: "Name",
                },
                ngModelAttrs: {
                    true: {
                        value: "multiple",
                    },
                },
            },
            wrapper: ["floatBootstrapLabel", "floatBootstrapHasError"],
            template: `<multi-select-tree data-input-model="data" multi-select="true"
                        data-output-model="model[options.key]" data-default-label="{{to.placeholder}}"
                        data-select-only-leafs="false"></multi-select-tree>`,

            controller: [
                "$scope",
                "$timeout",
                function ($scope, $timeout) {
                    $scope.selectedItems = [];
                    $scope.refresh = false;
                    $scope.$watch("model[options.key]", function (newValue, oldValue) {
                        if (newValue && $scope.to.addTop && !$scope.refresh && $scope.selectedItems.length <= 0) {
                            $scope.refresh = true;
                            for (var i = 0; i < newValue.length; i++) {
                                var entity = newValue[i];
                                if (entity && entity.id) {
                                    AddTop(entity.id);
                                } else if (entity) {
                                    AddTop(entity);
                                }
                            }
                            $scope.selectedItems = $scope.model[$scope.options.key].map(function (item) {
                                return item.id || item;
                            });
                            if (
                                $scope.to.options &&
                                $scope.to.options.length > 0 &&
                                $scope.to.options[0].Name != "Yükleniyor..." &&
                                $scope.selectedItems &&
                                $scope.selectedItems.length > 0
                            ) {
                                $scope.data = getTreeData($scope.to.options, null);
                                $scope.selectedItems = [];
                            }
                            $timeout(function () {
                                $scope.refresh = false;
                            }, 300);
                        }
                    });
                    $scope.$watch("to.options", function (newValue, oldValue) {
                        if ($scope.to.options && $scope.to.options.length > 0) {
                            $scope.refresh = true;
                            $scope.data = getTreeData($scope.to.options, null);
                            $timeout(function () {
                                $scope.refresh = false;
                            }, 300);
                        }
                    });

                    function getTreeData(options, topProp) {
                        var data = $scope.to.topProp ? options.filter((obj) => obj[$scope.to.topProp] === topProp) : options;
                        if (data.length <= 0) {
                            return [];
                        }
                        return data.map((obj) => {
                            var rObj = {};
                            rObj.id = obj[$scope.to.valueProp];
                            rObj.name = obj[$scope.to.labelProp];
                            if ($scope.selectedItems && $scope.selectedItems.length > 0) {
                                var selectedItem = $scope.selectedItems.find(function (item) {
                                    return item == obj[$scope.to.valueProp];
                                });
                                if (selectedItem) {
                                    rObj.selected = true;
                                }
                            } else if ($scope.model[$scope.options.key]) {
                                var selectedItem = $scope.model[$scope.options.key].find(function (item) {
                                    return item == obj[$scope.to.valueProp];
                                });
                                if (selectedItem) {
                                    rObj.selected = true;
                                }
                            }
                            rObj.children = $scope.to.topProp ? getTreeData(options, obj.Id) : [];
                            return rObj;
                        });
                    }

                    function AddTop(Id) {
                        var category = $scope.to.options.find(function (item) {
                            return item.Id == Id;
                        });
                        if (category && category.TopCategoryId) {
                            $scope.model[$scope.options.key].push({
                                id: category.TopCategoryId,
                            });
                            AddTop(category.TopCategoryId);
                        }
                    }
                },
            ],
        });

        formlyConfigProvider.setType({
            name: "floatTreeSelect",
            defaultOptions: {
                templateOptions: {
                    valueProp: "Id",
                    labelProp: "Name",
                },
            },
            wrapper: ["floatBootstrapLabel", "floatBootstrapHasError"],
            template: `<multi-select-tree data-input-model="data" multi-select="false"
                        data-output-model="model[options.key]" data-default-label="{{to.placeholder}}"
                        data-select-only-leafs="false"></multi-select-tree>`,

            controller: [
                "$scope",
                "$timeout",
                function ($scope, $timeout) {
                    $scope.$watch("to.options", function (newValue, oldValue) {
                        if ($scope.to.options && $scope.to.options.length > 0) {
                            $scope.data = getTreeData($scope.to.options, null);
                        }
                    });

                    function getTreeData(options, topProp) {
                        var data = $scope.to.topProp ? options.filter((obj) => obj[$scope.to.topProp] === topProp) : options;
                        if (data.length <= 0) {
                            return [];
                        }
                        return data.map((obj) => {
                            var rObj = {};
                            rObj.id = obj[$scope.to.valueProp];
                            rObj.name = obj[$scope.to.labelProp];
                            if ($scope.model[$scope.options.key] && $scope.model[$scope.options.key].indexOf(obj.Id) > -1) {
                                rObj.selected = true;
                            }
                            rObj.children = $scope.to.topProp ? getTreeData(options, obj.Id) : [];
                            return rObj;
                        });
                    }
                },
            ],
        });

        formlyConfigProvider.setType({
            name: "float-async-ui-select",
            wrapper: ["floatBootstrapLabel", "floatBootstrapHasError"],
            template: `<ui-select ng-model="model[options.key]" required="{{to.required}}" class="form-control" theme="bootstrap" ng-disabled="to.disabled">
                            <ui-select-match placeholder="{{to.placeholder}}">{{$select.selected[to.labelProp] }}</ui-select-match>
                            <ui-select-choices repeat="option[to.valueProp] as option in to.options"
                                refresh="to.refresh($select.search, options, model)"
                                refresh-delay="1000">
                                <div ng-bind-html="option[to.labelProp] | highlight: $select.search"></div>
                            </ui-select-choices>
                        </ui-select>`,
            defaultOptions: {
                templateOptions: {
                    templateClass: "float-async-ui-select-template",
                    refreshDelay: 2000,
                },
            },
            controller: [
                "$scope",
                function ($scope) {
                    setTimeout(function () {
                        if (
                            $("#" + $scope.id)
                                .closest("fieldset")
                                .prop("disabled")
                        ) {
                            $scope.to.disabled = true;
                        }
                    }, 1000);
                },
            ],
        });

        formlyConfigProvider.setType({
            name: "float-async-ui-select-multiple",
            wrapper: ["floatBootstrapLabel", "floatBootstrapHasError"],
            template: `<ui-select multiple ng-model="model[options.key]" required="{{to.required}}" class="select-multiple-placeholder" search theme="bootstrap" ng-disabled="to.disabled">
                            <ui-select-match placeholder="{{to.placeholder}}">{{$item[to.labelProp] }}</ui-select-match>
                            <ui-select-choices repeat="option[to.valueProp] as option in to.options"
                                refresh="to.refresh($select.search, options, model)"
                                refresh-delay="1000">
                            <div ng-bind-html="option[to.labelProp] | highlight: $select.search"></div>
                            </ui-select-choices>
                        </ui-select>`,
            defaultOptions: {
                templateOptions: {
                    templateClass: "float-async-ui-select-multiple-template",
                },
            },
            controller: [
                "$scope",
                function ($scope) {
                    setTimeout(function () {
                        if (
                            $("#" + $scope.id)
                                .closest("fieldset")
                                .prop("disabled")
                        ) {
                            $scope.to.disabled = true;
                        }
                    }, 1000);
                },
            ],
        });

        formlyConfigProvider.setType({
            name: "floatTinyMCE",
            wrapper: ["floatBootstrapHasError"],
            template: `<div class="row float-tinymce">
                        <div class="col-xs-12">
                            <label for="{{::id}}">
                                <span ng-if="!to.resource">{{to.label}}</span>
                                <span ng-if="to.resource">{{to.resource | translate}}</span>
                                <span style="color:red">{{to.required ? "*" : ""}}</span>
                            </label>
                        </div>
                        <div class="col-xs-12"><textarea ui-tinymce="to.tinymceOptions" ng-model="model[options.key]"></div>
                        </div>`,
            defaultOptions: {
                templateOptions: {
                    tinymceOptions: {
                        image_advtab: true,
                        image_dimensions: false,
                        image_title: true,
                        relative_urls: false,
                        forced_root_block: "",
                        height: "274",
                        extended_valid_elements: "svg[*],g[*],path[*],i[*],span[*]",
                        fontsize_formats: "2px 4px 6px 8px 10px 12px 14px 18px 24px 36px",
                        lineheight_formats: "2px 4px 6px 8px 10px 12px 14px 18px 24px 36px",
                        images_upload_credentials: true,
                        plugins:
                            "image code imagetools advlist autolink lists link image charmap print preview hr anchor pagebreak searchreplace wordcount visualblocks visualchars code fullscreen insertdatetime media nonbreaking save table contextmenu directionality emoticons template paste textcolor colorpicker textpattern imagetools codesample toc lineheight",
                        toolbar1:
                            " styleselect sizeselect fontselect fontsizeselect lineheightselect  | bold italic strikethrough forecolor backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image media codesample | fullscreen preview",
                        content_css: [globalSettings.WebServer + "/themes/" + globalSettings.ThemeName + "/dist/css/site.css"],
                        file_picker_callback: function (cb, value, meta) {
                            this.settings.activateFileManager(cb, value, meta);
                        },
                        setup: function (ed) {
                            if (
                                $("#" + ed.id)
                                    .closest("fieldset")
                                    .prop("disabled")
                            ) {
                                ed.settings.readonly = true;
                            }
                        },
                        templates: [
                            {
                                title: "Resim - Yazı",
                                description: "Adds a timestamp indicating the last time the document modified.",
                                content:
                                    '<div class="row"><div class="col-md-6">Resim buraya</div><div class="col-md-6">Yazı buraya</div></div></br>',
                            },
                            {
                                title: "Yazı - Resim",
                                description: "These values will be replaced when the template is inserted into the editor content.",
                                content:
                                    '<div class="row"><div class="col-md-6">Yazı buraya</div><div class="col-md-6">Resim buraya</div></div></br>',
                            },
                        ],
                    },
                },
            },
            controller: [
                "$scope",
                "$uibModal",
                "Settings",
                function ($scope, $uibModal, Settings) {
                    var $uibModalInstance;
                    $scope.to.tinymceOptions.activateFileManager = function (cb) {
                        $scope.tinycb = cb;

                        $uibModalInstance = $uibModal.open({
                            windowClass: "filemanager-modal",
                            template: '<div class="modal-body" id="modal-body-{{name}}">' + "<angular-filemanager></angular-filemanager>" + "</div>",
                        });

                        function pickItem(evt) {
                            var item = evt.detail.item;
                            var fileName = Settings.MediaServer + "/" + item.path.join("/") + "/" + item.name;
                            if (fileName.includes(".svg")) {
                                $.ajax({
                                    url: fileName,
                                    headers: {
                                        "Access-Control-Allow-Origin": Settings.MediaServer,
                                    },
                                    crossDomain: true,
                                    data: null,
                                    success: function (data) {
                                        tinyMCE.activeEditor.insertContent(jQuery(data).find("svg")[0].outerHTML);
                                    },
                                    dataType: "xml",
                                    async: false,
                                });
                            } else {
                                $scope.tinycb(fileName, {
                                    title: item.name,
                                });
                            }
                            $uibModalInstance.dismiss();
                            window.removeEventListener("onPickItem", pickItem);
                        }
                        window.addEventListener("onPickItem", pickItem, false);
                    };
                },
            ],
        });

        formlyConfigProvider.setType({
            name: "floatSingleImage",
            wrapper: ["floatBootstrapHasError"],
            template: `<div class="float-single-media">
                            <img ng-src="{{imgSrc}}" ng-if="imgSrc" style="max-height:40px;max-width:40px;margin:4px;" />
                            <button ng-if="imgSrc" ng-click="cancelImage()" style="position:absolute;top:0;left:48px;background:white;border:1px solid black;height:21px;width:21px;"> X </button>
                            <button ng-if="!imgSrc" type="button" class="btn btn-default" ng-click="selectFile()" translate="form.select-image">Select Image</button>
                            <label for="{{::id}}">
                                <span ng-if="!to.resource">{{to.label}}</span>
                                <span ng-if="to.resource">{{to.resource | translate}}</span>
                                <span style="color:red">{{to.required ? "*" : ""}}</span>
                            </label>
                        </div>`,
            controller: [
                "$scope",
                "$uibModal",
                "Settings",
                function ($scope, $uibModal, Settings) {
                    $scope.$watch("model", function (newValue, oldValue) {
                        if (newValue) {
                            var imgSrc = $scope.getProperty($scope.model, $scope.to.valueModel);
                            if (imgSrc) {
                                $scope.imgSrc = Settings.MediaServer + "/" + imgSrc;
                            }
                        }
                    });

                    var $uibModalInstance;
                    $scope.selectFile = function () {
                        $uibModalInstance = $uibModal.open({
                            windowClass: "filemanager-modal",
                            template: '<div class="modal-body" id="modal-body-{{name}}">' + "<angular-filemanager></angular-filemanager>" + "</div>",
                        });

                        function pickItem(evt) {
                            var item = evt.detail.item;
                            if (!Array.isArray(item)) {
                                var name = globalSettings.MediaServer + "/" + item.path.join("/") + "/" + item.name;
                                $scope.imgSrc = name;
                                $scope.model[$scope.options.key] =
                                    $scope.to.valueKey === "FileName"
                                        ? item.name
                                        : $scope.to.valueKey === "FullPath"
                                        ? "/" + item.path.join("/") + "/" + item.name
                                        : item.content;
                            }
                            $uibModalInstance.dismiss();
                            window.removeEventListener("onPickItem", pickItem);
                        }
                        window.addEventListener("onPickItem", pickItem, false);
                    };

                    $scope.cancelImage = function () {
                        $scope.imgSrc = null;
                        $scope.model[$scope.options.key] = null;
                    };

                    $scope.getProperty = function (object, propertyName) {
                        if (!object) {
                            return "";
                        }
                        if (!propertyName) {
                            return "";
                        }
                        var parts = propertyName.split("."),
                            length = parts.length,
                            i,
                            property = object || this;
                        for (i = 0; i < length; i++) {
                            if (property) {
                                property = property[parts[i]];
                            }
                        }
                        return property;
                    };
                },
            ],
        });

        formlyConfigProvider.setType({
            name: "floatSingleMedia",
            wrapper: ["floatBootstrapHasError"],
            template: `<div class="float-single-media">
                            <button ng-if="mediaName" ng-show="!formState.readOnly" ng-click="cancelImage()" style="background:white;border:1px solid black;"> X </button> <span ng-if="mediaName" id="{{id}}">{{mediaName}}</span> <button ng-if="!imgSrc" type="button" class="btn btn-default" ng-click="selectFile()" translate="form.select-file">Select File</button>
                            <label for="{{::id}}">
                                <span ng-if="!to.resource">{{to.label}}</span>
                                <span ng-if="to.resource">{{to.resource | translate}}</span>
                                <span style="color:red">{{to.required ? "*" : ""}}</span>
                            </label>
                        </div>`,
            controller: [
                "$scope",
                "$uibModal",
                "Settings",
                function ($scope, $uibModal, Settings) {
                    setTimeout(function () {
                        var mediaName = $scope.getProperty($scope.model, $scope.to.valueModel);
                        if (mediaName) {
                            $scope.mediaName = mediaName;
                        }
                        if (
                            $("#" + $scope.id)
                                .closest("fieldset")
                                .prop("disabled")
                        ) {
                            $scope.formState.readOnly = true;
                        }
                    }, 1000);

                    var $uibModalInstance;
                    $scope.selectFile = function () {
                        $uibModalInstance = $uibModal.open({
                            windowClass: "filemanager-modal",
                            template: '<div class="modal-body" id="modal-body-{{name}}">' + "<angular-filemanager></angular-filemanager>" + "</div>",
                        });

                        function pickItem(evt) {
                            var item = evt.detail.item;
                            $scope.mediaName = item.name;
                            $scope.model[$scope.options.key] = item.content;
                            $uibModalInstance.dismiss();
                            window.removeEventListener("onPickItem", pickItem);
                        }
                        window.addEventListener("onPickItem", pickItem, false);
                    };

                    $scope.cancelImage = function () {
                        $scope.mediaName = null;
                        $scope.model[$scope.options.key] = null;
                    };

                    $scope.getProperty = function (object, propertyName) {
                        var parts = propertyName.split("."),
                            length = parts.length,
                            i,
                            property = object || this;

                        for (i = 0; i < length; i++) {
                            property = property ? property[parts[i]] : "";
                        }

                        return property;
                    };
                },
            ],
        });
    }
})();
