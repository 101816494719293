(function () {
    'use strict';

    angular
        .module('app.blogPost')
        .controller('BlogPostListController', BlogPostListController);

    BlogPostListController.$inject = ['$scope', '$location', 'BlogPostService','NgTableParams', 'Settings', 'CulturesService', '$state'];

    function BlogPostListController($scope, $location, BlogPostService, NgTableParams, Settings, CulturesService, $state) {
        var vm = this;
        vm.labels = ['label-success', 'label-warning', 'label-info', 'label-primary', 'label-danger', 'label-default'];
        activate();

        function activate() {
            vm.refresh = function () {
                vm.loading = true;
                vm.tableParams.reload();
                vm.loading = false;
            };

            vm.settings = Settings;

            vm.getFilterParams = function (name, type, placeholder) {
                var json = '{"' + name + '": { "id": "' + type + '", "placeholder": "' + placeholder + '" }}';
                return JSON.parse(json);
            };

            vm.firstLoad = true;
            vm.tableParams = new NgTableParams({}, { dataset: [] });
            vm.tableParams = new NgTableParams(
                angular.extend({ page: 1, count: 18, sorting: { Name: "asc" } }, {}),
                {
                    getData: function (params) {
                        if (!vm.firstLoad) {
                            $location.search(params.url());
                        }
                        vm.firstLoad = false;
                        var filterData2 = params.filter();
                        var filterData = {};
                        filterData.PageIndex = params.page();
                        filterData.PageSize = params.count();
                        filterData.SortProperty = Object.keys(params.sorting())[0];
                        filterData.SortType = params.sorting()[Object.keys(params.sorting())[0]];
                        var keys = Object.keys(filterData2);
                        for (var i = 0; i < keys.length; i++) {
                            filterData[keys[i]] = filterData2[keys[i]];
                        }
                        return BlogPostService.getPosts(filterData).then(function (data) {
                            params.total(data.FilteredCount);
                            return data.Data;
                        });
                    }
                });
        }

        vm.getAllPostMedia = function (PostId) {
            for (var row of vm.tableParams.data) {
                if (row.Id !== PostId) {
                    row.openPopover = false;
                }
            }
            vm.imageLoading = true;
            vm.selectedPostImages = null;
            BlogPostService.getAllPostMedia(ProductId)
                .then(function (data) {
                    vm.selectedPostImages = data;
                    vm.imageLoading = false;
                });
        };
    }
})();