(function () {
    'use strict';

    angular
        .module('app.production')
        .factory('TierPricesService', Service);

    Service.$inject = ['$http', '$q', 'ngAuthSettings', 'logger', 'config', 'Settings', 'RolesService'];

    function Service($http, $q, ngAuthSettings, logger, config, Settings, RolesService) {
        var service = {
            getAll: getAll,
            get: get,
            add: add,
            edit: edit,
            del: del,
            getFields: getFields
        };
        var baseUrl = ngAuthSettings.apiServiceBaseUri;

        var serviceBaseUrl = baseUrl + 'api/TierPrices/';

        var fields = [
            {
                key: 'Title',
                type: 'horizontalInput',
                templateOptions: {
                    required: true,
                    label: 'Başlık',
                    resource: 'form.title',
                    placeholder: 'Başlık '
                }
            },
            {
                key: 'Quantity',
                type: 'horizontalInput',
                templateOptions: {
                    required: true,
                    label: 'Miktar',
                    resource: 'form.quantity',
                    placeholder: 'Miktar ',
                    numberMask: 2
                }
            },
            {
                key: 'Price',
                type: 'horizontalInput',
                templateOptions: {
                    required: true,
                    label: 'Fiyat',
                    resource: 'form.price',
                    placeholder: 'Fiyat ',
                    numberMask: 2
                }
            },
            {
                key: 'RoleId',
                type: 'horizontalSelect',
                templateOptions: {
                    label: 'Rol',
                    resource: 'form.role',
                    options: [{ Name: '...' }]
                },
                controller: ['$scope', function ($scope) {
                    RolesService.getAll().then(function (response) {
                        $scope.to.options = response.Data;
                    });
                }]
            },
            {
                key: 'StartDate',
                type: 'horizontalDatepicker',
                templateOptions: {
                    label: 'Başlangıç Tarihi',
                    resource: 'form.start-date',
                    placeholder: 'Başlangıç Tarihi'
                }
            },
            {
                key: 'EndDate',
                type: 'horizontalDatepicker',
                templateOptions: {
                    label: 'Bitiş Tarihi',
                    resource: 'form.end-date',
                    placeholder: 'Bitiş Tarihi'
                }
            }
        ];

        return service;

        /////////////////////////////////////////

        function getFields() {
            return fields;
        }

        function getAll(filterParams) {
            var deferred = $q.defer();
            $http.get(serviceBaseUrl, {
                params: filterParams
            })
                .then(getComplete)
                .catch(getFailed);

            function getComplete(response) {
                deferred.resolve(response.data);
            }

            function getFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function get(Id) {
            var deferred = $q.defer();

            $http.get(serviceBaseUrl + Id)
                .then(getComplete)
                .catch(getFailed);

            function getComplete(response) {
                deferred.resolve(response.data);
            }

            function getFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function add(entity) {
            var deferred = $q.defer();
            $http.post(serviceBaseUrl, entity)
                .then(editComplete)
                .catch(editFailed);

            function editComplete(response) {
                deferred.resolve(response.data);
            }

            function editFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function edit(entity) {
            var deferred = $q.defer();

            $http.put(serviceBaseUrl + entity.Id, entity)
                .then(editComplete)
                .catch(editFailed);

            function editComplete(response) {
                deferred.resolve(response.data);
            }

            function editFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function del(entity) {
            var deferred = $q.defer();

            $http.delete(serviceBaseUrl + entity.Id, entity)
                .then(editComplete)
                .catch(editFailed);

            function editComplete(response) {
                deferred.resolve(response.data);
            }

            function editFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }
    }
})();