(function () {
    'use strict';

    angular.module('app.hbIntegration')
        .run(appRun);

    appRun.$inject = ['routerHelper'];

    function appRun(routerHelper) {
        routerHelper.configureStates(getStates());
    }

    function getStates() {
        return [
            {
                state: 'hbIntegration',
                config: {
                    url: '/hbIntegration',
                    title: 'Hepsiburada Entegrasyonu',
                    abstract: true,
                    templateUrl: 'app/hbIntegration/hbIntegration.html',
                    settings: {
                        name: 'integration',
                        nav: 200,
                        icon: 'fa fa-cogs',
                        resource: 'menu.integration',
                        content: 'Entegrasyonlar'
                    }
                }
            },
            {
                state: 'hbIntegration.ProductList',
                config: {
                    templateUrl: 'app/hbIntegration/hbIntegration.ProductList.html',
                    controller: 'ProductListController',
                    controllerAs: 'vm',
                    url: '/ProductList',
                    title: 'Ürün Listesi',
                    settings: {
                        nav: 10,
                        name: 'hbIntegration.Productlist',
                        content: 'Ürün Listesi',
                        resource: 'menu.hbIntegration.ProductList'
                    }
                }
            },
            {
                state: 'hbIntegration.hbmatchcategorylist',
                config: {
                    templateUrl: 'app/hbIntegration/hbIntegration.hbmatchcategorylist.html',
                    controller: 'HBMatchCategoryListController',
                    controllerAs: 'vm',
                    url: '/hbmatchcategorylist',
                    title: 'Eşleşmiş Kategori Listesi',
                    settings: {
                        nav: 10,
                        name: 'hbIntegration.hbmatchcategorylist',
                        content: 'Eşleşmiş Kategori Listesi',
                        resource: 'menu.hbIntegration.hbmatchcategorylist'
                    }
                }
            },
            {
                state: 'hbIntegration.hbaddmatchcategory',
                config: {
                    templateUrl: 'app/hbIntegration/hbIntegration.hbaddmatchcategory.html',
                    controller: 'HBAddMatchCategoryController',
                    controllerAs: 'vm',
                    url: '/hbaddmatchcategory',
                    title: 'Kategori Eşleştir',
                    settings: {
                        nav: 10,
                        name: 'hbIntegration.hbaddmatchcategory',
                        content: 'Kategori Eşleştir',
                        resource: 'menu.hbIntegration.hbaddmatchcategory'
                    }
                }
            },
            {
                state: 'hbIntegration.hbeditmatchcategory',
                config: {
                    templateUrl: 'app/hbIntegration/hbIntegration.hbeditmatchcategory.html',
                    controller: 'HBEditMatchCategoryController',
                    controllerAs: 'vm',
                    url: '/hbeditmatchcategory/:Id',
                    title: 'Eşleşmiş Kategori Düzenle',
                    settings: {
                        nav: 10,
                        name: 'hbIntegration.hbeditmatchcategory',
                        content: 'Eşleşmiş Kategori Düzenle',
                        resource: 'menu.hbIntegration.hbeditmatchcategory'
                    }
                }
            },
            {
                state: 'hbIntegration.hbeditmatchcategoryfilter',
                config: {
                    templateUrl: 'app/hbIntegration/hbIntegration.hbeditmatchcategoryfilter.html',
                    controller: 'HBEditMatchCategoryFilterController',
                    controllerAs: 'vm',
                    url: '/hbeditmatchcategoryfilter/:Id',
                    title: 'Eşleşmiş Kategori Filtre Düzenle',
                    settings: {
                        nav: 10,
                        name: 'hbIntegration.hbeditmatchcategoryfilter',
                        content: 'Eşleşmiş Kategori Filtre Düzenle',
                        resource: 'menu.hbIntegration.hbeditmatchcategoryfilter'
                    }
                }
            },
            {
                state: 'hbIntegration.hbeditmatchcategoryfiltervalue',
                config: {
                    templateUrl: 'app/hbIntegration/hbIntegration.hbeditmatchcategoryfiltervalue.html',
                    controller: 'HBEditMatchCategoryFilterValueController',
                    controllerAs: 'vm',
                    url: '/hbeditmatchcategoryfiltervalue/:Id',
                    title: 'Eşleşmiş Kategori Filtre Değeri Düzenle',
                    settings: {
                        nav: 10,
                        name: 'hbIntegration.hbeditmatchcategoryfiltervalue',
                        content: 'Eşleşmiş Kategori Filtre Değeri Düzenle',
                        resource: 'menu.hbIntegration.hbeditmatchcategoryfiltervalue'
                    }
                }
            },
            {
                state: 'hbIntegration.hbmatchcategoryfiltervaluelist',
                config: {
                    templateUrl: 'app/hbIntegration/hbIntegration.hbmatchcategoryfiltervaluelist.html',
                    controller: 'HBMatchCategoryFilterValueListController',
                    controllerAs: 'vm',
                    url: '/hbmatchcategoryfiltervaluelist/:Id',
                    title: 'Eşleşmiş Kategori Filtre Değeri Listesi',
                    settings: {
                        nav: 10,
                        name: 'hbIntegration.hbmatchcategoryfiltervaluelist',
                        content: 'Eşleşmiş Kategori Filtre Değeri Listesi',
                        resource: 'menu.hbIntegration.hbmatchcategoryfiltervaluelist'
                    }
                }
            },
            {
                state: 'hbIntegration.hbmatchcategoryfilterlist',
                config: {
                    templateUrl: 'app/hbIntegration/hbIntegration.hbmatchcategoryfilterlist.html',
                    controller: 'HBMatchCategoryFilterListController',
                    controllerAs: 'vm',
                    url: '/hbmatchcategoryfilterlist/:Id',
                    title: 'Eşleşmiş Kategori Filtre Listesi',
                    settings: {
                        nav: 10,
                        name: 'hbIntegration.hbmatchcategoryfilterlist',
                        content: 'Eşleşmiş Kategori Filtre Listesi',
                        resource: 'menu.hbIntegration.hbmatchcategoryfilterlist'
                    }
                }
            }
        ];
    }
})();