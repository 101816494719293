(function() {
    "use strict";

    angular.module("app.collections").factory("CollectionsService", CollectionsService);

    CollectionsService.$inject = [
        "$http",
        "$q",
        "Settings",
        "ngAuthSettings",
        "logger",
        "config",
        "ProductsGeneralService",
        "MediaService"
    ];

    function CollectionsService($http, $q, Settings, ngAuthSettings, logger, config, ProductsGeneralService, MediaService) {
        var service = {
            getCollections: getCollections,
            getCollection: getCollection,
            addCollection: addCollection,
            editCollection: editCollection,
            deleteCollection: deleteCollection,
            getCollectionFields: getCollectionFields
        };

        var serviceBase = ngAuthSettings.apiServiceBaseUri + "api/collections/";

        var collectionFields = [
            {
                key: "TopCollectionId",
                type: "horizontalSelect",
                templateOptions: {
                    label: "Üst Koleksiyon",
                    resource: "form.top-collection",
                    options: [{ Name: "Yükleniyor..." }]
                },
                controller: [
                    "$scope",
                    function($scope) {
                        getCollections({ PageSize: -1 }).then(function(response) {
                            var opt = [{ Name: "" }];
                            $scope.to.options = opt.concat(response.Data);
                        });
                    }
                ]
            },
            {
                key: "Name",
                type: "horizontalInput",
                templateOptions: {
                    required: true,
                    type: "text",
                    label: "Adı",
                    resource: "form.name",
                    placeholder: "Adı"
                }
            },
            {
                key: "Description",
                type: "horizontalTinyMCE",
                templateOptions: {
                    label: "Açıklama",
                    resource: "form.description",
                    tinymceOptions: {
                        plugins:
                            "image code imagetools advlist autolink lists link image charmap print preview hr anchor pagebreak searchreplace wordcount visualblocks visualchars code fullscreen insertdatetime media nonbreaking save table contextmenu directionality emoticons template paste textcolor colorpicker textpattern imagetools codesample toc lineheight",
                        toolbar1:
                            "undo redo | insert | styleselect | bold italic | sizeselect | bold italic | fontselect |  fontsizeselect lineheightselect | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image",
                        toolbar2: "print preview media | forecolor backcolor emoticons | codesample",
                        image_advtab: true,
                        image_dimensions: false,
                        templates: [
                            { title: "Test template 1", content: "Test 1" },
                            { title: "Test template 2", content: "Test 2" }
                        ],
                        content_css: [
                            Settings.WebServer + "/themes/" + Settings.ThemeName + "/dist/css/mazakacore-ecommerce.css"
                        ],
                        image_title: true,

                        //automatic_uploads: true,
                        //images_upload_url: '/api/Media',
                        file_picker_types: "image",
                        relative_urls: false,
                        forced_root_block: "",
                        height: "400",
                        fontsize_formats: "2px 4px 6px 8px 10px 12px 14px 18px 24px 36px",
                        lineheight_formats: "2px 4px 6px 8px 10px 12px 14px 18px 24px 36px",
                        images_upload_credentials: true,
                        entity_encoding: "raw",
                        file_picker_callback: function(cb, value, meta) {
                            var input = document.createElement("input");
                            input.setAttribute("type", "file");
                            input.setAttribute("accept", "image/*");
                            input.onchange = function() {
                                var file = this.files[0];
                                var id = "blobid" + new Date().getTime();
                                var blobCache = tinymce.activeEditor.editorUpload.blobCache;
                                var blobInfo = blobCache.create(id, file);
                                blobCache.add(blobInfo);
                                cb(blobInfo.blobUri(), { title: file.name });
                            };
                            input.click();
                        },
                        images_upload_handler: function(blobInfo, success, failure) {
                            var formData = new FormData();
                            formData.append("File", blobInfo.blob(), blobInfo.filename());
                            MediaService.add(formData).then(function(data) {
                                var name = Settings.MediaServer + "/" + data.FileName;
                                success(name);
                            }, failure);
                        },
                        setup: function(ed) {
                            if (
                                $("#" + ed.id)
                                    .closest("fieldset")
                                    .prop("disabled")
                            ) {
                                ed.settings.readonly = true;
                            }
                        }
                    }
                }
            },
            {
                key: "BackgroundImageId",
                type: "horizontalSingleImage",
                templateOptions: {
                    label: "Arkaplan Resmi",
                    valueKey: "Id",
                    resource: "form.background-image",
                    valueModel: "BackgroundImage.FileName"
                }
            },
            {
                key: "MainImageId",
                type: "horizontalSingleImage",
                templateOptions: {
                    label: "Ana Resim",
                    resource: "form.main-image",
                    valueKey: "Id",
                    valueModel: "MainImage.FileName"
                }
            },
            {
                key: "DisplayOrder",
                type: "horizontalInput",
                templateOptions: {
                    type: "number",
                    resource: "form.display-order",
                    label: "Sıra",
                    placeholder: "Sıra"
                }
            },
            {
                key: "Active",
                type: "horizontalCheckbox",
                templateOptions: {
                    label: "Aktif",
                    resource: "form.active",
                    placeholder: "Aktif"
                }
            },
            {
                key: "MetaKeyword",
                type: "horizontalInput",
                templateOptions: {
                    type: "text",
                    resource: "form.meta-keyword",
                    label: "Seo keywords"
                }
            },
            {
                key: "MetaDescription",
                type: "horizontalTextarea",
                templateOptions: {
                    label: "Seo description",
                    resource: "form.meta-description",
                    maxlength: 160
                }
            }
        ];

        return service;

        /////////////////////////////////////////

        function getCollectionFields() {
            return collectionFields;
        }

        function getCollections(filterParams) {
            var deferred = $q.defer();

            $http
                .get(serviceBase, {
                    params: filterParams
                })
                .then(getComplete)
                .catch(getFailed);

            function getComplete(response) {
                deferred.resolve(response.data);
            }

            function getFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function getCollection(Id) {
            var deferred = $q.defer();

            $http
                .get(serviceBase + Id)
                .then(getProductComplete)
                .catch(getProductFailed);

            function getProductComplete(response) {
                deferred.resolve(response.data);
            }

            function getProductFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function addCollection(collection) {
            var deferred = $q.defer();

            $http
                .post(serviceBase, collection)
                .then(addComplete)
                .catch(addFailed);

            function addComplete(response) {
                deferred.resolve(response.data);
            }

            function addFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function editCollection(collection) {
            var deferred = $q.defer();

            $http
                .put(serviceBase + collection.Id, collection)
                .then(editComplete)
                .catch(editFailed);

            function editComplete(response) {
                deferred.resolve(response.data);
            }

            function editFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function deleteCollection(collection) {
            var deferred = $q.defer();

            $http
                .delete(serviceBase + collection.Id, collection)
                .then(deleteComplete)
                .catch(deleteFailed);

            function deleteComplete(response) {
                deferred.resolve(response.data);
            }

            function deleteFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }
    }
})();
