(function () {
    'use strict';

    angular
        .module('app.getProducts')
        .run(appRun);

    appRun.$inject = ['routerHelper'];

    function appRun(routerHelper) {
        routerHelper.configureStates(getStates());
    }

    function getStates() {
        return [
            {
                state: 'getProducts',
                config: {
                    url: '/getProducts',
                    title: 'Ürün Çekme',
                    abstract: true,
                    templateUrl: 'app/getProducts/getProducts.html',
                    settings: {
                        nav: 3,
                        name: 'Ürün çekme',
                        icon: 'fa fa-import',
                        content: 'getproducts',
                        resource: 'menu.getproducts'
                    }
                }
            },
            {
                state: 'getProducts.Marketplace',
                config: {
                    templateUrl: 'app/getProducts/getProducts.MarketPlace.html',
                    controller: 'MarketPlaceController',
                    controllerAs: 'vm',
                    url: '/Marketplace',
                    title: 'Ürün Çekme',
                    settings: {
                        name: 'getProducts.MarketPlace',
                        content: 'Get Products',
                        breadcrumbIcon: 'local_mall',
                        breadcrumb: 'Get Products',
                        resource: 'menu.getProducts.MarketPlace'
                    }
                }
            },
            {
                state: 'getProducts.Erp',
                config: {
                    templateUrl: 'app/getProducts/getProducts.Erp.html',
                    controller: 'ErpController',
                    controllerAs: 'vm',
                    url: '/Erp',
                    title: 'Ürün Çekme',
                    settings: {
                        name: 'getProducts.Erp',
                        content: 'Get Products',
                        breadcrumbIcon: 'local_mall',
                        breadcrumb: 'Get Products',
                        resource: 'menu.getProducts.Erp'
                    }
                }
            },
            {
                state: 'getProducts.AddXmlServices',
                config: {
                    templateUrl: 'app/getProducts/getProducts.AddXmlServices.html',
                    controller: 'AddXmlServicesController',
                    controllerAs: 'vm',
                    url: '/AddXmlServices',
                    title: 'Ürün Çekme',
                    settings: {
                        name: 'getProducts.AddXmlServices',
                        content: 'Get Products',
                        breadcrumbIcon: 'local_mall',
                        breadcrumb: 'Get Products',
                        resource: 'menu.getProducts.AddXmlServices'
                    }
                }
            },
            {
                state: 'getProducts.SupplyList',
                config: {
                    templateUrl: 'app/getProducts/getProducts.SupplyList.html',
                    controller: 'SupplyListController',
                    controllerAs: 'vm',
                    url: '/SupplyList',
                    title: 'Ürün Çekme',
                    settings: {
                        name: 'getProducts.SupplyList',
                        content: 'Get Products',
                        breadcrumbIcon: 'local_mall',
                        breadcrumb: 'Get Products',
                        resource: 'menu.getProducts.SupplyList'
                    }
                }
            },
            {
                state: 'getProducts.XmlProductUpdate',
                config: {
                    templateUrl: 'app/getProducts/getProducts.XmlProductUpdate.html',
                    controller: 'XmlProductController',
                    controllerAs: 'vm',
                    url: '/XmlProductUpdate/:Id',
                    title: 'Ürün Çekme',
                    settings: {
                        name: 'getProducts.XmlProductUpdate',
                        content: 'Get Products',
                        breadcrumbIcon: 'local_mall',
                        breadcrumb: 'Get Products',
                        resource: 'menu.getProducts.XmlProductUpdate'
                    }
                }
            }
        ];
    }
})();