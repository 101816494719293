(function () {
    'use strict';

    angular.module('app.option', [
        // Angular modules
        'ngRoute'

        // Custom modules

        // 3rd Party Modules

    ]);
})();