(function () {
    'use strict';

    angular
        .module('app.excel')
        .controller('ExcelCategoryExportController', ExcelCategoryExportController);

    ExcelCategoryExportController.$inject = ['$scope', '$stateParams', '$filter', '$location', 'logger', 'ExcelService'];

    function ExcelCategoryExportController($scope, $stateParams, $filter, $location, logger, ExcelService) {
        var vm = this;

        activate();

        function activate() {
            vm.excelCategoryExport = {};
        }

        vm.excelCategoryExportFields = ExcelService.getExcelCategoryExportFields();
        vm.excelCategoryFilterFields = ExcelService.getExcelCategoryFilterFields();


        vm.exportCategoryExcel = function () {
            console.log("vm.excelCategoryExport", vm.excelCategoryExport);
            vm.loading = true;
            ExcelService.exportCategoryFile(vm.excelCategoryExport).then(function (result, a, b, c) {
                console.log('excel result', result, a, b, c);
                vm.loading = false;
            });
        };

        vm.exportCategory = function () {
            console.log("vm.excelCategoryExport", vm.excelCategoryExport);
            vm.loading = true;
            setTimeout(function () {
                $("#exportForm").submit();
            }, 1000);
            vm.loading = false;
        };

    }
})();

