(function () {
    'use strict';

    angular
        .module('app.designTemplate')
        .run(appRun);

    appRun.$inject = ['routerHelper'];

    function appRun(routerHelper) {
        routerHelper.configureStates(getStates(), "/");
    }

    function getStates() {
        return [
            {
                state: 'designTemplate',
                config: {
                    url: '/designTemplate',
                    templateUrl: 'app/designTemplate/designTemplate.html',
                    controller: 'DesignTemplateController',
                    controllerAs: 'vm',
                    title: 'Tasarımlar',
                    settings: {
                        //nav: 103,
                        //topName: 'products',
                        //name: 'designTemplate',
                        //content: 'Tasarımlar',
                        //breadcrumb: 'Tasarımlar',
                        //breadcrumbIcon: 'location_city'
                    }
                }
            }
        ];
    }
})();