(function () {
	"use strict";

	angular
		.module("app.orders")
		.controller("OrdersListController", OrdersListController);

	OrdersListController.$inject = [
		"$scope",
		"$location",
		"$stateParams",
		"OrdersService",
		"NgTableParams",
		"SharedData",
		"$uibModal",
		"InvoiceService",
		"SettingsService",
		"EInvoiceSettingService"
	];

    function OrdersListController(
        $scope,
        $location,
        $stateParams,
        OrdersService,
        NgTableParams,
        SharedData,
        $uibModal,
        InvoiceService,
        SettingsService,
        EInvoiceSettingService
    ) {
        var vm = this;
        vm.SharedData = SharedData;
        vm.defaultProvider = null;
        activate();


        function activate() {
            vm.refresh = function () {
                vm.loading = true;
                vm.tableParams.reload();
                vm.loading = false;

            };

            EInvoiceSettingService.getAllProviders().then(function (data) {
                vm.defaultProvider = data.filter(prov => prov.Default === true);
                vm.defaultProvider = vm.defaultProvider[0];
            });


            vm.firstLoad = true;
            vm.tableParams = new NgTableParams(
                angular.extend(
                    { page: 1, count: 18, sorting: { OrderDate: "desc" } },
                    $location.search()
                ),
                {
                    getData: function (params) {
                        if (!vm.firstLoad) {
                            $location.search(params.url());
                        }
                        vm.firstLoad = false;
                        var filterData2 = params.filter();

                        var filterData = {}; // params.filter();
                        filterData.PageIndex = params.page();
                        filterData.PageSize = params.count();
                        filterData.StatusId = vm.FilterStatus;
                        filterData.SortProperty = Object.keys(
                            params.sorting()
                        )[0];
                        filterData.SortType = params.sorting()[
                            Object.keys(params.sorting())[0]
                        ];

                        //filterData.SelectFields = "Id,Code,OrderDate,Customer.Name,Customer.Surname,OrderDate,Total,TotalDiscount,Status.Name,Description,Bank.Name,OrderProduct,OrderProduct.Product";

                        var keys = Object.keys(filterData2);
                        for (var i = 0; i < keys.length; i++) {
                            filterData[keys[i]] = filterData2[keys[i]];
                        }
                        return OrdersService.getOrders(filterData).then(
                            function (data) {
                                params.total(data.FilteredCount);
                                return data.Data;
                            }
                        );
                    }
                }
            );


            OrdersService.getOrderStatuses()
                .then(function (data) {
                    vm.OrderStatus = data.filter(function (x) {
                        if (x.Id == '1efca84d-a006-40f5-aa8e-e5f278e9b03a' ||
                            x.Id == '59158dcb-c607-487f-9712-898e08858c90' ||
                            x.Id == 'a9752b35-21be-4f12-9ba3-41f0acdd8a04' ||
                            x.Id == '654ef3ff-1fca-431f-821d-c073aea6fa73') {
                            return false
                        } else {
                            return true;
                        }
                    });
                });
        }

        vm.StatusFilter = function (param) {
            vm.FilterStatus = param;
            activate();

        }

        vm.jsonParse = function (objString) {
            return JSON.parse(objString);
        }

        var clicked = false;
        vm.SelectAll = function () {
            if (clicked != true) {
                var nonChecked = vm.tableParams.data.filter(x => x.IsSelected != true);
                for (var i = 0; i < nonChecked.length; i++) {
                    nonChecked[i].IsSelected = true;
                }
                clicked = true;
            }
            else {
                var nonChecked = vm.tableParams.data.filter(x => x.IsSelected == true);
                for (var i = 0; i < nonChecked.length; i++) {
                    nonChecked[i].IsSelected = undefined;
                }
                clicked = false;
            }
        }
        vm.SelectAllFilter = {
            IsSelected: { id: 'ng-table/check.html', placeholder: 'Tümünü Seç' }
        }

        vm.MarketOrderRefresh = function () {
            OrdersService.getMarketOrders().then(
                function (data) {
                    params.total(data.FilteredCount);
                    // return data.Data;
                }
            );
        };

        //vm.getMarketOrders();

        vm.TotalFilter = {
            TotalMin: {
                id: "ng-table/money.html",
                placeholder: "Minimum Ücret"
            },
            TotalMax: {
                id: "ng-table/money.html",
                placeholder: "Maximum Ücret"
            }
        };
        vm.DateFilter = {
            OrderDateMin: {
                id: "ng-table/date.html",
                placeholder: "Minimum Tarih"
            },
            OrderDateMax: {
                id: "ng-table/date.html",
                placeholder: "Maximum Tarih"
            }
        };

        vm.Status = OrdersService.getOrderStatuses().then(function (response) {
            return response.map(function (item) {
                return { id: item.Id, title: item.Name };
            });
        });


        vm.getColor = function (statusId) {
            if (statusId.toUpperCase() === "6FAB9E8B-E3EF-4336-8F98-BBA4607193D1") {
                return "5cb8a0";
            }
            else if (statusId.toUpperCase() === "02F5383E-753E-4F0C-945A-D6FBBEFFBF56") {
                return "000";
            } else if (statusId.toUpperCase() === "1EFCA84D-A006-40F5-AA8E-E5F278E9B03A") {
                return "ff6b6b";
            }
            else if (statusId.toUpperCase() === "02F5383E-753E-4F0C-945A-D6FBBEFFBF56") {
                return "efbb29";
            }
            else if (statusId.toUpperCase() === "DCC9D370-5182-47A7-B0C3-9C65A745D712") {
                return "0c98e3";
            }
            else if (statusId.toUpperCase() === "9537CD7E-8665-4533-8247-80B1DA78CB06") {
                return "fcd316";
            }
            else if (statusId.toUpperCase() === "29BE5037-642A-4854-AE7D-772D0BE8367E") {
                return "757472";
            }
            else if (statusId.toUpperCase() === "654EF3FF-1FCA-431F-821D-C073AEA6FA73") {
                return "c12b1c";
            } else {
                return "5cb8a0";
            }
        }

        vm.findInvoiceId = function (orderData) {
            var orderProductData = orderData.OrderProduct;
            const result = Object.values(orderProductData).every((value) => value.InvoiceId != null);
            return result;
        }
        vm.CreateCargoNumber = function (data) {
            OrdersService.CreateCargoNumber(data)
                .then(function (response) {
                    window.location.reload();

                    vm.loading = false;
                })
                .catch(function () {
                    vm.loading = false;
                });
        }

        vm.getEInvoice = function (orderData) {

            var findInvoice = vm.findInvoiceId(orderData);

            vm.modalTitle = "Fatura Görüntüleme";
            $uibModal.open({
                templateUrl: 'ModalContent.html',
                controller: 'IncomeModalOrderController',
                controllerAs: 'vm',
                windowClass: 'app-modal-window',
                resolve: {
                    items: function () {
                        return {
                            data: orderData.OrderProduct[0].InvoiceId
                        };
                    }
                }
            }).then(function () {
                vm.RefreshInvoice();
            });
        }

        vm.createShipmentCodes = function () {
            var selectedData = vm.tableParams.data.filter(x => x.IsSelected == true && x.ShipmentCode == null);
            OrdersService.CreateCargoNumberSelected(selectedData)
                .then(function (response) {
                    window.location.reload();
                    vm.loading = false;
                })
                .catch(function () {
                    vm.loading = false;
                });

        }

        vm.createInvoices = function () {
            var selectedData = vm.tableParams.data.filter(x => x.IsSelected == true);
            InvoiceService.sendSelectedEInvoice(selectedData)
                .then(function (response) {
                    window.location.reload();
                    vm.loading = false;
                })
                .catch(function () {
                    vm.loading = false;
                });

        }

        vm.showOrder = function (order) {
            vm.openModal(order)
        }

        vm.modalTitle = "Sipariş Özeti";
        vm.modalInstance = null;
        vm.openModal = function (data) {
            vm.modalInstance = $uibModal.open({
                templateUrl: 'ModalOrderContent.html',
                controller: 'ModalOrderController',
                controllerAs: 'vm',
                resolve: {
                    items: function () {
                        return {
                            data: data
                        };
                    }
                }
            });
        }

        vm.changeStatus = function (StatusId) {
            if (StatusId && StatusId !== vm.order.StatusId) {
                vm.loading = true;
                OrdersService.changeStatus(vm.order.Id, StatusId)
                    .then(function () {
                        OrdersService.getOrder($stateParams.OrderId)
                            .then(function (data) {
                                vm.order = data;
                                vm.customer = vm.order.Customer;
                                vm.calculateTotal();
                                vm.loading = false;
                            })
                            .catch(function (response) {
                                alert(response.data.message);
                                vm.loading = false;
                            });
                    })
                    .catch(function (response) {
                        alert(response.data.message);
                        vm.loading = false;
                    });
            }
        };

        vm.changeStatusRow = function (StatusId, OrderId, Customer) {
            vm.loading = true;
            OrdersService.changeStatus(OrderId, StatusId)
                .then(function () {
                    OrdersService.getOrder(OrderId)
                        .then(function (data) {
                            vm.order = data;
                            vm.customer = Customer;
                            vm.loading = false;
                        })
                        .catch(function (response) {
                            alert(response.data.message);
                            vm.loading = false;
                        });
                    vm.tableParams.reload();

                })
                .catch(function (response) {
                    alert(response.data.message);
                    vm.loading = false;
                });
        };

        vm.printOrdersCargo = function () {

            var selectedData = vm.tableParams.data.filter(x => x.IsSelected == true);
            var printContents = "";
            for (var i = 0; i < selectedData.length; i++) {
                if (selectedData[i].CargoJobId == null) {
                    selectedData[i].CargoJobId = "";
                }
                if (selectedData[i].CargoReferenceCode == null) {
                    selectedData[i].CargoReferenceCode = selectedData[i].ShipmentCode;
                }
                if (selectedData[i].DeliveryAddress.TcNo != null) {
                    selectedData[i].DeliveryAddress.TcNo = selectedData[i].DeliveryAddress.TcNo.substring(0, 5) + "******";
                }
                printContents = printContents + ` <div id="cargo-print"  style="padding:0px; min-width:148mm;min-height:215mm;">
                                        <table class="table" style="padding:0px; min-width:148mm;min-height:210mm;">
                                            <tr>
                                                <th colspan="6" style="border-top:none;">
                                                    <div>
                                                        <div class="col-xs-6">
                                                            <img class="img-responsive" style= "height:50p"
                                                                 src="${vm.SharedData.Settings.WebServer + '/themes/' + vm.SharedData.Settings.ThemeName + '/images/logo.png'}" />
                                                        </div>
                                                    </div>
                                                </th>
                                            </tr>
                                            <tr>
                                                <td colspan="3" ng-if="vm.SharedData.Settings">
                                                    <h5><b translate="orders.sender-addressInfo">Gönderici Adres Bilgileri</b></h5>
                                                    <address>
                                                        <strong>
                                                            ${vm.SharedData.Settings.CompanyName}
                                                        </strong>
                                                        <p>
                                                            ${vm.SharedData.Settings.CompanyPhone}
                                                        </p>
                                                        <p>
                                                            ${vm.SharedData.Settings.CompanyAddress}
                                                        </p>
                                                        <p>
                                                            ${vm.SharedData.Settings.CompanyDistrict}/${vm.SharedData.Settings.CompanyCity}
                                                        </p>
                                                    </address>
                                                </td>
                                                <td colspan="3" ng-if="vm.order.DeliveryAddressId">
                                                    <h5><b translate="orders.buyer-addressInfo">Alıcı Adres Bilgileri</b></h5>
                                                    <address>
                                                        <strong>
                                                            ${selectedData[i].DeliveryAddress.Title} - ${selectedData[i].DeliveryAddress.User}
                                                            ${(selectedData[i].DeliveryAddress.Name || '') + ' ' + (selectedData[i].DeliveryAddress.Surname || '')}
                                                        </strong>
                                                        <p>
                                                            ${selectedData[i].DeliveryAddress.Phone1}
                                                            ${" / " + selectedData[i].DeliveryAddress.Phone2 ? selectedData[i].DeliveryAddress.Phone2 : ""}
                                                            ${selectedData[i].DeliveryAddress.FaxNumber ? " / " + selectedData[i].DeliveryAddress.FaxNumber : ""}
                                                        </p>
                                                        <p ng-if="selectedData[i].DeliveryAddress.TcNo">
                                                            TcNo:${selectedData[i].DeliveryAddress.TcNo}
                                                        </p>
                                                        <p>
                                                            ${selectedData[i].DeliveryAddress.Description}
                                                            ${selectedData[i].DeliveryAddress.ZipPostalCode}
                                                            ${selectedData[i].DeliveryAddress.ApartmentNo} ${selectedData[i].DeliveryAddress.Street1}
                                                            ${selectedData[i].DeliveryAddress.City}${selectedData[i].DeliveryAddress.CityName}
                                                            ${selectedData[i].DeliveryAddress.District}${selectedData[i].DeliveryAddress.DistrictName}
                                                            ${selectedData[i].DeliveryAddress.StateProvince}${selectedData[i].DeliveryAddress.StateProvinceName}
                                                            ${selectedData[i].DeliveryAddress.Country}${selectedData[i].DeliveryAddress.CountryName}
                                                        </p>
                                                    </address>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="6" style="border-top:none; text-align:center" ng-if="selectedData[i].CargoJobId">
                                         
                                                    <div class="col-xs-12" align="center">
                                                        <img class="img-responsive" style="border-top:none; text-align:center; height:50px;"
                                                             src="https://barcode.tec-it.com/barcode.ashx?code=Code128&modulewidth=fit&data='${selectedData[i].CargoJobId}'" />
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="6" style="border-top:none; text-align:center">
                                                    <div class="col-xs-12" align="center">
                                                        <img class="img-responsive" style="border-top:none; text-align:center;height:100px;"
                                                             src="https://barcode.tec-it.com/barcode.ashx?code=Code128&modulewidth=fit&data='${selectedData[i].CargoReferenceCode}'" />
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="6" style="border-top:none; text-align:center">
                                                    <strong>
                                                        <h2>Barkod No:${selectedData[i].CargoReferenceCode}</h2>
                                                    </strong>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                        </table>
                                    </div>`;
            }

            //var printContents = document.getElementById(divName).innerHTML;
            var popupWin = window.open('', '_blank', '');
            popupWin.document.open();
            var printContent = `<html>
                                    <body onload="window.print()">
                                   ${printContents}
                                    </body>
                                    <link href="/dist/css/mazakacore.css"  rel="stylesheet" />
                                    <script src="/dist/js/mazakacore-lib.js" ></script>
                                    <script src="/dist/js/mazakacore-angular-lib.js" ></script>
                                    <script src="/dist/js/mazakacore-app.js" ></script>
                                    <style>
                                        table {
                                            font-size:10px;
                                            table-layout:auto;
                                        }
                                        .dd {
                                            display:inline-block;
                                            font-size:10px;
                                            font-weight:bold;
                                        }
                                    </style>
                                </html>`;


            popupWin.document.write(printContent);
            popupWin.document.close();
        };
        vm.printOrders = function () {

            var selectedData = vm.tableParams.data.filter(x => x.IsSelected == true);
            var printContents = "";
            for (var i = 0; i < selectedData.length; i++) {
                if (selectedData[i].CargoJobId == null) {
                    selectedData[i].CargoJobId = "";
                }
                if (selectedData[i].CargoReferenceCode == null) {
                    selectedData[i].CargoReferenceCode = selectedData[i].ShipmentCode;
                }
                var tax = "";
                if (selectedData[i].Customer.TaxNumber || selectedData[i].Customer.TaxOffice) {
                    tax = `<p><span class="dd">Vergi Dairesi / Vergi No:</span>
                               <span ng-if=" selectedData[i].Customer.TaxOffice">${selectedData[i].Customer.TaxOffice}</span> /
                               <span ng-if=" selectedData[i].Customer.TaxNumber">${selectedData[i].Customer.TaxNumber}</span>
                           </p>`;
                }
                var transferBank = "";
                if (selectedData[i].TransferEFTBankId) {
                    transferBank = `<span style="color:red">
                                        ${selectedData[i].TransferEFTBank.Name}
                                    </span>`;
                }
                var currency = "";
                if (selectedData[i].SalesOrderCurrency.length != 0) {
                    var curr = "";
                    for (var i = 0; i < selectedData[i].SalesOrderCurrency.length; i++) {
                        curr = curr + `<div class="pull-left" >
                                                               <b>'${curr.Currency.Symbol}</b> - ${formatter.format(selectedData[i].SalesOrderCurrency.Currency.Rate)}</div>`;
                    }
                    currency = `<tr>
                                                    <td colspan="7">
                                                        <p>
                                                            <div class="col-md-12">
                                                                <div class="pull-left"><b>Döviz Kurları:</b></div>
                                                                ${curr}
                                                            </div>
                                                        </p>
                                                    </td>
                                                </tr>`;
                }

                var productList = "";
                for (var j = 0; j < selectedData[i].OrderProduct.length; j++) {
                    productList = productList +
                        `
                                                    <tr>
                                                                <td>${selectedData[i].OrderProduct[j].Code}</td>
                                                                <td>${selectedData[i].OrderProduct[j].Code}</td>
                                                                <td>${selectedData[i].OrderProduct[j].Name}</td>
                                                                <td>
                                                                    <span>
                                                                            ${formatter.format((selectedData[i].OrderProduct[j].UnitPrice) * (selectedData[i].OrderProduct[j].Currency.Rate))}     
                                                                    </span>
                                                                    
                                                                </td>
                                                                <td>${selectedData[i].OrderProduct[j].Quantity}</td>
                                                                <td>
                                                                    %${selectedData[i].OrderProduct[j].Tax}KDV
                                                                </td>
                                                                <td>
                                                                        ${formatter.format(selectedData[i].OrderProduct[j].Quantity * (selectedData[i].OrderProduct[j].UnitPriceWithDiscount || selectedData[i].OrderProduct[j].UnitPrice) * (selectedData[i].OrderProduct[j].TaxIncluded ? 1.00 : ((selectedData[i].OrderProduct[j].Tax / 100) + 1.00)))}

                                                                </td>
                                                            </tr>
                         `;
                }

                printContents = printContents + `<div id="order-print" >
                                                        <table class="table" style="line-height:2px;table-layout:auto;min-width:148mm;min-height:213mm;">
                                                            <tr>
                                                                <th colspan="7" style="border-top:none;">
                                                                    <div>
                                                                        <div class="col-xs-4">
                                                                            <img class="img-responsive" style="max-height:50px;"
                                                                                 src="${vm.SharedData.Settings.WebServer + '/themes/' + vm.SharedData.Settings.ThemeName + '/images/logo.png'}" />
                                                                        </div>
                                                                        <div class="col-xs-4" align="center">
                                                                            <img 
                                                                                 style="border-top:none; text-align:center;max-height:50px;"
                                                                                 src="https://barcode.tec-it.com/barcode.ashx?code=Code128&modulewidth=fit&data='${selectedData[i].CargoJobId}'" />
                                                                        </div>
                                                                        <div class="col-xs-4" align="center">
                                                                            <img
                                                                                 style="border-top:none; text-align:center;max-height:50px;"
                                                                                 src="https://barcode.tec-it.com/barcode.ashx?code=Code128&modulewidth=fit&data='${selectedData[i].CargoReferenceCode}'" />
                                                                           
                                                                        </div>
                                                                    </div>
                                                                </th>
                                                            </tr>
                                                            <tr>
                                                                <td colspan="${selectedData[i].DeliveryAddressId ? 2 : 2}">
                                                                    <p>
                                                                        <b class="dd">
                                                                            <span translate="orders.detail.orderNumber">Sipariş Numarası : </span>
                                                                            : ${selectedData[i].Code}
                                                                        </b>&nbsp;
                                                                        <span style="color:red">${selectedData[i].Bank.Name}</span>
                                                                        ${transferBank}                                                                       
                                                                        &nbsp;
                                                                        ${selectedData[i].OrderDate}
                                                                    </p>
                                                                    <p>Ad Soyad/Ünvan:${selectedData[i].Customer.Name} ${selectedData[i].Customer.Surname}</p>
                                                                    <p>
                                                                        Telefon: ${selectedData[i].DeliveryAddress.Phone1}
                                                                        ${" / " + selectedData[i].DeliveryAddress.Phone2 ? selectedData[i].DeliveryAddress.Phone2 : ""}
                                                                        ${selectedData[i].DeliveryAddress.FaxNumber ? " / " + selectedData[i].DeliveryAddress.FaxNumber : ""}
                                                                    </p>
                                                                   ${tax}
                                                                </td>
                                                                <td colspan="2">
                                                                    <p>
                                                                        <b>Adres Başlığı:</b> ${selectedData[i].DeliveryAddress.Title}
                                                                    </p>
                                                                    <p>
                                                                        Şehir:
                                                                        ${selectedData[i].DeliveryAddress.StateProvince}${selectedData[i].DeliveryAddress.StateProvinceName}
                                                                    </p>
                                                                    <p>
                                                                        Adres: ${selectedData[i].DeliveryAddress.Description}
                                                                        ${selectedData[i].DeliveryAddress.ZipPostalCode}
                                                                        ${selectedData[i].DeliveryAddress.ApartmentNo} ${selectedData[i].DeliveryAddress.Street1}
                                                                        ${selectedData[i].DeliveryAddress.City}${selectedData[i].DeliveryAddress.CityName}
                                                                        ${selectedData[i].DeliveryAddress.District}${selectedData[i].DeliveryAddress.DistrictName}
                                                                        ${selectedData[i].DeliveryAddress.StateProvince}${selectedData[i].DeliveryAddress.StateProvinceName}
                                                                        ${selectedData[i].DeliveryAddress.Country}${selectedData[i].DeliveryAddress.CountryName}
                                                                    </p>
                                                                </td>
                                                                <td colspan="2">
                                                                    <p>
                                                                        <b>Sipariş Notu:</b> ${selectedData[i].Description}
                                                                    </p>
                                                                </td>
                                                            </tr>
                                                           ${currency}
                                                            <tr >
                                                                <th style="width:2%;">#</th>
                                                                <th style="width:10%;" >Ürün Kodu</th>
                                                                <th style="width:30%;" >Ürün Adı</th>
                                                                <th style="width:14%;" >Birim Fiyat</th>
                                                                <th style="width:4%;" >Miktar</th>
                                                                <th style="width:18%;" >Vergi</th>
                                                                <th style="width:22%;" >Toplam Fiyat</th>
                                                            </tr>
                                                            ${productList}
                                                            <tr>
                                                                <td colspan="7">
                                                                </td>
                                                            </tr>
                                                            <style>
                                                                .totalList {
                                                                    font-size: 8px;
                                                                    display: block;
                                                                    float: left;
                                                                    width: 100%;
                                                                }

                                                                    .totalList td {
                                                                        display: block;
                                                                        float: left;
                                                                        width: 20%;
                                                                    }

                                                                        .totalList td div {
                                                                            display: block;
                                                                            float: left;
                                                                            width: 100%;
                                                                        }
                                                            </style>
                                                            <tr>
                                                                <td colspan="7">
                                                                    <table style="line-height:2px;  width:100%; table-layout:auto;">
                                                                        <tr class="totalList">
                                                                            <td>
                                                                                <div>
                                                                                         <b>Ara Toplam:</b>
                                                                                    ${formatter.format(selectedData[i].SubTotal)}
                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                <div>
                                                                                    <b>Vergiler Toplamı:</b>
                                                                                    ${formatter.format(selectedData[i].TotalTax)}
                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                <div>
                                                                                    <b>Kargo Toplamı:</b>
                                                                                    ${formatter.format(selectedData[i].ShippingPrice)}
                                                                                </div>
                                                                              
                                                                            </td>
                                                                            <td>
                                                                                <div>
                                                                                <b>Genel İndirim:</b>
                                                                                ${formatter.format(selectedData[i].GeneralDiscount)}
                                                                            </td>
                                                                            <td>
                                                                                <div>
                                                                                    <b>Genel Toplam:</b>
                                                                                    ${formatter.format(selectedData[i].Total)}
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    </table>
                                                                </td>
                                                            </tr>
                                                            <tr></tr>
                                                            <tr>
                                                                <th colspan="7">
                                                                    <div style="font-size:8px;text-align:center">
                                                                        Mazaka Yazılım E-Ticaret Paketleri Aracılığıyla
                                                                        Hazırlanmıştır.
                                                                    </div>
                                                                </th>
                                                            </tr>
                                                        </table>
                                                    </div>`;
            }
            var popupWin = window.open('', '_blank', '');
            popupWin.document.open();
            var printContent = `<html>
                                    <body onload="window.print()">
                                   ${printContents}
                                    </body>
                                    <link href="/dist/css/mazakacore.css"  rel="stylesheet" />
                                    <script src="/dist/js/mazakacore-lib.js" ></script>
                                    <script src="/dist/js/mazakacore-angular-lib.js" ></script>
                                    <script src="/dist/js/mazakacore-app.js" ></script>
                                    <style>
                                        table {
                                            font-size:10px;
                                            table-layout:auto;
                                        }
                                        .dd {
                                            display:inline-block;
                                            font-size:10px;
                                            font-weight:bold;
                                        }
                                    </style>
                                </html>`;


            popupWin.document.write(printContent);
            popupWin.document.close();
        };

        vm.printOrderShipmentCode = function (selectedData) {
            var printContents = "";

            if (selectedData.CargoJobId == null) {
                selectedData.CargoJobId = "";
            }
            if (selectedData.CargoReferenceCode == null) {
                selectedData.CargoReferenceCode = selectedData.ShipmentCode;
            }
            if (selectedData.DeliveryAddress.TcNo != null) {
                selectedData.DeliveryAddress.TcNo = selectedData.DeliveryAddress.TcNo.substring(0, 5) + "******";
            }
            printContents = printContents + `<div id="cargo-print"  style="padding:0px; min-width:148mm;min-height:215mm;">
                                        <table class="table" style="padding:0px; min-width:148mm;min-height:210mm;">
                                            <tr>
                                                <th colspan="6" style="border-top:none;">
                                                    <div>
                                                        <div class="col-xs-6">
                                                            <img class="img-responsive" style= "height:50p"
                                                                 src="${vm.SharedData.Settings.WebServer + '/themes/' + vm.SharedData.Settings.ThemeName + '/images/logo.png'}" />
                                                        </div>
                                                    </div>
                                                </th>
                                            </tr>
                                            <tr>
                                                <td colspan="3" ng-if="vm.SharedData.Settings">
                                                    <h5><b translate="orders.sender-addressInfo">Gönderici Adres Bilgileri</b></h5>
                                                    <address>
                                                        <strong>
                                                            ${vm.SharedData.Settings.CompanyName}
                                                        </strong>
                                                        <p>
                                                            ${vm.SharedData.Settings.CompanyPhone}
                                                        </p>
                                                        <p>
                                                            ${vm.SharedData.Settings.CompanyAddress}
                                                        </p>
                                                        <p>
                                                            ${vm.SharedData.Settings.CompanyDistrict}/${vm.SharedData.Settings.CompanyCity}
                                                        </p>
                                                    </address>
                                                </td>
                                                <td colspan="3" ng-if="vm.order.DeliveryAddressId">
                                                    <h5><b translate="orders.buyer-addressInfo">Alıcı Adres Bilgileri</b></h5>
                                                    <address>
                                                        <strong>
                                                            ${selectedData.DeliveryAddress.Title} - ${selectedData.DeliveryAddress.User}
                                                            ${(selectedData.DeliveryAddress.Name || '') + ' ' + (selectedData.DeliveryAddress.Surname || '')}
                                                        </strong>
                                                        <p>
                                                            ${selectedData.DeliveryAddress.Phone1}
                                                            ${" / " + selectedData.DeliveryAddress.Phone2 ? selectedData.DeliveryAddress.Phone2 : ""}
                                                            ${selectedData.DeliveryAddress.FaxNumber ? " / " + selectedData.DeliveryAddress.FaxNumber : ""}
                                                        </p>
                                                        <p >
                                                            TcNo:${selectedData.DeliveryAddress.TcNo}
                                                        </p>
                                                        <p>
                                                            ${selectedData.DeliveryAddress.Description}
                                                            ${selectedData.DeliveryAddress.ZipPostalCode}
                                                            ${selectedData.DeliveryAddress.ApartmentNo} ${selectedData.DeliveryAddress.Street1}
                                                            ${selectedData.DeliveryAddress.City}${selectedData.DeliveryAddress.CityName}
                                                            ${selectedData.DeliveryAddress.District}${selectedData.DeliveryAddress.DistrictName}
                                                            ${selectedData.DeliveryAddress.StateProvince}${selectedData.DeliveryAddress.StateProvinceName}
                                                            ${selectedData.DeliveryAddress.Country}${selectedData.DeliveryAddress.CountryName}
                                                        </p>
                                                    </address>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="6" style="border-top:none; text-align:center" ng-if="selectedData[i].CargoJobId">
                                         
                                                    <div class="col-xs-12" align="center">
                                                        <img class="img-responsive" style="border-top:none; text-align:center; height:50px;"
                                                             src="https://barcode.tec-it.com/barcode.ashx?code=Code128&modulewidth=fit&data='${selectedData.CargoJobId}'" />
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="6" style="border-top:none; text-align:center">
                                                    <div class="col-xs-12" align="center">
                                                        <img class="img-responsive" style="border-top:none; text-align:center;height:100px;"
                                                             src="https://barcode.tec-it.com/barcode.ashx?code=Code128&modulewidth=fit&data='${selectedData.CargoReferenceCode}'" />
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="6" style="border-top:none; text-align:center">
                                                    <strong>
                                                        <h2>Barkod No:${selectedData.CargoReferenceCode}</h2>
                                                    </strong>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                        </table>
                                    </div>`;


            //var printContents = document.getElementById(divName).innerHTML;
            var popupWin = window.open('', '_blank', '');
            popupWin.document.open();
            var printContent = `<html>
                                    <body onload="window.print()">
                                   ${printContents}
                                    </body>
                                    <link href="/dist/css/mazakacore.css"  rel="stylesheet" />
                                    <script src="/dist/js/mazakacore-lib.js" ></script>
                                    <script src="/dist/js/mazakacore-angular-lib.js" ></script>
                                    <script src="/dist/js/mazakacore-app.js" ></script>
                                    <style>
                                        table {
                                            font-size:10px;
                                            table-layout:auto;
                                        }
                                        .dd {
                                            display:inline-block;
                                            font-size:10px;
                                            font-weight:bold;
                                        }
                                    </style>
                                </html>`;


            popupWin.document.write(printContent);
            popupWin.document.close();
        };

        var formatter = new Intl.NumberFormat('tr-TR', {
            style: 'currency',
            currency: 'TRY',
        });


        vm.printOrderDetailsold = function (selectedData) {

            var printContents = "";
            if (selectedData.CargoJobId == null) {
                selectedData.CargoJobId = "";
            }
            if (selectedData.CargoReferenceCode == null) {
                selectedData.CargoReferenceCode = selectedData.ShipmentCode;
            }
            var tax = "";
            if (selectedData.Customer.TaxNumber || selectedData.Customer.TaxOffice) {
                tax = `<p><span class="dd">Vergi Dairesi / Vergi No:</span>
                               <span ng-if=" selectedData[i].Customer.TaxOffice">${selectedData.Customer.TaxOffice}</span> /
                               <span ng-if=" selectedData[i].Customer.TaxNumber">${selectedData.Customer.TaxNumber}</span>
                           </p>`;
            }
            var transferBank = "";
            if (selectedData.TransferEFTBankId) {
                transferBank = `<span style="color:red">
                                        ${selectedData.TransferEFTBank.Name}
                                    </span>`;
            }
            var currency = "";
            if (selectedData.SalesOrderCurrency.length != 0) {
                var curr = "";
                for (var i = 0; i < selectedData.SalesOrderCurrency.length; i++) {
                    curr = curr + `<div class="pull-left" >
                                                               <b>'${curr.Currency.Symbol}</b> - ${formatter.format(selectedData.SalesOrderCurrency.Currency.Rate)}</div>`;
                }
                currency = `<tr>
                                                    <td colspan="7">
                                                        <p>
                                                            <div class="col-md-12">
                                                                <div class="pull-left"><b>Döviz Kurları:</b></div>
                                                                ${curr}
                                                            </div>
                                                        </p>
                                                    </td>
                                                </tr>`;
            }

            var productList = "";
            for (var j = 0; j < selectedData.OrderProduct.length; j++) {
                productList = productList +
                    `
                                                    <tr>
                                                                <td>${selectedData.OrderProduct[j].Code}</td>
                                                                <td>${selectedData.OrderProduct[j].Code}</td>
                                                                <td>${selectedData.OrderProduct[j].Name}</td>
                                                                <td>
                                                                    <span>
                                                                            ${formatter.format((selectedData.OrderProduct[j].UnitPrice) * (selectedData.OrderProduct[j].Currency.Rate))}     
                                                                    </span>
                                                                    
                                                                </td>
                                                                <td>${selectedData.OrderProduct[j].Quantity}</td>
                                                                <td>
                                                                    %${selectedData.OrderProduct[j].Tax}KDV
                                                                </td>
                                                                <td>
                                                                        ${formatter.format(selectedData.OrderProduct[j].Quantity * (selectedData.OrderProduct[j].UnitPriceWithDiscount || selectedData.OrderProduct[j].UnitPrice) * (selectedData.OrderProduct[j].TaxIncluded ? 1.00 : ((selectedData.OrderProduct[j].Tax / 100) + 1.00)))}

                                                                </td>
                                                            </tr>
                         `;
            }

            printContents = printContents + `<div id="order-print" >
                                                        <table class="table" style="line-height:2px;table-layout:auto;min-width:148mm;min-height:213mm;">
                                                            <tr>
                                                                <th colspan="7" style="border-top:none;">
                                                                    <div>
                                                                        <div class="col-xs-4">
                                                                            <img class="img-responsive" style="max-height:50px;"
                                                                                 src="${vm.SharedData.Settings.WebServer + '/themes/' + vm.SharedData.Settings.ThemeName + '/images/logo.png'}" />
                                                                        </div>
                                                                        <div class="col-xs-4" align="center">
                                                                            <img 
                                                                                 style="border-top:none; text-align:center;max-height:50px;"
                                                                                 src="https://barcode.tec-it.com/barcode.ashx?code=Code128&modulewidth=fit&data='${selectedData.CargoJobId}'" />
                                                                        </div>
                                                                        <div class="col-xs-4" align="center">
                                                                            <img
                                                                                 style="border-top:none; text-align:center;max-height:50px;"
                                                                                 src="https://barcode.tec-it.com/barcode.ashx?code=Code128&modulewidth=fit&data='${selectedData.CargoReferenceCode}'" />
                                                                           
                                                                        </div>
                                                                    </div>
                                                                </th>
                                                            </tr>
                                                            <tr>
                                                                <td colspan="${selectedData.DeliveryAddressId ? 2 : 2}">
                                                                    <p>
                                                                        <b class="dd">
                                                                            <span translate="orders.detail.orderNumber">Sipariş Numarası : </span>
                                                                            : ${selectedData.Code}
                                                                        </b>&nbsp;
                                                                        <span style="color:red">${selectedData.Bank.Name}</span>
                                                                        ${transferBank}                                                                       
                                                                        &nbsp;
                                                                        ${selectedData.OrderDate}
                                                                    </p>
                                                                    <p>Ad Soyad/Ünvan:${selectedData.Customer.Name} ${selectedData.Customer.Surname}</p>
                                                                    <p>
                                                                        Telefon: ${selectedData.DeliveryAddress.Phone1}
                                                                        ${" / " + selectedData.DeliveryAddress.Phone2 ? selectedData.DeliveryAddress.Phone2 : ""}
                                                                        ${selectedData.DeliveryAddress.FaxNumber ? " / " + selectedData.DeliveryAddress.FaxNumber : ""}
                                                                    </p>
                                                                   ${tax}
                                                                </td>
                                                                <td colspan="2">
                                                                    <p>
                                                                        <b>Adres Başlığı:</b> ${selectedData.DeliveryAddress.Title}
                                                                    </p>
                                                                    <p>
                                                                        Şehir:
                                                                        ${selectedData.DeliveryAddress.StateProvince}${selectedData.DeliveryAddress.StateProvinceName}
                                                                    </p>
                                                                    <p>
                                                                        Adres: ${selectedData.DeliveryAddress.Description}
                                                                        ${selectedData.DeliveryAddress.ZipPostalCode}
                                                                        ${selectedData.DeliveryAddress.ApartmentNo} ${selectedData.DeliveryAddress.Street1}
                                                                        ${selectedData.DeliveryAddress.City}${selectedData.DeliveryAddress.CityName}
                                                                        ${selectedData.DeliveryAddress.District}${selectedData.DeliveryAddress.DistrictName}
                                                                        ${selectedData.DeliveryAddress.StateProvince}${selectedData.DeliveryAddress.StateProvinceName}
                                                                        ${selectedData.DeliveryAddress.Country}${selectedData.DeliveryAddress.CountryName}
                                                                    </p>
                                                                </td>
                                                                <td colspan="2">
                                                                    <p>
                                                                        <b>Sipariş Notu:</b> ${selectedData.Description}
                                                                    </p>
                                                                </td>
                                                            </tr>
                                                           ${currency}
                                                            <tr >
                                                                <th style="width:2%;">#</th>
                                                                <th style="width:10%;" >Ürün Kodu</th>
                                                                <th style="width:30%;" >Ürün Adı</th>
                                                                <th style="width:14%;" >Birim Fiyat</th>
                                                                <th style="width:4%;" >Miktar</th>
                                                                <th style="width:18%;" >Vergi</th>
                                                                <th style="width:22%;" >Toplam Fiyat</th>
                                                            </tr>
                                                            ${productList}
                                                            <tr>
                                                                <td colspan="7">
                                                                </td>
                                                            </tr>
                                                            <style>
                                                                .totalList {
                                                                    font-size: 8px;
                                                                    display: block;
                                                                    float: left;
                                                                    width: 100%;
                                                                }

                                                                    .totalList td {
                                                                        display: block;
                                                                        float: left;
                                                                        width: 20%;
                                                                    }

                                                                        .totalList td div {
                                                                            display: block;
                                                                            float: left;
                                                                            width: 100%;
                                                                        }
                                                            </style>
                                                            <tr>
                                                                <td colspan="7">
                                                                    <table style="line-height:2px;  width:100%; table-layout:auto;">
                                                                        <tr class="totalList">
                                                                            <td>
                                                                                <div>
                                                                                         <b>Ara Toplam:</b>
                                                                                    ${formatter.format(selectedData.SubTotal)}
                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                <div>
                                                                                    <b>Vergiler Toplamı:</b>
                                                                                    ${formatter.format(selectedData.TotalTax)}
                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                <div>
                                                                                    <b>Kargo Toplamı:</b>
                                                                                    ${formatter.format(selectedData.ShippingPrice)}
                                                                                </div>
                                                                              
                                                                            </td>
                                                                            <td>
                                                                                <div>
                                                                                <b>Genel İndirim:</b>
                                                                                ${formatter.format(selectedData.GeneralDiscount)}
                                                                            </td>
                                                                            <td>
                                                                                <div>
                                                                                    <b>Genel Toplam:</b>
                                                                                    ${formatter.format(selectedData.Total)}
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    </table>
                                                                </td>
                                                            </tr>
                                                            <tr></tr>
                                                            <tr>
                                                                <th colspan="7">
                                                                    <div style="font-size:8px;text-align:center">
                                                                        Mazaka Yazılım E-Ticaret Paketleri Aracılığıyla
                                                                        Hazırlanmıştır.
                                                                    </div>
                                                                </th>
                                                            </tr>
                                                        </table>
                                                    </div>`;

            var popupWin = window.open('', '_blank', '');
            popupWin.document.open();
            var printContent = `<html>
                                    <body onload="window.print()">
                                   ${printContents}
                                    </body>
                                    <link href="/dist/css/mazakacore.css"  rel="stylesheet" />
                                    <script src="/dist/js/mazakacore-lib.js" ></script>
                                    <script src="/dist/js/mazakacore-angular-lib.js" ></script>
                                    <script src="/dist/js/mazakacore-app.js" ></script>
                                    <style>
                                        table {
                                            font-size:10px;
                                            table-layout:auto;
                                        }
                                        .dd {
                                            display:inline-block;
                                            font-size:10px;
                                            font-weight:bold;
                                        }
                                    </style>
                                </html>`;


            popupWin.document.write(printContent);
            popupWin.document.close();
        };

        vm.resolvePaymentMethodwithId = function (methodId) {
            return new Promise(resolve => {
                resolve(OrdersService.getPaymentMethodId(methodId));
            });
        }

        vm.resolvePaymentStatuswithId = function (statusId) {
            return new Promise(resolve => {
                resolve(OrdersService.getPaymentStatusId(statusId));
            });
        }

        vm.printOrderDetailsv2 = async function (selectedData) {
            var getPaymentMethodType = await vm.resolvePaymentMethodwithId(selectedData.Payment[0].PaymentMethodId);
            var getPaymentStatus = await vm.resolvePaymentStatuswithId(selectedData.Payment[0].PaymentStatusId);

            var printContents = "";

            var head = `<div class="row">
               <div class="column" >
                <h1>Sipariş</h1>
                <img  src="https://barcode.tec-it.com/barcode.ashx?code=Code128&modulewidth=fit&data=
                        '${(selectedData.CargoReferenceCode === null ? selectedData.ShipmentCode : selectedData.CargoReferenceCode) === null ? " "
                    : (selectedData.CargoReferenceCode === null ? selectedData.ShipmentCode : selectedData.CargoReferenceCode)}'"/>
                    </div >
                    <div class="column">
                        <h1 style="font-weight: normal; font-size: 25px;" align="right">${new Date().toLocaleDateString()}</h1>
                    </div>
                </div >`

            printContents += head;

            var infoRaw = ` 
            <div class="row">
                <div class="column">
                    <h1 style="font-size: 20px;">Teslimat Bilgileri</h1>
                <table>
                <th>İsim Soyisim</th>
                <td>${selectedData.Customer.FullName === null ? "" : selectedData.Customer.FullName}</td>
              </tr>
              <tr>
                <th>Adres</th>
                <td>${selectedData.DeliveryAddress === null ? "" : selectedData.DeliveryAddress.FullAddress}</td>
              </tr>
              <tr>
                <th>Ülke/İl/İlçe</th>
                <td>${(selectedData.DeliveryAddress.Country === null ? 'Türkiye' : selectedData.DeliveryAddress.Country) + '/'
                + (selectedData.DeliveryAddress.StateProvinceName === null ? '' : selectedData.DeliveryAddress.StateProvinceName) + '/'
                + (selectedData.DeliveryAddress.DistrictName === null ? '' : selectedData.DeliveryAddress.DistrictName)}</td>
              </tr>
              <tr>
                <th>Posta Kodu</th>
                <td>${selectedData.DeliveryAddress.ZipPostalCode === null ? "" : selectedData.DeliveryAddress.ZipPostalCode}</td>
              </tr>
              <tr>
                <th>TCKN</th>
                <td>${selectedData.Customer.TcNo === null ? "11111111111" : selectedData.Customer.TcNo}</td>
              </tr>
              <tr>
                <th>Telefon</th>
                <td>${(selectedData.Customer.PhoneNumber === null ? "" : selectedData.Customer.PhoneNumber)}</td>
              </tr>
              <tr>
                <th>E-Posta</th>
                <td>${(selectedData.Customer.Email === null ? "" : selectedData.Customer.Email)}</td>
              </tr>
              <tr>
                <th>Teslimat Kodu</th>
                <td>${(selectedData.Customer.FullName === null ? "" : selectedData.Customer.FullName)}</td>
              </tr>
              <tr>
                <th>Kargo Bilgisi</th>
                <td>${(selectedData.ShipmentProvider.Name === null ? "" : selectedData.ShipmentProvider.Name)}</td>
              </tr>
                </table>
                </div>
                <div class="column">
                    <h1 style="font-size: 20px;">Fatura Bilgileri</h1>
                <table>
              <tr>
                <th>İsim Soyisim</th>
                <td>${selectedData.InvoiceAddress.User === null ? "" : selectedData.InvoiceAddress.User}</td>
              </tr>
              <tr>
                <th>Adres</th>
                <td>${selectedData.InvoiceAddress.FullAddress === null ? "" : selectedData.InvoiceAddress.FullAddress}</td>
              </tr>
              <tr>
                <th>Ülke/İl/İlçe</th>
                <td>${(selectedData.InvoiceAddress.Country === null ? 'Türkiye' : selectedData.InvoiceAddress.Country) + '/'
                + (selectedData.InvoiceAddress.StateProvinceName === null ? "" : selectedData.InvoiceAddress.StateProvinceName) + '/'
                + (selectedData.InvoiceAddress.DistrictName === null ? "" : selectedData.InvoiceAddress.DistrictName)}</td>
              </tr> 
              <tr>
                <th>Posta Kodu</th>
                <td>${selectedData.InvoiceAddress.ZipPostalCode === null ? "" : selectedData.InvoiceAddress.ZipPostalCode}</td>
              </tr>
              <tr>
                <th>Telefon</th>
                <td>${selectedData.InvoiceAddress.Phone1 === null ? selectedData.InvoiceAddress.Phone2 : selectedData.InvoiceAddress.Phone1}</td>
              </tr>
              <tr>
                <th>Fatura Ünvanı</th>
                <td>${selectedData.InvoiceAddress.User === null ? "" : selectedData.InvoiceAddress.User}</td>
              </tr>
              <tr>
                <th>Vergi No</th>
                <td>${(selectedData.InvoiceAddress.TcNo === null ? selectedData.InvoiceAddress.TaxNumber : selectedData.InvoiceAddress.TcNo) === null ? ""
                    : (selectedData.InvoiceAddress.TcNo === null ? selectedData.InvoiceAddress.TaxNumber : selectedData.InvoiceAddress.TcNo)}</td>
              </tr>
              <tr>
                <th style="height: 70px;">Vergi Dairesi</th>
                <td style="height: 70px;">${(selectedData.InvoiceAddress.TaxOffice === null ? "" : selectedData.InvoiceAddress.TaxOffice) === null ? ""
                    : (selectedData.InvoiceAddress.TaxOffice === null ? "" : selectedData.InvoiceAddress.TaxOffice)}</td>
              </tr>
              <tr>
              </tr>
                </table>
                </div>
            </div>            
            `

            printContents += infoRaw;

            var paymentType = `
            
                <p> </p>
                <div>
                    <table>
                    <tr>
                        <th style="text-align: center;">Odeme Tipi</th>
                        <th style="text-align: center;">Banka</th>
                        <th style="text-align: center;">Tutar</th>
                        <th style="text-align: center;">Taksit</th>
                        <th style="text-align: center;">Kart No</th>
                        <th style="text-align: center;">Durum</th>
                    </tr>
                    <tr>
                        <td style="text-align: center;">${getPaymentMethodType.Name === null ? "" : getPaymentMethodType.Name}</td >
                        <td style="text-align: center;">${selectedData.Payment[0].Bank.Name === null ? "" : selectedData.Payment[0].Bank.Name}</td>
                        <td style="text-align: center;">${selectedData.Payment[0].ExpectedTotal === null ? "" : selectedData.Payment[0].ExpectedTotal.toFixed(2)}</td>
                        <td style="text-align: center;">${selectedData.Payment[0].Installment === null ? "" : selectedData.Payment[0].Installment}</td>
                        <td style="text-align: center;">${selectedData.Payment[0].MaskedCreditCard === null ? "" : selectedData.Payment[0].MaskedCreditCard}</td>
                        <td style="text-align: center;">${!(getPaymentStatus.Name === null ? "" : getPaymentStatus.Name) ? "" : (getPaymentStatus.Name === null ? "" : getPaymentStatus.Name)}</td> 
                    </tr>
                    </table>
                </div>
                <p> </p>
            `
            printContents += paymentType;

            var productsHead = `
                <div>
                    <table>
                    <tr>
                        <th style="text-align: center;width: 10%;">Resim</th>
                        <th style="text-align: center;width: 20%;">S.Kodu</th>
                        <th style="text-align: center;width: 20%;">Barkod</th>
                        <th style="text-align: center;width: 25%;">Ürün Adı</th>
                        <th style="text-align: center;width: 5%;">Miktar</th>
                        <th style="text-align: center;width: 10%;">Birim Fiyatı</th>
                        <th style="text-align: center;width: 10%;">Toplam</th>
                    </tr>
                `
            printContents += productsHead

            var productList = "";



            selectedData.OrderProduct.forEach(function (element) {
                productList = productList + `
                    <tr id='pageBr' >
                        <td style="text-align: center;"><img src="${vm.SharedData.Settings.MediaServer + '/' +
                    (element.Product.FirstImage === null ? "no-image.png" : element.Product.FirstImage)}"style="height:60px"/></td>
                        <td style="text-align: center;">${element.Code}</td>
                        <td style="text-align: center;">${element.Product.Barcode === null ? "" : element.Product.Barcode}</td>
                        <td style="text-align: center;">${element.Name}</td>
                        <td style="text-align: center;">${element.Quantity}</td>
                        <td style="text-align: center;">${(element.UnitPriceWithDiscount * element.Currency.Rate).toFixed(2)}</td> 
                        <td style="text-align: center;">${(element.UnitPriceWithDiscount * element.Quantity * element.Currency.Rate).toFixed(2)}</td> 
                    </tr>


                `;
            });
            if (selectedData.OrderProduct.length > 3) {
                var deneme = document.getElementById("pageBr");
                console.log(deneme);
            }

            printContents += productList + `</table></div>`;

            var subTotal = `
                <p> </p>
                <div class="row">
                    <div class="column">
                  <table>
                  <tr>
                    <th>Kapıda Odeme Tutarı</th>
                    <td>${selectedData.ServicePrice.toFixed(2)}</td>
                  </tr>
                  <tr>
                    <th>Kargo Bedeli</th>
                    <td>${selectedData.ShippingPrice.toFixed(2)}</td>
                  </tr>
                  <tr>
                    <th>İndirim Tutarı</th>
                    <td>${selectedData.TotalDiscount.toFixed(2)}</td>
                  </tr>
                  <tr>
                    <th>Diğer İndirimler</th>
                    <td>${selectedData.TotalTaxDiscount.toFixed(2)}</td>
                  </tr>
                    </table>
                    </div>
                    <div class="column">
                    <table>
                  <tr>
                    <th>Ara Toplam</th>
                    <td>${selectedData.SubTotal.toFixed(2)}</td>
                  </tr>
                  <tr>
                    <th>Kdv Toplam</th>
                    <td>${selectedData.TotalTax.toFixed(2)}</td>
                  </tr>
                  <tr>
                    <th>Genel Toplam</th>
                    <td>${selectedData.Total.toFixed(2)}</td>
                  </tr>
                  <tr>
                  </tr>
                    </table>
                    </div>
                </div>
            `;

            printContents += subTotal;

            var popupWin = window.open('', '_blank', '');
            popupWin.document.open();
            var printContent = `

                                <html>
                                    <body onload="window.print()">
                                   ${printContents}
                                    </body>
                                         <style>
                                                *{
                                                  box-sizing: border-box;
                                                }
                                                .row 
                                                {
                                                  margin-left:-5px;
                                                  margin-right:-5px;
                                                } 
                                                .column 
                                                {
                                                  float: left;
                                                  width: 50%;
                                                  padding: 3px;
                                                }
                                                .row::after 
                                                {
                                                  content: "";
                                                  clear: both;
                                                  display: table;
                                                }
                                                table 
                                                {
                                                  font-family: arial, sans-serif;
                                                  border-collapse: collapse;
                                                  width: 100%;
                                                }
                                                td, th 
                                                {
                                                  height: 30px;
                                                  border: 1px solid #000000;
                                                  text-align: left;
                                                  padding: 8px;
                                                }
                                                img 
                                                {
                                                    max-width:40%;
                                                    height: auto;
                                                }
                                                tbody {page-break-after:always;}
                                                
                                                .pageBreak { page-break-after: always; }
                                                
                                    </style>
                                </html>`;


            popupWin.document.write(printContent);
            popupWin.document.close();
        }

        vm.printOrderDetails = async function (selectedData) {

            var getPaymentMethodType = await vm.resolvePaymentMethodwithId(selectedData.Payment[0].PaymentMethodId);
            var getPaymentStatus = await vm.resolvePaymentStatuswithId(selectedData.Payment[0].PaymentStatusId);

            var printContents = "";

            var jsonParse = function (objString) {
                if (typeof objString !== "string") {
                    return ""; 
                }
                var result = JSON.parse(objString);
                if (typeof result === "string") {
                    return vm.jsonParse(result);
                }
                return result;
            };

            var cargoBarcode = "https://barcode.tec-it.com/barcode.ashx?code=Code128&modulewidth=fit&data=";

            //cargoBarcode += !(!selectedData.CargoReferenceCode ? selectedData.ShipmentCode : selectedData.CargoReferenceCode) ? "0" : (!selectedData.CargoReferenceCod ? selectedData.ShipmentCode : selectedData.CargoReferenceCode);

            cargoBarcode += (selectedData.Code === null ? "0" : selectedData.Code);

            var orderdateAndCode = `
                                <div class="row">
                                    <div class="column">
                                        <h1 style="font-size: 16px; font-family: arial, sans-serif;">Sipariş Tarihi/Kodu:</h1>
                                        <h1 style="font-size: 16px; font-family: arial, sans-serif;">${(new Date(Date.parse(selectedData.OrderDate)).toLocaleDateString()) + '/' + (selectedData.Code === null ? "" : selectedData.Code)}</h1>
                                    </div>
                                    <div class="column">
                                      <img src="${cargoBarcode}" 
                                       style="max-height: 75px; float:right;"/>
                                    </div>
                                </div>`

            printContents += orderdateAndCode;

            var paymentArea = `<div>
		                        <table>
			                        <tr>
				                        <th style="text-align: center; width: 300px;">Odeme Tipi</th>
				                        <th style="text-align: center; width: 300px;">Banka</th>
				                        <th style="text-align: center; width: 220px;">Tutar</th>
				                        <th style="text-align: center; width: 220px;">Banka Komisyonu</th>
				                        <th style="text-align: center; width: 220px;">Kargo Bedeli</th>
				                        <th style="text-align: center; width: 220px;">Kapıda Odeme Tutarı</th>
				                        <th style="text-align: center; width: 60px;">Taksit</th>
				                        <th style="text-align: center; width: 300px;">Kart No</th>
				                        <th style="text-align: center; width: 150px;">Durum</th>
			                        </tr>
                                      <tr>
                                          <td style="text-align: left;">${getPaymentMethodType.Name === null ? "" : getPaymentMethodType.Name}</td>
                                          <td style="text-align: left;">${selectedData.Payment[0].Bank.Name === null ? "" : selectedData.Payment[0].Bank.Name}</td>
                                          <td style="text-align: center;">${selectedData.Payment[0].ExpectedTotal === null ? "" : selectedData.Payment[0].ExpectedTotal.toFixed(2)}</td>
                                          <td style="text-align: center;">0.00</td>
                                          <td style="text-align: center;">${selectedData.ShippingPrice.toFixed(2)}</td>
                                          <td style="text-align: center;">${selectedData.ServicePrice.toFixed(2)}</td>
                                          <td style="text-align: center;">${selectedData.Payment[0].Installment === null ? "" : selectedData.Payment[0].Installment}</td>
                                          <td style="text-align: center;">${selectedData.Payment[0].MaskedCreditCard === null ? "" : selectedData.Payment[0].MaskedCreditCard}</td>
                                          <td style="text-align: center;">${!(getPaymentStatus.Name === null ? "" : getPaymentStatus.Name) ? "" : (getPaymentStatus.Name === null ? "" : getPaymentStatus.Name)}</td> 
                                      </tr>
                                      </table>
                                </div>`

            printContents += paymentArea;

            var deliveryArea = `
                        <div class="row">
                            <div class="column">
                              <h1 style="font-size: 14px;">Teslimat Bilgileri</h1>
                            <table>
                          <tr>
                            <th style="height: 15px; width: 200px;">İsim Soyisim</th>
                            <td style="height: 15px; width: 800px">${selectedData.Customer.FullName}</td>
                          </tr>
                          <tr>
                            <th style="height: 45px;">Adres</th>
                            <td style="height: 45px;">${!selectedData.DeliveryAddress ? "" : selectedData.DeliveryAddress.FullAddress}</td>
                          </tr>
                          <tr>
                            <th style="height: 15px;">Ülke/ İl/ İlçe</th>
                            <td style="height: 15px;">${(selectedData.DeliveryAddress.Country === null ? 'Türkiye' : selectedData.DeliveryAddress.Country)
                + (selectedData.DeliveryAddress.StateProvinceName === null ? '' : ( '/' + selectedData.DeliveryAddress.StateProvinceName)) 
                + (selectedData.DeliveryAddress.DistrictName === null ? '' : ( '/' + selectedData.DeliveryAddress.DistrictName))}</td>
                          </tr>
                          <tr>
                            <th style="height: 15px;">TCKN</th>
                            <td style="height: 15px;">${selectedData.DeliveryAddress.TcNo === null ? "1111111111" : selectedData.DeliveryAddress.TcNo}</td>
                          </tr>
                          <tr>
                            <th style="height: 15px;">Telefon</th>
                            <td style="height: 15px;">${(selectedData.Customer.PhoneNumber === null ? "" : selectedData.Customer.PhoneNumber)}</td>
                          </tr>
                          <tr>
                            <th style="height: 15px;">E-Posta</th>
                            <td style="height: 15px;">${(selectedData.Customer.Email === null ? "" : selectedData.Customer.Email)}</td>
                          </tr>
                            </table>
                            </div>
                            <div class="column">
                                <h1 style="font-size: 14px;">Fatura Bilgileri</h1>
                            <table>
                          <tr>
                            <th style="height: 15px;  width: 200px;">Fatura Ünvanı</th>
                            <td style="height: 15px;  width: 800px;">${selectedData.InvoiceAddress.User === null ? "" : selectedData.InvoiceAddress.User}</td>
                          </tr>  
                          <tr>
                            <th style="height: 15px;">İsim Soyisim</th>
                            <td style="height: 15px;">${selectedData.InvoiceAddress.User === null ? "" : selectedData.InvoiceAddress.User}</td>
                          </tr>
                          <tr>
                            <th style="height: 15px;">Vergi No/Dairesi</th>
                            <td style="height: 15px;">${(selectedData.InvoiceAddress.TcNo === null ? selectedData.InvoiceAddress.TaxNumber : selectedData.InvoiceAddress.TcNo) === null ? ""
                    : (selectedData.InvoiceAddress.TcNo === null ? selectedData.InvoiceAddress.TaxNumber : selectedData.InvoiceAddress.TcNo)
                        + '/' + (selectedData.InvoiceAddress.TaxOffice === null ? "" : selectedData.InvoiceAddress.TaxOffice) === null ? ""
                        : (selectedData.InvoiceAddress.TaxOffice === null ? "" : selectedData.InvoiceAddress.TaxOffice)}</td>
                          </tr>
                          <tr>
                            <th style="height: 15px;">Telefon</th>
                            <td style="height: 15px;">${selectedData.InvoiceAddress.Phone1 === null ? selectedData.InvoiceAddress.Phone2 : selectedData.InvoiceAddress.Phone1}</td>
                          </tr>
                          <tr>
                            <th style="height: 45px;">Adres</th>
                            <td style="height: 45px;">${(selectedData.InvoiceAddress.FullAddress === null ? "" : selectedData.InvoiceAddress.FullAddress)
                                            + '/' + (selectedData.InvoiceAddress.ZipPostalCode === null ? "" : selectedData.InvoiceAddress.ZipPostalCode)}</td>
                          </tr>
                          <tr>
                            <th style="height: 15px;">Ülke/ İl/ İlçe</th>
                            <td style="height: 15px;">${(selectedData.InvoiceAddress.Country === null ? 'Türkiye' : selectedData.InvoiceAddress.Country) 
                                + (selectedData.InvoiceAddress.StateProvinceName === null ? "" : ( '/' + selectedData.InvoiceAddress.StateProvinceName))
                                + (selectedData.InvoiceAddress.DistrictName === null ? "" : ( '/' + selectedData.InvoiceAddress.DistrictName))}</td>
                          </tr>
                            </table>
                            </div>
                        </div>
                    <p></p>
                    `

            printContents += deliveryArea;

            var productsHead = `
                <div>
		            <table style="margin-right: -10px; margin-top: 5px;">
                    <tr>
                        <th style="text-align: center; width: 8%;">Resim</th>
                        <th style="text-align: center; width: 15%;">Ürün Kodu</th>
                        <th style="text-align: center; width: 15%;">Barkod</th>
                        <th style="text-align: center; width: 26%;">Ürün Adı</th>
                        <th style="text-align: center; width: 2%;">Miktar</th>
                        <th style="text-align: center; width: 10%;">Birim Fiyatı</th>
                        <th style="text-align: center; width: 6%;">KDV</th>
                        <th style="text-align: center; width: 10%;">Toplam</th>
                    </tr>
                `
            printContents += productsHead

            var productList = "";

            selectedData.OrderProduct.forEach(function (element) {
                var elementVariant = "";

                Object.values(jsonParse(element.Product.ProductVariantString)).forEach(function (value) {
                    elementVariant += value;
                });

                productList = productList + `
                    <tr id='pageBr'>
                        <td style="text-align: left;"><img src="${vm.SharedData.Settings.MediaServer + '/' +
                    (element.Product.FirstImage === null ? "no-image.png" : element.Product.FirstImage)}" style="height:50px"/></td>
                        <td style="text-align: left;">${element.Code}</td>
                        <td style="text-align: left;">${element.Product.Barcode === null ? "" : element.Product.Barcode}</td>
                        <td style="text-align: left;">${element.Name + " " + (!elementVariant ? "" : "(" + elementVariant + ")")}</td>
                        <td style="text-align: center;">${element.Quantity}</td>
                        <td style="text-align: center;">${(element.UnitPriceWithDiscount.toFixed(2)) + "" + element.Currency.Symbol}</td>
                        <td style="text-align: center;">${element.Tax}</td>
                        <td style="text-align: center;">${(element.UnitPriceWithDiscount * element.Quantity * element.Currency.Rate).toFixed(2) + "₺"}</td> 
                    </tr>            

                `;



                //for (const [key, value] of jsonParse(element.VariantValues)) {
                //    console.log(key, value);
                //}
            });

            printContents += productList + `</table></div>`;

            var subTotal = `
            <p> </p>
                    <div class="row">
                        <div class="column">
                        </div>
                        <div class="column">
                        <table class="subtotal">  
                      <tr>
                        <th style="height: 15px;">Hediye Çeki İndirimi</th>
                        <td style="height: 15px;">0,00 </td>
                      </tr>
                      <tr>
                        <th style="height: 15px; width: 70%;">Ara Toplam</th>
                        <td style="height: 15px; width: 30%;">${(selectedData.SubTotal - selectedData.SubTotalDiscount).toFixed(2) + "" + selectedData.Currency.Symbol}</td>
                      </tr>
                      <tr>
                        <th style="height: 15px;">Kdv Toplam</th>
                        <td style="height: 15px;">${(selectedData.TotalTax - selectedData.TotalTaxDiscount).toFixed(2) + "" + selectedData.Currency.Symbol}</td>
                      </tr>
                      <tr>
                        <th style="height: 15px;">Genel Toplam</th>
                        <td style="height: 15px;">${(selectedData.Payment[0].ExpectedTotal).toFixed(2) + "" + selectedData.Currency.Symbol}</td>
                      </tr>
                      <tr>
                        <th style="height: 15px;">Kalan Bakiye</th>
                        <td style="height: 15px;">0,00</td>
                      </tr>
                      <tr>
                      </tr>
                        </table>
                        </div>
                    </div>
            `;

            printContents += subTotal;

            var footer = `  <footer> 
                                <div>
                                  <p style="text-align:center; color:#D3D3D3; font-size: 12px;">Mazaka Yazılım E-Ticaret Sistemleriyle Yazılmıştır</p>
                              </div>   
                            </footer>`;

            printContents += footer;


            var popupWin = window.open('', '_blank', '');
            popupWin.document.open();
            var printContent = `

                                <html>
                                    <body onload="window.print()">
                                   ${printContents}
                                    </body>
                                         <style>
                                                *{
                                                   box-sizing: border-box;
                                                    }
                                                    .row 
                                                    {
                                                      margin-left:-10px;
                                                      margin-right:-10px;
                                                    } 
                                                    .column 
                                                    {
                                                      float: left;
                                                      width: 50%;
                                                      padding: 10px;
                                                    }
                                                    .row::after 
                                                    {
                                                      content: "";
                                                      clear: both;
                                                      display: table;
                                                    }
                                                    table 
                                                    {
                                                      font-family: arial, sans-serif;
                                                      border-collapse: collapse;
                                                      width: 100%;
                                                      font-size: 10px;
                                                    }
                                                    td, th 
                                                    {
                                                      border: 1px solid #000000;
                                                      text-align: left;
                                                      padding: 5px;
                                                    }
                                                    img 
                                                    {
                                                        max-width:100%;
                                                        height: auto;
                                                        width: auto;
                                                    }

                                                    .subtotal{ page-break-inside:avoid; page-break-after:auto; float: right; }

                                                    table { page-break-inside:auto }
                                                    tr    { page-break-inside:avoid; page-break-after:auto }
                                                    thead { display:table-header-group }
                                                    tfoot { display:table-footer-group }
                                                
                                    </style>
                                </html>`;


            popupWin.document.write(printContent);
            popupWin.document.close();
        }



    }
		



		angular
			.module('app.invoices')
			.controller('IncomeModalOrderController', InvoiceModalController);

		InvoiceModalController.$inject = ['$scope', '$stateParams', '$filter', '$location', 'logger', 'InvoiceService', '$sce', 'items'];

		function InvoiceModalController($scope, $stateParams, $filter, $location, logger, InvoiceService, $sce, items) {

			var vm = this;

			vm.jsonAnswer = null;
			selectedData[i] = items;
			vm.content = null;

			activate();
			vm.loading = true;

			function activate() {

				InvoiceService.getPreviewInvoice(vm.order.data).then(function (response) {

					vm.content = $sce.trustAsHtml(response);
					vm.loading = false;

				});
			}

			vm.download = function () {
				InvoiceService.getDownloadInvoice(vm.order.data).then(function (response) {
					window.open("data:application/pdf;base64," + response);
					return data;

				});

			}

		}
		angular
			.module('app.excel')
			.controller('ModalOrderController', ModalOrderController);

		ModalOrderController.$inject = ['$scope', '$stateParams', '$filter', '$location', 'logger', '$uibModal', '$uibModalStack', '$uibModalInstance', 'items', 'SharedData', 'OrdersService'];

		function ModalOrderController($scope, $stateParams, $filter, $location, logger, $uibModal, $uibModalStack, $uibModalInstance, items, SharedData, OrdersService) {
			var vm = this;
			vm.SharedData = SharedData;
			vm.content = items;
			activate();
			function activate() {
				vm.data = vm.content.data;
				vm.modalTitle = "Sipariş Detayı";
			}
			vm.printOrderShipmentCode = function (selectedData) {
				var printContents = "";

				if (selectedData.CargoJobId == null) {
					selectedData.CargoJobId = "";
				}
				if (selectedData.CargoReferenceCode == null) {
					selectedData.CargoReferenceCode = selectedData.ShipmentCode;
				}
				if (selectedData.DeliveryAddress.TcNo != null) {
					selectedData.DeliveryAddress.TcNo = selectedData.DeliveryAddress.TcNo.substring(0, 5) + "******";
				}
				printContents = printContents + `<div id="cargo-print"  style="padding:0px; min-width:148mm;min-height:215mm;">
                                        <table class="table" style="padding:0px; min-width:148mm;min-height:210mm;">
                                            <tr>
                                                <th colspan="6" style="border-top:none;">
                                                    <div>
                                                        <div class="col-xs-6">
                                                            <img class="img-responsive" style= "height:50p"
                                                                 src="${vm.SharedData.Settings.WebServer + '/themes/' + vm.SharedData.Settings.ThemeName + '/images/logo.png'}" />
                                                        </div>
                                                    </div>
                                                </th>
                                            </tr>
                                            <tr>
                                                <td colspan="3" ng-if="vm.SharedData.Settings">
                                                    <h5><b translate="orders.sender-addressInfo">Gönderici Adres Bilgileri</b></h5>
                                                    <address>
                                                        <strong>
                                                            ${vm.SharedData.Settings.CompanyName}
                                                        </strong>
                                                        <p>
                                                            ${vm.SharedData.Settings.CompanyPhone}
                                                        </p>
                                                        <p>
                                                            ${vm.SharedData.Settings.CompanyAddress}
                                                        </p>
                                                        <p>
                                                            ${vm.SharedData.Settings.CompanyDistrict}/${vm.SharedData.Settings.CompanyCity}
                                                        </p>
                                                    </address>
                                                </td>
                                                <td colspan="3" ng-if="vm.order.DeliveryAddressId">
                                                    <h5><b translate="orders.buyer-addressInfo">Alıcı Adres Bilgileri</b></h5>
                                                    <address>
                                                        <strong>
                                                            ${selectedData.DeliveryAddress.Title} - ${selectedData.DeliveryAddress.User}
                                                            ${(selectedData.DeliveryAddress.Name || '') + ' ' + (selectedData.DeliveryAddress.Surname || '')}
                                                        </strong>
                                                        <p>
                                                            ${selectedData.DeliveryAddress.Phone1}
                                                            ${" / " + selectedData.DeliveryAddress.Phone2 ? selectedData.DeliveryAddress.Phone2 : ""}
                                                            ${selectedData.DeliveryAddress.FaxNumber ? " / " + selectedData.DeliveryAddress.FaxNumber : ""}
                                                        </p>
                                                        <p >
                                                            TcNo:${selectedData.DeliveryAddress.TcNo}
                                                        </p>
                                                        <p>
                                                            ${selectedData.DeliveryAddress.Description}
                                                            ${selectedData.DeliveryAddress.ZipPostalCode}
                                                            ${selectedData.DeliveryAddress.ApartmentNo} ${selectedData.DeliveryAddress.Street1}
                                                            ${selectedData.DeliveryAddress.City}${selectedData.DeliveryAddress.CityName}
                                                            ${selectedData.DeliveryAddress.District}${selectedData.DeliveryAddress.DistrictName}
                                                            ${selectedData.DeliveryAddress.StateProvince}${selectedData.DeliveryAddress.StateProvinceName}
                                                            ${selectedData.DeliveryAddress.Country}${selectedData.DeliveryAddress.CountryName}
                                                        </p>
                                                    </address>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="6" style="border-top:none; text-align:center" ng-if="selectedData[i].CargoJobId">
                                         
                                                    <div class="col-xs-12" align="center">
                                                        <img class="img-responsive" style="border-top:none; text-align:center; height:50px;"
                                                             src="https://barcode.tec-it.com/barcode.ashx?code=Code128&modulewidth=fit&data='${selectedData.CargoJobId}'" />
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="6" style="border-top:none; text-align:center">
                                                    <div class="col-xs-12" align="center">
                                                        <img class="img-responsive" style="border-top:none; text-align:center;height:100px;"
                                                             src="https://barcode.tec-it.com/barcode.ashx?code=Code128&modulewidth=fit&data='${selectedData.CargoReferenceCode}'" />
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="6" style="border-top:none; text-align:center">
                                                    <strong>
                                                        <h2>Barkod No:${selectedData.CargoReferenceCode}</h2>
                                                    </strong>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                        </table>
                                    </div>`;


				var popupWin = window.open('', '_blank', '');
				popupWin.document.open();
				var printContent = `<html>
                                    <body onload="window.print()">
                                   ${printContents}
                                    </body>
                                    <link href="/dist/css/mazakacore.css"  rel="stylesheet" />
                                    <script src="/dist/js/mazakacore-lib.js" ></script>
                                    <script src="/dist/js/mazakacore-angular-lib.js" ></script>
                                    <script src="/dist/js/mazakacore-app.js" ></script>
                                    <style>
                                        table {
                                            font-size:10px;
                                            table-layout:auto;
                                        }
                                        .dd {
                                            display:inline-block;
                                            font-size:10px;
                                            font-weight:bold;
                                        }
                                    </style>
                                </html>`;


				popupWin.document.write(printContent);
				popupWin.document.close();
			};

			var formatter = new Intl.NumberFormat('tr-TR', {
				style: 'currency',
				currency: 'TRY',
			});
			vm.resendMail = function (id) {
				if (confirm("Sipariş maili tekrar gönderilecektir onaylıyor musunuz?")) {
					OrdersService.resendStatusMail(id).then(function (data) {

					});
				}
			};

			vm.printOrderDetails = function (selectedData) {

				var printContents = "";
				if (selectedData.CargoJobId == null) {
					selectedData.CargoJobId = "";
				}
				if (selectedData.CargoReferenceCode == null) {
					selectedData.CargoReferenceCode = selectedData.ShipmentCode;
				}
				var tax = "";
				if (selectedData.Customer.TaxNumber || selectedData.Customer.TaxOffice) {
					tax = `<p><span class="dd">Vergi Dairesi / Vergi No:</span>
                               <span ng-if=" selectedData[i].Customer.TaxOffice">${selectedData.Customer.TaxOffice}</span> /
                               <span ng-if=" selectedData[i].Customer.TaxNumber">${selectedData.Customer.TaxNumber}</span>
                           </p>`;
				}
				var transferBank = "";
				if (selectedData.TransferEFTBankId) {
					transferBank = `<span style="color:red">
                                        ${selectedData.TransferEFTBank.Name}
                                    </span>`;
				}
				var currency = "";
				if (selectedData.SalesOrderCurrency.length != 0) {
					var curr = "";
					for (var i = 0; i < selectedData.SalesOrderCurrency.length; i++) {
						curr = curr + `<div class="pull-left" >
                                                               <b>'${curr.Currency.Symbol}</b> - ${formatter.format(selectedData.SalesOrderCurrency.Currency.Rate)}</div>`;
					}
					currency = `<tr>
                                                    <td colspan="7">
                                                        <p>
                                                            <div class="col-md-12">
                                                                <div class="pull-left"><b>Döviz Kurları:</b></div>
                                                                ${curr}
                                                            </div>
                                                        </p>
                                                    </td>
                                                </tr>`;
				}

				var productList = "";
				for (var j = 0; j < selectedData.OrderProduct.length; j++) {
					productList = productList +
						`
                                                    <tr>
                                                                <td>${selectedData.OrderProduct[j].Code}</td>
                                                                <td>${selectedData.OrderProduct[j].Code}</td>
                                                                <td>${selectedData.OrderProduct[j].Name}</td>
                                                                <td>
                                                                    <span>
                                                                            ${formatter.format((selectedData.OrderProduct[j].UnitPrice) * (selectedData.OrderProduct[j].Currency.Rate))}     
                                                                    </span>
                                                                    
                                                                </td>
                                                                <td>${selectedData.OrderProduct[j].Quantity}</td>
                                                                <td>
                                                                    %${selectedData.OrderProduct[j].Tax}KDV
                                                                </td>
                                                                <td>
                                                                        ${formatter.format(selectedData.OrderProduct[j].Quantity * (selectedData.OrderProduct[j].UnitPriceWithDiscount || selectedData.OrderProduct[j].UnitPrice) * (selectedData.OrderProduct[j].TaxIncluded ? 1.00 : ((selectedData.OrderProduct[j].Tax / 100) + 1.00)))}

                                                                </td>
                                                            </tr>
                         `;
				}

				printContents = printContents + `<div id="order-print" >
                                                        <table class="table" style="line-height:2px;table-layout:auto;min-width:148mm;min-height:213mm;">
                                                            <tr>
                                                                <th colspan="7" style="border-top:none;">
                                                                    <div>
                                                                        <div class="col-xs-4">
                                                                            <img class="img-responsive" style="max-height:50px;"
                                                                                 src="${vm.SharedData.Settings.WebServer + '/themes/' + vm.SharedData.Settings.ThemeName + '/images/logo.png'}" />
                                                                        </div>
                                                                        <div class="col-xs-4" align="center">
                                                                            <img 
                                                                                 style="border-top:none; text-align:center;max-height:50px;"
                                                                                 src="https://barcode.tec-it.com/barcode.ashx?code=Code128&modulewidth=fit&data='${selectedData.CargoJobId}'" />
                                                                        </div>
                                                                        <div class="col-xs-4" align="center">
                                                                            <img
                                                                                 style="border-top:none; text-align:center;max-height:50px;"
                                                                                 src="https://barcode.tec-it.com/barcode.ashx?code=Code128&modulewidth=fit&data='${selectedData.CargoReferenceCode}'" />
                                                                           
                                                                        </div>
                                                                    </div>
                                                                </th>
                                                            </tr>
                                                            <tr>
                                                                <td colspan="${selectedData.DeliveryAddressId ? 2 : 2}">
                                                                    <p>
                                                                        <b class="dd">
                                                                            <span translate="orders.detail.orderNumber">Sipariş Numarası : </span>
                                                                            : ${selectedData.Code}
                                                                        </b>&nbsp;
                                                                        <span style="color:red">${selectedData.Bank.Name}</span>
                                                                        ${transferBank}                                                                       
                                                                        &nbsp;
                                                                        ${selectedData.OrderDate}
                                                                    </p>
                                                                    <p>Ad Soyad/Ünvan:${selectedData.Customer.Name} ${selectedData.Customer.Surname}</p>
                                                                    <p>
                                                                        Telefon: ${selectedData.DeliveryAddress.Phone1}
                                                                        ${" / " + selectedData.DeliveryAddress.Phone2 ? selectedData.DeliveryAddress.Phone2 : ""}
                                                                        ${selectedData.DeliveryAddress.FaxNumber ? " / " + selectedData.DeliveryAddress.FaxNumber : ""}
                                                                    </p>
                                                                   ${tax}
                                                                </td>
                                                                <td colspan="2">
                                                                    <p>
                                                                        <b>Adres Başlığı:</b> ${selectedData.DeliveryAddress.Title}
                                                                    </p>
                                                                    <p>
                                                                        Şehir:
                                                                        ${selectedData.DeliveryAddress.StateProvince}${selectedData.DeliveryAddress.StateProvinceName}
                                                                    </p>
                                                                    <p>
                                                                        Adres: ${selectedData.DeliveryAddress.Description}
                                                                        ${selectedData.DeliveryAddress.ZipPostalCode}
                                                                        ${selectedData.DeliveryAddress.ApartmentNo} ${selectedData.DeliveryAddress.Street1}
                                                                        ${selectedData.DeliveryAddress.City}${selectedData.DeliveryAddress.CityName}
                                                                        ${selectedData.DeliveryAddress.District}${selectedData.DeliveryAddress.DistrictName}
                                                                        ${selectedData.DeliveryAddress.StateProvince}${selectedData.DeliveryAddress.StateProvinceName}
                                                                        ${selectedData.DeliveryAddress.Country}${selectedData.DeliveryAddress.CountryName}
                                                                    </p>
                                                                </td>
                                                                <td colspan="2">
                                                                    <p>
                                                                        <b>Sipariş Notu:</b> ${selectedData.Description}
                                                                    </p>
                                                                </td>
                                                            </tr>
                                                           ${currency}
                                                            <tr >
                                                                <th style="width:2%;">#</th>
                                                                <th style="width:10%;" >Ürün Kodu</th>
                                                                <th style="width:30%;" >Ürün Adı</th>
                                                                <th style="width:14%;" >Birim Fiyat</th>
                                                                <th style="width:4%;" >Miktar</th>
                                                                <th style="width:18%;" >Vergi</th>
                                                                <th style="width:22%;" >Toplam Fiyat</th>
                                                            </tr>
                                                            ${productList}
                                                            <tr>
                                                                <td colspan="7">
                                                                </td>
                                                            </tr>
                                                            <style>
                                                                .totalList {
                                                                    font-size: 8px;
                                                                    display: block;
                                                                    float: left;
                                                                    width: 100%;
                                                                }

                                                                    .totalList td {
                                                                        display: block;
                                                                        float: left;
                                                                        width: 20%;
                                                                    }

                                                                        .totalList td div {
                                                                            display: block;
                                                                            float: left;
                                                                            width: 100%;
                                                                        }
                                                            </style>
                                                            <tr>
                                                                <td colspan="7">
                                                                    <table style="line-height:2px;  width:100%; table-layout:auto;">
                                                                        <tr class="totalList">
                                                                            <td>
                                                                                <div>
                                                                                         <b>Ara Toplam:</b>
                                                                                    ${formatter.format(selectedData.SubTotal)}
                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                <div>
                                                                                    <b>Vergiler Toplamı:</b>
                                                                                    ${formatter.format(selectedData.TotalTax)}
                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                <div>
                                                                                    <b>Kargo Toplamı:</b>
                                                                                    ${formatter.format(selectedData.ShippingPrice)}
                                                                                </div>
                                                                              
                                                                            </td>
                                                                            <td>
                                                                                <div>
                                                                                <b>Genel İndirim:</b>
                                                                                ${formatter.format(selectedData.GeneralDiscount)}
                                                                            </td>
                                                                            <td>
                                                                                <div>
                                                                                    <b>Genel Toplam:</b>
                                                                                    ${formatter.format(selectedData.Total)}
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    </table>
                                                                </td>
                                                            </tr>
                                                            <tr></tr>
                                                            <tr>
                                                                <th colspan="7">
                                                                    <div style="font-size:8px;text-align:center">
                                                                        Mazaka Yazılım E-Ticaret Paketleri Aracılığıyla
                                                                        Hazırlanmıştır.
                                                                    </div>
                                                                </th>
                                                            </tr>
                                                        </table>
                                                    </div>`;

				var popupWin = window.open('', '_blank', '');
				popupWin.document.open();
				var printContent = `<html>
                                    <body onload="window.print()">
                                   ${printContents}
                                    </body>
                                    <link href="/dist/css/mazakacore.css"  rel="stylesheet" />
                                    <script src="/dist/js/mazakacore-lib.js" ></script>
                                    <script src="/dist/js/mazakacore-angular-lib.js" ></script>
                                    <script src="/dist/js/mazakacore-app.js" ></script>
                                    <style>
                                        table {
                                            font-size:10px;
                                            table-layout:auto;
                                        }
                                        .dd {
                                            display:inline-block;
                                            font-size:10px;
                                            font-weight:bold;
                                        }
                                    </style>
                                </html>`;


				popupWin.document.write(printContent);
				popupWin.document.close();
			};

			vm.findInvoiceId = function (orderData) {
				var orderProductData = orderData.OrderProduct;
				const result = Object.values(orderProductData).every((value) => value.InvoiceId != null);
				return result;
			}

		}

	}) ();
