(function () {
    'use strict';

    angular
      .module('app.sellers')
      .controller('SellersDetailController', SellersDetailController);

    SellersDetailController.$inject = ['$scope', '$stateParams', '$location', 'SellersService'];

    function SellersDetailController($scope, $stateParams,$location, SellersService) {
        var vm = this;

        vm.title = 'Müşteri Detayı';

        vm.entity = {};

        vm.fields = SellersService.getFields();

        activate();

        function activate() {
            
            SellersService.get($stateParams.Id).then(function (data) {
                vm.entity = data;
            });
        }

        vm.delete = function () {
            if (confirm("Silmek istediğinize emin misiniz?")) {
                SellersService.del(vm.entity).then(function (data) {
                    $location.path('/sellers/list');
                });
            }
        };
    }
})();