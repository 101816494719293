(function () {
	"use strict";

	angular
		.module('app.reporting')
		.factory('SummaryReportService', SummaryReportService);

	SummaryReportService.$inject = ['$http', '$q', 'ngAuthSettings'];

	function SummaryReportService($http, $q, ngAuthSettings) {

		var service = {

			getSummaryReportChart: SummaryReportChart,
			getSummaryReport:SummaryReport
		};


		var serviceBase = ngAuthSettings.apiServiceBaseUri;
		var endpoint = serviceBase + 'api/SalesReports/';

		return service;


		function SummaryReportChart(filterParams) {


			var deferred = $q.defer();
			$http.get(endpoint + 'TransactsReportChart', {
				params: filterParams
			})
				.then(getComplete)
				.catch(getFailed);

			function getComplete(response) {
				deferred.resolve(response.data);
			}

			function getFailed(error) {
				deferred.reject(error);
			}
			return deferred.promise;
		}
		function SummaryReport(filterParams) {


			var deferred = $q.defer();
			$http.get(endpoint + 'TransactsReport', {
				params: filterParams
			})
				.then(getComplete)
				.catch(getFailed);

			function getComplete(response) {
				deferred.resolve(response.data);
			}

			function getFailed(error) {
				deferred.reject(error);
			}
			return deferred.promise;
		}
	
	}
})();