(function () {
    'use strict';

    angular
        .module('app.invoices')
        .run(appRun);

    appRun.$inject = ['routerHelper'];


    function appRun(routerHelper) {
        routerHelper.configureStates(getStates());
    }

    function getStates() {
        return [
            {
                state: 'invoices',
                config: {
                    url: '/invoices',
                    title: 'Faturalar',
                    abstract: true,
                    templateUrl: 'app/invoices/invoices.html'
                }
            },
            {
                state: 'invoices.list',
                config: {
                    templateUrl: 'app/invoices/invoices.list.html',
                    controller: 'InvoiceListController',
                    controllerAs: 'vm',
                    url: '/list',
                    title: 'Giden Faturalar',
                    settings: {
                        nav: 4,
                        name: 'invoices.list',
                        topName: 'invoices',
                        content: 'Faturalar',
                        resource: 'menu.invoices.list'
                    }
                }
            },
            {
                state: 'invoices.incomelist',
                config: {
                    templateUrl: 'app/invoices/invoices.incomelist.html',
                    controller: 'IncomeList2Controller',
                    controllerAs: 'vm',
                    url: '/incomelist',
                    title: 'Gelen Faturalar',
                    settings: {
                        nav: 5,
                        name: 'invoices.incomelist',
                        topName: 'invoices',
                        content: 'Gelen Faturalar',
                        resource: 'menu.invoices.incomelist'
                    }
                }
            }
        ];
    }
})();