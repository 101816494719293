(function () {
    "use strict";

    angular
        .module("app.core")
        .controller("Intro", Intro);

    Intro.$inject = [
        "$location",
        "SharedData"
    ];

    function Intro(
        $location,
        SharedData) {
        /*jshint validthis: true */
        var vm = this;
        vm.SharedData = SharedData;
        if (SharedData.UserInfo) {
            $location.path('/dashboard');
        } else {
            $location.path('/account/loginorsignup');
        }
    }
})();