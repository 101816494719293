(function () {
    'use strict';

    angular.module('app.carts', [
        // Angular modules 
        'ngRoute',
        'ngFileUpload',
        'ui.utils.masks'

        // Custom modules 

        // 3rd Party Modules
        
    ]);
})();