(function () {
    'use strict';

    angular
        .module('app.hbIntegration')
        .controller('ProductListController', ProductListController);

    ProductListController.$inject = ['$location', 'HBIntegrationService', 'NgTableParams', '$uibModal', 'ProductsService'];

    function ProductListController($location, HBIntegrationService, NgTableParams, $uibModal,ProductsService) {
        /* jshint validthis:true */
        var vm = this;
        vm.settings = globalSettings;
        activate();
    
        function activate() {
          
            vm.firstLoad = true;
            vm.tableParams = new NgTableParams({}, { dataset: [] });
            vm.tableParams = new NgTableParams(
                angular.extend({ page: 1, count: 18, sorting: { Name: "asc" } }, {}),
                {
                    getData: function (params) {
                        if (!vm.firstLoad) {
                            $location.search(params.url());
                        }
                        vm.firstLoad = false;
                        var filterData2 = params.filter();
                        var filterData = {};
                        filterData.PageIndex = params.page();
                        filterData.PageSize = params.count();
                        filterData.SortProperty = Object.keys(params.sorting())[0];
                        filterData.SortType = params.sorting()[Object.keys(params.sorting())[0]];
                        //filterData.SelectFields ="Id,Name";
                        var keys = Object.keys(filterData2);
                        for (var i = 0; i < keys.length; i++) {
                            filterData[keys[i]] = filterData2[keys[i]];
                        }
                        return ProductsService.getProducts(filterData).then(function (data) {
                            params.total(data.FilteredCount);
                            return data.Data;
                        });
                    }
                });
            vm.fields = HBIntegrationService.getProductFields();
        }


        vm.transferHepsiburadaProduct = function (entity) {
            vm.loading = true;
            HBIntegrationService.TransferProduct(entity).then(function (data) {
                vm.openModal(data);
                vm.loading = false;
            });
        };

        vm.transferAllHepsiburadaProduct = function () {
            vm.loading = true;
            HBIntegrationService.TransferAllProduct().then(function (data) {
                  vm.openModal(data);
                vm.loading = false;
            });
        };


        // Modal
               
        vm.modalTitle = "Hepsiburada Error";
        vm.modalInstance = null;
        vm.openModal = function (data) {
            vm.modalInstance = $uibModal.open({
                templateUrl: 'ModalContent.html',
                controller: 'ModalController',
                controllerAs: 'vm',
                resolve: {
                    items: function () {
                        return {
                            data: data
                        };
                    }
                }
            });
        }
    }

    angular
        .module('app.excel')
        .controller('ModalController', ModalController);

    ModalController.$inject = ['$scope', '$stateParams', '$filter', '$location', 'logger', 'ExcelService', '$uibModal', '$uibModalStack', '$uibModalInstance', 'items'];

    function ModalController($scope, $stateParams, $filter, $location, logger, ExcelService, $uibModal, $uibModalStack, $uibModalInstance, items) {
        var vm = this;
        vm.content = items;
        activate();
        function activate() {
            vm.data = vm.content.data;
        }
    }
})();