(function () {
    'use strict';

    angular
        .module('app.getProducts')
        .controller('AddXmlServicesController', AddXmlServicesController);

    AddXmlServicesController.$inject = ['$scope', '$stateParams', '$filter', '$location', 'logger', 'GetProductsService', 'NgTableParams', '$uibModal', '$uibModalStack', 'ProductsGeneralService', 'OptionService', 'ProductsService'];

    function AddXmlServicesController($scope, $stateParams, $filter, $location, logger, GetProductsService, NgTableParams, $uibModal, $uibModalStack, ProductsGeneralService, OptionService, ProductsService) {
        var vm = this;
        vm.xml = true;
        activate();

        console.log("asd");
       
        function activate() {
            //  vm.loading = false;
        }


        vm.tableParams = new NgTableParams(
            angular.extend({
                page: 1,
                count: 10,
                sorting: {
                    Name: "asc"
                }
            }, {}), {
            getData: function (params) {
                if (!vm.firstLoad) {
                    $location.search(params.url());
                }
                vm.firstLoad = false;
                var filterData2 = params.filter();
                var filterData = {};
                filterData.PageIndex = params.page(),
                    filterData.PageSize = params.count(),
                    filterData.SortProperty = Object.keys(params.sorting())[0],
                    filterData.SortType = params.sorting()[Object.keys(params.sorting())[0]];
                var keys = Object.keys(filterData2);
                for (var i = 0; i < keys.length; i++) {
                    filterData[keys[i]] = filterData2[keys[i]];
                }
                    filterData.MarketPlace = vm.marketPlace;

                    return GetProductsService.XMLAnalysis(filterData).then(function (data) {

                    params.total(data.FilteredCount);
                    vm.loadingDetails = false;
                    vm.MarketOptionList = data.Data;
                    //vm.OptionTableParams.reload();
                    vm.MarketProductList = data.Data;

                    // console.log("get Data" ,data.Data);
                    return data.Data;
                });
            }
        });



        vm.marketPlaceFields = GetProductsService.getXmlServicesFields();

        vm.StartXmlAnalysis = function () {
            GetProductsService.XMLAnalysis(vm.marketPlace).then(function (data) {
                console.log("data", data);
                vm.xml = false;
                vm.data = data;
                vm.NodeMap = true;
               // vm.Nodelist = data.XmlViewModel;
            });
        };

        vm.NextCategory = function (data) {
            vm.NodeMap = false;
            GetProductsService.XmlDetailSave(data).then(function (data) {
                vm.NodeDetails = true;
                vm.dataDetails = data;
                console.log("ret data ",data);
            });
            console.log("data ", data);

        }


        vm.NextProductUpdate = function (data) {
            vm.NodeDetails = true;
            console.log("son save", data);

            GetProductsService.XmlProductSave(data).then(function (data) {
                $location.path('/getProducts/SupplyList');
            });


        }

        

        vm.cancel = function () {
            console.log("dismiss");
            $uibModalStack.dismissAll();
        }
    }
})();

