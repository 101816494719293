(function () {
    "use strict";

    angular
        .module("app.orders")
        .controller("OrderProductListController", OrderProductListController);

    OrderProductListController.$inject = [
        "$location",
        "OrderProductsService",
        "NgTableParams"
    ];

    function OrderProductListController(
        $location,
        OrderProductsService,
        NgTableParams
    ) {
        var vm = this;

        activate();

        function activate() {
            vm.refresh = function () {
                vm.loading = true;
                vm.tableParams.reload();
                vm.loading = false;
            };

            vm.firstLoad = true;
            vm.tableParams = new NgTableParams(
                angular.extend({
                        page: 1,
                        count: 18,
                        sorting: {
                            "Order.OrderDate": "desc"
                        }
                    },
                    $location.search()
                ), {
                    getData: function (params) {
                        if (!vm.firstLoad) {
                            $location.search(params.url());
                        }
                        vm.firstLoad = false;
                        var filterData2 = params.filter();
                        var filterData = {}; // params.filter();
                        filterData.PageIndex = params.page();
                        filterData.PageSize = params.count();
                        filterData.SortProperty = Object.keys(
                            params.sorting()
                        )[0];
                        filterData.SortType = params.sorting()[
                            Object.keys(params.sorting())[0]
                        ];
                        //filterData.SelectFields =
                        //    "Id,Code,OrderDate,Customer.Name,Customer.Surname,OrderDate,Total,TotalDiscount,Status.Name,Description,Bank.Name";
                        var keys = Object.keys(filterData2);
                        for (var i = 0; i < keys.length; i++) {
                            filterData[keys[i]] = filterData2[keys[i]];
                        }
                        return OrderProductsService.getSalesOrderProducts(filterData).then(
                            function (data) {
                                params.total(data.FilteredCount);
                                return data.Data;
                            }
                        );
                    }
                }
            );
        }

        vm.TotalFilter = {
            TotalMin: {
                id: "ng-table/money.html",
                placeholder: "Minimum Ücret"
            },
            TotalMax: {
                id: "ng-table/money.html",
                placeholder: "Maximum Ücret"
            }
        };
        vm.DateFilter = {
            OrderDateMin: {
                id: "ng-table/date.html",
                placeholder: "Minimum Tarih"
            },
            OrderDateMax: {
                id: "ng-table/date.html",
                placeholder: "Maximum Tarih"
            }
        };

        vm.Status = OrderProductsService.getSalesOrderProductStatus().then(function (response) {
            return response.map(function (item) {
                return {
                    id: item.Id,
                    title: item.Name
                };
            });
        });
    }
})();