(function() {
    "use strict";

    angular
        .module("app.contracts")
        .controller("ContractsAddController", AddController);

    AddController.$inject = ["$location", "$stateParams", "ContractsService"];

    function AddController($location, $stateParams, Service) {
        /* jshint validthis:true */
        var vm = this;

        vm.entity = {};
        vm.fields = Service.getFields();

        vm.add = function() {
            if (vm.form.$valid) {
                vm.loading = true;
                Service.add(vm.entity).then(function (data) {
                    vm.loading = false;
                    $location.path('/contracts/detail/' + data.Id);
                });
            }
        };

        vm.cancel = function() {
            $location.path("/contracts/list");
        };
    }
})();
