(function () {
    'use strict';

    angular
        .module('app.reporting')
        .controller('SalesReportController', SalesReportController);

    SalesReportController.$inject = ['$scope', '$timeout', 'SalesReportService', 'NgTableParams', '$location'];

    function SalesReportController($scope, $timeout, SalesReportService, NgTableParams, $location) {

        var vm = this;

        activate();

        function activate() {

            vm.refresh = function () {
                vm.loading = true;
                vm.tableParams.reload();
                vm.loading = false;
            };

            vm.firstLoad = true;
            vm.tableParams = new NgTableParams(
                angular.extend({ page: 1, count: 10 }, $location.search()),
                {
                    getData: function (params) {
                        if (!vm.firstLoad) {
                            $location.search(params.url());
                        }
                        vm.firstLoad = false;
                        var filterData2 = params.filter();
                        var filterData = {};
                        filterData.PageIndex = params.page();
                        filterData.PageSize = params.count();
                        filterData.ProductName = params.filter().Name;
                        filterData.StartDate = params.filter().OrderDateMin;
                        filterData.DueDate = params.filter().OrderDateMax;
                        filterData.SortProperty = Object.keys(params.sorting())[0];
                        filterData.SortType = params.sorting()[Object.keys(params.sorting())[0]];
                        var keys = Object.keys(filterData2);
                        for (var i = 0; i < keys.length; i++) {
                            filterData[keys[i]] = filterData2[keys[i]];
                        }
                        return SalesReportService.getSummaryReport(filterData).then(function (data) {
                            console.log("report data",data);
                            params.total(data.Result.FilteredCount);
                            return data.Result.Data;
                        });
                    }
                });
            vm.OrderDateFilter = {
                OrderDateMin: { id: 'ng-table/date.html', placeholder: 'Min Tarih' },
                OrderDateMax: { id: 'ng-table/date.html', placeholder: 'Max Tarih' }
            };

            //console.log("başladı");

            //let filters= {
            //"StartDate": "2021-01 - 10",
            //"DueDate": "2021 - 01 - 19",
            //"ProductName": "",
            //"PageIndex": 0,
            //"PageSize": 200}

            //console.log("filters", filters)
            //let xx = SalesReportService.getSummaryReport(filters).then(x => console.log(x.Result.Data))
            

            //console.log("bitti");
        };

    

    }
})();