(function () {
    'use strict';

    angular
        .module('app.cultures')
        .run(appRun);

    appRun.$inject = ['routerHelper'];

    function appRun(routerHelper) {
        routerHelper.configureStates(getStates());
    }

    function getStates() {
        return [
            {
                state: 'cultures',
                config: {
                    url: '/cultures',
                    title: 'Diller',
                    abstract: true,
                    templateUrl: 'app/cultures/cultures.html'
                }
            },
            {
                state: 'cultures.list',
                config: {
                    templateUrl: 'app/cultures/cultures.list.html',
                    controller: 'CulturesController',
                    controllerAs: 'vm',
                    url: '/cultures',
                    title: 'Diller',
                    settings: {
                        nav: 4,
                        name: 'cultures.list',
                        topName: 'system',
                        content: 'Diller',
                        resource: 'menu.cultures'
                    }
                }
            }
        ];
    }
})();